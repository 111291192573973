let stropheConnection = require('./stropheConnection.js');
let utils = require('./utils.js');
let conversationst = require('./conversation.js');
let groups = require('./groups.js');
let recentdesign = require('./recentdesign.js');
let language = require('./locale/language.js');
let audio = require('./audio.js');
let storage = require('./storage.js');
let Push = require('push.js');
let vcard = require("./vCard.js");

module.exports = {
    forwardacknowlege: function (sendfromid, msgid, broadcast_msgid) {
        if ($('#chat-' + msgid).length) {
            $('#chat-' + msgid).find('.msg-status-sign').html('<span class="receiptStatus"> Sent</span>');
        }
        if ($("[id='"+sendfromid+"']").length) {
            $("[id='"+sendfromid+"']").find('.receiptStatus').html('Sent');
        }
    },
    fwdSeenAckDocTitleSet:function(sendfromid,msgid){
        if ($("[id='"+sendfromid+"']").find(".notify").text() != 0) {
            if (document.title != "Ikonix Connect") {
                let title;
                if (document.title.substring(1, 1) != 0) {
                    let count = document.title.substring(1, 1) - 1;
                    count = parseInt(count);
                    if(count == 0){
                        title = "Ikonix Connect"
                    }else {
                        title = "(" + count + ") Ikonix Connect"
                    }
                }
                document.title = title;
            }
        }
        $('#chat-' + msgid).attr('data-status', 2);
        $("[id='"+sendfromid+"']").find('.notify').html(parseInt(0));
        $("[id='"+sendfromid+"']").find('.notify').css("display", "none");
    },
    forwardseenacknowlege: function (sendfromid, msgid, priority, msgAck, toUser) {
        let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
        if (groupInfo != null && typeof groupInfo[toUser] != "undefined") {
            sendfromid = toUser;
        }
        if ($("[id='"+sendfromid+"']").length) {

            module.exports.fwdSeenAckDocTitleSet(sendfromid,msgid)
            
        }
        if(priority == "2" && msgAck == "0" && $("[id='conversation"+toUser+"']").length == 0) {
            module.exports.seenPriorityMessages(toUser);
        }
    },
    fwdmessageBodyContent: function(nodeJid,messageContent,messageid){
        let messageBodyContent;
        if( $("[id='"+nodeJid+"']").find('.priority-message-recent').text() == language.locali().priority_recent) {
            messageBodyContent = utils.checkMessageType(messageContent, messageid, 2);
        } else {
            messageBodyContent = utils.checkMessageType(messageContent, messageid);
        }

        return messageBodyContent;
    },
    fwdMsgGetRStatus: function(rackStatus,status,ackStatus){
        let rStatus;
        if (ackStatus != "") {
            rStatus = status + ', ' + rackStatus;
        } else {
            rStatus = status;
        }

        return rStatus;
    },
    fwdMsgGetRStatusSecond: function(rackStatus,status){
        let rStatus;
        if (rackStatus != "") {
            rStatus = status + ', ' + rackStatus;
        } else {
            rStatus = status;
        }

        return rStatus;
    },
    fwdmessagerackAndrStatusObj: function(chattype,broadcastProfile,divjid,nodeJid,msgAck){
        let broadcastDetail,broadcastMembersCount,rackStatus,rackAndResObj,status,rStatus,ackStatus;
        let gackstatus = '';
        let totalMembers = 0;

        if (chattype == 'chat' && broadcastProfile != null && typeof broadcastProfile[divjid] != "undefined") {
            broadcastDetail = JSON.parse(localStorage.getItem("broadcastDetails"));
            broadcastMembersCount = _.size(broadcastDetail[nodeJid]);
             status = "<span class='receiptStatus'>Del'd <span class='deliveredCount'>0</span> of <span class='totalMembers'>" + broadcastMembersCount + "</span>, Read <span class='readCount'>0</span> of <span class='totalMembers'>" + broadcastMembersCount + "</span></span>";
            if (msgAck == 1) {
                rackStatus = "<span>Ack'd <span class='ackCount'>" + gackstatus + "</span> of <span id='totAckCount'>" + totalMembers + "</span></span>";                        
            } else if (msgAck == 2) {
                rackStatus = "<span>(Acc'd <span class='accCount'>" + gackstatus + "</span>, Rej'd <span class='rejCount'>" + grejstatus + "</span>) of <span id='totAckCount'>" + totalMembers + "</span></span>";
            } else {
                rackStatus = '';
            }
            rStatus = module.exports.fwdMsgGetRStatus(rackStatus,status,ackStatus)
            
        } else {
            status = '<span class="receiptStatus"> Sent</span>';
            if (msgAck == 1) {
                rackStatus = '<span class="ackStatus">Ack Pending</span>';
            } else if (msgAck == 2) {
                rackStatus = '<span class="ackStatus">Acc/Rej Pending</span>';
            } else {
                rackStatus = '';
            }   
            rStatus = module.exports.fwdMsgGetRStatusSecond(rackStatus,status)
        }

        rackAndResObj = {
            rackStatus  :   rackStatus,
            rStatus     :   rStatus
        };

        return rackAndResObj;

    },
    fwdmessageGetReply: function(messageContent,messageid){
        if (typeof messageContent.replyTo != "undefined" && messageContent.replyTo != '') {
            conversationst.getReplyMessage(messageid, messageContent.replyTo, 'chat');
        }
    },
    fwdmessageGetFileUrl: function(messageContent){
        let fileurl;
        if (messageContent.message_type == 'audio' || messageContent.message_type == "video" || messageContent.message_type == "image" || messageContent.message_type == "file") {
            fileurl = '-' + messageContent.media.file_url
        } else {
            fileurl = '';
        }
        return fileurl;  
    },
    fwdmessageGetSenderBoxHtml: function(senderBoxInputObj){
        let senderBox   =   senderBoxInputObj.senderBox,
            msgPriority =   senderBoxInputObj.msgPriority,
            msgactivitycontent  =   senderBoxInputObj.msgactivitycontent,
            msgcontent  =   senderBoxInputObj.msgcontent,
            time    =   senderBoxInputObj.time,
            status  =   senderBoxInputObj.status,
            rackStatus  = senderBoxInputObj.rackStatus;

        if(typeof(msgPriority) != "undefined" && msgPriority == 2) {
            senderBox += '<div class="priority-message">' + language.locali().priority_message + '</div>';
        } else if (typeof(msgPriority) != "undefined" && msgPriority == 0) {
            senderBox += '<div class="low-priority"><img class="low-priority-arrow" src="./src/images/svg/down-arrow-white.svg" alt="low-priority"></div>';
        }
        if(rackStatus != "") {
            senderBox += msgcontent + msgactivitycontent + '<span class="message-time"><span class="msg-sent-time pull-right">' + time + '</span><span class="msg-status-sign">' + status + '</span>, ' + rackStatus + '</span></div></div></div>';
        } else {
            senderBox += msgcontent + msgactivitycontent + '<span class="message-time"><span class="msg-sent-time pull-right">' + time + '</span><span class="msg-status-sign">' + status + '</span>' + rackStatus + '</span></div></div></div>';
        }

        return senderBox;
    },
    fwdmessageCallMsgLayout: function(lastContent,divjid,senderBox,to){
        if (typeof lastContent == "undefined" || lastContent) {                    
            $("[id='conversation"+divjid+"']").append(senderBox);
            let today = utils.todayDate();
            if ($('#' + today).length != 0) {
                $('#' + today).remove();
            }
            if ($("[id='conversation"+divjid+"']").find(".current").length == 0) {
                let messagedate = '<div class="row message-previous current message-date" id = "' + today + '"><div class="col-sm-12 previous"><a>Today</a></div></div>';
                $("[id='conversation"+divjid+"']").prepend(messagedate);
            }
        } else {                    
            conversationst.MessageLayout(to, 'down', '');
            setTimeout(function () {
                $("[id='conversation"+divjid+"']").append(senderBox);
            }, 1000);
        }
    },
    fwdmessageCallMediaDesign: function(messageContent,messageid){
        if (messageContent.message_type == "image" || messageContent.message_type == "video") {
            utils.mediadesign(messageContent.message_type, messageid);
        }
    },
    
    fwdmessageCallChat: function(chattype,broadcastProfile,divjid){
        if (chattype == 'chat') {
            if (broadcastProfile != null && typeof broadcastProfile[divjid] != "undefined") {
                conversationst.getBroadcastChatMedia(utils.toJID(), '');
            } else {
                conversationst.getSingleChatMedia(utils.toJID(), '');
            }
        }else if (chattype == 'normal') {
            conversation.getGroupChatMedia(utils.toJID(), '');
        }
    },
    forwardmessage: function (fwdMsgObj) {
        let from = fwdMsgObj.from;
        let to = fwdMsgObj.to;
        let chattype = fwdMsgObj.chattype;
        let messageid = fwdMsgObj.messageid;
        let sendfrom = fwdMsgObj.sendfrom;
        let msgbody = fwdMsgObj.msgbody;
        let time = fwdMsgObj.time;
        let broadcastmsgid = fwdMsgObj.broadcastmsgid;
        let msgAck = fwdMsgObj.msgAck;
        let msgPriority = fwdMsgObj.msgPriority;

        let divjid = stropheConnection.Strophe().getNodeFromJid(to);
        let userJID = stropheConnection.logininfo.userJID;
        let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
        let rStatus = "";
        let messageContent,messageBodyContent,notifyName,msgcontent;
        if (sendfrom == userJID) {
            messageContent = JSON.parse(utils.decrypt(msgbody, messageid));
            notifyName = messageContent.message_to;
            let nodeJid = stropheConnection.Strophe().getNodeFromJid(to);

            messageBodyContent = module.exports.fwdmessageBodyContent(nodeJid,messageContent,messageid)
           
            msgcontent = messageBodyContent[1];
            let msgtype = messageBodyContent[0];
            let msgactivitycontent = module.exports.chatdropdown(messageBodyContent[2], sendfrom, messageid, chattype, msgPriority);
            let rackStatus,fileurl,senderBox;

            if ($('#chat-' + broadcastmsgid).length == 0) {
                let rackAndrStatusObj = module.exports.fwdmessagerackAndrStatusObj(chattype,broadcastProfile,divjid,nodeJid,msgAck)

                rackStatus  =   rackAndrStatusObj.rackStatus;
                rStatus     =   rackAndrStatusObj.rStatus;

                module.exports.fwdmessageGetReply(messageContent,messageid);
               
                fileurl = module.exports.fwdmessageGetFileUrl(messageContent);
                             
                senderBox = '<div class="row message-body" id="chat-' + broadcastmsgid + '" data-broadcastId = "'+broadcastmsgid+'" data-priority="' + msgPriority+ '" data-ack="' + msgAck+ '"><div class="select-msg-blk"><input type="checkbox" class="select-message" value="' + broadcastmsgid + '-' + messageBodyContent[2] + fileurl + '"><label></label></div> <div class="col-sm-12 message-main-sender msg-continue" ><span class="tail-sender tail-msg-continue"></span><div class="sender">';

                let senderBoxInputObj = {
                    senderBox   :   senderBox,
                    msgPriority :   msgPriority,
                    msgactivitycontent  :   msgactivitycontent,
                    msgcontent  :   msgcontent,
                    time    :   time,
                    status  :   rStatus,
                    rackStatus  : rackStatus
                }
                senderBox = module.exports.fwdmessageGetSenderBoxHtml(senderBoxInputObj);
               let lastContent = true;
                module.exports.fwdmessageCallMsgLayout(lastContent,divjid,senderBox,to);

                module.exports.fwdmessageCallMediaDesign(messageContent,messageid);

                module.exports.fwdmessageCallChat(chattype,broadcastProfile,divjid);
                
                conversationst.mediaviewClick();
                utils.scrollbottom("[id='conversation"+divjid+"']");
            }
            let updateChatObj = {
                from         :from,
                nodeJid      :nodeJid,
                msgtype      :msgtype,
                status       :rStatus,
                messageid    :messageid,
                unReadCount  :0,
                n            :time,
                tstatusImage :"",
                notifierName :notifyName
              }
              
            recentdesign.Updaterecent(updateChatObj);
        }
        return true;
    },
    receivedmessage: function (recMsgObj) {
        let from = recMsgObj.from;
        let typechat = recMsgObj.typechat;
        let msgid = recMsgObj.msgid;
        let sendfrom = recMsgObj.sendfrom;
        let msgbody = recMsgObj.msgbody;
        let time = recMsgObj.time;
        let forward = recMsgObj.forward;
        let msgAck = recMsgObj.msgAck;
        let msgPriority = recMsgObj.msgPriority;
        let broadcastid = recMsgObj.broadcastid;
        let broadcast_msgid = recMsgObj.broadcast_msgid;
        let recentStatus = "";
        let divjid = stropheConnection.Strophe().getNodeFromJid(from);
        let userJID = stropheConnection.logininfo.userJID;
        let statusTo,messageid,displayName,messageContent,nodeJid,messageBodyContent,msgcontent,msgtype,chattype,msgactivitycontent,fileurl,userInfo;
        let unReadCount = -1;
        $('.select-messages').show();
        $('.contact-clearmsg').show();
        let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
        let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
        if (typechat != 'bot') {
            messageContent = JSON.parse(utils.decrypt(msgbody, msgid));
            nodeJid = stropheConnection.Strophe().getNodeFromJid(from);
            if( $("[id='"+nodeJid+"']").find('.priority-message-recent').text() == language.locali().priority_recent || (msgPriority == 2 && msgAck != 0 && stropheConnection.Strophe().getNodeFromJid(sendfrom) != userJID) || (msgPriority == 2 && msgAck == 0 && stropheConnection.Strophe().getNodeFromJid(sendfrom) != userJID && $("#conversation"+nodeJid+":visible").length
            == 0)) {
                messageBodyContent = utils.checkMessageType(messageContent, msgid, 2);
            } else {
                messageBodyContent = utils.checkMessageType(messageContent, msgid);
            }            
            msgcontent = messageBodyContent[1];
            msgtype = messageBodyContent[0];
            chattype = messageBodyContent[2];
        } else {
            messageContent = {};
            messageContent.message_type = "text";
            messageContent.message = msgbody;
            messageBodyContent = utils.checkMessageType(messageContent, msgid, msgPriority);
            msgcontent = messageBodyContent[1];
            msgtype = messageBodyContent[0];
            chattype = messageBodyContent[2];
            nodeJid = from;
            if (typeof messageContent.replyTo != "undefined" && messageContent.replyTo != '') {
                conversationst.getReplyMessage(msgid, messageContent.replyTo, 'chat');
            }
        }
        if (chattype == 'audio' || chattype == "video" || chattype == "image" || chattype == "file") {
            fileurl = '-' + messageContent.media.file_url
        } else {
            fileurl = '';
        }
        let notifyJid = nodeJid;
        userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
        let fromJid = stropheConnection.Strophe().getBareJidFromJid(from);

        if (typechat != 'bot') {
            if (groupInfo != null && typeof groupInfo[nodeJid] != "undefined") {
                statusTo = stropheConnection.Strophe().getBareJidFromJid(sendfrom);
            } else {
                statusTo = stropheConnection.Strophe().getBareJidFromJid(fromJid);
            }
            let randomValue = utils.cryptoRandomGenerate();
            
            messageid = randomValue.toString(36).replace(/[^a-z]+/g, '').substring(0, 9);
            groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
            userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
            if (sendfrom != userJID && forward) {
                if (userInfo != null && (typeof userInfo[nodeJid] != "undefined" || ( groupInfo != null && typeof groupInfo[nodeJid] == "undefined") ) ) { //new userJid
                    let ackmessage = stropheConnection.StropheJs().$msg({
                        to: statusTo,
                        type: 'chat',
                        id: messageid
                    }).c('received', {
                        xmlns: 'urn:xmpp:receipts',
                        id: msgid,
                        group_id:broadcastid,
                        broadcastmsg_id:broadcast_msgid
                    });
                    if (stropheConnection.Connection().connected) {
                        stropheConnection.Connection().sendIQ(ackmessage);
                    }
                } else if (groupInfo != null && typeof groupInfo[nodeJid] != "undefined") {
                    let ackGrmessage = stropheConnection.StropheJs().$msg({
                        to: sendfrom  + "@" + stropheConnection.Server(),
                        type: 'chat',
                        id: messageid

                    }).c('received', {
                        xmlns: 'urn:xmpp:receipts',
                        group_id: nodeJid + "@mix." + stropheConnection.Server(),
                        message_priority: msgPriority,
                        id: msgid
                    });
                 
                    if (stropheConnection.Connection().connected) {
                         stropheConnection.Connection().sendIQ(ackGrmessage);
                        $('#chat-' + msgid).attr('data-status', 2);
                    }
                }
            }
        }
        
        let rStatus = "";
        let tstatusImage = "";
        let msgfrom = sendfrom;
        displayName = "";
        let notifyName = nodeJid;
        let groupsDetail,groupMembersCount,messageConvFrom,receiptStatus,rtStatus,atStatus,rackStatus,messagebody,ackStatus;
        if (groupInfo != null && typeof groupInfo[nodeJid] != "undefined") {
            groupsDetail = JSON.parse(localStorage.getItem("groupDetails"));
            groupMembersCount = _.size(groupsDetail[nodeJid]) - 1;
            try {
                messageConvFrom = messageContent.message_from;
                if (messageConvFrom == 'Switchboard' && sendfrom == '123456789'){
                    groupMembersCount = groupMembersCount + 1;
                }
            } catch (err) {

            }
            receiptStatus = "<span class='receiptStatus'>Del'd <span class='deliveredCount'>0</span> of <span class='totalMembers'>" + groupMembersCount + "</span>, Read <span class='readCount'>0</span> of <span class='totalMembers'>" + groupMembersCount + "</span></span>";
            rtStatus = '<span class="deliverRecent">Delivered <span class="deliverCountRecent">0</span> of <span class="totalMemRecent">' + groupMembersCount + '</span>,</span><span class="readRecent">Read <span class="readCountRecent">0</span> of <span class="totalMemRecent">' + groupMembersCount + '</span>,</span>';
            if (msgAck == 1) {
                ackStatus = "<span class='ackStatus'>Ack'd <span class='ackCount'>0</span> of <span class='totalMembers'>" + groupMembersCount + "</span></span>";
                rackStatus = "<span class='ackStatus'>Ack'd <span class='acknowledgeCount'>0</span> of <span class='totalMembers'>" + groupMembersCount + "</span></span>";
                atStatus = '<span class="acknowledgeRecent">Acknowledged <span class="acknowledgeCountRecent">0</span> of <span class="totalMemRecent">' + groupMembersCount + '</span></span>';
            } else if (msgAck == 2) {
                ackStatus = "<span class='ackStatus'>(Acc'd <span class='accCount'>0</span>, Rej'd <span class='rejCount'>0</span>) of <span class='totalMembers'>" + groupMembersCount + "</span></span>";
                rackStatus = "<span class='ackStatus'>(Acc'd <span class='acceptCount'>0</span>, Rej'd <span class='rejectCount'>0</span>) of <span class='totalMembers'>" + groupMembersCount + "</span></span>";
                atStatus = '<span class="acceptRecent">Accepted <span class="acceptCountRecent">0</span> of <span class="totalMemRecent">' + groupMembersCount + '</span>,</span><span class="rejectRecent">Rejected <span class="rejectCountRecent">0</span> of <span class="totalMemRecent">' + groupMembersCount + '</span></span>';
            } else {
                ackStatus = "";
                rackStatus = receiptStatus
                atStatus = '';
            }
            if (ackStatus == "") {
               
                if($('#chat-'+msgid)[0]){
                    if (sendfrom == userJID) {
                        rStatus = receiptStatus;
                    }else {
                        rStatus = '';
                    }
                    recentStatus = rackStatus;
                }else {
                    
                    if (sendfrom == userJID) {
                        rStatus = receiptStatus;
                        recentStatus = rackStatus;

                    }else {
                        rStatus = '';
                        recentStatus = '';
                    }
                }
               
                tstatusImage = rtStatus;
            } else {
                if($('#chat-'+msgid)[0]){
                    rStatus = receiptStatus + ', ' + ackStatus;
                    recentStatus = receiptStatus + ', ' + rackStatus;
                    
                }else {
                    if (sendfrom == userJID) {
                        rStatus = receiptStatus + ', ' + ackStatus;
                        recentStatus = receiptStatus + ', ' + rackStatus;
                    }else {
                        rStatus =  ackStatus;
                        recentStatus = rackStatus;
                    }
                    
                }
                tstatusImage = rtStatus + atStatus;
            }

            if (userInfo != null && typeof userInfo[msgfrom] != "undefined") {
                displayName = userInfo[msgfrom][1];
            } else {
                displayName = messageContent.message_from;
            }

            notifyName = groupInfo[nodeJid][1] + ":" + displayName;
            conversationst.getGroupChatMedia(fromJid, 3);
            if (typeof messageContent.replyTo != "undefined" && messageContent.replyTo != '') {
                conversationst.getReplyMessage(msgid, messageContent.replyTo, 'normal');
            }
            $("[id='"+nodeJid+"']").find(".list-msg-status").html(tstatusImage);
        } else if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
            notifyName = userInfo[nodeJid][1];
            conversationst.getSingleChatMedia(fromJid, '');
            if (typeof messageContent.replyTo != "undefined" && messageContent.replyTo != '') {
                conversationst.getReplyMessage(msgid, messageContent.replyTo, 'chat');
            }
            if (msgAck == 1) {
                  ackStatus = "<span class='ackStatus'>Ack Pending</span>";
              } else if (msgAck == 2) {
                  ackStatus = "<span class='ackStatus'>Acc/Rej Pending</span>";
              } else {
                ackStatus = "";
              }
              
              recentStatus = (ackStatus != '') ? ackStatus : '';
        } else if (broadcastProfile != null && typeof broadcastProfile[nodeJid] != "undefined") {
            let broadcastDetail = JSON.parse(localStorage.getItem("broadcastDetails"));
            let broadcastMembersCount = _.size(broadcastDetail[nodeJid]);
            receiptStatus = "<span class='receiptStatus'>Del'd <span class='deliveredCount'>0</span> of <span class='totalMembers'>" + broadcastMembersCount + "</span>, Read <span class='readCount'>0</span> of <span class='totalMembers'>" + broadcastMembersCount + "</span></span>";
            if (msgAck == 1) {
                ackStatus = "<span class='ackStatus'>Ack <span class='acknowledgeCount'>0</span> of <span class='totalMembers'>" + broadcastMembersCount + "</span></span>";
            } else if (msgAck == 2) {
                ackStatus = "<span class='ackStatus'>(Acc'd <span class='acceptCount'>0</span>, Rej'd <span class='rejectCount'>0</span>) of <span class='totalMembers'>" + broadcastMembersCount + "</span></span>";
            } else {
                ackStatus = "";
            }
            if (ackStatus == "") {
                rStatus = receiptStatus;
            } else {
                rStatus = receiptStatus + ', ' + ackStatus;
            }
            conversationst.getBroadcastChatMedia(fromJid, '');
            if (typeof messageContent.replyTo != "undefined" && messageContent.replyTo != '') {
                conversationst.getReplyMessage(msgid, messageContent.replyTo, 'chat');
            }
        } else {
            notifyName = messageContent.message_from;
            conversationst.getSingleChatMedia(fromJid, '');
            if (typeof messageContent.replyTo != "undefined" && messageContent.replyTo != '') {
                conversationst.getReplyMessage(msgid, messageContent.replyTo, 'chat');
            }
            vcard.getVcard(fromJid);
        }
        let usersPriorityInfo = JSON.parse(localStorage.getItem("usersPriorityInfo"));
        if(msgfrom != userJID && typeof(msgPriority) != "undefined" && msgPriority == 2 && msgAck != 0) {
            if(usersPriorityInfo != null && typeof(usersPriorityInfo[nodeJid]) != "undefined") {
                let priorityCount = usersPriorityInfo[nodeJid][1];
                let newCount = parseInt(priorityCount) + 1;
                usersPriorityInfo[nodeJid][1] = newCount;
                $("[id='conversation"+nodeJid+"']").find(".priority-message-conversation").html("PRIORITY: You have <span class='priority-count'>" + newCount + "</span> Priority Messages")
            } else {
                usersPriorityInfo[nodeJid] = [];
                usersPriorityInfo[nodeJid][0] = 0;
                usersPriorityInfo[nodeJid][1] = 1;
                $("[id='conversation"+nodeJid+"']").prepend("<div class='priority-message-conversation'> PRIORITY: You have a Priority Message </div>");
            }
            localStorage.setItem("usersPriorityInfo", JSON.stringify(usersPriorityInfo));            
            if($("[id='conversation"+nodeJid+"']").is(":visible")) {
                let priorityMessages = JSON.parse(localStorage.getItem("priorityMessages"));
                priorityMessages.unshift(msgid);
                localStorage.setItem("priorityMessages", JSON.stringify(priorityMessages));
            }
        } else if (msgfrom != userJID && typeof(msgPriority) != "undefined" && msgPriority == 2 && msgAck == 0 && $("[id='conversation"+divjid+"']").length == 0) {            
            if(usersPriorityInfo != null && typeof(usersPriorityInfo[nodeJid]) != "undefined") {
                let priorityCount = usersPriorityInfo[nodeJid][0];
                let newCount = parseInt(priorityCount) + 1;
                usersPriorityInfo[nodeJid][0] = newCount;
            } else {
                usersPriorityInfo[nodeJid] = [];
                usersPriorityInfo[nodeJid][0] = 1;
                usersPriorityInfo[nodeJid][1] = 0;
            }
            localStorage.setItem("usersPriorityInfo", JSON.stringify(usersPriorityInfo));
        }
        if ($("[id='conversation"+divjid+"']").length != 0) {
            if ($("[id='conversation"+divjid+"']").find('.current').length == 0) {
                let messagebody = '<div class="row message-previous current"><div class="col-sm-12 previous"><a>Today</a></div></div>';
                $("[id='conversation"+divjid+"']").append(messagebody);
            }
            msgactivitycontent = module.exports.chatdropdown(messageBodyContent[2], stropheConnection.Strophe().getNodeFromJid(sendfrom), msgid, typechat, msgPriority);
            let senderBox;
            if (msgfrom == userJID) {                
                senderBox = '<div class="row message-body" id="chat-' + msgid + '" data-broadcastmsgid ="'+broadcast_msgid+'" data-fromuser="'+messageContent.message_from+'" data-priority="' + msgPriority+ '" data-ack="' + msgAck+ '" ><div class="select-msg-blk"><input type="checkbox" class="select-message" value="' + msgid + '-' + messageBodyContent[2] + fileurl + '"><label></label></div> <div class="col-sm-12 message-main-sender msg-continue" ><span class="tail-sender tail-msg-continue"></span><div class="sender" >';
                if(typeof(msgPriority) != "undefined" && msgPriority == 2) {
                    senderBox += '<div class="priority-message">' + language.locali().priority_message + '</div>';
                } else if (typeof(msgPriority) != "undefined" && msgPriority == 0) {
                    senderBox += '<div class="low-priority"><img class="low-priority-arrow rollicon" src="./src/images/svg/down-arrow-white.svg" alt="low-priority"></div>';
                }                
                senderBox += msgcontent + msgactivitycontent + '<span class="message-time"><span class="msg-sent-time pull-right">' + time + '</span><span class="msg-status-sign">' + rStatus + '</span></span></div></div></div>';
            } else {
                tstatusImage = "";
                let ackDiv;
                if (msgAck == 1) {
                    ackDiv = '<div class="btn-merge"><span id="' + sendfrom + '" class="acknowledge ackClick">Tap to Acknowledge <i class="acknow"></i></span></div>';
                } else if (msgAck == 2) {
                    ackDiv = '<div class="btn-merge"><span id="' + sendfrom + '" class="acknow-accept"><span>Accept</span><i><img src="./src/images/Accept_ic.svg" alt="accept"/></i></span><span id="' + sendfrom + '" class="acknow-reject"><span>Reject</span><i><img src="./src/images/Reject_ic.svg" alt="accept"/></i></span></div>';
                } else {
                    ackDiv = '';
                }
                let msgPriorityClass = "";
                if(typeof(msgPriority) != "undefined" && msgPriority == 2) {
                    msgPriorityClass = "priority-message-main";
                }

                let ChatAudioClass = '';

                senderBox = '<div class="row message-body" id="chat-' + msgid + '" data-broadcastmsgid ="'+broadcast_msgid+'" data-status="1" data-fromuser="'+messageContent.message_from+'" data-priority="' + msgPriority+ '" data-ack="' + msgAck+ '" data-msgfrom="'+ sendfrom +'"><div class="select-msg-blk"><input type="checkbox" class="select-message" value="' + msgid + '-' + messageBodyContent[2] + fileurl + '"><label></label></div> <div class="col-sm-12 message-main-receiver msg-continue"><span class="tail-receiver tail-msg-continue"></span><div class="receiver ' + messageBodyContent[2] + '-attachment' + ' '+ChatAudioClass+'  '+msgPriorityClass+'" >';
                if(typeof(msgPriority) != "undefined" && msgPriority == 2) {
                    senderBox += '<div class="priority-message">' + language.locali().priority_message + '</div>';
                } else if (typeof(msgPriority) != "undefined" && msgPriority == 0) {
                    senderBox += '<div class="low-priority"><img class="low-priority-arrow" src="./src/images/svg/down-arrow-blue.svg" alt="low-priority"></div>';
                }
                senderBox += '<a class="receivefrom">' + displayName + '</a>' + msgcontent + msgactivitycontent + '<span class="message-time pull-right"><span class="msg-sent-time"> <span class="receive-msg-status-sign" style="padding: 5px">' + rStatus +'</span>' + time + '</span></span>' + ackDiv + '</div></div></div>';
            }
            if ($('#chat-' + msgid).length == 0) {
                $( "[id='conversation"+divjid+"']").append(senderBox);
                if (msgfrom == userJID) {
                    utils.unstarmsg(msgid);
                }
            }
            if (chattype == "image" || chattype == "video") {
                utils.mediadesign(chattype, msgid);
            }
            if (chattype == 'audio') {
                $(document).ready(function () {
                    $(document).unbind().on("click", ".pButton", function() {
                        nodeJid = $(this).attr('id');
                        let result = nodeJid.split("-");
                        audio.audioDesign(result[0]);
                    });
                })
                
            }
        }
        if (nodeJid == stropheConnection.Strophe().getNodeFromJid(utils.toJID())) {
            if (sendfrom != userJID) {
                if (userInfo != null && typeof userInfo[nodeJid] != "undefined") { 
                    if(!document.hidden){ //Check Current tab Visiblity
                        let randomValue = utils.cryptoRandomGenerate();
                        let seenid = randomValue.toString(36).replace(/[^a-z]+/g, '').substring(0, 9);
                        let seenmessage = stropheConnection.StropheJs().$msg({
                            to: statusTo,
                            type: 'chat',
                            id: seenid
                        }).c('seen', {
                            xmlns: 'urn:xmpp:receipts',
                            id: msgid,
                            message_priority: msgPriority,
                            message_ack: msgAck,
                            to_user: nodeJid,
                            broadcastmsg_id:broadcast_msgid
                        });
                        if (stropheConnection.Connection().connected) {
                            stropheConnection.Connection().sendIQ(seenmessage);
                            $('#chat-' + msgid).attr('data-status', 2);
                        }
                    }else {
                        /** Unread message Notification Count add **/
                        let notifyCount = parseInt($("[id='"+nodeJid+"']").find('.notify').text()) + 1;
                        $("[id='"+nodeJid+"']").find('.notify').text(notifyCount);
                        $("[id='"+nodeJid+"']").find('.notify').css("display", "flex");

                        if (document.title != "Ikonix Connect") {
                            if (document.title.substring(1, 1) != 0) {
                              let notifyCount = $('#recentcontent .notify:visible').length;
                              let count = notifyCount;
                              count = parseInt(count);
                              document.title = "(" + count + ") Ikonix Connect";
                            }
                        } else {
                             document.title = "(" + 1 + ") Ikonix Connect";
                        }

                        if(broadcast_msgid != null && broadcast_msgid != ''){
                            let broadcastSeenPendingMsgData = JSON.parse(localStorage.getItem("broadcastSeenPendingMsgData"));
                        
                            if(!(broadcastSeenPendingMsgData != null && typeof broadcastSeenPendingMsgData != 'undefined' && broadcastSeenPendingMsgData.length > 0)){
                                broadcastSeenPendingMsgData = [];
                            }
                            let randomValue = utils.cryptoRandomGenerate();
    
                            let seenid = randomValue.toString(36).replace(/[^a-z]+/g, '').substring(0, 9);
                            let msgData = {};
                            msgData.to = sendfrom  + "@" + stropheConnection.Server();
                            msgData.type = 'chat';
                            msgData.msgid = msgid;
                            msgData.msgPriority = msgPriority;
                            msgData.msgAck = msgAck;
                            msgData.nodeJid = nodeJid;
                            msgData.broadcast_msgid = broadcast_msgid;
                            msgData.seenid = seenid;
    
                            broadcastSeenPendingMsgData.push(msgData)
                           
                            /** store message iq in local storage **/
                            localStorage.setItem("broadcastSeenPendingMsgData", JSON.stringify(broadcastSeenPendingMsgData));
                        }else {
                            let chatSeenPendingMsgData = {};
                            let randomValue = utils.cryptoRandomGenerate();
    
                            let seenid = randomValue.toString(36).replace(/[^a-z]+/g, '').substring(0, 9);
    
                            chatSeenPendingMsgData.statusTo = statusTo;
                            chatSeenPendingMsgData.type = 'chat';
                            chatSeenPendingMsgData.seenid = seenid;
                            chatSeenPendingMsgData.msgid = msgid;
                            chatSeenPendingMsgData.msgPriority = msgPriority;
                            chatSeenPendingMsgData.msgAck = msgAck;
                            chatSeenPendingMsgData.nodeJid = nodeJid;
                            /** store message iq in local storage **/
                            localStorage.setItem("chatSeenPendingMsgData", JSON.stringify(chatSeenPendingMsgData));
    
                        }
                    }
                } else if (groupInfo != null && typeof groupInfo[nodeJid] != "undefined") {
                    if(!document.hidden){ //Check Current tab Visiblity
                        let seenmessage = stropheConnection.StropheJs().$msg({
                            to: sendfrom  + "@" + stropheConnection.Server(),
                            type: 'chat',
                            id: messageid

                        }).c('seen', {
                            xmlns: 'urn:xmpp:receipts',
                            group_id: nodeJid + "@mix." + stropheConnection.Server(),
                            message_priority: msgPriority,
                            message_ack: msgAck,
                            id: msgid,
                        });
                    
                        if (stropheConnection.Connection().connected) {
                            stropheConnection.Connection().sendIQ(seenmessage);
                            $('#chat-' + msgid).attr('data-status', 2);
                        }
                    }else {
                        /** Unread message Notification Count add **/
                        let notifyCount = parseInt($("[id='"+nodeJid+"']").find('.notify').text()) + 1;
                        $("[id='"+nodeJid+"']").find('.notify').text(notifyCount);
                        $("[id='"+nodeJid+"']").find('.notify').css("display", "flex");

                        if (document.title != "Ikonix Connect") {
                            if (document.title.substring(1, 1) != 0) {
                              let notifyCount = $('#recentcontent .notify:visible').length;
                              let count = notifyCount;
                              count = parseInt(count);
                              document.title = "(" + count + ") Ikonix Connect";
                            }
                        } else {
                             document.title = "(" + 1 + ") Ikonix Connect";
                        }
                        
                        let groupSeenPendingMsgData = JSON.parse(localStorage.getItem("groupSeenPendingMsgData"));
                        
                        if(!(groupSeenPendingMsgData != null && typeof groupSeenPendingMsgData != 'undefined' && groupSeenPendingMsgData.length > 0)){
                            groupSeenPendingMsgData = [];
                        }
                        
                        let msgData = {};
                        msgData.to = sendfrom  + "@" + stropheConnection.Server();
                        msgData.type = 'chat';
                        msgData.messageid = messageid;
                        msgData.group_id = nodeJid + "@mix." + stropheConnection.Server();
                        msgData.msgPriority = msgPriority;
                        msgData.msgAck = msgAck;
                        msgData.nodeJid = msgid;

                        groupSeenPendingMsgData.push(msgData)
                       
                        /** store message iq in local storage **/
                        localStorage.setItem("groupSeenPendingMsgData", JSON.stringify(groupSeenPendingMsgData));
                    }
                }
            }
            if (storage.getbrowserStatus()) {
                if (messageBodyContent[2] == "text" || messageBodyContent[2] == "auto_text") {
                    let str = messageBodyContent[0];
                    str = str.replace("<i class='text-italic name-status'>", "");
                    str = str.replace("<span class='priority-message-recent'>" + language.locali().priority_recent + "</span>", "");
                    messagebody = str.replace("</i>", "");
                } else {
                    messagebody = messageBodyContent[2];
                }
                let  myInfo = JSON.parse(localStorage.getItem("myStatus"));
                let myStatus = typeof myInfo !== 'undefined'?myInfo:1;
                if (msgfrom != userJID && myStatus !=0) {
                    Push.clear();
                    let msgTone;
                    if(msgPriority == 2) {
                        msgTone = document.getElementById('high-priority-message-tone');
                        msgTone.play();
                    } else if(msgPriority == 0) {
                        msgTone = document.getElementById('low-priority-message-tone');
                        msgTone.play();
                    } else {
                        msgTone = document.getElementById('message-tone');
                        msgTone.play();
                    }
                    /** Call Notification Function**/
                    utils.commonNotificationMsgAlert(notifyName,messagebody,notifyJid);
                    
                }
            }
            utils.scrollbottom("[id='conversation"+divjid+"']");
            conversationst.mediaviewClick();
        } else {
            if (messageBodyContent[2] == "text" || messageBodyContent[2] == "auto_text") {
                let str = messageBodyContent[0];
                str = str.replace("<i class='text-italic name-status'>", "");
                str = str.replace("<span class='priority-message-recent'>" + language.locali().priority_recent + "</span>", "");
                messagebody = str.replace("</i>", "");
            } else {
                messagebody = messageBodyContent[2];
            }
            let  myInfo = JSON.parse(localStorage.getItem("myStatus"));
            let myStatus = typeof myInfo !== 'undefined'?myInfo:1;
            if (msgfrom != userJID && myStatus !=0) {
                /** Call Notification Function**/
                if(broadcastProfile != null && broadcastProfile[stropheConnection.Strophe().getNodeFromJid(utils.toJID())] == null){
                    let msgTone
                    tstatusImage = "";
                    Push.clear();
                    if(msgPriority == 2) {
                        msgTone = document.getElementById('high-priority-message-tone');
                        msgTone.play();
                    } else if(msgPriority == 0) {
                        msgTone = document.getElementById('low-priority-message-tone');
                        msgTone.play();
                    } else {
                        msgTone = document.getElementById('message-tone');
                        msgTone.play();
                    }
                    utils.commonNotificationMsgAlert(notifyName,messagebody,notifyJid);
                }

            } 
        }
        let notifycount = 0;
        let blockUserInfo = JSON.parse(localStorage.getItem("blockUserInfo"));

        if ($("[id='"+nodeJid+"']").length && nodeJid != stropheConnection.Strophe().getNodeFromJid(utils.toJID())) {
            notifycount = $("[id='"+nodeJid+"']").find('.notify').text();
        }
        if ($("[id='"+nodeJid+"']").length && nodeJid != stropheConnection.Strophe().getNodeFromJid(utils.toJID())) {
            if (msgfrom != userJID) {
                if (blockUserInfo != null && typeof blockUserInfo[nodeJid] == "undefined") {
                    $("[id='"+nodeJid+"']").find('.notify').html(parseInt(notifycount) + 1);
                    $("[id='"+nodeJid+"']").find('.notify').css("display", "flex");
                }
            }
        } else if ($("[id='"+nodeJid+"']").length == 0) {
            unReadCount = 1;
        }
        let updateChatObj = {
            from         :from,
            nodeJid      :nodeJid,
            msgtype      :msgtype,
            status       :recentStatus,
            messageid    :msgid,
            unReadCount  :unReadCount,
            n            :time,
            tstatusImage :tstatusImage,
            notifierName :notifyName
          }
            if (blockUserInfo != null && typeof blockUserInfo[nodeJid] == "undefined") {
                recentdesign.Updaterecent(updateChatObj);
            }
        if (nodeJid == stropheConnection.Strophe().getNodeFromJid(utils.toJID())) {
            $('.heading-online').css('display', 'block');
            $('.heading-status').css('display', 'none');
        }
    },
    chatdropdown: function (msgtype, Msgfrom, msgid, chattype, msgPriority) {
        let usersProfileInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
        let defaultcontent = "";
        let userJID = stropheConnection.logininfo.userJID;
        let favoutirecontent = '<li role="presentation" id="fav-menu-' + msgid + '"><a  class="starmsg" >' + language.locali().star_message + '</a></li>';
        let msgactivitycontent = '<div class="dropdown msg-opt"><a class="dropdown-toggle" id="menu1" type="button" data-toggle="dropdown">';
        msgactivitycontent += '<i class="option-arrow"></i></a><ul class="dropdown-menu head-option" role="menu" aria-labelledby="menu1">';
        
        if (chattype == "normal") {
            msgactivitycontent += '<li role="presentation"><a  class="message-info" >' + language.locali().message_info + '</a></li>';
        }
        msgactivitycontent += defaultcontent + '<li role="presentation"><a  class="forwardsingle" id="' + msgtype + '">' + language.locali().forward + '</a></li>';
        msgactivitycontent += defaultcontent + '<li role="presentation"><a  class="replysingle">' + language.locali().reply + '</a></li>';
        if (Msgfrom == userJID) {
            let now = new Date();
            let today = new Date(now.toUTCString().slice(0, -4));
            let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            let dateTime = date + ' ' + time;
            msgactivitycontent += '<li role="presentation"><a  class="deletesingle" data-msgtime="' + dateTime + '">' + language.locali().delete + '</a></li>';
        } else {
            if(typeof(msgPriority) != "undefined" && msgPriority != 2) {
                msgactivitycontent += '<li role="presentation"><a  class="deletesingle">' + language.locali().delete + '</a></li>';
            }
        }
        msgactivitycontent += favoutirecontent;
        if(usersProfileInfo != null && typeof usersProfileInfo[Msgfrom] != "undefined" && usersProfileInfo[Msgfrom][7] == 1)
            msgactivitycontent += '<li role="presentation"><a  class="replysingle">' + language.locali().reply + '</a></li>';

        msgactivitycontent += "</ul></div>";
        return msgactivitycontent;
    },
    groupmemberaddCallMsgLayout: function(lastContent,divjid,messagebody){
        if (typeof lastContent != "undefined" && lastContent) {
            $("[id='conversation"+divjid+"']").append(messagebody);
        } else {
            conversationst.MessageLayout(utils.toJID(), 'down', '');
        }
    },
    groupmemberAddImg(profileimage,mediaUrl,userToken){
        let image;
        if (utils.getHexRegexPattern().test(profileimage)) {
            image = profileimage;
        } else if (profileimage !== "src/images/avatar/group/group_four.svg" && profileimage.trim() !== "") {
            image = mediaUrl + profileimage + "?mf=" + userToken;
        } else if (profileimage.trim() == "") {
            image = "src/images/avatar/group/group_four.svg";
        } else {
            image = profileimage;
        }
        return image;
    },
    groupmemberaddGrpMemId: function(grpMemberAddObj){
        let msgContent  =  grpMemberAddObj.msgContent,
            groupInfo   =  grpMemberAddObj.groupInfo,
            groupid     =  grpMemberAddObj.groupid,
            nodeJid     =  grpMemberAddObj.nodeJid,
            userJID     =  grpMemberAddObj.userJID,
            notifyJid   =  grpMemberAddObj.notifyJid,
            from        =  grpMemberAddObj.from,
            publisher   =  grpMemberAddObj.publisher,
            addid       =  grpMemberAddObj.addid,
            messageFrom =  grpMemberAddObj.messageFrom,
            messageTo   =  grpMemberAddObj.messageTo;

        let displayName = "";

            if (msgContent != '') {
                if (groupInfo != null && typeof groupInfo[groupid] != "undefined") {
                    displayName = groupInfo[groupid][1];
                    let  myInfo = JSON.parse(localStorage.getItem("myStatus"));
                    let myStatus = typeof myInfo !== 'undefined'?myInfo:1;
                    if (nodeJid != userJID && myStatus !=0) {
                        Push.clear();
                        let msgTone = document.getElementById('message-tone');
                        msgTone.play();
                       
                        /** Call Notification Function**/
                        utils.commonNotificationMsgAlert(displayName,msgContent,notifyJid);

                    }
                } else {
                    groups.getgroupdetails(groupid + "@mix." + stropheConnection.Server());
                    setTimeout(function () {
                        module.exports.groupmemberadd(from, publisher, addid, messageFrom, messageTo);
                    }, 1000);
                }
            }
    },

    groupmemberaddFirstSplit: function(grpMemberObj){
        let msgContent  =   grpMemberObj.msgContent,
            groupInfo   =   grpMemberObj.groupInfo,
            groupid     =   grpMemberObj.groupid,
            mediaUrl    =   grpMemberObj.mediaUrl,
            userToken   =   grpMemberObj.userToken,
            from        =   grpMemberObj.from;
        let groupname,profileimage,image;

            if (groupInfo != null && typeof groupInfo[groupid] != "undefined") {
                groups.getgroupdetails(from);
                groupname = groupInfo[groupid][1];
                profileimage = groupInfo[groupid][2];
                image = module.exports.groupmemberAddImg(profileimage,mediaUrl,userToken);
               
                setTimeout(function () {
                    let usersPriorityInfo = JSON.parse(localStorage.getItem("usersPriorityInfo"));                    
                    if(usersPriorityInfo != null && typeof(usersPriorityInfo[groupid]) != "undefined" && (parseInt(usersPriorityInfo[groupid][0]) + parseInt(usersPriorityInfo[groupid][1])) > 0) {
                        msgContent = '<span class="priority-message-recent">PRIORITY</span>' + msgContent;
                    }

                    let recentChatObj = {
                        nodeJid       :groupid,
                        name          :groupname,
                        image         :image,
                        msgcontent    :msgContent,
                        time          :'',
                        status        :'',
                        messageid     :'',
                    }
                    recentdesign.recentchatcontent(recentChatObj);
                    recentdesign.RecentChatDesign();
                }, 1000);
            } else {
                groups.getGroup(groupid);
            }
    },

    groupmemberaddSecondSplit: function(grpMemberObj){
        let msgContent  =   grpMemberObj.msgContent,
            groupInfo   =   grpMemberObj.groupInfo,
            groupid     =   grpMemberObj.groupid,
            mediaUrl    =   grpMemberObj.mediaUrl,
            userToken   =   grpMemberObj.userToken,
            from        =   grpMemberObj.from;
            let groupname,profileimage;

            if (groupInfo != null && typeof groupInfo[groupid] != "undefined") {
                let profile;
                groups.getgroupdetails(from);
                groupname = groupInfo[groupid][1];
                profileimage = groupInfo[groupid][2];
                profile = module.exports.groupmemberAddImg(profileimage,mediaUrl,userToken);

                setTimeout(function () {
                    let recentChatObj = {
                        nodeJid       :groupid,
                        name          :groupname,
                        image         :profile,
                        msgcontent    :msgContent,
                        time          :'',
                        status        :'',
                        messageid     :'',
                    }
                      
                    recentdesign.recentchatcontent(recentChatObj);
                    recentdesign.RecentChatDesign();
                }, 1000);
            } else {
                let recentChatObj = {
                    nodeJid       :groupid,
                    name          :groupid,
                    image         :'./src/images/avatar/group/group_one.webp',
                    msgcontent    :msgContent,
                    time          :'',
                    status        :'',
                    messageid     :'',
                    unReadCount   :'sdffgr',
                }
                recentdesign.recentchatcontent(recentChatObj);
                recentdesign.RecentChatDesign();
                groups.getGroup(groupid);
            }
    },
    groupmemberadd: function (from, publisher, addid, messageFrom, messageTo) {
        let mediaUrl = window._env_.ApiURL + "/media/";
        let divjid = stropheConnection.Strophe().getNodeFromJid(utils.toJID());
        let groupid = stropheConnection.Strophe().getNodeFromJid(from);
        let nodeJid = stropheConnection.Strophe().getNodeFromJid(publisher);
        let addJid = stropheConnection.Strophe().getNodeFromJid(addid);
        let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
        let userJID = stropheConnection.logininfo.userJID;
        let userToken = storage.getuserToken();
        let msgContent = utils.groupstatus(groupid, nodeJid, addJid, 2, messageFrom, messageTo);
        let notifyJid = groupid;
        let messagebody = '<div class="row message-previous"> <div class="col-sm-12 previous"><a>' + msgContent + '</a></div></div>';
        if (groupid == stropheConnection.Strophe().getNodeFromJid(utils.toJID())) {
            let lastContent = true;
            module.exports.groupmemberaddCallMsgLayout(lastContent,divjid,messagebody);
            utils.forwardmsg();
        } else {
            let grpMemberAddObj = {
                msgContent  :   msgContent,
                groupInfo   :   groupInfo,
                groupid     :   groupid,
                mediaUrl    :   mediaUrl,
                userToken   :   userToken,
                nodeJid     :   nodeJid,
                userJID     :   userJID,
                notifyJid   :   notifyJid,
                from        :   from,
                publisher   :   publisher,
                addid       :   addid,
                messageFrom :   messageFrom,
                messageTo   :   messageTo
            }
            module.exports.groupmemberaddGrpMemId(grpMemberAddObj);

           
        }
        if($("[id='"+groupid+"']").length) {
            let grpMemberObj = {
                msgContent  :   msgContent,
                groupInfo   :   groupInfo,
                groupid     :   groupid,
                mediaUrl    :   mediaUrl,
                userToken   :   userToken,
                from        :   from
            }
            module.exports.groupmemberaddFirstSplit(grpMemberObj);

           
        } else {
            let grpMemberObj = {
                msgContent  :   msgContent,
                groupInfo   :   groupInfo,
                groupid     :   groupid,
                mediaUrl    :   mediaUrl,
                userToken   :   userToken,
                from        :   from,
               
            }
            module.exports.groupmemberaddSecondSplit(grpMemberObj);

           
        }
        if (groupid == stropheConnection.Strophe().getNodeFromJid(utils.toJID())) {
            $('.heading-online').css('display', 'block');
            $('.heading-status').css('display', 'none');
            setTimeout(function () {
                conversationst.updateuserprofile(groupid);
            }, 1000);
        }
    },
    exitGroupRecentChatAppend: function(groupid,msgContent,userJID,addJid){
        let msgcontentRecent;
        $("#recentchatcontent").mCustomScrollbar("destroy");
        $("[id='"+groupid+"']").prependTo("#recentcontent");
        let usersPriorityInfo = JSON.parse(localStorage.getItem("usersPriorityInfo"));
        if(usersPriorityInfo != null && typeof(usersPriorityInfo[groupid]) != "undefined" && (parseInt(usersPriorityInfo[groupid][0]) + parseInt(usersPriorityInfo[groupid][1])) > 0 && addJid != userJID) {
            msgcontentRecent = '<span class="priority-message-recent">PRIORITY</span>' + msgContent;
        } else {
            msgcontentRecent = msgContent;
        }
        $("[id='"+groupid+"']").find('.name-status').html(msgcontentRecent);
        $("[id='"+groupid+"']").find('.time-meta').html('');
        $("[id='"+groupid+"']").find('.list-msg-status').html('')
        $("[id='"+groupid+"']").find('.name-status i').css('display', 'inline-block');
        $("[id='"+groupid+"']").find('.list-msg-status i').css('display', 'inline-block');
        $("[id='"+groupid+"']").find('.typing-msg').css('display', 'none');
        if ($("[id='"+groupid+"']").find('.exitclass').find('.exit-group-recent')) {
            $("[id='"+groupid+"']").find('.exitclass').find('.exit-group-recent').toggleClass('exit-group-recent exit-group-delete');
            $("[id='"+groupid+"']").find('.exitclass').find('.exit-group-delete').html("Delete Group");
            recentdesign.recentdropdownClick();
            utils.determineDropDirection();
        }
        $("#recentchatcontent").mCustomScrollbar({
            mouseWheelPixels: 500 //change this to a value, that fits your needs
        });                    
    },
    groupMebRemoveDisplayName: function(groupid){
        let displayName = "";
        let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));

        if (groupInfo != null && typeof groupInfo[groupid] != "undefined") {
            displayName = groupInfo[groupid][1];
        }
        return displayName;
    },
    groupMemberRemoveFirst: function(from,value,groupid,nodeToJid,messageFrom,messageTo,userJID){
        let divjid = stropheConnection.Strophe().getNodeFromJid(utils.toJID());
        let notifyJid = groupid;
        let displayName,msgContent,messagebody;

        let addJid = value.user;
        
        msgContent = utils.groupstatus(groupid, nodeToJid, addJid, 3, messageFrom, messageTo);
        displayName = module.exports.groupMebRemoveDisplayName(groupid)
                
        messagebody = '<div class="row message-previous"> <div class="col-sm-12 previous"><a>' + msgContent + '</a></div></div>';
        if (groupid == stropheConnection.Strophe().getNodeFromJid(utils.toJID())) {
            if (typeof lastContent == "undefined" || lastContent) {
                $("[id='conversation"+divjid+"']").append(messagebody);
            } else {
                conversationst.MessageLayout(utils.toJID(), 'down', '');
            }
            utils.forwardmsg();
        } else {
            let  myInfo = JSON.parse(localStorage.getItem("myStatus"));
            let myStatus = typeof myInfo !== 'undefined'?myInfo:1;
            if (nodeToJid != userJID && myStatus !=0) {
                Push.clear();
                let msgTone = document.getElementById('message-tone');
                msgTone.play();

                /** Call Notification Function**/
                utils.commonNotificationMsgAlert(displayName,msgContent,notifyJid);

            }
        }
        if ($("[id='"+groupid+"']").length) {
            module.exports.exitGroupRecentChatAppend(groupid,msgContent,userJID,addJid)
        } else {
            let recentChatObj = {
                nodeJid       :groupid,
                name          :groupid,
                image         :'./src/images/avatar/group/group_four.svg',
                msgcontent    :msgContent,
                time          :'',
                status        :'',
                messageid     :'',
            }
            recentdesign.recentchatcontent(recentChatObj);
            recentdesign.RecentChatDesign();
        }
        if (groupid == stropheConnection.Strophe().getNodeFromJid(utils.toJID())) {
            $('.heading-online').css('display', 'block');
            $('.heading-status').css('display', 'none');
        }
        let userid = stropheConnection.Strophe().getNodeFromJid(from);
        if (userid == stropheConnection.Strophe().getNodeFromJid(utils.toJID())) {
            $('#member-' + addJid).remove();
        }
    },
    groupmemberremove: function (from, publisher, removeid, messageFrom, messageTo) {
        let groupDetail = JSON.parse(localStorage.getItem("groupDetails"));
        let nodeToJid = stropheConnection.Strophe().getNodeFromJid(publisher);
        let groupid = stropheConnection.Strophe().getNodeFromJid(from);
        let groupmember;
        let userJID = stropheConnection.logininfo.userJID;
        if (groupDetail != null && typeof groupDetail[groupid] != "undefined") {
            groupmember = groupDetail[groupid];
            let value = groupmember[removeid];
            if (typeof value != "undefined") {
                module.exports.groupMemberRemoveFirst(from,value,groupid,nodeToJid,messageFrom,messageTo,userJID)
            } else {
                return true;
            }
            groups.getgroupdetails(groupid + "@mix." + stropheConnection.Server());
        } else {
            groups.getgroupdetails(groupid + "@mix." + stropheConnection.Server());
            setTimeout(function () {
                module.exports.groupmemberremove(from, publisher, removeid);
            }, 1000);
        }
    },
    makeAdminFirst:function(divjid,messagebody){
        if (typeof lastContent == "undefined" || lastContent) {
            $("[id='conversation"+divjid+"']").append(messagebody);
        } else {
            conversationst.MessageLayout(utils.toJID(), 'down', '');
        }
        utils.forwardmsg();
    },
    makeAdminSecond:function(nodeToJid,userJID,displayName,msgContent,notifyJid){
        let  myInfo = JSON.parse(localStorage.getItem("myStatus"));
        let myStatus = typeof myInfo !== 'undefined'?myInfo:1;
        if (nodeToJid != userJID && myStatus !=0) {
            Push.clear();
            let msgTone = document.getElementById('message-tone');
            msgTone.play();

            /** Call Notification Function**/
            utils.commonNotificationMsgAlert(displayName,msgContent,notifyJid);

        }
    },
    makeadmin: function (from, publisher, adminid, messageFrom, messageTo) {
        let divjid = stropheConnection.Strophe().getNodeFromJid(utils.toJID());
        let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
        let nodeToJid = stropheConnection.Strophe().getNodeFromJid(publisher);
        let nodeJid = stropheConnection.Strophe().getNodeFromJid(publisher);
        let groupid = stropheConnection.Strophe().getNodeFromJid(from);
        let addJid = stropheConnection.Strophe().getNodeFromJid(adminid);
        let userJID = stropheConnection.logininfo.userJID;
        let fromJid = stropheConnection.Strophe().getBareJidFromJid(from);

        let msgContent = utils.groupstatus(groupid, nodeJid, addJid, 5, messageFrom, messageTo);
        let displayName = "";
        let msgcontentRecent;
        if (groupInfo != null && typeof groupInfo[groupid] != "undefined") {
            displayName = groupInfo[groupid][1] + ":" + groupInfo[groupid][1];
        }
        let notifyJid = groupid;
        let messagebody = '<div class="row message-previous"> <div class="col-sm-12 previous"><a>' + msgContent + '</a></div></div>';
        if (stropheConnection.Strophe().getNodeFromJid(fromJid) == stropheConnection.Strophe().getNodeFromJid(utils.toJID())) {
            module.exports.makeAdminFirst(divjid,messagebody)
        } else {
            module.exports.makeAdminSecond(nodeToJid,userJID,displayName,msgContent,notifyJid)
        }
        if ($("[id='"+groupid+"']").length) {
            $("#recentchatcontent").mCustomScrollbar("destroy");
            $("[id='"+groupid+"']").prependTo("#recentcontent");
            let usersPriorityInfo = JSON.parse(localStorage.getItem("usersPriorityInfo"));
            if(usersPriorityInfo != null && typeof(usersPriorityInfo[groupid]) != "undefined" && (parseInt(usersPriorityInfo[groupid][0]) + parseInt(usersPriorityInfo[groupid][1])) > 0) {
                msgcontentRecent = '<span class="priority-message-recent">PRIORITY</span>' + msgContent;
            } else {
                msgcontentRecent = msgContent;
            }
            $("[id='"+groupid+"']").find('.name-status').html(msgcontentRecent);
            $("[id='"+groupid+"']").find('.time-meta').html('');
            $("[id='"+groupid+"']").find('.list-msg-status').html('')
            $("[id='"+groupid+"']").find('.sideBar-msg').css('display', 'inline-block');
            $("[id='"+groupid+"']").find('.list-msg-status i').css('display', 'inline-block');
            $("[id='"+groupid+"']").find('.typing-msg').css('display', 'none');
            $("#recentchatcontent").mCustomScrollbar({
                mouseWheelPixels: 500 //change this to a value, that fits your needs
            });
        } else {
            let recentChatObj = {
                nodeJid       :groupid,
                name          :groupid,
                image         :'./src/images/avatar/group/group_four.svg',
                msgcontent    :msgContent,
                time          :'',
                status        :'',
                messageid     :'',
            }
            recentdesign.recentchatcontent(recentChatObj);
            recentdesign.RecentChatDesign();
        }
        groups.getgroupdetails(from);
        if (groupid == stropheConnection.Strophe().getNodeFromJid(utils.toJID())) {
            $('.heading-online').css('display', 'block');
            $('.heading-status').css('display', 'none');
            conversationst.updateuserprofile(groupid);
        }
    },
    
    fwdReceiveBroadcast: function(msgid,totCount){
        if ($('#' + msgid).length != 0) {
            $('#' + msgid).parent().next().find('.msg-sent-status ')
            $('#' + msgid).parent().next().find('.deliveredCount').text(totCount);
            $('#' + msgid).children('.message-main-receiver').children('.receiver').children('.message-time').children('.msg-status-sign').find('.readCount').text(totCount);
            $('#' + msgid).parent().next().find('.deliverCountRecent').text(totCount);
        }
        if ($('#chat-' + msgid).length != 0) {
            $('#chat-' +msgid).children('.message-main-receiver').children('.receiver').children('.msg-status-sign').find('.readCount').html(totCount);
            $('#chat-' +msgid).children('.message-main-receiver').children('.receiver').children('.message-time').children('.msg-status-sign').children('.receiptStatus').find('.readCount').html(totCount);
            $('#chat-' + msgid).find('.deliveredCount').text(totCount);
        }
    },
    fwdReceiveGroup: function(msgid,totCount){
        if ($('#' + msgid).length != 0) {
            $('#' + msgid).parent().next().find('.msg-sent-status ')
            $('#' + msgid).parent().next().find('.deliveredCount').text(totCount);
            $('#' + msgid).children('.message-main-receiver').children('.receiver').children('.message-time').children('.msg-status-sign').find('.readCount').text(totCount);
            $('#' + msgid).parent().next().find('.deliverCountRecent').text(totCount);
        }
        if ($('#chat-' + msgid).length != 0) {
            $('#chat-' +msgid).children('.message-main-receiver').children('.receiver').children('.msg-status-sign').find('.readCount').html(totCount);
            $('#chat-' +msgid).children('.message-main-receiver').children('.receiver').children('.message-time').children('.msg-status-sign').children('.receiptStatus').find('.readCount').html(totCount);
            $('#chat-' + msgid).find('.deliveredCount').text(totCount);
        }
  
        let ToJid = JSON.parse(localStorage.getItem("toJID"));
        let mid = $('.message-info-bubble').find('input[name="messageid"]').val();
        if (mid == msgid) {
            utils.getGroupMsginfo(msgid, ToJid);
            let senderDiv = $("#chat-" + msgid).find(".sender").html();
            $("#updMessageInfo").html('<div class="sender">' + senderDiv + '</div><input type="hidden" name="messageid" value=""/>');
        }
    },
    fwdReceiveUser: function(msgid){
        if ($('#' + msgid).length != 0) {
            if($('#' + msgid).parent().next().find('.receiptStatus').html() != 'Read'){
                $('#' + msgid).parent().next().find('.receiptStatus').html('Delivered');
            }
        }
        if ($('#chat-' + msgid).length != 0) {
            if ($('#chat-' + msgid).find('.receiptStatus').text() == ' Sent') {
                $('#chat-' + msgid).find('.msg-status-sign').html('<span>Delivered</span>');
            }
        }
    },
    forwardreceive: function (from, msgid, totCount) {
        let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
        let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
        let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
        let tojid = stropheConnection.Strophe().getNodeFromJid(utils.toJID());
        let id = $('#' + msgid).closest('.sideBar-body').attr('id');
        if (typeof id == "undefined") {
            id = tojid;
        }
        if (broadcastProfile != null && typeof broadcastProfile[id] != "undefined" && broadcastProfile[id][0] == 'broadcast') {
            if ($('#' + msgid).length != 0) {
                $('#' + msgid).find('.deliveredCount').html(totCount);
            }
            if ($('#chat-' + msgid).length != 0) {
                $('#chat-' + msgid).find('.deliveredCount').text(totCount);
            }
            module.exports.fwdReceiveBroadcast(msgid,totCount)

        } else if (groupInfo != null && typeof groupInfo[id] != "undefined") {
            module.exports.fwdReceiveGroup(msgid,totCount)
        } else if (userInfo != null && typeof userInfo[id] != "undefined") {
            module.exports.fwdReceiveUser(msgid)
        }
    },
    fwdSeenGroupFun(msgid){
        let ToJid = JSON.parse(localStorage.getItem("toJID"));
        let senderDiv;
        let mid = $('.message-info-bubble').find('input[name="messageid"]').val();
        
        if (mid == msgid) {
            utils.getGroupMsginfo(msgid, ToJid);
            senderDiv = $("#chat-" + msgid).find(".sender").html();
            $("#updMessageInfo").html('<div class="sender">' + senderDiv + '</div><input type="hidden" name="messageid" value=""/>');
        }
    },
    forwardseen: function (from, msgid, delCount, seen_count, priority, msgAck, toUser) {
        let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
        let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
        let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
        let tojid = stropheConnection.Strophe().getNodeFromJid(utils.toJID());
        let id = $('#' + msgid).closest('.sideBar-body').attr('id');
        if (typeof id == "undefined") {
            id = tojid;
        }
        if (broadcastProfile != null && typeof broadcastProfile[id] != "undefined" && broadcastProfile[id][0] == 'broadcast') {
            if ($('#chat-' + msgid).length != 0) {
                $('#chat-' + msgid).find('.msg-status-sign').html('<span class="receiptStatus"> Sent</span>');
            }
        } else if (groupInfo != null && typeof groupInfo[id] != "undefined") {
            module.exports.fwdSeenGroupFun(msgid)
        } else if (userInfo != null && typeof userInfo[id] != "undefined") {
            if ($('#' + msgid).length != 0) {
                $('#' + msgid).parent().next().find('.receiptStatus').html('Read');
            }
            if ($('#chat-' + msgid).length != 0) {
                $('#chat-' + msgid).parent().find('.msg-status-sign').html('<span>Read</span>');
            }
        }
        if(priority == "2" && msgAck == "0" && $("[id='conversation"+toUser+"']").length == 0) {
            module.exports.seenPriorityMessages(toUser);
        }
    },
    forwardackstatusGetId: function(id,tojid){
        if (typeof id == "undefined") {
            id = tojid;
        }
        return id;
    },
    forwardackGroupAccCheck: function(msgid,existingAccCount,existingAckCount,from,loggedUser){
        $('#chat-' + msgid).find('.accCount').text(existingAccCount);
        if ($('#' + msgid).length != 0) {
            $('#' + msgid).children('.message-main-receiver').children('.receiver').children('.message-time').children('.msg-status-sign').find('.ackCount').text(existingAckCount);
            $('#' + msgid).parent().next().find('.acceptCount').text(existingAccCount);
            $('#' + msgid).parent().next().find('.acceptCountRecent').text(existingAccCount);
        }
        
        let messageFromIq
            if(from){
                messageFromIq =from.split('@')[0];
            }else {
                messageFromIq = ''
            }
            /** Check from user is same logged user **/
            if(messageFromIq == loggedUser){
                $('#chat-'+msgid).find('.acknow-accept').off('click');
                $('#chat-'+msgid).find('.acknow-accept').removeClass("acknow-accept").addClass("accepted");
                $('#chat-'+msgid).find('.acknow-reject').remove();
                $('#chat-'+msgid).find('.accepted').html('Accepted <i><img src="./src/images/Accept_ic.svg" alt="accept"/></i>');
            }
    },
    forwardackGroupRejCheck: function(msgid,existingRejCount,existingAckCount,from,loggedUser){
        $('#chat-' + msgid).find('.rejCount').text(existingRejCount);
        if ($('#' + msgid).length != 0) {
            $('#' + msgid).children('.message-main-receiver').children('.receiver').children('.message-time').children('.msg-status-sign').find('.ackCount').text(existingAckCount);
            $('#' + msgid).parent().next().find('.rejectCount').text(existingRejCount);
            $('#' + msgid).parent().next().find('.rejectCountRecent').text(existingRejCount);
        }

        let messageFromIq
            if(from){
                messageFromIq =from.split('@')[0];
            }else {
                messageFromIq = ''
            }
            /** Check from user is same logged user **/
            if(messageFromIq == loggedUser){
                $('#chat-'+msgid).find('.acknow-reject').off('click');
                $('#chat-'+msgid).find('.acknow-reject').removeClass("acknow-reject").addClass("rejected");
                $('#chat-'+msgid).find('.acknow-accept').remove();
                $('#chat-'+msgid).find('.rejected').html('Rejected <i><img src="./src/images/Reject_ic.svg" alt="accept"/></i>');
            }
    },
    forwardackGroupAckCheck: function(msgid,existingAckCount,from,loggedUser){
        $('#chat-' + msgid).find('.ackCount').text(existingAckCount);
        if ($('#' + msgid).length != 0) {
            $('#' + msgid).children('.message-main-receiver').children('.receiver').children('.message-time').children('.msg-status-sign').find('.ackCount').text(existingAckCount);
            $('#' + msgid).parent().next().find('.acknowledgeCount').text(existingAckCount);

            $('#' + msgid).parent().next().find('.acknowledgeCountRecent').text(existingAckCount);
        }

        let messageFromIq
        if(from){
            messageFromIq = from.split('@')[0];
        }else {
            messageFromIq = ''
        }
        /** Check from user is same logged user **/
        if(messageFromIq == loggedUser){
            $('#chat-' + msgid).find('.ackClick').removeClass("ackClick").addClass("acknowledged");
            $('#chat-' + msgid).find('.acknowledged').html('Acknowledged <i class="acknow"></i>');
            $("#acknowledge-msg").removeClass("open");
            $('#chat-' + msgid).find('.acknowledged').off('click');
        }
    },
    forwardackGroupFirstSplit: function(msgid,ackStatus,from,loggedUser,msgPriority,id){
        if ($('#chat-' + msgid).length != 0) {
            let existingAckCount = $('#chat-' + msgid).find('.ackCount').text();
            let existingAccCount,existingRejCount;
            if (existingAckCount == "") {
                existingAccCount = $('#chat-' + msgid).find('.accCount').text();
                existingRejCount = $('#chat-' + msgid).find('.rejCount').text();
                if (ackStatus == 1) {
                    existingAccCount++;

                    module.exports.forwardackGroupAccCheck(msgid,existingAccCount,existingAckCount,from,loggedUser)
                    
                } else {
                    existingRejCount++;
                    module.exports.forwardackGroupRejCheck(msgid,existingRejCount,existingAckCount,from,loggedUser)

                    
                }
            } else {
                existingAckCount++;
                module.exports.forwardackGroupAckCheck(msgid,existingAckCount,from,loggedUser)
            }
           
        }
    },
    forwardackGroupSecondSplit: function(msgid,ToJid,mid){
        let senderDiv;
        if (mid == msgid) {
            utils.getGroupMsginfo(msgid, ToJid);
            if(typeof $("#chat-" + msgid).find(".sender").html() == 'undefined'){ // If Sender Div Empty
                senderDiv = $("#chat-" + msgid).find(".receiver").html();

            }else {
                senderDiv = $("#chat-" + msgid).find(".sender").html();

            }
            $("#updMessageInfo").html('<div class="sender">' + senderDiv + '</div><input type="hidden" name="messageid" value=""/>');
        }
    },
    forwardackUserFirstSplit: function(msgid,ackStatus){
        if ($('#' + msgid).length != 0) {
            if ($('#' + msgid).parent().next().find('.ackStatus').html() == 'Ack Pending') {
                $('#' + msgid).parent().next().find('.ackStatus').html('Acknowledged');
            } else if ($('#' + msgid).parent().next().find('.ackStatus').html() == 'Acc/Rej Pending') {
                if (ackStatus == 1) {
                    $('#' + msgid).parent().next().find('.ackStatus').html('Accepted');
                } else {
                    $('#' + msgid).parent().next().find('.ackStatus').html('Rejected');
                }
            }
        }
    },
    accOrRejStatusWithComma: function(msgid,ackStatus){
        if (ackStatus == 1) {
            $('#chat-' + msgid).find('.ackStatus').html(', Accepted');
        } else {
            $('#chat-' + msgid).find('.ackStatus').html(', Rejected');
        }
    },
    accOrRejStatusWithoutComma: function(msgid,ackStatus){
        if (ackStatus == 1) {
            $('#chat-' + msgid).find('.ackStatus').html('Accepted');
        } else {
            $('#chat-' + msgid).find('.ackStatus').html('Rejected');
        }
    },
    forwardackUserSecondSplit: function(msgid,ackStatus,id,msgPriority){
        if ($('#chat-' + msgid).length != 0) {
            if ($('#chat-' + msgid).find('.ackStatus').html() == ', Ack Pending') {
                $('#chat-' + msgid).find('.ackStatus').html(', Acknowledged');
            }else if($('#chat-' + msgid).find('.ackStatus').html() == 'Ack Pending'){
                $('#chat-' + msgid).find('.ackStatus').html('Acknowledged');
            }else if ($('#chat-' + msgid).find('.ackStatus').html() == ', Acc/Rej Pending') {
                module.exports.accOrRejStatusWithComma(msgid,ackStatus);
            }else if ($('#chat-' + msgid).find('.ackStatus').html() == 'Acc/Rej Pending') {
                module.exports.accOrRejStatusWithoutComma(msgid,ackStatus);
            }
            if($('#chat-' + msgid).find('.priority-message').text() == language.locali().priority_message || msgPriority == 2) {
                utils.priorityMessageAcknowledged(id, msgid);
            }
        }
    },

    forwardackstatus: function (from, msgid, ackStatus, msgPriority) {
        let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
        let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
        let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
        let tojid = stropheConnection.Strophe().getNodeFromJid(utils.toJID());
        let id = $('#' + msgid).closest('.sideBar-body').attr('id');
        let ToJid,mid;
        let loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
        let loggedUser = loginUserInfo[0][0];
        
        id = module.exports.forwardackstatusGetId(id,tojid)
        if (broadcastProfile != null && typeof broadcastProfile[id] != "undefined" && broadcastProfile[id][0] == 'broadcast') {
            module.exports.forwardackGroupFirstSplit(msgid,ackStatus,from,loggedUser,msgPriority,id)
           
        } else if (groupInfo != null && typeof groupInfo[id] != "undefined") {

            module.exports.forwardackGroupFirstSplit(msgid,ackStatus,from,loggedUser,msgPriority,id)
          
            ToJid = JSON.parse(localStorage.getItem("toJID"));
            mid = $('.message-info-bubble').find('input[name="messageid"]').val();
            module.exports.forwardackGroupSecondSplit(msgid,ToJid,mid)

            
        } else if (userInfo != null && typeof userInfo[id] != "undefined") {
            module.exports.forwardackUserFirstSplit(msgid,ackStatus)

            module.exports.forwardackUserSecondSplit(msgid,ackStatus,id,msgPriority)
          
        }
    },
    fwdCallPriorityMsgCheck: function(msgid,from,userJID){
        $('.msg-sent-status').html('');
        if(from == userJID) {
            if($('#' + msgid).next().find(".priority-message-recent").length > 0) {
                $('#' + msgid).next().find('.name-status').html("<i class='text-italic name-status'><span class='priority-message-recent'>" + language.locali().priority_recent + "</span> You deleted this message</i>");
            } else {
                $('#' + msgid).next().find('.name-status').html('<i class="text-italic"> You deleted this message</i>');
            }
        } else {
            if($('#' + msgid).next().find(".priority-message-recent").length > 0) {
                $('#' + msgid).next().find('.name-status').html("<i class='text-italic name-status'><span class='priority-message-recent'>" + language.locali().priority_recent + "</span> This message was deleted</i>");
            } else {
                $('#' + msgid).next().find('.name-status').html('<i class="text-italic"> This message was deleted</i>');
            }
        }            
    },
    forwardrecall: function (from, msgid, msgPriority, groupId) {
        let userJID = stropheConnection.logininfo.userJID;
        if(from != userJID) {
            if(typeof groupId != "undefined" && msgPriority == 2) {
                utils.priorityMessageAcknowledged(groupId, msgid);
            } else if (typeof groupId == "undefined" && msgPriority == 2) {
                utils.priorityMessageAcknowledged(from, msgid);
            }
        }
        if ($('#' + msgid).length != 0) {
            $('#' + msgid).find('.list-msg-status').html('');
        }
        if ($("#chat-" + msgid).find('.sender').length) {
            let msgtime = $("#chat-" + msgid).find(".msg-sent-time").html();
            $("#chat-" + msgid).find('.select-msg-blk').remove();
            $("#chat-" + msgid).find('.sender').addClass("deletesender");
            let Timediv = '<span class="message-time pull-right"><span class="msg-sent-time">' + msgtime + '</span></span>';
            $("#chat-" + msgid).find('.sender').html('<div class="message-text main-deletemsg"><svg xmlns="http://www.w3.org/2000/svg" class = "deletedmsg" width="20" height="20" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="#B9B9B9" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z" /></svg><i class="text-italic deletedcontent">You deleted this message</i></div>' + Timediv);
        }
        if ($("#chat-" + msgid).find('.receiver').length) {
            $("#chat-" + msgid).find('.select-msg-blk').remove();
            $("#chat-" + msgid).find('.receiver').html('<div class="message-text main-deletemsg"><svg xmlns="http://www.w3.org/2000/svg" class = "deletedmsg" width="20" height="20" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="#B9B9B9" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z"/></svg><i class="text-italic deletedcontent">This message was deleted</i></div>');
            /** Update Favorite Message Start **/
            let updateStarmessage = stropheConnection
                .StropheJs()
                .$iq({
                    type: "set",
                    id: utils.getId(),
                    to: stropheConnection.Server()
                })
                .c("query", {
                    xmlns: "jabber:iq:user_fav",
                    status: "update_favourite_message",
                });
                if (stropheConnection.Connection().connected) {
                    stropheConnection.Connection().send(updateStarmessage);
                }
            /** Update Favorite Message End **/

        }
        if ($('#' + msgid).length != 0) {
            module.exports.fwdCallPriorityMsgCheck(msgid,from,userJID)
        }        
        $(".message-info-block .right-msg-back").trigger("click");
        $(".media-detail-section .right-msg-back").trigger("click");
        $("#right-prof-back").trigger("click");
        $(".conversation").removeClass("small-pane");
    },
    composingStatus: function (from, groupid) {
        let userid = stropheConnection.Strophe().getNodeFromJid(from);
        let groupDetails = JSON.parse(localStorage.getItem("groupDetails"));
        let groupBareJid = stropheConnection.Strophe().getNodeFromJid(groupid);
        let groupParticipantNames = {};

        let userstatus;
        let groupmember = (groupDetails != null) ? groupDetails[groupBareJid] : '';
        if (groupid != '') {
            for (let keys in groupmember) {
                let values = groupmember[keys];
                groupParticipantNames[values.user] = values.displayName;
            }
            userstatus = groupParticipantNames[userid.toLowerCase()] + " typing...";
            userid = stropheConnection.Strophe().getNodeFromJid(groupid);
        } else {
            userstatus = " typing...";
        }
        if (userid == stropheConnection.Strophe().getNodeFromJid(utils.toJID())) {
            $('.heading-online').css('display', 'none');
            $('.heading-status').css('display', 'block');
            $('.heading-status').html(userstatus);
        }
        if ($("[id='"+userid+"']").length != 0) {
            $("[id='"+userid+"']").find('.name-status').css('display', 'none');
            $("[id='"+userid+"']").find('.typing-msg').css('display', 'block');
            $("[id='"+userid+"']").find('.typing-msg').html(userstatus);
        }
    },
    goneStatus: function (from, groupid) {
        let userid = stropheConnection.Strophe().getNodeFromJid(from);
        if (groupid != '') {
            userid = stropheConnection.Strophe().getNodeFromJid(groupid);
        }
        if (userid == stropheConnection.Strophe().getNodeFromJid(utils.toJID())) {
            $('.heading-online').css('display', 'block');
            $('.heading-status').css('display', 'none');
        }
        if ( $("[id='"+userid+"']").length != 0) {
            $("[id='"+userid+"']").find('.name-status').css('display', 'flex');
            $("[id='"+userid+"']").find('.typing-msg').css('display', 'none');
        }
    },
    //show reminder messages
    sendReminderMessage: function (messageId, fromUser) {
        let rostersData = JSON.parse(localStorage.getItem("usersProfileInfo"));
        let groupsData = JSON.parse(localStorage.getItem("groupProfile"));
        let reminderFrom;
        if (rostersData != null && typeof rostersData[fromUser] != "undefined") {
            reminderFrom = rostersData[fromUser][1];
        }else if (groupsData != null && typeof groupsData[fromUser] != "undefined") {
            reminderFrom = groupsData[fromUser][1];
        } 
        let reminderContent = reminderFrom + ": You have Priority Message";
        let  myInfo = JSON.parse(localStorage.getItem("myStatus"));
        let myStatus = typeof myInfo !== 'undefined'?myInfo:1;
        if( myStatus !=0)
        {
            let msgTone = document.getElementById('high-priority-message-tone');
            msgTone.play();
            Push.clear();

            Notification.requestPermission().then(function(permission) {
                if (permission === 'granted') {
                    // Permission is granted, create the push notification
                    Push.create("Reminder", {
                        body: reminderContent,
                        timeout: 20000,
                        onClick: function () {
                            window.focus();
                            recentdesign.reminderClickAction(messageId, fromUser);
                            this.close();
                        }
                    });
                } else if (permission === 'denied') {
                    // Permission is denied, show a message to the user
                    console.log('Push notification permission is denied. You may not receive notifications.');
            
                    // You can also provide a UI element or a button to prompt the user to grant permission
                    // Example: showPermissionPromptButton();
                } else {
                    // Permission is in the default state (neither granted nor denied)
                    console.log('Push notification permission is not granted yet.');
                }
            }).catch(function(error) {
                console.log('Error requesting push notification permission:', error);
            });
            
        }
    },
    //update if priority message is seen
    seenPriorityMessages: function (toUser) {
        let usersPriorityInfo = JSON.parse(localStorage.getItem("usersPriorityInfo"));
        let noAckCount = 0;
        if(usersPriorityInfo != null && typeof(usersPriorityInfo[toUser]) != "undefined") {
            if(usersPriorityInfo[toUser][0] > 0)
                noAckCount = parseInt(usersPriorityInfo[toUser][0]) - 1;
            if((parseInt(noAckCount) + parseInt(usersPriorityInfo[toUser][1])) == 0) {
                delete usersPriorityInfo[toUser];
                $("#"+toUser).find(".priority-message-recent").remove();
            } else {
                usersPriorityInfo[toUser][0] = noAckCount;
            }
            localStorage.setItem("usersPriorityInfo", JSON.stringify(usersPriorityInfo));
        }
    }
};
