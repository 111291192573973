let stropheConnection = require("./stropheConnection.js");
let lastActivityUtils = require("./utils.js");
let lastActivityTimeFormat = require("./timeformat.js");
let lastActivitystorage = require("./storage.js");
module.exports = {
 
  lastActivity: function (toJID) {
    let lastactivity = stropheConnection.StropheJs().$iq({ type: "get", id: lastActivityUtils.getId(), to: toJID }).c("query", { xmlns: "jabber:iq:last" });
    if (stropheConnection.Connection().connected) {
      stropheConnection.Connection().sendIQ(lastactivity, module.exports.lastactivityHandler);
    }
  },
  lastactivityHandler: function (lastactivity) {
    try {
      let toJID = lastActivityUtils.toJID();
      let from = lastactivity.getAttribute("from");
      let fromUser = stropheConnection.Strophe().getNodeFromJid(from);
      let usersProfileInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
      if (fromUser != lastActivitystorage.adminName() && (usersProfileInfo != null && typeof usersProfileInfo[fromUser] != "undefined" && usersProfileInfo[fromUser][7] != "1")) {
        if ($(lastactivity).find("query").length) {
          module.exports.lastActivityQueryHandler(lastactivity,toJID,from);
        }
      }
    } catch (err) {
      console.log("lastactivityHandler", err);
      console.log("lastactivityHandler iq", lastactivity);
    }
    return true;
  },
  lastActivityQueryHandler: function(lastactivity,toJID,from){
    if (toJID != "") {
      if (stropheConnection.Strophe().getNodeFromJid(from) == stropheConnection.Strophe().getNodeFromJid(toJID)) {
        let userstatus;
        if ($(lastactivity).find("query").attr("seconds") == 0) {
          userstatus = "online";
        } else {
          userstatus = "last seen at " + lastActivityTimeFormat.convertTimestamp($(lastactivity).find("query").attr("seconds"), "last");
        }
        $(".heading-online").css("display", "block");
        $(".heading-status").css("display", "none");
        $(".heading-online").html(userstatus);
      }
    }
  }
};