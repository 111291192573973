let stropheConnection = require("./stropheConnection.js");
let timeFormat = require("./timeformat.js");
let utils = require("./utils.js");
let recentdesign = require("./recentdesign.js");
let anchorme = require("anchorme").default;
let userprofile = require("./userprofile.js");
let storage = require("./storage.js");
let groups = require("./groups.js");
const vCard = require("./vCard.js");
let language = require('./locale/language.js');
let broadcastProfilePage = require('./broadcastProfile.js')
let recentListCount = 0;
module.exports = {
  toJID: function () {
    return JSON.parse(localStorage.getItem("toJID"));
  },
  getRecentChat: function () {
    let recentiq = stropheConnection.StropheJs().$iq({ type: "get", id: utils.getId(), to: stropheConnection.Server() }).c("query", {
      xmlns: "jabber:iq:user_activities",
      limit: recentListCount,
      status: "recent_chat"
    });
    if (stropheConnection.Connection().connected) {

      stropheConnection.Connection().sendIQ(recentiq, module.exports.getRecentChatCallBack);
    }
  },
  getRecentChatCallBack: function (recentiq) {
    try{
    let mediaUrl = window._env_.ApiURL + "/media/";
    delete localStorage.usersPriorityInfo;
    let usersPriorityInfo = {};
    let userToken = storage.getuserToken();
    $(".loader").css("display", "none");
    let userJID = stropheConnection.logininfo.userJID.toLowerCase();

    $("#forwardcontact").mCustomScrollbar("destroy");
    document.title = "Ikonix Connect";
    $("#sideBar").html("<div id='norecent'>No recent chat available</div>");
    let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
    let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
    let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
    let blockListUserInfo = JSON.parse(localStorage.getItem("blockListUserInfo"));
    let blockUserInfo = JSON.parse(localStorage.getItem("blockUserInfo"));
    $(recentiq).find("chat").each(function () {
      let botType = $(this).find("bot").text();
      let msgfrom = $(this).find("user").text();
      let time = timeFormat.convertTimestamp($(this).find("time").text(), "recent_at");
      let status = $(this).find("status").text();
      let msgid = $(this).find("msgid").text();
      let msgto = $(this).find("msgfrom").text();
      let msgbody = $(this).find("body").text();
      let publisher = $(this).find("publisher").text();
      let chattype = $(this).find("chattype").text();
      let deletedstatus = $(this).find("deletestatus").text();
      let deletedby = $(this).find("deletedby").text();
      let chatdeletedstatus = $(this).find("chatdeletestatus").text();
      let chatdeletedby = $(this).find("chatdeletedby").text();
      let blockedby = $(this).find("blocked").text();
      let unReadCount = $(this).find("unreadcount").text();
      let msg_ack = $(this).find("msg_ack").text();
      let ack_status = $(this).find("ack_status").text();
      let msgConvFrom = $(this).find("message_from").text();
      let msgConvTo = $(this).find("message_to").text();
      let recallstatus = $(this).find("recallstatus").text();
      let broadcastmsg_id = $(this).find("broadcastmsg_id").text();

      let priorityCount = $(this).find("prioritycount").text();
      let noAckPriorityCount = $(this).find("highnoackprioritycount").text();
      let name,tempname,profileimage,image,messageid,statusImage,msgcontent,messageBodyContent,receiptStatus,ackStatus,recentChatMsgFrom='';
      if (msgfrom == userJID) {
        tempname = msgto.toLowerCase();
      } else {
        tempname = msgfrom.toLowerCase();
      }

      if (groupInfo != null && typeof groupInfo[tempname] != "undefined") {
        profileimage = groupInfo[tempname][2];
        if (utils.getHexRegexPattern().test(profileimage)) {
          image = profileimage;
        } else if (profileimage !== "src/images/avatar/group/group_four.svg" && profileimage.trim() !== "") {
          image = mediaUrl + profileimage + "?mf=" + userToken;
        } else if (profileimage.trim() == "") {
          image = "src/images/avatar/group/group_four.svg";
        } else {
          image = profileimage;
        }

        name = groupInfo[tempname][1];
        messageid = msgid;
      } else if (userInfo != null && typeof userInfo[tempname] != "undefined") {
        profileimage = userInfo[tempname][2];
          if (utils.getHexRegexPattern().test(profileimage)) {
            image = profileimage;
          } else if (typeof profileimage != 'undefined' && profileimage !== "src/images/avatar/chat/profile_img_one.svg" && profileimage.trim() !== "") {
            image = mediaUrl + profileimage + "?mf=" + userToken;
          } else if (typeof profileimage === 'undefined' || profileimage.trim() == "") {
            image = "src/images/avatar/chat/profile_img_one.svg";
          } else {
            image = profileimage;
          }
        name = userInfo[tempname][1];
        messageid = msgid;
      } else if (broadcastProfile != null && typeof broadcastProfile[tempname] != "undefined") {
        profileimage = broadcastProfile[tempname][2];
        
        if (profileimage !== "src/images/avatar/broadcast/profile_img_one.png" && profileimage.trim() !== "") {
          image = mediaUrl + profileimage + "?mf=" + userToken;
        } else if (profileimage.trim() == "") {
          image = "src/images/avatar/broadcast/profile_img_one.png";
        } else {
          image = profileimage;
        }
        name = broadcastProfile[tempname][1];
        messageid = msgid;
      } else if (tempname != userJID) {


        if (chattype == "chat") {
          if (userInfo == null) {
            userInfo = {};
          }
          userInfo[tempname] = [];
          userInfo[tempname].push("chat");
          userInfo[tempname].push(tempname);
          userInfo[tempname].push("src/images/avatar/chat/profile_img_one.svg");
          userInfo[tempname].push(language.locali().default_status);
          localStorage.setItem("usersProfileInfo", JSON.stringify(userInfo));
          image = "src/images/avatar/chat/profile_img_one.svg";
          userprofile.getUserProfile(tempname);
          vCard.getVcard(tempname + "@" + stropheConnection.Server());
      
        } else {
            groups.getGroup(tempname);
        }
        
        name = tempname;
        messageid = msgid;

      }
      
      if (msgid == 0) {
        if (chattype == "chat") {
          if (broadcastProfile != null && typeof broadcastProfile[msgfrom] != "undefined") {
            profileimage = broadcastProfile[msgfrom][2];
            if (profileimage !== "src/images/avatar/broadcast/profile_img_one.png" && profileimage.trim() !== "") {
              image = mediaUrl + profileimage + "?mf=" + userToken;
            } else if (profileimage.trim() == "") {
              image = "src/images/avatar/broadcast/profile_img_one.png";
            } else {
              image = profileimage;
            }
            name = broadcastProfile[msgfrom][1];
            messageid = msgid;
            statusImage = "";
            msgcontent = "Broadcast Created";
          } else {
            msgcontent = "";
            statusImage = "";
          }

        } else if (chattype == "normal") {
          msgcontent = utils.groupstatus(tempname, publisher, status, msgbody, msgConvFrom, msgConvTo);
          statusImage = "";
          if(priorityCount > 0 || noAckPriorityCount > 0) {
            usersPriorityInfo[tempname] = [];
            usersPriorityInfo[tempname][0] = parseInt(noAckPriorityCount);
            usersPriorityInfo[tempname][1] = parseInt(priorityCount);
            if(msgbody == 3 && status == userJID) {
              // user removed from the group
            } else {
              msgcontent = '<span class="priority-message-recent">PRIORITY</span>' + msgcontent;
            } 
          }
        }
      } else {
        if (botType == "chat") {
          msgcontent = "";
          try {
            let msgContent = JSON.parse(utils.decrypt(msgbody, msgid));
            recentChatMsgFrom = msgContent.message_from;
            if(priorityCount > 0 || noAckPriorityCount > 0) {
              usersPriorityInfo[tempname] = [];
              usersPriorityInfo[tempname][0] = parseInt(noAckPriorityCount);
              usersPriorityInfo[tempname][1] = parseInt(priorityCount);
              messageBodyContent = utils.checkMessageType(msgContent, msgid, 2);
            } else {
              messageBodyContent = utils.checkMessageType(msgContent, msgid);
            }
            msgcontent = messageBodyContent[0];
          }catch (err){
            console.log(err);
          }
        } else {
          msgbody = $(this).find("message").text();
          msgcontent = "<i class='text-italic'>" + anchorme(minEmoji(msgbody), {
            attributes: [
              function (urlObj) {
                if (urlObj.protocol !== "mailto:")
                  return { name: "target", value: "blank" };
              }
            ]
          }) + "</i>";
          msgcontent = '<div class="message-text"><div class="statictext" style="display:none;">' + msgbody + "</div>" + msgcontent + "</div>";
        }

        if (chattype == "chat") {
          if (userInfo != null && typeof userInfo[tempname] != "undefined") {
            if (msgfrom == userJID) {

              if (recallstatus == 1) {
                if(priorityCount > 0 || noAckPriorityCount > 0) {
                  msgcontent = "<i class='text-italic name-status'><span class='priority-message-recent'>PRIORITY</span> You deleted this message</i>";
                } else {
                  msgcontent = "You deleted this message";
                }
                statusImage = "";
              } else {
                if (status == 1) {
                  receiptStatus = "<span class='receiptStatus'>Delivered</span>";
                } else if (status == 2) {
                  receiptStatus = "<span class='receiptStatus'>Read</span>";
                } else if (status == 0) {
                  receiptStatus = "<span class='receiptStatus'>Sent</span>";
                }

                if (msg_ack == 1) {
                  if (ack_status == 1) {
                    ackStatus = "<span class='ackStatus'>Acknowledged</span>";
                  } else {
                    ackStatus = "<span class='ackStatus'>Ack Pending</span>";
                  }
                } else if (msg_ack == 2) {
                  if (ack_status == 1) {
                    ackStatus = "<span class='ackStatus'>Accepted</span>";
                  } else if (ack_status == 2) {
                    ackStatus = "<span class='ackStatus'>Rejected</span>";
                  } else {
                    ackStatus = "<span class='ackStatus'>Acc/Rej Pending</span>";
                  }
                } else {
                  ackStatus = "";
                }

                if (ackStatus == "") {
                  statusImage = receiptStatus;
                } else {
                  statusImage = receiptStatus + ', ' + ackStatus;
                }
              }
            } else {
              if (recallstatus == 1 && msgcontent != "") {
                if(priorityCount > 0 || noAckPriorityCount > 0) {
                  msgcontent = "<i class='text-italic name-status'><span class='priority-message-recent'>PRIORITY</span> This message was deleted</i>";
                } else {
                  msgcontent = "This message was deleted";
                }
              }
              if (msg_ack == 1) {
                if (ack_status == 1) {
                  ackStatus = "<span class='ackStatus'>Acknowledged</span>";
                } else {
                  ackStatus = "<span class='ackStatus'>Ack Pending</span>";
                }
              } else if (msg_ack == 2) {
                if (ack_status == 1) {
                  ackStatus = "<span class='ackStatus'>Accepted</span>";
                } else if (ack_status == 2) {
                  ackStatus = "<span class='ackStatus'>Rejected</span>";
                } else {
                  ackStatus = "<span class='ackStatus'>Acc/Rej Pending</span>";
                }
              } else {
                ackStatus = "";
              }
              
              statusImage = (ackStatus != '') ? ackStatus : '';
            }
          }
        } else if (chattype == "normal") {
          if (groupInfo != null && typeof groupInfo[tempname] != "undefined") {
            receiptStatus = "<span class='receiptStatus'>Del'd <span class='deliveredCount'>0</span> of <span class='totalMembers'>0</span>, Read <span class='readCount'>0</span> of <span class='totalMembers'>0</span></span>";

            if(publisher == userJID) {
              if (recallstatus == 1 && msgcontent != "") {
                if(priorityCount > 0 || noAckPriorityCount > 0) {
                  msgcontent = "<i class='text-italic name-status'><span class='priority-message-recent'>PRIORITY</span> You deleted this message</i>";
                } else {
                  msgcontent = "You deleted this message";
                }
                statusImage = "";
              } else {

                if (msg_ack == 1) {
                  ackStatus = "<span class='ackStatus'>Ack'd <span class='acknowledgeCount'>0</span> of <span class='totalMembers'>0</span></span>";
                } else if (msg_ack == 2) {
                  ackStatus = "<span class='ackStatus'>(Acc'd <span class='acceptCount'>0</span>, Rej'd <span class='rejectCount'>0</span>) of <span class='totalMembers'>0</span></span>";
                } else {
                  ackStatus = "";
                }

                if (ackStatus == "") {
                  statusImage = receiptStatus;
                } else {
                  statusImage =  receiptStatus +', '+ ackStatus;
                }
              }
            } else {

              if (recallstatus == 1 && msgcontent != "") {
                if(priorityCount > 0 || noAckPriorityCount > 0) {
                  msgcontent = "<i class='text-italic name-status'><span class='priority-message-recent'>PRIORITY</span> This message was deleted</i>";
                } else {
                  msgcontent = "This message was deleted";
                }
              } 
              statusImage = "";
            }
            utils.getGroupMsginfo(messageid, tempname + "@mix." + stropheConnection.Server());
          } else {
            statusImage = "";
          }
        } else if (chattype == "broadcast") {
          if (recallstatus == 1 && msgcontent != "") {
            msgcontent = "You deleted this message";
            statusImage = "";
          } else {
            statusImage = "<span class='receiptStatus'> Sent</span>";
          }
        } else {
          statusImage = "";
        }
        messageid = msgid;
        status = statusImage;
      }

      if (status == 4) {
        msgcontent = "";
        statusImage = "";
        messageid = "";
      }
      if (msgcontent != "") {
        if (blockListUserInfo != null && typeof blockListUserInfo[tempname] != "undefined") {
          image = "src/images/avatar/chat/profile_img_one.svg";
          if (userInfo != null && typeof userInfo[tempname] != "undefined") {
            userInfo[tempname][2] = image;
            userInfo[tempname][3] = language.locali().default_status;
            localStorage.setItem("usersProfileInfo", JSON.stringify(userInfo));
          }
        }

        if (blockUserInfo != null && typeof blockUserInfo[tempname] != "undefined") {
          msgcontent = "";
          statusImage = "";
          time = "";
          messageid = "";
        }
        if ((deletedstatus == 1 && deletedby == userJID) || blockedby == msgto || deletedstatus == 2 ) {
          msgcontent = "";
          statusImage = "";
          time = "";
          messageid = "";
        }

        if ((chatdeletedstatus == 1 && chatdeletedby == userJID) || blockedby == msgto || chatdeletedstatus == 2 ) {
          //deleted chat
        } else {
          let msgStatusImage = statusImage;
          let recentChatObj = {
            nodeJid       :tempname,
            name          :name,
            image         :image,
            msgcontent    :msgcontent,
            time          :time,
            status        :msgStatusImage,
            messageid     :messageid,
            unReadCount   :unReadCount,
            tstatusImage  :"",
            updateRecent  :0,
            recentChatMsgFrom:recentChatMsgFrom
          }
          
          recentdesign.recentchatcontent(recentChatObj);
        }
      }
      if (chattype == "chat") {
        if (publisher != "") {
          tempname = publisher;
          if (broadcastProfile != null && typeof broadcastProfile[publisher] != "undefined") {
            if (deletedstatus == 0 && deletedby != userJID) {
              messageid = msgid;
              receiptStatus = "<span class='receiptStatus'>Del'd <span class='deliveredCount'>0</span> of <span class='totalMembers'>0</span>, Read <span class='readCount'>0</span> of <span class='totalMembers'>0</span></span>";

              if (recallstatus == 1 && msgcontent != "") {
                if(priorityCount > 0 || noAckPriorityCount > 0) {
                  msgcontent = "<i class='text-italic name-status'><span class='priority-message-recent'>PRIORITY</span> You deleted this message</i>";
                } else {
                  msgcontent = "You deleted this message";
                }
                statusImage = "";
              } else {

                if (msg_ack == 1) {
                  ackStatus = "<span class='ackStatus'>Ack'd <span class='acknowledgeCount'>0</span> of <span class='totalMembers'>0</span></span>";
                } else if (msg_ack == 2) {
                  ackStatus = "<span class='ackStatus'>(Acc'd <span class='acceptCount'>0</span>, Rej'd <span class='rejectCount'>0</span>) of <span class='totalMembers'>0</span></span>";
                } else {
                  ackStatus = "";
                }

                if (ackStatus == "") {
                  statusImage = receiptStatus;
                } else {
                  statusImage =  receiptStatus +', '+ ackStatus;
                }
              }
            let id = utils.randomString(16, 'a#');
            
            broadcastProfilePage.getBroadcastMsginfo(broadcastmsg_id,stropheConnection.Server(),id);
              
            } else {
              msgcontent = "";
              statusImage = "";
              time = "";
              messageid = "";
            }
            profileimage = broadcastProfile[publisher][2];
            if (profileimage !== "src/images/avatar/broadcast/profile_img_one.png" && profileimage.trim() !== "") {
              image = mediaUrl + profileimage + "?mf=" + userToken;
            } else if (profileimage.trim() == "") {
              image = "src/images/avatar/broadcast/profile_img_one.png";
            } else {
              image = profileimage;
            }
            name = broadcastProfile[publisher][1];
            if ((deletedstatus == 1 && deletedby == userJID) || blockedby == msgto || deletedstatus == 2 ) {
              msgcontent = "";
              statusImage = "";
              time = "";
              messageid = "";
            }
            if ((chatdeletedstatus == 1 && chatdeletedby == userJID) || blockedby == msgto || chatdeletedstatus == 2 ) {
              //deleted chat
            } else {
              let recentChatObj = {
                nodeJid       :tempname,
                name          :name,
                image         :image,
                msgcontent    :msgcontent,
                time          :time,
                status        :statusImage,
                messageid     :messageid,
                unReadCount   :unReadCount,
                tstatusImage  :"",
                updateRecent  :0,
                recentChatMsgFrom:recentChatMsgFrom
              }
              recentdesign.recentchatcontent(recentChatObj);
            }
          }
        }
      }
    });
    localStorage.setItem("usersPriorityInfo", JSON.stringify(usersPriorityInfo));
    if ($(recentiq).find("chat").length) {
      $("#norecent").remove();
      $("#norecentcontent").remove();
      recentdesign.RecentChatDesign();
      $(".heading-dot").show();
    }
  } catch (err) {
    console.log("Recent chat callback Handler", err);
  }
    return true;
  }
};
