let broadCastStropheConnection = require("./stropheConnection.js");
let broadCastRecentdesign = require("./recentdesign");
let broadCastUtils = require("./utils.js");
let recent = require('./recent.js');
let stropheConnection = require("./stropheConnection.js");
const conversation = require("./conversation.js");


module.exports = {
  toJID: function () {
    if (localStorage.getItem("toJID")) {
      return JSON.parse(localStorage.getItem("toJID"));
    } else {
      return "";
    }
  },
  /** Get Broadcast List IQ**/
  getBroadcast: function () {
    let broadcastiq = broadCastStropheConnection.StropheJs().$iq({
      type: "get",
      to: broadCastStropheConnection.Server(),
      id: broadCastUtils.randomString(10, "a#")
    }).c("query", {
      xmlns: "jabber:iq:mod_broadcast",
      status: "get_my_broadcast"
    });
    if (broadCastStropheConnection.Connection().connected) {
      broadCastStropheConnection.Connection().send(broadcastiq);
    }
  },
  /** Get Broadcast Callback IQ Function**/
  getBroadcastCallback: function (broadcastiq) {
    try {
      $(broadcastiq).find("query").each(function (index) {
        let name,status;
        let queryType = $(this).attr("type");
        if (typeof queryType != "undefined" && (queryType == "create_broadcast" || queryType == "add_broadcast_users" || queryType == "remove_broadcast_users")) {
          name = $(this).attr("mute");
          let from = broadcastiq.getAttribute("from");
          let fromuser = broadCastStropheConnection.Strophe().getNodeFromJid(from);

          module.exports.getBroadcast();
          setTimeout(function () {
            let image = "src/images/avatar/broadcast/profile_img_one.png";
            let messageid = 0;
            let statusImage = "";
            let time = "";
            let msgcontent = "Broadcast Created";
            let recentChatObj = {
              nodeJid       :fromuser,
              name          :name,
              image         :image,
              msgcontent    :msgcontent,
              time          :time,
              status        :statusImage,
              messageid     :messageid,
              unReadCount   :0,
              tstatusImage  :1
            }
            module.exports.CheckIsCreateBroadcast(queryType,fromuser)

            broadCastRecentdesign.recentchatcontent(recentChatObj);
            broadCastRecentdesign.RecentChatDesign();
            recent.getRecentChat()

          }, 2000);
        } else if(typeof queryType != "undefined" && queryType == "delete_broadcast"){
          let broadcastId = broadcastiq.getAttribute("id");
          let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
          if(broadcastProfile != null){
            broadcastProfile = broadcastProfile[broadcastId]
            localStorage.setItem("broadcastProfile", JSON.stringify(broadcastProfile));  
          }
        } else {
          let broadcastId = broadcastiq.getAttribute("id");;
          let broadcastProfile = {};
          let broadcastDetails = JSON.parse(localStorage.getItem("broadcastDetails"));
          if (broadcastDetails == null) {
            broadcastDetails = {};
          }

          $(broadcastiq).find("broadcast").each(function () {
            let nodeJid = $(this).find("broadcastid").text();
            let displayName = $(this).find("broadcastname").text();
            displayName = decodeURIComponent(displayName);
            broadcastProfile[nodeJid] = [];
            broadcastProfile[nodeJid].push("broadcast");
            broadcastProfile[nodeJid].push(displayName);
            broadcastProfile[nodeJid].push("src/images/avatar/broadcast/profile_img_one.png");
            localStorage.setItem("broadcastProfile", JSON.stringify(broadcastProfile));
            broadcastDetails[nodeJid] = {};
            $(this).find("participants").each(function () {
              let index = 0;
              $(this).find("participant").each(function () {
                let userId = $(this).attr("participant_id");
                let nickName = $(this).find("nickName").text();
                let image = $(this).find("image").text();
                status = $(this).find("status").text();
                broadcastDetails[nodeJid][index] = {};
                broadcastDetails[nodeJid][index].user = userId;
                broadcastDetails[nodeJid][index].nickName = nickName;
                broadcastDetails[nodeJid][index].image = image;
                broadcastDetails[nodeJid][index].status = status;
                index++;
              });
            });
          });
          localStorage.setItem("broadcastDetails", JSON.stringify(broadcastDetails));

           /** Check Current User Exist Or Not**/
          let broadCastUsers = [];
          conversation.updateuserprofGetGrpUser(broadCastUsers,broadcastDetails,broadcastId)
        }
      });
    } catch (error) {
      console.log("BroadCast Callback Error : ", error)
    }
   
    return true;
  },
  /** Create Broadcast IQ Function**/
  createBroadcast: function () {
    let userJID = broadCastStropheConnection.logininfo.userJID;
    let broadcastId = broadCastUtils.getId() + userJID;
    let toAddress = broadcastId + "@" + broadCastStropheConnection.Server();
    let tempBroadcast = JSON.parse(localStorage.getItem("tempBroadcast"));
    let name = encodeURIComponent(tempBroadcast.name);
    let broadcastAddUserInfo = JSON.parse(localStorage.getItem("broadcastAddUserInfo"));


    let createBroadcastiq = broadCastStropheConnection.StropheJs().$iq({
      type: "set",
      to: toAddress,
      id: broadCastUtils.randomString(10, "a#")
    }).c("query", {
      xmlns: "jabber:iq:mod_broadcast"
    }).c("broadcast",{
      name : name,
      status: "create_broadcast"

    }).c('users', {});
    for (let key in broadcastAddUserInfo) {
      createBroadcastiq.c("user").t(broadcastAddUserInfo[key][0]).up();
    };
    if (broadCastStropheConnection.Connection().connected) {
      broadCastStropheConnection.Connection().sendIQ(createBroadcastiq,module.exports.getBroadcastCallback);
    }
  },
  getCreateBroadcastCallback: function (createBroadcastiq){
    try {
      console.log("BROADCAST IQ CALL BACK")
      recent.getRecentChat()
      console.log("DONE")
    } catch (error) {
      console.log('Create Broadcast Call Back Error : ', error)
    }
  },
  /** Add Participant in existing Broadcast IQ Function**/
  addBroadcast: function (userid, msgToName) {
    let broadcastId = $('.add-broadcast-participant-link').attr('data-broadcast-id');
    let broadcastName = $('.add-broadcast-participant-link').attr('data-broadcast-name');
    let toAddress = broadcastId + "@" + broadCastStropheConnection.Server();
    userid = broadCastStropheConnection.Strophe().getNodeFromJid(userid);
    let createBroadcastiq = broadCastStropheConnection.StropheJs().$iq({
      type: "set",
      to: toAddress,
      id: broadcastId
    }).c("query", {
      xmlns: "jabber:iq:mod_broadcast"
    }).c("broadcast",{
      name : broadcastName,
      status: "create_broadcast"

    }).c('users', {}).c("user").t(userid).up();
    if (broadCastStropheConnection.Connection().connected) {
      broadCastStropheConnection.Connection().sendIQ(createBroadcastiq);
    }
  },
  /** Delete Broadcast IQ Function**/
  deleteBroadcast: function(nodeJid){
    let broadcastId = nodeJid;
    let toAddress = broadcastId + "@" + broadCastStropheConnection.Server();

    let deleteBroadcastIQ = broadCastStropheConnection.StropheJs().$iq({
      type: "set",
      to: toAddress,
      id: broadcastId
    }).c("query", {
      xmlns: "jabber:iq:mod_broadcast"
    }).c("broadcast",{
      status: "delete_broadcast"
    }).up();
    if (broadCastStropheConnection.Connection().connected) {
      broadCastStropheConnection.Connection().sendIQ(deleteBroadcastIQ,module.exports.getBroadcast);
    }
    
  },
  /** Remove Participant in existing Broadcast IQ Function**/
  removemember: function(memberJid, memberName,broadcastId){
    let toAddress = broadcastId + "@" + broadCastStropheConnection.Server();
    let removeBroadcastMembIQ = broadCastStropheConnection.StropheJs().$iq({
      type: "set",
      to: toAddress,
      id: broadcastId
    }).c("query", {
      xmlns: "jabber:iq:mod_broadcast"
    }).c("broadcast",{
      name : 'set',
      status: "remove_broadcast_users"

    }).c('users', {}).c("user").t(memberJid).up();
    delete localStorage.broadcastRmvUserInfo;

    if (broadCastStropheConnection.Connection().connected) {
      broadCastStropheConnection.Connection().sendIQ(removeBroadcastMembIQ);
    }
  },
  CheckIsCreateBroadcast: function(queryType,fromuser){
    if(queryType != "create_broadcast"){
      conversation.updateuserprofile(fromuser)
    }
  }
};