let stropheConnection = require('./stropheConnection.js');
let utils = require('./utils.js');
let storage = require('./storage.js');
let userprofile = require('./userprofile.js');
let language = require('./locale/language.js');

module.exports = {
   
    setVcard: function (data) {
        $('.error-node').html(' ');

        let vcardiq = stropheConnection.StropheJs().$iq({
            type: 'set',
            id: utils.getId()
        }).c('vCard', {
            xmlns: 'vcard-temp'
        }).c("nickName").t(data.displayname).up()
            .c("image").t(data.userimage).up()
            .c("status").t(data.userstatus).up()
            .c("role").t(data.role).up()
            .c("department").t(data.department).up()
            .c("siteId").t(data.siteId).up()
            .c("mobile_number").t(data.mobileNumber).up()
            .c("work_number").t(data.workNumber).up()
            .c("pager_number").t(data.pagerNumber).up()
            .c("comm_notes").t(data.nodes).up()
            .c("personal_mobile_number").t(data.personalMobileNumber).up();

        if (stropheConnection.Connection().connected) {
            stropheConnection.Connection().send(vcardiq);
        }
        let presenseiq = stropheConnection.StropheJs().$pres().c('x', {
            xmlns: 'vcard-temp:x:update'
        });
        if (stropheConnection.Connection().connected) {
            stropheConnection.Connection().send(presenseiq);
        }
    },
    getVcard: function (userid) {
        let vcardiq = stropheConnection.StropheJs().$iq({
            type: 'get',
            id: utils.randomString(16, 'aA#'),
            to: userid
        }).c('query', {
            xmlns: 'vcard-temp'
        });
        if (stropheConnection.Connection().connected) {
            stropheConnection.Connection().sendIQ(vcardiq, module.exports.vcardHandler);
        }
    },
    vcardHandler: function (vcard) {
        try {
            let userToken = storage.getuserToken(); 
            let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
            let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
            let toJID = utils.toJID();
            let mediaUrl = window._env_.ApiURL + "/media/";

            if ($(vcard).text() != "") {
                let admin = 0;
                let userJID = stropheConnection.logininfo.userJID;
                let from = vcard.getAttribute("from");
                let nickname = "";
                let image = "";
                let imageId = "";
                let commNotes = "";
                let role = "";
                let department = "";
                let roleDept = "";
                let siteId = "";
                let siteAbbreviation = "";
                let fromuser = stropheConnection.Strophe().getNodeFromJid(from);
                let workNumber,pagerNumber,personalMobileNumber,mobileNumber,isActiveTechnician,unregisteredUser,description,icon,foregroundColor,userstatus,deptSite;
                $(vcard).find('vCard').each(function () {
                    nickname = $(this).find('nickName').text().trim();
                    role = $(this).find('role').text().trim();
                    department = $(this).find('department').text().trim();
                    siteId = $(this).find('siteId').text();
                    workNumber = $(this).find('work_number').text();
                    if (workNumber == "" || workNumber == null)
                        workNumber = "";
                    pagerNumber = $(this).find('pager_number').text();
                    if (pagerNumber == "" || pagerNumber == null){
                        pagerNumber = "";
                    }
                    
                    personalMobileNumber = $(this).find('personal_mobile_number').text();
                    if (personalMobileNumber == "" || personalMobileNumber == null)
                        personalMobileNumber = "";

                    mobileNumber = $(this).find('mobile_number').text();
                    if (mobileNumber == "" || mobileNumber == null)
                        mobileNumber = "";
                    siteAbbreviation = utils.getSiteAbbreviation(siteId).trim();
                    admin = $(this).find('isAdmin').text().trim();
                    isActiveTechnician = $(this).find('isActiveTechnician').text().trim();
                    commNotes = $(this).find('comm_notes').text().trim();
                    unregisteredUser = $(this).find('unregistered_user').text().trim();
                    description = $(this).find('description').text().trim();
                    icon = $(this).find('icon').text().trim();
                    foregroundColor = $(this).find('foregroundColor').text().trim();

                    if (nickname == "") 
                        nickname = fromuser;

                    imageId = $(this).find('image').text();
                        if (imageId !== 'src/images/avatar/chat/profile_img_one.svg' && imageId.trim() !== '') {
                            image = mediaUrl + imageId + "?mf=" + userToken;
                        } else if (imageId.trim() == '') {
                            image = 'src/images/avatar/chat/profile_img_one.svg';
                        } else {
                            image = imageId;
                        }
                

                    userstatus = $(this).find('status').text();

                });

                if (fromuser == storage.adminName()) {
                    if (userInfo == null) {
                        userInfo = {};
                    }
                    userInfo[fromuser] = [];
                    userInfo[fromuser].push("chat");
                    userInfo[fromuser].push(nickname);
                    userInfo[fromuser].push(imageId);
                    userInfo[fromuser].push(userstatus);
                    localStorage.setItem("usersProfileInfo", JSON.stringify(userInfo));
                }

                if (fromuser == userJID) {
                    /**  Mysite Contact and Role Location Change **/  
                    if(siteId != ""){
                    let locationId = JSON.parse(localStorage.getItem("locationId"));
                    let vcardLocationId = { //Location Id Obj
                        mysite_contact: siteId,
                        mysite_role : siteId,
                        is_contact_update : false,
                        is_role_update : false

                    }
                    if(locationId == null && typeof locationId != "undefined"){// Empty LocationId
                        if(siteId != "")
                            localStorage.setItem("locationId",JSON.stringify(vcardLocationId));
                    }else {
                        // Update Mysite Contact & Role Ids
                        if(!(locationId.is_contact_update && locationId.is_role_update)) {
                            if(!locationId.is_contact_update && !locationId.is_role_update && siteId != "") {

                                localStorage.setItem("locationId",JSON.stringify(vcardLocationId));
                            } else if(locationId.is_contact_update && !locationId.is_role_update) {
                                vcardLocationId.mysite_contact = locationId.mysite_contact;
                                vcardLocationId.is_contact_update = locationId.is_contact_update;
                                localStorage.setItem("locationId",JSON.stringify(vcardLocationId));

                            } else if(!locationId.is_contact_update && locationId.is_role_update) {
                                vcardLocationId.mysite_role = locationId.mysite_role
                                vcardLocationId.is_role_update = locationId.is_role_update;
                                localStorage.setItem("locationId",JSON.stringify(vcardLocationId));

                            }
                            // Mysite Contact & Role Name Change 
                            let siteDetails = JSON.parse(localStorage.getItem("siteDetails"));
                            if(siteDetails != null && typeof siteDetails != "undefined"  && siteDetails.length != 0) {
                                if(vcardLocationId != null && typeof vcardLocationId != "undefined" && vcardLocationId != ""){
                                    let mysite_contact_id = vcardLocationId.mysite_contact;
                                    let mysite_role_id = vcardLocationId.mysite_role;

                                    let mysite_contact_details;
                                    let mysite_role_details;
                                    
                                    // If Selected Contact Sites Not Availabe
                                    if(typeof siteDetails[mysite_contact_id] != "undefined" ){
                                    mysite_contact_details = siteDetails[mysite_contact_id]
                                    }else {
                                    if(typeof originalLocationId != "undefined" && originalLocationId != "")
                                        mysite_contact_details = siteDetails[siteId]
                                    }
                                    
                                    // If Selected Roles Sites Not Availabe
                                    if(typeof siteDetails[mysite_role_id] != "undefined" ){
                                    mysite_role_details = siteDetails[mysite_role_id]
                                    }else {
                                    if(typeof originalLocationId != "undefined" && originalLocationId != "")
                                    mysite_role_details = siteDetails[siteId]
                                    }


                                    if(typeof mysite_role_details != "undefined" && mysite_role_details != ""){
                                    let abbName = mysite_role_details.abbreviation;
                                    let name = mysite_role_details.name;
                                    if(typeof abbName != "undefined" && abbName != "") {
                                        $("#mySiteRoleTxt").html(abbName)

                                    }else {
                                        $("#mySiteRoleTxt").html(name)
                                        
                                    }
                                    }
                                }
                            }
                        }
                    }      
                }

                let concatedLetter;
                if(image !== 'src/images/avatar/chat/profile_img_one.svg' && image.trim() !== ''){
                    $('.headingProfileDiv img').show();
                    $('.headingProfileDiv svg').hide();
                    $('.headingProfileDiv img').attr('id', 'leftSideProfileImgId');
                    
                    $('.headingProfileDiv img').attr('src', image);
                    $('.headingProfileDiv img').attr('data-imageId', imageId);
                }else {
                    if(nickname != "") {
                        $('.headingProfileDiv img').hide();
                        $('.headingProfileDiv svg').show();

                        concatedLetter = utils.getImageDisplayText(nickname);
                        $('.headingProfileDiv svg text').text(concatedLetter);
                    }
                }
                    $('.profile-image').attr('id', 'leftSideProfileInfoImgId');
                    
                    $(".myName").html(minEmoji(nickname));
                    
                    $('.profile-image').attr('src', image);
                    $('.profile-image').attr('data-imageId', imageId);
                    $(".profile-name").text(minEmoji(nickname));
                    let siteName = utils.getSiteName(siteId);
                    $("#siteDetails").text(siteId);
                    if(siteName != '')
                    $(".myRoleDept").html(siteName);
                    
                    if (userstatus != 0) {
                        $('#checkbox-switch').prop('checked', true);
                        $('.slider-offline').hide();
                        $('.slider-online').show();
                    }else {
                        $('#checkbox-switch').prop('checked', false);
                        $('.slider-online').hide();
                        $('.slider-offline').show();
                    }

                    // this is for showing in web chat header
                    $(".user-profile-status").html(minEmoji(utils.getStatusName(userstatus)));
                    $(".user-profile-status").css("color", utils.getStatusImage(userstatus));

                    if (userstatus == 0) {
                        $(".profile-status").html('Offline');
                        $(".profile-status").css("color", '#e90e18');
                        $(".profile-status").attr("data-id", userstatus);


                        $(".user-profile-status").html('Offline');
                        $(".user-profile-status").css("color", '#e90e18');
                        $(".user-profile-status").attr("data-id", userstatus);

                    } else {
                        $(".profile-status").html(minEmoji(utils.getStatusName(userstatus)));
                        $(".profile-status").css("color", utils.getStatusImage(userstatus));
                        $(".profile-status").attr("data-id", userstatus);

                    }

                    $('#profile').find(".profile-role").text(role);
                    $('#profile').find(".profile-loc").text(department);
                    $('#profile').find(".profile-address").text(siteName);
                    $('#profile').find(".profile-address").attr('data-id',siteId);

                    $('#profile').find(".profile-telephone").text(workNumber);
                    $('#profile').find(".profile-mobile").text(mobileNumber);
                    $('#profile').find(".profile-pager").text(pagerNumber);
                    $('#profile').find(".profile-cmnt").text(commNotes);
                    $('#profile').find(".commu_notes").text(commNotes);
                    $('#profile').find('.profile-personal-mobile').html(personalMobileNumber);
                  

                    let myInfo = JSON.parse(localStorage.getItem("myInfo"));
                    if (myInfo != null && typeof myInfo[fromuser] == "undefined" || myInfo == null) {
                        myInfo = {};
                        myInfo[fromuser] = [];
                    }

                    myInfo[fromuser][0]  = 'chat';
                    myInfo[fromuser][1]  = nickname;
                    myInfo[fromuser][2]  = imageId;
                    myInfo[fromuser][3]  = userstatus;
                    myInfo[fromuser][4]  = mobileNumber;
                    myInfo[fromuser][6]  = role;
                    myInfo[fromuser][8]  = siteName;
                    myInfo[fromuser][9]  = department;
                    myInfo[fromuser][10] = workNumber;
                    myInfo[fromuser][11] = pagerNumber;
                    myInfo[fromuser][13] = siteId;
                    myInfo[fromuser][14] = isActiveTechnician;
                    myInfo[fromuser][15] = commNotes;
                    myInfo[fromuser][16] = personalMobileNumber;

                    localStorage.setItem("myInfo", JSON.stringify(myInfo));

                    if (image.indexOf('profile_img_one') != -1) {
                        $('#profilepicturepick li:last-child').hide();
                        $('#profilepicturepick li:first-child').hide();
                    } else {
                        $('#profilepicturepick li:last-child').show();
                        $('#profilepicturepick li:first-child').show();
                    }


                    if (isActiveTechnician == 1) {
                        $(".profile-oncall").css("display", "flex");
                        $(".profile-oncall").attr("data-name", nickname);
                    }

                    $('.sett-content').find('.profile-name').text(minEmoji(nickname));
                    $('.sett-content').find('.profile-status').html(minEmoji(utils.getStatusName(userstatus)));
                    $('.sett-content').find('.profile-status').css("color", utils.getStatusImage(userstatus));
                    $('.sett-content').find('.profile-status').attr("data-id", userstatus);


                    $(".userprofile-name").html(minEmoji(nickname));
                    $(".userprofile-status").html(minEmoji(utils.getStatusName(userstatus)));
                    $(".userprofile-status").css("cssText", "color: "+utils.getStatusImage(userstatus) +"!important");
                    $(".userprofile-status").attr("data-id", userstatus);


                    $('.my-name').val(minEmoji(nickname));
                    $('.my-status').val(minEmoji(utils.getStatusName(userstatus)));
                    $('.my-status').css("color", utils.getStatusImage(userstatus));
                    $(".my-status").attr("data-id", userstatus);
                    
                    if(siteName != '')
                    $(".myRoleDept").html(siteName);

                    if ($('.myName')[0].scrollWidth > $('.myName').innerWidth())
                        $('.myName').attr("title", nickname);


                    if ($('.myRoleDept')[0].scrollWidth > $('.myRoleDept').innerWidth())
                        $('.myRoleDept').attr("title", siteName);

                } else {

                    fromuser = fromuser.toLowerCase();
                    if (userInfo != null && typeof userInfo[fromuser] != "undefined" && userInfo[fromuser][0] == 'chat') {
                        userInfo[fromuser] = [];
                        userInfo[fromuser].push('chat');
                        userInfo[fromuser].push(nickname);
                        userInfo[fromuser].push(imageId);
                        userInfo[fromuser].push(userstatus);
                        userInfo[fromuser].push(role);
                        userInfo[fromuser].push(department);
                        userInfo[fromuser].push(siteId);
                        userInfo[fromuser].push(admin);
                        userInfo[fromuser].push(isActiveTechnician);
                        userInfo[fromuser].push(mobileNumber);
                        userInfo[fromuser].push(workNumber);
                        userInfo[fromuser].push(pagerNumber);
                        userInfo[fromuser].push(commNotes);
                        userInfo[fromuser].push(description);
                        userInfo[fromuser].push(icon);
                        userInfo[fromuser].push(foregroundColor);
                        userInfo[fromuser].push(unregisteredUser);
                        userInfo[fromuser].push(personalMobileNumber);


                        
                        localStorage.setItem("usersProfileInfo", JSON.stringify(userInfo));
                        
                        if (toJID != '' && fromuser == stropheConnection.Strophe().getNodeFromJid(toJID) && unregisteredUser == "1") {
                            $(".blocked-person").text("You cannot send messages to this chat");
                            $(".row.reply").css("display", "none");
                            $(".blocked-person").css("display", "block");
                            $("#replyemoji").hide();
                            $("#microphone").hide();
                            $(".status-all").css("display", "none");
                            $(".profile-status").css("display", "none");
                        }

                        if (admin == 0 && typeof (userInfo[fromuser][4]) === "undefined") {
                            userprofile.getUserProfile(fromuser);
                        }

                    } else if (groupInfo != null && typeof groupInfo[fromuser] != "undefined" && groupInfo[fromuser][0] == 'group') {
                        if (groupInfo == null) {
                            groupInfo = {};
                        }
                        delete groupInfo[fromuser];
                        groupInfo[fromuser] = [];
                        groupInfo[fromuser].push('group');
                        groupInfo[fromuser].push(nickname);
                        groupInfo[fromuser].push(imageId);

                        if ($("[id='" + fromuser + "']").length) {
                            $("[id='" + fromuser + "']").find('.contact-list').text(minEmoji(nickname));
                        }

                        if (toJID != '' && fromuser == stropheConnection.Strophe().getNodeFromJid(toJID)) {
                            $('#message').find('.heading-name-meta').html(minEmoji(nickname));
                            $('.groupname').html(minEmoji(nickname) + '<i class="groupNameEdit"><svg xmlns="http://www.w3.org/2000/svg" width="13.787" height="13.787" viewBox="0 0 13.787 13.787"> <path id="Edit_2_" data-name="Edit (2)" d="M10.7,140.726l1.4-1.4L10.268,137.5l-1.4,1.4v.675h1.157v1.157Zm5.3-8.677a.249.249,0,0,0-.4.012l-4.218,4.218a.273.273,0,1,0,.386.386l4.218-4.218A.249.249,0,0,0,16,132.049Zm-.193-1.735,3.471,3.471-8.1,8.1H7.713v-3.471Zm5.351,1.591-1.109,1.109-3.471-3.471,1.109-1.109a1.164,1.164,0,0,1,1.639,0l1.832,1.832a1.164,1.164,0,0,1,0,1.639Z" transform="translate(-7.713 -128.096)" fill="#9a9a9a"></path> </svg></i>');
                        }
                        localStorage.setItem("groupProfile", JSON.stringify(groupInfo));

                    } else {
                        if ($("[id='" + fromuser + "']").length) {
                            $("[id='" + fromuser + "']").find('.name-meta').html(nickname);
                            $("[id='" + fromuser + "']").find('.other-prof-pic').attr("src", image);
                        }

                        $('#message').find('.heading-name-meta').html(nickname);
                        $('#message').find('.right-side-prof').attr('src', imageId);
                        $('#message').find('.right-side-prof').attr('usemap', imageId);
                        $('#message .heading-avatar-icon div').first().attr('src','')
                        $("#message").css("display", "block");

                        $('.infocontent').html(language.locali().contact_info);
                        $('.heading-online').css('display', 'block');
                        $('#groupparticipant').css('display', 'none');
                        $('#userprofile').find('.user-contact').css('display', 'block');
                        $('#userprofile').find('.profile-inner').html('<img src="' + image + '" onerror="' + userprofile.profileImgError() + '"  /><span class ="groupname">' + nickname + '</span>');

                        $('#userprofile').find('.user-contact .user-status').html(minEmoji(utils.getStatusName(userstatus)));
                        $('#userprofile').find('.user-contact .user-status').css("color", utils.getStatusImage(userstatus));

                        userInfo[fromuser] = [];
                        userInfo[fromuser].push('chat');
                        userInfo[fromuser].push(nickname);
                        userInfo[fromuser].push(imageId);
                        userInfo[fromuser].push(userstatus);
                        userInfo[fromuser].push(role);
                        userInfo[fromuser].push(department);
                        userInfo[fromuser].push(siteId);
                        userInfo[fromuser].push(admin);
                        userInfo[fromuser].push(isActiveTechnician);
                        userInfo[fromuser].push(mobileNumber);
                        userInfo[fromuser].push(workNumber);
                        userInfo[fromuser].push(pagerNumber);
                        userInfo[fromuser].push(commNotes);
                        userInfo[fromuser].push(personalMobileNumber);
                        
                        localStorage.setItem("usersProfileInfo", JSON.stringify(userInfo));
                    }

                }
                if(admin != '1'){
                    roleDept = utils.frameUserRoleDept(role, department, siteAbbreviation);
                }else {
                    roleDept = utils.getSiteAbbreviation(siteId).trim();
                    $('.heading-info').css('display', 'none');
                }

                if (department != "" && siteAbbreviation != "") {
                    deptSite = department + ", " + siteAbbreviation;
                } else if (department == "" && siteAbbreviation != "") {
                    deptSite = siteAbbreviation;
                } else if (department != "" && siteAbbreviation == "") {
                    deptSite = department;
                } else {
                    deptSite = '-';
                }

                if (toJID != '' && fromuser == stropheConnection.Strophe().getNodeFromJid(toJID)) {
                    $('#message').find('.heading-name-meta').html(nickname);
                    $('.right-side-prof').attr('src', image);
                    $('#message .heading-avatar-icon div').first().attr('src','')
                    
                    $('#userprofile').find('.profile-inner').find('img').attr('src', image);
                    $('#userprofile').find('.user-contact .profile-status').html(minEmoji(utils.getStatusName(userstatus)));
                    $('#userprofile').find('.user-contact .profile-status').css("color", utils.getStatusImage(userstatus));
                    $('#userprofile').find('.user-contact .profile-status').attr("data-id", userstatus);


                    $('#userprofile').find('.user-contact .profile-role').html(role);
                    $('#userprofile').find('.user-contact .profile-loc').html(department);
                    $('#userprofile').find('.profile-pager').html(pagerNumber);
                    $('#userprofile').find('.commu_notes').html(commNotes);
                    $('#userprofile').find('.profile-mobile').html(mobileNumber);
                    $('#userprofile').find('.profile-telephone').html(workNumber);
                    $('#userprofile').find(".commu_notes").html(commNotes);
                    $('#userprofile').find('.profile-address').html(utils.getSiteName(siteId));
                    $('#userprofile').find('.profile-personal-mobno').html(personalMobileNumber);

                    if(commNotes){
                        $('#userprofile .profile-inner').addClass('commu_notes-main');

                    }else {
                        $('#userprofile .profile-inner').removeClass('commu_notes-main');
                    }
                    if(unregisteredUser == 1){
                        let msgEl = $('#conversation .msglist:visible .row');
                        if(msgEl.length > 0){
                            $(".blocked-person").css("display", "block");
                            $('#attachmentDiv').css('display','none')
                            $('#con-head .heading-dot').css('display','none')
                            $(".blocked-person").text("You cannot send messages to this chat.");
                            $('#blck-unblck-del .block-con').hide();
                            $('#blck-unblck-del .unblock-con').hide();
                            $('.invitation_div').hide()

                        }else {

                        $('.invitation_div').css('display','block!important')
                        let email = $("[id='nonapp-"+userJid+"']").html();
                        $(".block-con").hide();
                        $(".unblock-con").show();
                        $(".blocked-person").css("display", "none");
                        $('.heading-attach').hide();
                        $(".row.reply").css("display", "none");
                        $(".contact-exit").css("display", "none");
                        $(".contact-msg").css("display", "none");
                        $(".contact-clearmsg").css("display", "none");
                        $("#pager_right").css("display", "none");
                        $("#replyemoji").hide();
                        $("#microphone").hide();
                        let iosInviteLink =  language.locali().IosInviteLink
                        let andrioidInviteLink =  language.locali().andrioidInviteLink


                        $(".msglist").html('<div class="invitation_div"><div class="invitation_class"><b style="font-size:13px;">Invitation to download Ikonix Connect</b></div><a  class="button_mail" href="mailto:'+email+'?subject=Invitation%20to%20use%20Ikonix%20Connect&body=Hi%20'+ nickname +'%2C%0A%0AIkonix%20Connect%20is%20a%20secure%20enterprise%20messaging%20app%20designed%20to%20connect%20people%20across%20our%20organisation.%0A%0ATo%20get%20started%2Cdownload%20it%20here%0A%0AiOS:%20'+encodeURIComponent(iosInviteLink)+'%0A%0AAndroid:'+encodeURIComponent(andrioidInviteLink)+'%0A%0A"><b>INVITE</b></a></div>')

                        }
                        
    
                    }else {
                        let blockUserInfo = JSON.parse(localStorage.getItem("blockUserInfo"));
             
                        $('.invitation_div').css('display','none')
                        if(!(blockUserInfo != null && typeof blockUserInfo[fromuser] != "undefined")){
                            $(".blocked-person").css("display", "none");
                            $(".row.reply").css("display", "block");
                            $('.row.reply').css('display', 'flex');
                            $('#blck-unblck-del .unblock-con').show();

                        } else {
                            $('#blck-unblck-del .block-con').show();
                            $('#blck-unblck-del .unblock-con').hide();

                        }
                        if(userInfo != null && typeof userInfo[fromuser] != "undefined"){
                            if(userInfo[fromuser][3] == '') { // Check User status is empty (alias chat)
                                $(".blocked-person").css("display", "block");
                                $(".row.reply").css("display", "none");
                            }else { //Normal Chat Users
                                $(".row.reply").css("display", "block");
                                $('.row.reply').css('display', 'flex');
                                $('#attachmentDiv').css('display','block')
                                $('#con-head .heading-dot').css('display','block')
                                $(".contact-exit").css("display", "block");
                                $(".contact-msg").css("display", "block");
                                $(".contact-clearmsg").css("display", "block");
                                
                                $("#replyemoji").show();
                                $("#microphone").show();
                                $('#blck-unblck-del .unblock-con').hide();
                            }
                        }
                       
                    }
                }

                if ($("[id='" + fromuser + "']").length) {
                    $("[id='" + fromuser + "']").find(".avatar-icon").find("img").attr("src", image);
                }

                if ($("[id='roster-" + fromuser + "']").length) {

                    $("[id='roster-" + fromuser + "']").find(".avatar-icon").find("img").attr("src", image);
                    $("[id='roster-" + fromuser + "']").find(".status-all").text(utils.getStatusName(userstatus));
                    $("[id='roster-" + fromuser + "']").find(".status-all").css("color", utils.getStatusImage(userstatus));
                    $("[id='roster-" + fromuser + "']").find(".name-meta").text(nickname);
                    $("[id='roster-" + fromuser + "']").find(".role-div .name-status").text(role);
                    $("[id='roster-" + fromuser + "']").find(".dept-div .name-status").text(deptSite);
                }
                    let loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
                    let loggedUser = loginUserInfo[0][0];
                if ($("#recentchatcontent").find("[id='recentContacImg-" + fromuser + "']")) {
                    if(userInfo != null && typeof userInfo[fromuser] == 'undefined' && typeof fromuser != 'undefined' && fromuser != loggedUser && (groupInfo == null || (groupInfo != null && typeof groupInfo[fromuser] == 'undefined'))){
                        utils.updateRosterDetails(fromuser,nickname)
                    }
                    
                    let profStatus = utils.getStatusName(userstatus);
                    if (profStatus == "") {
                        $("#recentchatcontent").find("[id='" + fromuser + "']").find(".role-dept-meta").css("margin-top", "5px");
                    } else {

                        $("#recentchatcontent").find("[id='" + fromuser + "']").find(".profile-status-meta").text(profStatus);
                        $("#recentchatcontent").find("[id='" + fromuser + "']").find(".profile-status-meta").css("color", utils.getStatusImage(userstatus));
                        $("#recentchatcontent").find("[id='" + fromuser + "']").find(".profile-status-meta").attr("title", utils.getStatusName(userstatus));

                    }

                    let concatedLetter;
                    if(image === 'src/images/avatar/chat/profile_img_one.svg' || image.trim() === ''){
                        if(nickname != "") {
                            concatedLetter = utils.getImageDisplayText(nickname);
                            $('#recentContactImg-'+fromuser+' text').text(concatedLetter)

                        }
                    }

                    $("#recentchatcontent").find("[id='" + fromuser + "']").find(".name-meta").text(nickname);
                    $("#recentchatcontent").find("[id='" + fromuser + "']").find(".profession").text(roleDept);
                    $("#recentchatcontent").find("[id='" + fromuser + "']").find(".profession").attr("title", roleDept);
                    
                    
                }

                if ($("[id='member-" + fromuser + "']").length) {
                    $("[id='member-" + fromuser + "']").find(".name-status").text(utils.getStatusName(userstatus));
                    $("[id='member-" + fromuser + "']").find(".name-status").css("color", utils.getStatusImage(userstatus));
                }

                if ($("[id='stared-" + fromuser + "']").length) {
                    $("[id='stared-" + fromuser + "']").find(".userpersonal-name").html(minEmoji(nickname));
                    $("[id='stared-" + fromuser + "']").find(".avatar-icon chat-list").find("img").attr("src", image);
                }

                if ($("[id='participant-" + fromuser + "']").length) {
                    $("[id='participant-" + fromuser + "']").find(".avatar-icon").find("img").attr("src", image);
                    $("[id='participant-" + fromuser + "']").find(".status-all").text(utils.getStatusName(userstatus));
                    $("[id='participant-" + fromuser + "']").find(".status-all").css("color", utils.getStatusImage(userstatus));
                    $("[id='participant-" + fromuser + "']").find(".name-meta").text(nickname);
                    $("[id='participant-" + fromuser + "']").find(".role-div .name-status").text(role);
                    $("[id='participant-" + fromuser + "']").find(".dept-div .name-status").text(deptSite);
                }

                if ($("[id='forwardcontact-" + fromuser + "']").length) {
                    $("[id='forwardcontact-" + fromuser + "']").find(".avatar-icon").find("img").attr("src", image);
                    $("[id='forwardcontact-" + fromuser + "']").find(".name-meta").text(nickname);
                    $("[id='forwardcontact-" + fromuser + "']").find(".role-div .name-status").text(role);
                    $("[id='forwardcontact-" + fromuser + "']").find(".dept-div .name-status").text(deptSite);
                }
                if (userstatus == 0) {
                    $(".profile-status").html('Offline');
                    $(".profile-status").css("color", '#e90e18');
                    $(".profile-status").attr("data-id", userstatus);

                        $("#recentchatcontent").find("[id='" + fromuser + "']").find(".profile-status-meta").text('Offline');
                        $("#recentchatcontent").find("[id='" + fromuser + "']").find(".profile-status-meta").css("color", '#e90e18');
                        $("#recentchatcontent").find("[id='" + fromuser + "']").find(".profile-status-meta").css("display", 'block');
                        $("#recentchatcontent").find("[id='" + fromuser + "']").find(".profile-status-meta").attr("title", 'Offline');

                }
            }
        } catch (err) {
            console.log("vcard Handler", err);
            console.log("vcard iq", vcard);
        }
        return true;
    }
};
