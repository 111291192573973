let stropheConnection = require('./stropheConnection.js');
let FavUtils = require('./utils.js');
let favLanguage = require('./locale/language.js');
let favdesign = require('./favouritedesign.js');
let favStorage = require('./storage.js');
module.exports = {
 
    favouritemsg: function (messageId) {
        let favouritemsg = stropheConnection.StropheJs().$iq({
            type: 'set',
            id: FavUtils.getId(),
            to: stropheConnection.Server()
        }).c('query', {
            xmlns: 'jabber:iq:user_fav',
            message_id: messageId,
            status: "set_favourite_message"

        });

        if (stropheConnection.Connection().connected) {
            stropheConnection.Connection().sendIQ(favouritemsg);
            $(".unstardrop-all").show();
        }
    },

    unfavouritemsg: function (messageId) {
        let toJID = FavUtils.toJID();
        let broadcastId = "";
        if (toJID != null && typeof toJID != "undefined") {
            let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
            let nodeJid = stropheConnection.Strophe().getNodeFromJid(toJID);
            if (broadcastProfile != null && typeof broadcastProfile[nodeJid] != "undefined") {
                broadcastId = nodeJid;
            }
        }
        let unfavouritemsg = stropheConnection.StropheJs().$iq({
            type: 'set',
            id: FavUtils.getId(),
            to: stropheConnection.Server()
        }).c('query', {
            xmlns: 'jabber:iq:user_fav',
            message_id: messageId,
            broadcast_id: broadcastId,
            status: "update_favourite_message"

        });
        if (stropheConnection.Connection().connected) {
            stropheConnection.Connection().sendIQ(unfavouritemsg);
        }
    },

    favouritemsglist: function () {
        let favouritemsglist = stropheConnection.StropheJs().$iq({
            type: 'get',
            id: FavUtils.getId(),
            to: stropheConnection.Server()
        }).c('query', {
            xmlns: 'jabber:iq:user_fav',
            chat_type: '',
            to_user: '',
            status: "get_favourite_message"

        });
        if (stropheConnection.Connection().connected) {

            stropheConnection.Connection().sendIQ(favouritemsglist);
        }
    },
    favMsgListInitialDesign: function(){
        $("#star-content").html('');
        $(".unstardrop-all").show();
    },
    favMsgListCallbackFirstSplit: function(chatMsgCallObj,userid){
        let userId = userid;
        if (chatMsgCallObj.broadcastid != '' && chatMsgCallObj.favmsgid == chatMsgCallObj.broadcastmsgid) 
            userId = chatMsgCallObj.broadcastid;

        if((chatMsgCallObj.deletedStatus !=2 || chatMsgCallObj.chatDeleteStatus !=2 ) && chatMsgCallObj.recallStatus ==0){
            if(chatMsgCallObj.deletedStatus == 0 || (chatMsgCallObj.deletedStatus ==1 && chatMsgCallObj.deletedBy !=chatMsgCallObj.loggedUserId ) || (chatMsgCallObj.chatDeleteStatus ==1 && chatMsgCallObj.chatDeleteUser!=chatMsgCallObj.loggedUserId )){
                let fwdMsgObj = {
                    userid          :userId,
                    time   	        :chatMsgCallObj.time,
                    fvmsgid  	    :chatMsgCallObj.fvmsgid,
                    chat_type       :chatMsgCallObj.chat_type,
                    msgbody    	    :chatMsgCallObj.msgbody,
                    sendfrom        :chatMsgCallObj.sendfrom,
                    broadcastmsgid  :chatMsgCallObj.broadcastmsgid,
                    broadcastid     :chatMsgCallObj.broadcastid
                }
                favdesign.forwardmessage(fwdMsgObj);
            }
        }
    },
    favMsgListSecondDesign: function() {
        favdesign.favouriteclickaction();
        FavUtils.forwardmsg();
        setTimeout(function () {
            $('#norecent').hide();
            $('.sideRecentListNavLoader').hide()
        }, 1000);
        $("#star-content").mCustomScrollbar("destroy");
        $("#star-content").mCustomScrollbar();
        FavUtils.determineDropDirection();
    },
    favMsgListStarTxtVisibleCheck: function(){
        if($('.starredtext:visible').length == 0) {
            $(".unstardrop-all").hide();
            $("#star-content").html('<div class="no-favmsg-cnts">' + favLanguage.locali().no_favourite_message_yet + '</div>');
        }
    },
    getfavMsgListMsgTo(userid,msgto){
        if (userid == favStorage.adminName()) {
            msgto = userid;
        }
        return msgto;
    },
    favMsgListNormalChat(statusObj,fwdMsgObj){
        if( (statusObj.deletedStatus !=2 || statusObj.chatDeleteStatus !=2 ) && statusObj.recallStatus == 0 && statusObj.isCurentuseravailable){
            if(statusObj.deletedStatus == 0 || (statusObj.deletedStatus ==1 && statusObj.deletedBy !=statusObj.loggedUserId ) || (statusObj.chatDeleteStatus ==1 && statusObj.chatDeleteUser !=statusObj.loggedUserId )){
                
                favdesign.forwardmessage(fwdMsgObj);
            }
        }
    },
    getfavouritemsglistCallBack: function (favouritelistiq) {
        try {
            if ($(favouritelistiq).find('chat').length != 0) {
                module.exports.favMsgListInitialDesign();
                    $(favouritelistiq).find('chat').each(function () {
                        let userid = $(this).find('user').text();
                        let time = $(this).find('time').text();
                        let chat_type = $(this).find('chat_type').text();
                        let favourite_date = $(this).find('favourite_date').text();
                        
                        let msgid = $(this).find('msgid').text();
                        let from_notification = $(this).find('from_notification').text();
                        let msgto = $(this).find('message').attr('to');
                        let broadcastid = $(this).find('message').find("chatcontent").attr("broadcast_id");
                        let broadcastmsgid = $(this).find('message').find("chatcontent").attr("broadcast_msgid");
                        let favmsgid = $(this).find('msgid').text();
                        /** Clear Msg Status */
                        let deletedStatus = $(this).find('deleted_status').text(); 
                        let deletedBy = $(this).find('deleted_by').text();
                        /** Delete Msg Status */
                        let chatDeleteStatus = $(this).find('chat_delete_status').text();
                        let chatDeleteUser = $(this).find('chat_delete_user').text();
                        /** Delete Chat Status **/
                        let recallStatus = $(this).find('recall_status').text();

                        msgto = module.exports.getfavMsgListMsgTo(userid,msgto) // If UserId and admin Same
                       
                        let loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
                        let loggedUserId = loginUserInfo[0][0];
                        if (chat_type == 'chat') {
                            let msgbody = $(this).find('message').text();
                            msgto = stropheConnection.Strophe().getNodeFromJid(msgto);
                            let chatMsgCallObj = {
                                broadcastid     :broadcastid,
                                favmsgid        :favmsgid,
                                deletedStatus   :deletedStatus,
                                chatDeleteStatus:chatDeleteStatus,
                                recallStatus    :recallStatus,
                                deletedBy       :deletedBy,
                                loggedUserId    :loggedUserId,
                                chatDeleteUser  :chatDeleteUser,
                                userId          :userid,
                                time   	        :time,
                                fvmsgid  	    :msgid,
                                chat_type       :chat_type,
                                msgbody    	    :msgbody,
                                sendfrom        :msgto,
                                broadcastmsgid  :broadcastmsgid
                            }
                            module.exports.favMsgListCallbackFirstSplit(chatMsgCallObj,userid)
                        } else if (chat_type == 'normal') {
                            let msgbody = $(this).find('body').text();
                            let groupId = $(this).find('from_notification').text();
                            from_notification = stropheConnection.Strophe().getNodeFromJid(from_notification);
                            let isCurentuseravailable = module.exports.checkCurrentuserAvailable(groupId); // Check logged user available in group
                            let statusObj = {
                                deletedStatus: deletedStatus,
                                chatDeleteStatus:chatDeleteStatus,
                                recallStatus:recallStatus,
                                isCurentuseravailable:isCurentuseravailable,
                                deletedBy:deletedBy,
                                loggedUserId:loggedUserId,
                                chatDeleteUser:chatDeleteUser,
                            } 
                            let fwdMsgObj = {
                                userid          :groupId,
                                time   	        :time,
                                fvmsgid  	    :msgid,
                                chat_type       :chat_type,
                                msgbody    	    :msgbody,
                                sendfrom        :from_notification,
                                broadcastmsgid  :favourite_date,
                            }
                            module.exports.favMsgListNormalChat(statusObj,fwdMsgObj);


                        }
                    });
                    module.exports.favMsgListSecondDesign();
                    
            } else {
                $(".unstardrop-all").hide();
                $("#star-content").html('<div class="no-favmsg-cnts">' + favLanguage.locali().no_favourite_message_yet + '</div>');
            }
            module.exports.favMsgListStarTxtVisibleCheck();
            
        } catch (err) {
            console.log("favourite message handler", err);
            console.log("favourite message iq", favouritelistiq);
        }

        return true;
    },
    unfavouriteallmsg: function () {

        let unfavouritemsg = stropheConnection.StropheJs().$iq({
            type: 'set',
            id: FavUtils.getId(),
            to: stropheConnection.Server()
        }).c('query', {
            xmlns: 'jabber:iq:user_fav',
            status: "clearall_favourite_message"

        });
        if (stropheConnection.Connection().connected) {

            stropheConnection.Connection().sendIQ(unfavouritemsg);
           
            $("#star-content").html('');
            $('#conversation').find('.fevorate').remove();
        }
    },
    checkCurrentuserAvailable: function (groupId) { // Check logged user available in group
        let flag = false;
        let groupDetail = JSON.parse(localStorage.getItem("groupDetails"));
        let loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
        let loggedUser = loginUserInfo[0][0];
        let group = groupDetail[groupId];

        if(typeof group != 'undefined'){
            for (let value of Object.entries(group)) {
                if(value[1].user == loggedUser) {
                    flag = true;
                }
              }
        }
        return flag;
    }
};