let stropheConnection = require('./stropheConnection.js');
let sendMessage = require('./sendMessage.js');
let mediaUpload = require('./mediaupload');
let utils = require('./utils.js');

module.exports = {
    fileUploadToS3: function (file, filetype, messageid, popupId, fileSize, options) {
        let ack = 0;
        let user = stropheConnection.logininfo.userJID;
        let filename,fileLocation,ackFiles;
        if (file) {            
            if (filetype == 'audio') {
                ack = $("#ack").val();
                filename = user + utils.getTimestamp();
                options.name = filename;
            }
            fileLocation = module.exports.mediaFileUpload(file,messageid);
            if (fileLocation != '') {
                if (filetype != "audio") {
                    utils.closeProfilePopUp(popupId);
                } else {
                    $(".reply-recording").show();
                    $("#audio-pop").hide();
                    $('.loader').css("display", "none");
                    $('.loader').removeClass(" innerpage-loader");
                }                
                $('.loader').css("display", "none");
                ackFiles = $('input[name="ackCheckbox"]:checked').val();
                if(typeof ackFiles != "undefined") {
                    ack = ackFiles;
                }
                let sendChatObj = {
                    msg         	 :fileLocation,
                    messagetype      :filetype,
                    messageid        :messageid,
                    filesize         :fileSize,
                    options    	     :options,
                    touser  	     :"",
                    ack              :ack,
                    forwardUserName  :"",
                    forwardUserImage :"",
                }
                sendMessage.sendChatMessages(sendChatObj);
            }else{
                module.exports.fileTypeError(filetype)
            }
        } else {
            module.exports.fileError()
        }
    },
    fileTypeError: function(filetype){
        $("#infoContent").text('ERROR UPLOADING ' + filetype);
        $('#infobox').css("display", "block").delay(4000).fadeOut();
        $(".media-upload-send").css('display', 'block');
        $('.loader').css("display", "none");
        $('.loader').removeClass(" innerpage-loader");
    },
    fileError: function(){
        $("#infoContent").text('Nothing to upload');
        $('#infobox').css("display", "block").delay(4000).fadeOut();
        $('.loader').css("display", "none");
        $('.loader').removeClass(" innerpage-loader");
    },
    mediaFileUpload: function(file,messageid){
        let nodeJid,groupInfo,userInfo,broadcastProfile,chatType,fileLocation;
        nodeJid = stropheConnection.Strophe().getNodeFromJid(utils.toJID());
        groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
        userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
        broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
        if (broadcastProfile != null && typeof broadcastProfile[nodeJid] != "undefined" && broadcastProfile[nodeJid][0] == 'broadcast') {
            chatType = 'broadcast';
        } else if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
            chatType = 'chat';
        } else if (groupInfo != null && typeof groupInfo[nodeJid] != "undefined") {
            chatType = 'groupchat';
            nodeJid = utils.toJID();
        } else {
            chatType = 'chat';
        }
        fileLocation = mediaUpload.fileUpload(nodeJid, chatType, file, messageid);
        return fileLocation;
    }

};
