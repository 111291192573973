let storage = require('./storage.js');
let userSession = require('./session.js');
let utils = require('./utils.js');
let language = require('./locale/language.js');

let recordsCount = 0;
let oncallNames;

let self = module.exports = {
    // function used to get the profilestatus via API
    getOncallNames: function (userId, isActive, page, viewer, name) {
        let oncallsUrl = window._env_.ApiURL + "/onCalls/userOnCalls";
        let params = '?userId=' + userId + '&isActive=' + isActive + '&page=' + page;
        if(page == 1)
            oncallNames = [];
        $.ajax({
            url: oncallsUrl + params,
            type: 'GET',
            dataType: 'json',
            cache: false,
            contentType: false,
            processData: false,
            async: false,
            crossDomain: true,
            headers: {
                'authorization': storage.getuserToken()
            },
            statusCode: {
                401: function (response) {
                    accessTokenRegenerate(JSON.parse(localStorage.getItem("loginUserInfo"))[0][0], userId, isActive, page, viewer, name);
                    console.log('Security Token Error');
                },
                500: function (response) {
                    console.log('Internal Server Error');
                }
            },
            success: function (response) {
                $('#onCallUserId').val(userId)
                $('#onCallUserName').val(name)

                if(viewer == 0) {
                    module.exports.oncallSuccessView(response,page,userId,isActive,name)
                } else {
                    module.exports.oncallSuccessViewEmpty(response,page,userId,isActive,name)
                }           
            },
            error: function (jqXHR, exception) {

                utils.errorMsgHandling(jqXHR, exception)

            }
        });
        return oncallNames;
    },
    oncallSuccessView: function(response,page,userId,isActive,name){
        recordsCount = parseInt(recordsCount) + parseInt(response.count);
        if(response.count > 0) {
            let recordsPage = page;
            for (let keys in response.data) {
                let values = response.data[keys];
                oncallNames.push(" " + values.onCallName);
            }
            
            if(recordsCount < response.totalRecords) {
                recordsPage = page + 1;
                self.getOncallNames(userId, isActive, recordsPage, 0, name);
            } else {
                return oncallNames;
            }
            
        } else {
            return [];
        }
    },
    getContentDiv(contentDiv,response,page,userId,isActive,name){
        for (let keys in response.data) {
            let values = response.data[keys];                            
            let statusText,statusColor;
            if(userId == values.activeMemberId) {
                statusText = "On Call";
                statusColor = "#4045D4";
            }else {
                statusText = "Not On Call";
                statusColor = "";
            }
            $(".oncall_details_text").text(name);
            contentDiv += '<tr class="onCallTrElement"><td>' + values.onCallName + '</td><td style="color: '+ statusColor +'">' + statusText + '</td><td>' + utils.getSiteAbbreviation(values.siteId) + '</td></tr>';   
        }

        return contentDiv;
    },
    pagerFirstPageTableElAppend: function(response,page,userId,isActive,name){
        $('#onCallRoleList').scrollTop(0)
        $(".oncall-rolelist").empty();
        if(response.count > 0) {
            let contentDiv = '<table class="table  table-border "><thead><tr><th> Role </th><th> Status</th><th> Site</th><tr></thead><tbody>';

            contentDiv = module.exports.getContentDiv(contentDiv,response,page,userId,isActive,name)
           
            contentDiv += '</tbody></table>';
            $(".oncall-rolelist").append(contentDiv);
        }
    },
    pagerPageTableElAppend: function(response,page,userId,isActive,name){
        if(response.count > 0) {
            let contentDiv = '';

            contentDiv = module.exports.getContentDiv(contentDiv,response,page,userId,isActive,name)

            $("#onCallRoleList tbody").append(contentDiv)
        }
    },
    oncallSuccessViewEmpty: function(response,page,userId,isActive,name){
        if(page <= 1){
            module.exports.pagerFirstPageTableElAppend(response,page,userId,isActive,name);
           
        }else {
            module.exports.pagerPageTableElAppend(response,page,userId,isActive,name);
           
        }
        
    }
}

// function used to get the regenerate the access token to get the profile status
async function accessTokenRegenerate(username, userId, isActive, recordsPage, viewer, name) {
    await userSession.setSession(username);
    self.getOncallNames(userId, isActive, recordsPage, viewer, name);
}

// Function to check if scroll has reached the bottom
function isScrollAtBottom(elementId) {
    // Get the scroll position of the element you want to monitor
    let element = document.getElementById(elementId); // Replace with the ID of your element
    let scrollPosition = element.scrollTop;
    
    // Calculate the maximum scrollable height of the element
    let scrollableHeight = element.scrollHeight - element.clientHeight;
    
    // Check if the scroll position is at the bottom or near the bottom
    // You can adjust the threshold (e.g., 10) as needed
    let threshold = 10;
    return scrollableHeight - scrollPosition <= threshold;
}

$('#onCallRoleList').on('scroll', function () {
    let isScroll = isScrollAtBottom('onCallRoleList');
    if (isScroll) {
        
        let userId =  $('#onCallUserId').val()
        let name   =  $('#onCallUserName').val()

        let contactfwd = $('#onCallRoleList .onCallTrElement').length;
        let contactfwdLength = parseInt(contactfwd) / language.locali().default_oncall_pagination_size;
        let page = Math.round(contactfwdLength) + 1;
        if (contactfwd % language.locali().default_oncall_pagination_size == 0) {
            module.exports.getOncallNames(userId, 0, page, 1, name);

        }

    }
});
