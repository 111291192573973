let stropheConnection = require('./stropheConnection.js');
let utils = require('./utils.js');


module.exports = {
   
    setGroupInfo: function (groupname, userimage) {
        let myJid = JSON.parse(localStorage.getItem("loginUserInfo"))[0][0];
        let myInfo = JSON.parse(localStorage.getItem("myInfo"));
        let groupinfoiq = stropheConnection.StropheJs().$iq({
            type: 'set',
            id: utils.getId(),
            to: utils.toJID()
        }).c('query', {
            status: 'set_group_info',
            xmlns: 'jabber:iq:mixgroupchat'
        })
        .c('profile', {
            message_from: myInfo[myJid][1],
            message_to: ''
        }).c('vCard', {
            xmlns: 'vcard-temp'
        }).c("nickName").t(groupname).up()
        .c("image").t(userimage).up();
        if (stropheConnection.Connection().connected) {
            stropheConnection.Connection().sendIQ(groupinfoiq, module.exports.groupinfoiqCallBack);
        }
    },
    groupinfoiqCallBack: function (groupinfoiq) {
        try {
            let fromuser = groupinfoiq.getAttribute("from");
            let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
            let from = stropheConnection.Strophe().getBareJidFromJid(fromuser);
            let groupid = stropheConnection.Strophe().getNodeFromJid(from);
            let msgContent = "You updated this team profile";
            if (groupid == stropheConnection.Strophe().getNodeFromJid(utils.toJID())) {
                module.exports.groupInfoMsgCallback(groupInfo,groupid,msgContent);

            }
        } catch (err) {
            console.log("groupinfoiqCallBack", err);
            console.log('groupinfoiqCallBack iq', groupinfoiq);
        }
        return true;
    },
    groupInfoMsgCallback : function(groupInfo,groupid,msgContent){
        let lastContent = false;
        if (typeof lastContent != "undefined" || !lastContent) {
            let conversation = require('./conversation.js');
            conversation.MessageLayout(utils.toJID(), 'down', '');
        }
        if ($("[id='"+groupid+"']").length) {
            if (groupInfo != null && typeof groupInfo[groupid] != "undefined") {
                let usersPriorityInfo = JSON.parse(localStorage.getItem("usersPriorityInfo"));
                let msgcontentRecent;
                if(usersPriorityInfo != null && typeof(usersPriorityInfo[groupid]) != "undefined" && (parseInt(usersPriorityInfo[groupid][0]) + parseInt(usersPriorityInfo[groupid][1])) > 0) {
                    msgcontentRecent = '<span class="priority-message-recent">PRIORITY</span>' + msgContent;
                } else {
                    msgcontentRecent = msgContent;
                }
                $("[id='"+groupid+"']").find('.name-status').html(msgcontentRecent);
            }
        }
    }
};
