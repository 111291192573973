let storage = require('./storage.js');
let userSession = require('./session.js');

// Call API to update the netwoek address
let self = module.exports = {
    updateNetworkAddress: function (resourceIp) {
        let formData = {
            resourceIp: resourceIp
        };
        $.ajax({
            url: resourceUpdateUrl,
            type: 'POST',
            dataType: 'json',
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(formData),
            async: true,
            crossDomain: true,
            headers: {
                'authorization': storage.getuserToken()
            },
            statusCode: {
                401: function (response) {
                    accessTokenRegenerate(JSON.parse(localStorage.getItem("loginUserInfo"))[0][0]);
                },
                500: function (response) {
                    console.log('Internal Server Error');
                }
            },
            success: function (response) {
                console.log("Updated");
            },
            error: function (jqXHR, exception) {
                
            }
        });
        return true;
    }
}

// function used to get the post the resource ip
async function accessTokenRegenerate(username) {
    await userSession.setSession(username);
    self.updateNetworkAddress();
}