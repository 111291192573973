let stropheConnection = require("./stropheConnection.js");
let clearMsgUtils = require("./utils.js");
let clearMsgConversation = require("./conversation.js");
let clearMsgMediaUpload = require("./mediaupload.js");
let roster = require("./roster.js");
let favouritemsglist = require("./favourite.js");
module.exports = {
  /** Clear chat function and SubFunction Start **/
  clearChat: function(fntype) {
    const tojid = JSON.parse(localStorage.getItem("toJID"));
    const broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
    const clearJID = JSON.parse(localStorage.getItem("clearJID"));
    const clearuser = clearJID != null ? clearJID : stropheConnection.Strophe().getNodeFromJid(tojid);
    
    let chattype = module.exports.determineChatType(clearuser, broadcastProfile);
    let deleteType = module.exports.determineDeleteType(fntype);
  
    module.exports.updateRecentChatUI(clearuser, fntype);
  
    let clearChat = module.exports.buildClearChatIQ(clearuser, chattype, deleteType);
    let toJID = JSON.parse(localStorage.getItem("toJID"));
  
    module.exports.handleLocalStorage(clearJID, toJID);
  
    if (stropheConnection.Connection().connected) {
      module.exports.sendClearChatIQ(clearChat);
    }
  
    setTimeout(module.exports.hideRecentChatMessage, 1000);
  },
  
  determineChatType: function(clearuser, broadcastProfile) {
    const isBroadcast = broadcastProfile != null && broadcastProfile[clearuser]?.[0] === "broadcast";
    return isBroadcast ? "broadcast" : "chat";
  },
  
  determineDeleteType:function(fntype) {
    return fntype === "delete" ? 0 : 1;
  },
  
  updateRecentChatUI:function(clearuser, fntype) {
    if (fntype === "delete") {
      module.exports.handleDeleteRecentChat(clearuser);
    } else {
      module.exports.handleClearRecentChat(clearuser);
    }
  },
  
  handleDeleteRecentChat:function(clearuser) {
    const recentChatElement = $("[id='" + clearuser + "']");
    if (recentChatElement.length) {
      recentChatElement.remove();
    }
    if ($("[id='roster-" + clearuser + "']").length) {
      setTimeout(roster.getRoster, 1000);
    }
  },
  
  handleClearRecentChat:function(clearuser) {
    const recentChatElement = $("[id='" + clearuser + "']");
    if (recentChatElement.length) {
      // Update UI for clear, if needed
    }
  },
  
  buildClearChatIQ:function(clearuser, chattype, deleteType) {
    return stropheConnection.StropheJs().$iq({
      type: "set",
      id: clearMsgUtils.getId(),
      to: stropheConnection.Server()
    }).c("query", {
      xmlns: "jabber:iq:user_activities",
      remove_user: clearuser,
      type: "chat",
      favourite: 0,
      status: "clear_message",
      chat_type: chattype,
      delete_type: deleteType
    });
  },
  
  handleLocalStorage:function(clearJID, toJID) {
    delete localStorage.clearJID;
  },
  
  sendClearChatIQ:function(clearChat) {
    clearMsgMediaUpload.fileDelete("", "clearConversation");
    stropheConnection.Connection().sendIQ(clearChat, module.exports.getclearChatCallBack);
  },
  
  hideRecentChatMessage:function() {
    $('#norecent').hide();
    $('.sideRecentListNavLoader').hide();
  },
  /** Clear chat function and SubFunction End **/
  
  getclearChatCallBack: function (clearmessageiq) {
    try {
      favouritemsglist.favouritemsglist();
    } catch (err) {
      console.log("getclearChatCallBack", err);
      console.log("getclearChatCallBack iq", clearmessageiq);
    }
    return true;
  },
  deleteMessage: function (messageids) {
    let deleteMessage = stropheConnection.StropheJs().$iq({ type: "set", id: clearMsgUtils.getId(), to: stropheConnection.Server() }).c("query", {
      xmlns: "jabber:iq:user_activities",
      message_ids: messageids,
      to_user: stropheConnection.Strophe().getBareJidFromJid(clearMsgUtils.toJID()),
      type: "chat",
      status: "delete_message"
    });
    if (stropheConnection.Connection().connected) {
      stropheConnection.Connection().sendIQ(deleteMessage);
      favouritemsglist.favouritemsglist();
      $(".msg-close").trigger("click");
    }

    let updateStarmessage = stropheConnection
    .StropheJs()
    .$iq({
        type: "set",
        id: clearMsgUtils.getId(),
        to: stropheConnection.Server()
    })
    .c("query", {
        xmlns: "jabber:iq:user_fav",
        status: "update_favourite_message",
        message_id: messageids
    });

    if (stropheConnection.Connection().connected) {
        stropheConnection.Connection().send(updateStarmessage);

    }

  },
  RecallMessage: function (messageids, chatType, messageType) {
    let msgPriority = $("#chat-"+ messageids).attr("data-priority");
    let recallid,recallmessage;
    let toJID = JSON.parse(localStorage.getItem("toJID"));
    if (chatType == 'groupchat') {
       let randomValue = clearMsgUtils.cryptoRandomGenerate();

       recallid = randomValue.toString(36).replace(/[^a-z]+/g, '').substring(0, 9);
       recallmessage = stropheConnection.StropheJs().$iq({
        type: 'set',
        id: recallid,
        to: toJID
      }).c('query', {
        xmlns: 'jabber:iq:mixgroupchat',
        msgid: messageids,
        msg_priority: msgPriority,
        status: 'recall'
      });
      if (stropheConnection.Connection().connected) {
        $("#starredcontent-" + messageids).closest(".staredmsg-sideBar").remove();
        stropheConnection.Connection().sendIQ(recallmessage);
        if (messageType == "media") {
          $("#media-full-view").html("");
          $(".media-gallery-empty").css("display", "block");
          $("#media-small-view").html("");
          $("#media-full-view").html('<div class="media-detail-body">No Media and Docs</div>');
          clearMsgConversation.getSingleChatMedia(toJID, "");
        }
      }
    } else {
      let randomValue = clearMsgUtils.cryptoRandomGenerate();

      recallid = randomValue.toString(36).replace(/[^a-z]+/g, '').substring(0, 9);
      recallmessage = stropheConnection.StropheJs().$msg({
        to: toJID,
        type: "chat",
        id: recallid
      }).c('recall', {
        xmlns: 'urn:xmpp:receipts',
        msg_priority: msgPriority,
        id: messageids
      });
      if (stropheConnection.Connection().connected) {
        $("#starredcontent-" + messageids).closest(".staredmsg-sideBar").remove();
        stropheConnection.Connection().send(recallmessage);
        if (messageType == "media") {
          $("#media-full-view").html("");
          $(".media-gallery-empty").css("display", "block");
          $("#media-small-view").html("");
          $("#media-full-view").html('<div class="media-detail-body">No Media and Docs</div>');
          clearMsgConversation.getGroupChatMedia(toJID, "");
        }
      }
    }

    let updateStarmessage = stropheConnection
    .StropheJs()
    .$iq({
        type: "set",
        id: clearMsgUtils.getId(),
        to: stropheConnection.Server()
    })
    .c("query", {
        xmlns: "jabber:iq:user_fav",
        status: "update_favourite_message",
        message_id: messageids
    });

    if (stropheConnection.Connection().connected) {
        stropheConnection.Connection().send(updateStarmessage);

    }
  },
  clearallMessage: function () {
    let clearallMessage = stropheConnection.StropheJs().$iq({ type: "set", id: clearMsgUtils.getId(), to: stropheConnection.Server() }).c("query", {
      xmlns: "jabber:iq:user_activities",
      type: "chat",
      status: "clear_all",
      delete_type: "1"
    });
    if (stropheConnection.Connection().connected) {
      stropheConnection.Connection().sendIQ(clearallMessage, module.exports.getclearallmessageCallBack);
      favouritemsglist.favouritemsglist();
    }
  },
  deleteallconversation: function () {
    let clearallMessage = stropheConnection.StropheJs().$iq({ type: "set", id: clearMsgUtils.getId(), to: stropheConnection.Server() }).c("query", {
      xmlns: "jabber:iq:user_activities",
      type: "chat",
      status: "clear_all",
      delete_type: "0"
    });
    if (stropheConnection.Connection().connected) {
      stropheConnection.Connection().sendIQ(clearallMessage, module.exports.getclearallmessageCallBack);
      favouritemsglist.favouritemsglist();
      $("#index-chat-section").show();
      delete localStorage.toJID;
    }
  },
  clearAllMsgUIAlignment: function(){
    $(".message-info-block .right-msg-back").trigger("click");
    $(".media-detail-section .right-msg-back").trigger("click");
    $("#right-prof-back").trigger("click");
    $(".conversation").removeClass("small-pane");
    $("#media-small-view").html("");
    $("#media-full-view").html("");
  },
  getclearallmessageCallBack: function (clearallMessage) {
    try {
      favouritemsglist.favouritemsglist();
      let toJID = JSON.parse(localStorage.getItem("toJID"));
      let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
      let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
      if(toJID != null) {
        let clearuser = stropheConnection.Strophe().getNodeFromJid(toJID);
        if (userInfo != null && typeof userInfo[clearuser] != "undefined") {
          clearMsgConversation.getSingleChatMedia(toJID, "");
          module.exports.clearAllMsgUIAlignment();
        } else if (groupInfo != null && typeof groupInfo[clearuser] != "undefined") {
          clearMsgConversation.getGroupChatMedia(toJID, "");
          module.exports.clearAllMsgUIAlignment();
        }else {
          $("#index-chat-section").show();
          $("#message").hide();
        }
      }   
      
    } catch (err) {
      console.log("getclearallmessageCallBack", err);
      console.log("getclearallmessageCallBack iq", clearallMessage);
    }
    return true;
  }
};
