let stropheConnection = require("./stropheConnection.js");
let groups = require("./groups.js");
let groupdesign = require("./groupdesign.js");
let timeFormat = require("./timeformat.js");
let chatdesign = require("./chatdesign.js");
let broadcast = require("./broadcast.js");
let blockuser = require("./blockUser.js");
let utils = require("./utils.js");
let favourite = require("./favourite.js");
let recent = require("./recent.js");
let recentdesign = require("./recentdesign.js");
let storage = require("./storage.js");
let conversation = require("./conversation.js");
let language = require('./locale/language.js');
let userprofile = require("./userprofile.js");
let roles = require('./roles.js');
let msgPriority;

module.exports = {
  
  messageHandler: function (message) {
    try {
    $(message).find("groupdelete").each(function (index) {
     let groupId = $(message).find("groupdelete").attr("group_id");
      groupdesign.groupdelete(groupId.split("@")[0]);

    });
      let forward = true;
      let offline = false;
      let broadcastid = "";
      let userJID = stropheConnection.logininfo.userJID;
      let group_recall_id = "";
      let tojid = utils.toJID();
      let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
      let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));

      $(message).find("delay").each(function (index) {
        offline = true;
      });
      if (!offline) {
        $(message).find("sent").each(function (index) {
          if ($(this).find("composing").length == 0 && $(this).find("gone").length == 0) {
            $(this).find("message").each(function (index) {
              let from = $(this).attr("from");
              let to = $(this).attr("to");
              let chattype = $(this).attr("type");
              let broadcastmsgid;
              let msgid,sendfrom,time,msgAck,ackStatus;
              if ($(message).find("chatcontent").length) {
                broadcastid = $(message).find("chatcontent").attr("broadcast_id");
                if (broadcastProfile != null && typeof broadcastProfile[broadcastid] == "undefined") {
                  broadcast.getBroadcast();
                }
                broadcastmsgid = $(message).find("chatcontent").attr("broadcast_msgid");
              }
              if (chattype == "chat") {
                msgid = $(this).attr("id");
                sendfrom = "";
              }
              if ($(message).find("timestamp").length) {
                time = timeFormat.convertTimestamp($(message).find("timestamp").attr("time"), "history");
              }
              if ($(this).find("body").length) {
                let msgbody = $(this).find("body").text();
                if (chattype == "normal") {
                  msgid = $(this).find("body").attr("message_id");
                  sendfrom = $(this).find("body").attr("sent_from");
                  msgAck = $(message).find("body").attr("message_ack");
                  msgPriority = $(message).find("body").attr("message_priority");
                } else {
                  msgAck = $(message).find("chatcontent").attr("message_ack");
                  msgPriority = $(message).find("chatcontent").attr("message_priority");
                  sendfrom = stropheConnection.Strophe().getNodeFromJid(from);
                }

                if (broadcastid == "") {
                  let fwdMsgObj = {
                    from       	    :from,
                    to         	    :to,
                    chattype   	    :chattype,
                    messageid  	    :msgid,
                    sendfrom   	    :sendfrom,
                    msgbody    	    :msgbody,
                    time       	    :time,
                    broadcastmsgid  :msgid,
                    msgAck  	      :msgAck,
                    msgPriority  	  :msgPriority
                  }

                  chatdesign.forwardmessage(fwdMsgObj);
                } else {
                  broadcastid = broadcastid + "@" + stropheConnection.Server();
                  setTimeout(function () {
                    let fwdMsgObj = {
                      from       	    :from,
                      to         	    :broadcastid,
                      chattype   	    :chattype,
                      messageid  	    :msgid,
                      sendfrom   	    :sendfrom,
                      msgbody    	    :msgbody,
                      time       	    :time,
                      broadcastmsgid  :broadcastmsgid,
                      msgAck  	      :msgAck,
                      msgPriority  	  :msgPriority
                    }

                    chatdesign.forwardmessage(fwdMsgObj);
                  }, 2000);
                }
              }
              if ($(this).find("acknowledge").length) {
                let type = $(this).find("acknowledge").attr("type");
                let msgid = $(this).find("acknowledge").attr("id");
                let msgPriority = $(this).find("acknowledge").attr("message_priority");
                let msgAck = $(this).find("acknowledge").attr("message_ack");
                let toUser = $(this).find("acknowledge").attr("to_user");
                let sendfrom = $(this).find("acknowledge").attr("sent_to");
                let sendfromid = stropheConnection.Strophe().getNodeFromJid(sendfrom);
                if (type == "seen") {
                  chatdesign.forwardseenacknowlege(sendfromid, msgid, msgPriority, msgAck, toUser);
                }
              }
              if ($(this).find("acknowledged").length) {
                ackStatus = $(this).find("acknowledged").attr("ack_status");
                let toUser;
                if (typeof $(this).find("acknowledged").attr("id") != "undefined") {
                  msgid = $(this).find("acknowledged").attr("id");
                  msgPriority = $(this).find("acknowledged").attr("message_priority");
                  toUser = $(this).find("acknowledged").attr("to_user");
                } 
                if($('#chat-' + msgid).find('.priority-message').text() == language.locali().priority_message || msgPriority == 2) {
                  utils.priorityMessageAcknowledged(toUser, msgid);
                }
                
                if($('#chat-'+msgid).find('.ackClick').text() == 'Tap to Acknowledge ') {
                  $('#chat-'+msgid).find('.ackClick').off('click');
                  $('#chat-'+msgid).find('.ackClick').removeClass("ackClick").addClass("acknowledged");
                  $('#chat-'+msgid).find('.acknowledged').html('Acknowledged <i class="acknow"></i>');
                } else if ($('#chat-'+msgid).find('span.acknow-accept:contains("Accept")')) {
                  if(ackStatus == 1) {
                    $('#chat-'+msgid).find('.acknow-accept').off('click');
                    $('#chat-'+msgid).find('.acknow-accept').removeClass("acknow-accept").addClass("accepted");
                    $('#chat-'+msgid).find('.acknow-reject').remove();
                    $('#chat-'+msgid).find('.accepted').html('Accepted <i><img src="./src/images/Accept_ic.svg" alt="accept"/></i>');
                  } else if(ackStatus == 2) {
                    $('#chat-'+msgid).find('.acknow-reject').off('click');
                    $('#chat-'+msgid).find('.acknow-reject').removeClass("acknow-reject").addClass("rejected");
                    $('#chat-'+msgid).find('.acknow-accept').remove();
                    $('#chat-'+msgid).find('.rejected').html('Rejected <i><img src="./src/images/Reject_ic.svg" alt="accept"/></i>');
                  }
                }
              } else if ($(this).find("seen").length) {
                if (typeof $(this).find("seen").attr("id") != "undefined") {
                  msgid = $(this).find("seen").attr("id");
                } else {
                  msgid = message.getAttribute("id");
                }
                let priority = $(this).find("seen").attr("message_priority");
                let ack = $(this).find("seen").attr("message_ack");
                let toUser = $(this).find("seen").attr("to_user");
                chatdesign.forwardseenacknowlege(stropheConnection.Strophe().getNodeFromJid(to), msgid, priority, ack, toUser);
              } else if ($(this).find("recall").length) {
                if (typeof $(this).find("recall").attr("id") != "undefined") {
                  msgid = $(this).find("recall").attr("id");
                  group_recall_id = $(this).find("recall").attr("group_id");
                  msgPriority = $(this).find("recall").attr("msg_priority");
                }
                chatdesign.forwardrecall(stropheConnection.Strophe().getNodeFromJid(to), msgid, msgPriority, group_recall_id);
              }
              forward = false;
            });
          }
        });
        $(message).find("received").each(function (index) {
          if ($(this).attr("xmlns") == "urn:xmpp:carbons:2") {
            $(this).find("message").each(function (index) {
              let from = $(this).attr("from");
              let chattype = $(this).attr("type");
              let msgid,sendfrom,time,msgAck,groupid,favmsgid,ClearType;
              if (chattype == "chat") {
                msgid = $(this).attr("id");
                sendfrom = stropheConnection.Strophe().getNodeFromJid(from);
              }
              if ($(message).find("timestamp").length) {
                time = timeFormat.convertTimestamp($(message).find("timestamp").attr("time"), "history");
              }
              if ($(this).find("body").length) {
                let msgbody = $(this).find("body").text();
                if (chattype == "normal") {
                  msgid = $(this).find("body").attr("message_id");
                } else {
                  msgAck = $(message).find("chatcontent").attr("message_ack");
                  let msgPriority = $(message).find("chatcontent").attr("message_priority");
                  let recMsgObj = {
                    from         :from,
                    typechat     :chattype,
                    msgid        :msgid,
                    sendfrom     :sendfrom,
                    msgbody      :msgbody,
                    time         :time,
                    forward      :false,
                    msgAck       :msgAck,
                    msgPriority  :msgPriority,
                  }

                  chatdesign.receivedmessage(recMsgObj);
                }
              }
              if ($(this).find("composing").length && ($(this).find("error").length) == 0) {
                groupid = "";
                if ($(this).find("composing").attr("group_id")) {
                  groupid = $(this).find("composing").attr("group_id");
                }
                chatdesign.composingStatus(from, groupid);
              }
              if ($(this).find("gone").length) {
                groupid = "";
                if ($(this).find("gone").attr("group_id")) {
                  groupid = $(this).find("gone").attr("group_id");
                }
                chatdesign.goneStatus(from, groupid);
              }

              if ($(message).find("msgstatus").length) {
                if($(message).find("msgstatus").attr("type") == "received") {
                  if (typeof $(message).find("msgstatus").attr("id") != "undefined") {
                    msgid = $(message).find("msgstatus").attr("id");
                  } else {
                    msgid = message.getAttribute("id");
                  }
                  let deliver_count = $(message).find("msgstatus").attr("deliver_count");
                  let seen_count = $(message).find("msgstatus").attr("seen_count");
                  let totCount = (1*deliver_count) + (1*seen_count);
                  chatdesign.forwardreceive(from, msgid, totCount);
                }
              }

              if ($(message).find("msgstatus").length) {
                if($(message).find("msgstatus").attr("type") == "seen") {
                  if (typeof $(message).find("msgstatus").attr("id") != "undefined") {
                    msgid = $(message).find("msgstatus").attr("id");
                  } else {
                    msgid = message.getAttribute("id");
                  }
                  let deliver_count = $(message).find("msgstatus").attr("deliver_count");
                  let seen_count = $(message).find("msgstatus").attr("seen_count");
                  let priority = $(message).find("msgstatus").attr("message_priority");
                  let msgAck = $(message).find("msgstatus").attr("message_ack");
                  let toUser = $(message).find("msgstatus").attr("to_user");
                  let delCount = (1*deliver_count) + (1*seen_count);
                  chatdesign.forwardseen(from, msgid, delCount, seen_count, priority, msgAck, toUser);
                }
              }
              
              if ($(this).find("userblocked").length) {
                blockuser.setblockstatus($(this).find("userblocked").attr("type"), $(this).find("userblocked").attr("blocked_user"));
              }

              if ($(this).find("acknowledge").length) {
                let type = $(this).find("acknowledge").attr("type");
                let msgid = $(this).find("acknowledge").attr("id");
                let msgPriority = $(this).find("acknowledge").attr("message_priority");
                let msgAck = $(this).find("acknowledge").attr("message_ack");
                let toUser = $(this).find("acknowledge").attr("to_user");
                let sendfrom = $(this).find("acknowledge").attr("sent_to");
                let sendfromid = stropheConnection.Strophe().getNodeFromJid(sendfrom);
                if (type == "seen") {
                  chatdesign.forwardseenacknowlege(sendfromid, msgid, msgPriority, msgAck, toUser);
                }
              } else if ($(this).find("groupdelete").length) {
                let groupId = $(this).find("groupdelete").attr("group_id");
                groupdesign.groupdelete(groupId);
              }else if ($(this).find("recall").length) {
                if (typeof $(this).find("recall").attr("id") != "undefined") {
                  msgid = $(this).find("recall").attr("id");
                  group_recall_id = $(this).find("recall").attr("group_id");
                  msgPriority = $(this).find("recall").attr("msg_priority");
                }
                chatdesign.forwardrecall(stropheConnection.Strophe().getNodeFromJid(from), msgid, msgPriority, group_recall_id);
              } else if ($(this).find("favourite").length) {
                if ($(this).find("favourite").attr("type") == "set_favourite") {
                  favmsgid = $(this).find("favourite").attr("message_id");
                  utils.starmsg(favmsgid);
                  favourite.favouritemsglist();
                } else if ($(this).find("favourite").attr("type") == "unset_favourite") {
                  favmsgid = $(this).find("favourite").attr("message_id");
                  utils.unstarmsg(favmsgid);
                  favourite.favouritemsglist();
                }
              }
              if ($(message).find("logout").length) {

                utils.closePopups();
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = storage.getKeycloakLogoutURL();
                
                let type = $(message).find("logout").attr("type");
                // if admin logs out 
                if(type != "admin_logout_user") {
                  $("#on-off-msg").removeClass("open");
                  localStorage.setItem("AnotherSessionOn", 1);
                  $(".session-container").css("display", "flex");
                }
              }
              if ($(this).find("messageclear").length) {
                ClearType = $(this).find("messageclear").attr("type");
                if (ClearType == "clear_message") {
                  $("#recentcontent").html("");
                  recent.getRecentChat();
                  let UserId = $(this).find("messageclear").attr("remove_message_user");
                  let DeleteType = $(message).find("messageclear").attr("delete_type");
                  let clearuser = stropheConnection.Strophe().getNodeFromJid(UserId);
                  if (clearuser == null) {
                    clearuser = UserId;
                  }
                  
                  $("[id='conversation"+clearuser+"']").remove();
                  if (DeleteType == 0) {
                    if ($("[id='"+clearuser+"']").length) {
                      $("[id='"+clearuser+"']").remove();
                    }

                    if (tojid != null && typeof tojid != "undefined" && clearuser == stropheConnection.Strophe().getNodeFromJid(tojid)) {
                      $("#index-chat-section").show();
                    }
                    if ($(document).find(".recentcontent").length == 0) {
                      $("#recentcontent").html("<div id='norecent'>No recent chat available</div>");
                    }
                    $(".recentchatcontact").find("[id='forwardcontact-"+clearuser+"']").remove();
                    delete localStorage.tojid;
                  } else {
                    if ($("[id='"+clearuser+"']").length) {
                      $("[id='"+clearuser+"']").prependTo("#sideBar");
                      $("[id='"+clearuser+"']").find(".name-status").html("");
                      $("[id='"+clearuser+"']").find(".time-meta").html("");
                      $("[id='"+clearuser+"']").find(".list-msg-status").html("");
                      $("[id='"+clearuser+"']").find(".sideBar-msg ").find("p").toggleClass("");
                      $("[id='"+clearuser+"']").find(".notify").html(parseInt(0));
                      $("[id='"+clearuser+"']").find(".notify").css("display", "none");
                      $("#recentchatcontent").mCustomScrollbar({
                        mouseWheelPixels: 500 //change this to a value, that fits your needs
                      });
                    }
                    if (tojid != null && typeof tojid != "undefined" && clearuser == stropheConnection.Strophe().getNodeFromJid(tojid)) {
                      $(".select-messages").hide();
                      $(".contact-clearmsg").hide();
                      $(".del-msg-wrap").hide();
                      conversation.MessageLayout(tojid, "down", "");
                    }
                  }
                } else if (ClearType == "clear_all") {
                  let toid = stropheConnection.Strophe().getNodeFromJid(tojid);
                  $("[id='conversation"+toid+"']").remove();
                  recentdesign.recentClickAction(toid);
                  $("#recentcontent").html("");
                  recent.getRecentChat();
                } else if (ClearType == "delete_message") {
                  let MessageId = $(message).find("messageclear").attr("message_ids");
                  let msgids = MessageId.replace("&apos;", "");
                  let MessageIds = msgids.split(",");
                  $.each(MessageIds, function (index, value) {
                    let itemvalue = value.replace("'", "");
                    itemvalue = itemvalue.replace("'", "");
                    if ($("#chat-" + itemvalue)) {
                      $("#chat-" + itemvalue).remove();
                    }
                    if ($("[id='"+itemvalue+"']")) {
                      $("[id='"+itemvalue+"']").remove();
                    }
                    if ($("[id='replycontent-"+itemvalue+"']")) {
                      $("[id='replycontent-"+itemvalue+"']").removeClass("replycontent");
                      conversation.Replyclick();
                    }
                  });

                  let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
                  let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
                  let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
                  let clearuser = stropheConnection.Strophe().getNodeFromJid(tojid);
                  $("#media-full-view").html("");
                  $(".media-gallery-empty").css("display", "block");
                  $("#media-small-view").html("");
                  $("#media-full-view").html('<div class="media-detail-body">No Media and Docs</div>');
                  if (userInfo != null && typeof userInfo[clearuser] != "undefined") {
                    conversation.getSingleChatMedia(tojid, "");
                  } else if (groupInfo != null && typeof groupInfo[clearuser] != "undefined") {
                    conversation.getGroupChatMedia(tojid, "");
                  } else if (broadcastProfile != null && typeof broadcastProfile[clearuser] != "undefined") {
                    conversation.getBroadcastChatMedia(tojid, "");
                  }

                  $(".message-info-block .right-msg-back").trigger("click");
                  $(".media-detail-section .right-msg-back").trigger("click");
                  $(".right-prof-back").trigger("click");
                  $(".conversation").removeClass("small-pane");
                  recent.getRecentChat();
                  delete localStorage.selectedmsgInfo;
                }
              }
              forward = false;
            });
          }
         
        });
        if (forward) {
          let from = message.getAttribute("from");
          let chattype = message.getAttribute("type");
          let msgid = message.getAttribute("id");
          let sendfrom = "";
          let time = "";
          let msgAck,ackStatus,groupid,favmsgid,ClearType,broadcast_msgid;
          if ($(message).find("chatcontent").length) {
            broadcastid = $(message).find("chatcontent").attr("broadcast_id");
            broadcast_msgid = $(message).find("chatcontent").attr("broadcast_msgid");

            if (broadcastProfile != null && typeof broadcastProfile[broadcastid] == "undefined") {
              broadcast.getBroadcast();
            }
          }
          if (chattype == "chat") {
            msgid = message.getAttribute("id");
          }

          if ($(message).find("timestamp").length) {
            time = timeFormat.convertTimestamp($(message).find("timestamp").attr("time"), "history");
          }
          if ($(message).find("body").length) {
            let msgbody = $(message).find("body").text();
            let msgPriority;
            if (chattype == "normal") {
              msgid = $(message).find("body").attr("message_id");
              sendfrom = stropheConnection.Strophe().getNodeFromJid($(message).find("body").attr("sent_from"));
              msgAck = $(message).find("body").attr("message_ack");
              msgPriority = $(message).find("body").attr("message_priority");
            } else {
              sendfrom = stropheConnection.Strophe().getNodeFromJid(from);
              msgAck = $(message).find("chatcontent").attr("message_ack");
              msgPriority = $(message).find("chatcontent").attr("message_priority");
            }
            let recMsgObj = {
              from           : from,
              typechat       : chattype,
              msgid          : msgid,
              sendfrom       : sendfrom,
              msgbody        : msgbody,
              time           : time,
              forward        : true,
              msgAck         : msgAck,
              msgPriority    : msgPriority,
              broadcastid    : broadcastid,
              broadcast_msgid: broadcast_msgid
            }
            chatdesign.receivedmessage(recMsgObj);
          }

          if ($(message).find("oncall").length) {
            
            let siteId = $('#siteDetails').text();
            roles.getOnCalls(siteId, '', 1, 1,'roles');

            let status = $(message).find("oncall").attr("status");
            let activeMemberId = $(message).find("oncall").find("oncall_active_member").text();
            let oncallId = $(message).find("oncall").attr("oncall_id");
            let myOncalls = JSON.parse(localStorage.getItem("myOncalls"));
            if(status == "set_active_status") {              
              if(activeMemberId == stropheConnection.logininfo.userJID) {
                if ($.inArray(oncallId, myOncalls) == -1) {
                  myOncalls.unshift(oncallId);
                }
              } else {
                myOncalls = $.grep(myOncalls, function(e){ 
                  return e != oncallId; 
                });
              }
            } else if (status == "delete_oncall") {
              myOncalls = $.grep(myOncalls, function(e){ 
                return e != oncallId; 
              });              
            } else if (status == "add_oncall") {              
              if(activeMemberId == stropheConnection.logininfo.userJID && $.inArray(oncallId, myOncalls) == -1) {
                myOncalls.unshift(oncallId);
              }
            }
            localStorage.setItem("myOncalls", JSON.stringify(myOncalls));

            if(myOncalls.length == 0) {
              $(".profile-oncall").css("display", "none");
            } else {
              $(".profile-oncall").css("display", "flex");
            }
          }
          if (sendfrom != userJID) {
            
            if ($(message).find("acknowledge").length) {
              let type = $(message).find("acknowledge").attr("type");
              let msgid = $(message).find("acknowledge").attr("id");
              let msgPriority = $(message).find("acknowledge").attr("id");            
              let sendfrom = $(message).find("acknowledge").attr("sent_to");
              let sendfromid = stropheConnection.Strophe().getNodeFromJid(sendfrom);
              if (type == "acknowledge") {
                if (userInfo != null && typeof userInfo[sendfromid] != "undefined") {
                  let broadcast_msgid = $(message).find("acknowledge").attr("broadcast_msgid");
                  chatdesign.forwardacknowlege(sendfromid, msgid, broadcast_msgid);
                }  
              }

              if (type == "recall") {
                if (typeof $(message).find("recall").attr("id") != "undefined") {
                  msgid = $(message).find("recall").attr("id");
                  msgPriority = $(message).find("recall").attr("msg_priority");
                  group_recall_id = $(message).find("recall").attr("group_id");
                }
                chatdesign.forwardrecall(userJID, msgid, msgPriority, group_recall_id);
              }
            }

            if ($(message).find("recall").length) {
              if (typeof $(message).find("recall").attr("id") != "undefined") {
                msgid = $(message).find("recall").attr("id");
                msgPriority = $(message).find("recall").attr("msg_priority");
                group_recall_id = $(message).find("recall").attr("group_id");
              }
              chatdesign.forwardrecall(stropheConnection.Strophe().getNodeFromJid(from), msgid, msgPriority, group_recall_id);
            }
            
            if ($(message).find("groupdelete").length) {
              let groupId = $(message).find("groupdelete").attr("group_id");
              groupdesign.groupdelete(groupId);
            }
            if ($(message).find("messageclear").length) {
              ClearType = $(message).find("messageclear").attr("type");
              if (ClearType == "clear_message") {
                let UserId = $(message).find("messageclear").attr("remove_message_user");
                let DeleteType = $(message).find("messageclear").attr("delete_type");
                let clearuser = stropheConnection.Strophe().getNodeFromJid(UserId);
                
                if (clearuser == null) {
                  clearuser = UserId;
                }
                recent.getRecentChat();

                $("[id='conversation"+clearuser+"']").remove();
                if (DeleteType == 0) {
                  if ($("[id='"+clearuser+"']").length) {
                    $("[id='"+clearuser+"']").remove();
                  }

                  if (tojid != null && typeof tojid != "undefined" && clearuser == stropheConnection.Strophe().getNodeFromJid(tojid)) {
                    $("#index-chat-section").show();
                  }
                  if ($("#recentcontent").find(".recentcontent").length == 0) {
                    $("#recentcontent").html("<div id='norecent'>No recent chat available</div>");
                  }
                  $(".recentchatcontact").find("[id='forwardcontact-"+clearuser+"']").remove();
                  delete localStorage.tojid;
                } else {

                  if ($("[id='"+clearuser+"']").length) {
                    $("#recentchatcontent").mCustomScrollbar("destroy");
                    $("[id='"+clearuser+"']").prependTo("#recentcontent");
                    $("[id='"+clearuser+"']").find(".name-status").html("");
                    $("[id='"+clearuser+"']").find(".time-meta").html("");
                    $("[id='"+clearuser+"']").find(".list-msg-status").html("");
                    $("[id='"+clearuser+"']").find(".notify").html(parseInt(0));
                    $("[id='"+clearuser+"']").find(".notify").css("display", "none");
                    $("[id='"+clearuser+"']").find(".sideBar-msg ").find("p").toggleClass("");
                    $("#recentchatcontent").mCustomScrollbar({
                      mouseWheelPixels: 500 //change this to a value, that fits your needs
                    });
                  }
                  if (tojid != null && typeof tojid != "undefined" && clearuser == stropheConnection.Strophe().getNodeFromJid(tojid)) {
                    $(".select-messages").hide();
                    $(".contact-clearmsg").hide();
                    $(".del-msg-wrap").hide();
                    conversation.MessageLayout(tojid, "down", "");
                  }
                }
              } else if (ClearType == "clear_all") {
                let toid = stropheConnection.Strophe().getNodeFromJid(tojid);
                $("[id='conversation"+toid+"']").remove();
                recentdesign.recentClickAction(toid);
                $("#recentcontent").html("");
                recent.getRecentChat();
                if(toid == null || toid == "null"){
                  $("#index-chat-section").show();
                  $("#message").hide();
                }
              } else if (ClearType == "delete_message") {
                let MessageId = $(message).find("messageclear").attr("message_ids");
                let msgids = MessageId.replace("&apos;", "");
                let MessageIds = msgids.split(",");

                $.each(MessageIds, function (index, value) {
                  let itemvalue = value.replace("'", "");
                  itemvalue = itemvalue.replace("'", "");

                  if ($("#chat-" + itemvalue)) {
                    $("#chat-" + itemvalue).remove();
                  }
                  if ($("[id='"+itemvalue+"']")) {
                    $("[id='"+itemvalue+"']").remove();
                  }
                  if ($("#replycontent-" + itemvalue)) {
                    $("#replycontent-" + itemvalue).removeClass("replycontent");
                    conversation.Replyclick();
                  }
                });

                let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
                let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
                let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
                let clearuser = stropheConnection.Strophe().getNodeFromJid(tojid);
                $("#media-full-view").html("");
                $(".media-gallery-empty").css("display", "block");
                $("#media-small-view").html("");
                $("#media-full-view").html('<div class="media-detail-body">No Media and Docs</div>');
                if (userInfo != null && typeof userInfo[clearuser] != "undefined") {
                  conversation.getSingleChatMedia(tojid, "");
                } else if (groupInfo != null && typeof groupInfo[clearuser] != "undefined") {
                  conversation.getGroupChatMedia(tojid, "");
                } else if (broadcastProfile != null && typeof broadcastProfile[clearuser] != "undefined") {
                  conversation.getBroadcastChatMedia(tojid, "");
                }

                $(".message-info-block .right-msg-back").trigger("click");
                $(".media-detail-section .right-msg-back").trigger("click");
                $("#right-prof-back").trigger("click");
                $(".conversation").removeClass("small-pane");
                recent.getRecentChat();
                delete localStorage.selectedmsgInfo;
              }
            }
            if ($(message).find("logout").length) {

              utils.closePopups();
              localStorage.clear();
              sessionStorage.clear();
              window.location.href = storage.getKeycloakLogoutURL();
              
              let type = $(message).find("logout").attr("type");
              // if admin logs out 
              if(type != "admin_logout_user") {
                $("#on-off-msg").removeClass("open");
                localStorage.setItem("AnotherSessionOn", 1);
                $(".session-container").css("display", "flex");
              }
            }
            if ($(message).find("favourite").length) {
              if ($(message).find("favourite").attr("type") == "set_favourite_message") {
                favmsgid = $(message).find("favourite").attr("message_id");
                utils.starmsg(favmsgid);
                favourite.favouritemsglist();
              } else if ($(message).find("favourite").attr("type") == "update_favourite_message") {
                favmsgid = $(message).find("favourite").attr("message_id");
                utils.unstarmsg(favmsgid);
                favourite.favouritemsglist();
              } else if ($(message).find("favourite").attr("type") == "delete_favourite_message") {
                $("#star-content").html("");
                if (tojid !== null && typeof tojid !== "undefined") {
                  let divjid = stropheConnection.Strophe().getNodeFromJid(tojid);
                  $("[id='conversation"+divjid+"']").find(".fevorate").remove();
                }

                $('.unstarmsg').attr('class', function () {
                  $(".unstarmsg").text(language.locali().star_message);
                  $(this).removeClass('unstarmsg').addClass('starmsg');
                  utils.forwardmsg();
                });
              } else {
                if ($(message).find("favourite").attr("type") == "set_favourite") {
                  favmsgid = $(message).find("favourite").attr("message_id");
                  utils.starmsg(favmsgid);
                  favourite.favouritemsglist();
                } else if ($(message).find("favourite").attr("type") == "unset_favourite") {
                  favmsgid = $(message).find("favourite").attr("message_id");
                  utils.unstarmsg(favmsgid);
                  favourite.favouritemsglist();
                }
              }
            }

            if ($(message).find("msgstatus").length) { //Received for Group chat
              if($(message).find("msgstatus").attr("type") == "received") {
                if(typeof $(message).find("msgstatus").attr("broadcastmsg_id") != "undefined"  && $(message).find("msgstatus").attr("broadcastmsg_id") != ''){
                    msgid = $(message).find("msgstatus").attr("broadcastmsg_id");

                }else {
                  if (typeof $(message).find("msgstatus").attr("id") != "undefined") {
                    msgid = $(message).find("msgstatus").attr("id");
                  } else {
                    msgid = message.getAttribute("id");
                  }
                }
                
                let deliver_count = $(message).find("msgstatus").attr("deliver_count");
                let seen_count = $(message).find("msgstatus").attr("seen_count");
                let totCount = (1*deliver_count) + (1*seen_count);
                chatdesign.forwardreceive(from, msgid, totCount);
              }
            }
            else { //Received for Single chat
              if ($(message).find("received").length) {
                let msgid = $(message).find("received").attr("id");
                chatdesign.forwardreceive(from, msgid);
              }
            }
            
          if(typeof $(message).find("seen").attr('group_id') != 'undefined') { // Seen for Group chat
            let g_id = $(message).find("seen").attr('group_id').split('@')[0]

            let grpCovMsgs;
            if($('#conversation'+g_id+' .msglist:visible').length >0 ){
              grpCovMsgs = $('#conversation .msglist:visible .message-body .sender').closest('.message-body');

            }{
              grpCovMsgs = $('#conversation'+g_id+' .message-body .sender').closest('.message-body');
            }

            let chatIds = [];
            if(grpCovMsgs.length > 0){
                grpCovMsgs.each(function (key,value) {
                    let id = $(this).attr('id');
                    id = id.split('-')[1];
                    chatIds.push(id)
                })
                if(chatIds.length > 0) {
                    chatIds =  chatIds.join(',');
                    module.exports.readReciptUpdate(chatIds)
                }
            }
          }
          else { //Seen for Broadcast and  Single chat
            if ($(message).find("seen").length) {
              let broadcastID = $(message).find("seen").attr('broadcastmsg_id');
              let msgid;
              if(typeof broadcastID != 'undefined' && broadcastID != ''){
                let ToJid = JSON.parse(localStorage.getItem("toJID"));
                let g_id;
                if(ToJid != null && typeof ToJid != 'undefined'){
                  g_id = stropheConnection.Strophe().getNodeFromJid(ToJid);
                }
                
                let grpCovMsgs;
                if($('#conversation'+g_id+' .msglist:visible').length >0 ){
                  grpCovMsgs = $('#conversation .msglist:visible .message-body .sender').closest('.message-body');

                }{
                  grpCovMsgs = $('#conversation'+g_id+' .message-body .sender').closest('.message-body');
                }

                let chatIds = [];
                if(grpCovMsgs.length > 0){
                    grpCovMsgs.each(function (key,value) {
                        let id = $(this).attr('id');
                        id = id.split('-')[1];
                        chatIds.push(id)
                    })
                    if(chatIds.length > 0) {
                        chatIds =  chatIds.join(',');
                        module.exports.broadcastReadReciptUpdate(chatIds,g_id)
                    }
                }


              }else {
                msgid = $(message).find("seen").attr("id");
                chatdesign.forwardseen(from, msgid);
              }
              
            }
          }
       
           
            if ($(message).find("acknowledged").length) {
              ackStatus = $(message).find("acknowledged").attr("ack_status");
              if (typeof $(message).find("acknowledged").attr("id") != "undefined") {
                msgid = $(message).find("acknowledged").attr("id");
                msgPriority = $(message).find("acknowledged").attr("message_priority");
              } else {
                msgid = message.getAttribute("id");
                msgPriority = message.getAttribute("message_priority");
              }
              let broadcastMsgId = $(message).find("acknowledged").attr("broadcast_msgid");
              if(typeof broadcastMsgId != 'undefined'&& broadcastMsgId != 'undefined' && broadcastMsgId != ''){
                msgid = broadcastMsgId;
              }
              chatdesign.forwardackstatus(from, msgid, ackStatus, msgPriority);
            }
            if ($(message).find("userblocked").length) {
              blockuser.setblockstatus($(message).find("userblocked").attr("type"), $(message).find("userblocked").attr("blocked_user"));
            }
            if ($(message).find("composing").length && ($(message).find("error").length) == 0) {
              groupid = "";
              if ($(message).find("composing").attr("group_id")) {
                groupid = $(message).find("composing").attr("group_id");
              }
              chatdesign.composingStatus(from, groupid);
            }
            if ($(message).find("gone").length) {
              groupid = "";
              if ($(message).find("gone").attr("group_id")) {
                groupid = $(message).find("gone").attr("group_id");
              }
              chatdesign.goneStatus(from, groupid);
            }
            if ($(message).find("info").length) {
              let new_admin = "";
              let done_by,message_from,message_to,nodeJid,addJid,msgcontent,messagebody,msgcontentRecent;
              if ($(message).find("info").attr("new_admin")) {
                new_admin = $(message).find("info").attr("new_admin");
                done_by = $(message).find("info").attr("done_by");
                message_from = $(message).find("info").attr("message_from");
                message_to = $(message).find("info").attr("message_to");
                chatdesign.makeadmin(from, done_by, new_admin, message_from, message_to);
              } else if ($(message).find("info").attr("status")) {

                if ($(message).find("info").attr("status") == "group_participant_updated") 
                  groups.getgroupdetails(from);

                if ($(message).find("info").attr("status") == "group_profile_updated") {
                  nodeJid = stropheConnection.Strophe().getNodeFromJid(from);
                  groupid = stropheConnection.Strophe().getNodeFromJid(tojid);
                  done_by = $(message).find("info").attr("done_by");
                  addJid = stropheConnection.Strophe().getNodeFromJid(done_by);
                  message_from = $(message).find("info").attr("message_from");
                  message_to = $(message).find("info").attr("message_to");
                  groups.getGroup(nodeJid);
                  if (tojid != null && typeof tojid != "undefined" && nodeJid == groupid) {
                    msgcontent = utils.groupstatus(groupid, addJid, from, 4, message_from, message_to);
                    messagebody = '<div id = "notify-' + msgid + '" class="row message-previous"><div class="col-sm-12 previous"><a>' + msgcontent + "</a></div></div>";
                    $("[id='conversation"+groupid+"']").append(messagebody);

                    let usersPriorityInfo = JSON.parse(localStorage.getItem("usersPriorityInfo"));
                    if(usersPriorityInfo != null && typeof(usersPriorityInfo[groupid]) != "undefined" && (parseInt(usersPriorityInfo[groupid][0]) + parseInt(usersPriorityInfo[groupid][1])) > 0) {
                      msgcontentRecent = '<span class="priority-message-recent">PRIORITY</span>' + msgcontent;
                    } else {
                      msgcontentRecent = msgcontent;
                    }


                    $("[id='"+groupid+"']").find('.name-status').html(msgcontentRecent);
                    $("[id='"+groupid+"']").find('.list-msg-status').html('');
                    $("[id='"+groupid+"']").find('.time-meta').html(timeFormat.currenttime());
                  }
                }
              }
            }
            let publisher,addid,message_from,message_to;
            if ($(message).find("item").length) {
              publisher = $(message).find("item").attr("publisher");
            }
            if ($(message).find("participant").length) {
              addid = $(message).find("participant").attr("jid");
              message_from = $(message).find("participant").attr("message_from");
              message_to = $(message).find("participant").attr("message_to");
              chatdesign.groupmemberadd(from, publisher, addid, message_from, message_to);
            }
            if ($(message).find("participants").length) {
              addid = $(message).find("participants").attr("jid");
              message_from = $(message).find("participants").attr("message_from");
              message_to = $(message).find("participants").attr("message_to");
              chatdesign.groupmemberadd(from, publisher, addid, message_from, message_to);
            }
            if ($(message).find("retract").length) {
              let itemid = $(message).find("retract").attr("id");
              publisher = $(message).find("retract").attr("publisher");
              message_from = $(message).find("retract").attr("message_from");
              message_to = $(message).find("retract").attr("message_to");
              if (from && publisher && itemid) {
                chatdesign.groupmemberremove(from, publisher, itemid, message_from, message_to);
              }
            }

            if ($(message).find("updateprofile").length) {
              let userId = stropheConnection.logininfo.userJID;
            console.log('GKGKGKGKGKGKGKGKGGK')

              userprofile.getUserProfile(userId);
            }
          }
        }
        $(message).find("reminder").each(function () {
          if($(this).attr("type") == language.locali().priority_reminder) {
            let messageId = $(this).attr("id");
            let fromUser = $(this).attr("from_user");
            if(utils.checkBlockedOrNotInGroup(fromUser)) {
              chatdesign.sendReminderMessage(messageId, fromUser);
            }
          }
        });

      }

        
    } catch (error) {
      console.log("Message Handler Error :", error);
    }
    return true;
  },

  iqHandler: function (iq) {
    try {
      let userId = $(iq).find("query").attr("user_id");
      let userStatus = $(iq).find("query").attr("user_status");
      if(userId != undefined && userStatus != undefined) 
        utils.changeOncallStatus(userId, userStatus);
    } catch (err) {
      console.log("Receive iq handler", err);
      console.log("Receive iq", iq);
    }
    return true;
  },
  broadcastReadReciptUpdate: function (chatIds,g_id) {
    let readReciptList = stropheConnection.StropheJs().$iq({
        type: 'get',
        to: stropheConnection.Server()
    }).c('query', {
        xmlns: 'jabber:iq:user_activities',
        status: "get_broadcast_receipts_count",
        msg_ids: chatIds,
        broadcast_id:g_id
    });
    if (stropheConnection.Connection().connected) {
        stropheConnection.Connection().sendIQ(readReciptList, module.exports.getBroadcastReadReciptCallback);
    }
  },
  getBroadcastReadReciptCallback(readReceiptIq){
    let msgId;
    let delCount;
    let seenCount;
      $(readReceiptIq).find('receipt').each(function (key,value) {
        msgId =  $(this).attr('msgid');
        delCount = $(this).find('deliver_count').text();
        seenCount = $(this).find('seen_count').text();
        let chatEl = '#chat-'+msgId;

        delCount = (1*delCount) + (1*seenCount);

        $(chatEl).find('.deliveredCount').text(delCount)
        $(chatEl).find('.readCount').text(seenCount)

        if ($('#' + msgId).length != 0) {

          $('#' + msgId).parent().next().find('.readCount').text(seenCount);
          $('#' + msgId).parent().next().find('.deliveredCount').text(delCount);
          $('#' + msgId).parent().next().find('.readCountRecent').text(seenCount);
          $('#' + msgId).parent().next().find('.deliverCountRecent').text(delCount);
      }

      })

  },
  readReciptUpdate: function (chatIds) {
    let readReciptList = stropheConnection.StropheJs().$iq({
        type: 'get',
        to: stropheConnection.Server()
    }).c('query', {
        xmlns: 'jabber:iq:user_activities',
        status: "get_receipts_count",
        msg_ids: chatIds,
    });
    if (stropheConnection.Connection().connected) {
        stropheConnection.Connection().sendIQ(readReciptList, module.exports.getReadReciptCallback);
    }
  },

  getReadReciptCallback(readReceiptIq){
    let msgId;
    let delCount;
    let seenCount;
      $(readReceiptIq).find('receipt').each(function (key,value) {
        msgId =  $(this).attr('msgid');
        delCount = $(this).find('deliver_count').text();
        seenCount = $(this).find('seen_count').text();
        let chatEl = '#chat-'+msgId;

        delCount = (1*delCount) + (1*seenCount);

        $(chatEl).find('.deliveredCount').text(delCount)
        $(chatEl).find('.readCount').text(seenCount)

        if ($('#' + msgId).length != 0) {

          $('#' + msgId).parent().next().find('.readCount').text(seenCount);
          $('#' + msgId).parent().next().find('.deliveredCount').text(delCount);
          $('#' + msgId).parent().next().find('.readCountRecent').text(seenCount);
          $('#' + msgId).parent().next().find('.deliverCountRecent').text(delCount);
      }

      })

     

  }
};
