let storage = require('./storage.js');
let mediaUploaduserSession = require('./session.js');
let utils = require('./utils.js');

let self = module.exports = {

    fileUpload: function (toUser, chatType, filedata, messageId) {
        let mediaUrl = window._env_.ApiURL + "/media/";
        let formData = new FormData();
        $('#loader_upload').css("display",'block');
        formData.append('file', filedata);
        formData.append('type', chatType);
        formData.append('toUser', toUser);
        formData.append('messageId', messageId);
        let temp = {
            'file': filedata,
            'type': chatType,
            'toUser': toUser,
            'messageId': messageId
        };
        let fileUrl = '';
        $.ajax({
            url: mediaUrl,
            type: 'POST',
            dataType: 'json',
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            async: false,
            crossDomain: true,
            headers: {
                'authorization': storage.getuserToken()
            },
            statusCode: {
                401: function (response) {
                    console.log('Security Token Error');
                    //Regenerate the access token to make the request as valid when token fails.
                    let data = {
                        'functionName': 'fileUpload',
                        'file': temp.file,
                        'type': temp.type,
                        'toUser': temp.toUser,
                        'messageId': temp.messageId
                    }
                    accessTokenRegenerate(JSON.parse(localStorage.getItem("loginUserInfo"))[0][0], data);
                    module.exports.fileUpload(temp.toUser,temp.type,temp.file,temp.messageId)
                },
                500: function (response) {
                    console.log('Internal Server Error');
                }
            },
            success: function (response) {
                $('#loader_upload').css("display",'none');
                fileUrl = response.data.fileToken;
            },
            error: function (jqXHR, exception) {
                $('#loader_upload').css("display",'none');
                utils.errorMsgHandling(jqXHR, exception)

                fileUrl = '';
            }

        });
        return fileUrl;
    },
    getFileCtValue: function(fileExtension){
        let ct;
        switch (fileExtension) {
            case 'png':
                ct = 'image/png';
                break;
            case 'jpeg':
                ct = 'image/jpeg';
                break;
            case 'jpg':
                ct = 'image/jpg';
                break;
            case 'mp4':
                ct = 'video/mp4';
                break;
            case 'mp3':
                ct = 'audio/mp3';
                break;
            case 'aac':
                ct = 'audio/aac';
                break;
            case 'wav':
                ct = 'audio/wav';
                break;
            case 'pdf':
                ct = "application/pdf";
                break;
            case 'txt':
                ct = "text/plain";
                break;
            case 'doc':
                ct = "application/msword";
                break;
            case 'docx':
                ct = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                break;
            case 'xls':
                ct = "application/vnd.ms-excel";
                break;
            case 'ppt':
                ct = "application/vnd.ms-powerpoint";
                break;
            case 'pptx':
                ct = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
                break;
            case 'xlsx':
                ct = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                break;
            default:
                ct = 'application/jpeg';
                break;
        }
        return ct;
    },
    cachedFetchFunction(downloadUrl,temp,fileData){
        cachedFetch(downloadUrl).then(function (response) {
            if (!response.ok) {
                let msg;
                if (response.status === 0) {
                    msg = 'Not connect.\n Verify Network.';
                    utils.reconnectionPopupShow();
                } else if (response.status == 404) {
                    msg = 'Requested page not found. [404]';
                } else if (response.status == 500) {
                    msg = 'Internal Server Error [500].';
                } else if (response.exception === 'parsererror') {
                    msg = 'Requested JSON parse failed.';
                } else if (response.exception === 'timeout') {
                    msg = 'Time out error.';
                } else if (response.exception === 'abort') {
                    msg = 'Ajax request aborted.';
                } else {
                    msg = 'Uncaught Error.\n' + response.responseText;
                }
                console.log(msg);
                return fileData;
            } else {
                let response2 = response.clone();
                response2.arrayBuffer().then((buffer) => {
                    let base64Flag = `data:${temp.ct};base64,`;
                    let imageStr = module.exports.arrayBufferToBase64(buffer);
                    fileData = base64Flag + imageStr;
                    return fileData;
                });
            }
        }).catch(function () {
            return 'src/images/avatar/chat/profile_img_one.svg';
        });
    },
    fileDownload: function (fileId) {
        let mediaUrl = window._env_.ApiURL + "/media/";
        if (fileId.indexOf('src/images/avatar/') !== -1) {
            return fileId;
        }


        let fileExtension = '';
        if (fileId.indexOf('.') !== -1) {
            fileExtension = (fileId.substr(fileId.lastIndexOf('.') + 1)) != '' ? fileId.substr(fileId.lastIndexOf('.') + 1) : 'png';
        }
        let ct;

        ct = module.exports.getFileCtValue(fileExtension);
        let token = storage.getuserToken();

        let downloadUrl = mediaUrl + '/' + fileId + "?mf=" + token;
        let temp = {
            'fileId': fileId,
            'fileExtension': fileExtension,
            'ct': ct,
            'downloadUrl': downloadUrl
        };
        let fileData = '';

        let result = module.exports.cachedFetchFunction(downloadUrl,temp,fileData);
        return result;
    },
    fileForward: function (fileId, chatType, toUser) {
        let forwardUrl = window._env_.ApiURL + "/media/forward";
        let oFileId = {
            'fileId': fileId
        }
        fileId = fileId.split(" ");
        let formData = {
            'fileTokens': fileId,
            'type': chatType,
            'toUser': toUser
        };
        let fileUrl = '';
        $.ajax({
            url: forwardUrl,
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json; charset=utf-8',
            async: false,
            data: JSON.stringify(filesData),
            crossDomain: true,
            headers: {
                'authorization': storage.getuserToken()
            },
            statusCode: {
                401: function (response) {
                    console.log('Security Token Error');
                    //Regenerate the access token to make the request as valid when token fails.
                    let data = {
                        'functionName': 'fileForward',
                        'fileId': oFileId.fileId,
                        'type': formData.type,
                        'toUser': formData.toUser
                    }
                    accessTokenRegenerate(JSON.parse(localStorage.getItem("loginUserInfo"))[0][0], data);
                },
                500: function (response) {
                    console.log('Internal Server Error');
                }
            },
            success: function (response) {
                let fileID = oFileId.fileId;
                fileUrl = response.data[fileID];
                if (fileUrl.indexOf('http') !== -1) {
                    fileUrl = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
                }
            },
            error: function (jqXHR, exception) {

                utils.errorMsgHandling(jqXHR, exception)

            }
        });
        return fileUrl;
    },
    fileForwardMultiple: function (filesData) {
        let forwardUrl = window._env_.ApiURL + "/media/forward";
        let fileUrl = '';
        $.ajax({
            url: forwardUrl,
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json; charset=utf-8',
            async: false,
            data: JSON.stringify(filesData),
            crossDomain: true,
            headers: {
                'authorization': storage.getuserToken()
            },
            statusCode: {
                401: function (response) {
                    console.log('Security Token Error');
                    accessTokenRegenerate(JSON.parse(localStorage.getItem("loginUserInfo"))[0][0], data);
                },
                500: function (response) {
                    console.log('Internal Server Error');
                }
            },
            success: function (response) {

                fileUrl = response.data;
            },
            error: function (jqXHR, exception) {
                utils.errorMsgHandling(jqXHR, exception)

            }
        });
        return fileUrl;
    },
    arrayBufferToBase64: function (buffer) {
        let binary = '';
        let bytes = [].slice.call(new Uint8Array(buffer));
        bytes.forEach((b) => binary += String.fromCharCode(b));
        return window.btoa(binary);
    },
    fileDelete: function (fileId, status) {
        let mediaUrl = window._env_.ApiURL + "/media/";
        let oFileId = {
            'fileId': fileId
        }
        fileId = fileId.split(" ");
        let formData = {
            'fileTokens': fileId,
            'status': status
        };
        $.ajax({
            url: mediaUrl,
            type: 'DELETE',
            dataType: 'json',
            contentType: 'application/json; charset=utf-8',
            async: true,
            data: JSON.stringify(formData),
            crossDomain: true,
            headers: {
                'authorization': storage.getuserToken()
            },
            statusCode: {
                401: function (response) {
                    console.log('Security Token Error');
                    //Regenerate the access token to make the request as valid when token fails.
                    let data = {
                        'functionName': 'fileDelete',
                        'fileId': oFileId.fileId,
                        'status': formData.status
                    }
                    accessTokenRegenerate(JSON.parse(localStorage.getItem("loginUserInfo"))[0][0], data);
                },
                500: function (response) {
                    console.log('Internal Server Error');
                }
            },
            success: function (response) {

            },
            error: function (jqXHR, exception) {
                utils.errorMsgHandling(jqXHR, exception)
                
            }
        });

    }
};

async function accessTokenRegenerate(username, data) {
    await mediaUploaduserSession.setSession(username);
    if (data.functionName === 'fileDownload') {
        self.fileDownload(data.fileId);
    } else if (data.functionName === 'fileUpload') {
        self.fileUpload(data.toUser, data.type, data.file);
    } else if (data.functionName === 'fileForward') {
        self.fileForward(data.fileId, data.type, data.toUser);
    } else if (data.functionName === 'fileDelete') {
        self.fileDelete(data.fileId, data.status);
    } else if (data.functionName === 'fileForwardMultiple') {
        self.fileDelete(data.fileId, data.toUser);
    }
}
