let strophe = require('strophe.js');
let Strophe = strophe.Strophe;
let connection,resource;
let options = {
    'mechanisms': [
        Strophe.SASLSHA1,
        Strophe.SASLPlain
    ],
        sync: true
    };
let logininfo = {};

module.exports = {
    
    logininfo: logininfo,
    Strophe: function () {
        return Strophe;
    },
    StropheJs: function () {
        return strophe;
    },
    Connection: function () {
        return connection;
    },
    Server: function () {
        let settings = JSON.parse(localStorage.getItem("settings"));
        let XmppServerHost = settings.xmppHost;
        
        return XmppServerHost;
    },
    Resource: function () {
        return resource;
    },
    NewConnect: function () {
        let settings = JSON.parse(localStorage.getItem("settings"));
        let XmppSocketHost = 'wss://'+settings.xmppHost+':'+settings.websocketXmppPort+'/websocket';
        connection = new Strophe.Connection(XmppSocketHost, { sync: true });
        connection.options.sync = true;
        connection.options.keepalive = true;
        resource = connection.getUniqueId();
    }
};