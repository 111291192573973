let stropheConnection = require('./stropheConnection.js');
let utils = require('./utils.js');
let lastActivity = require('./lastActivity.js');
let storage = require("./storage.js");
let userOncalls = require('./userOncalls.js');
const userprofile = require('./userprofile.js');
let status = "";
let imageId = "";
let role = "";
let department = "";
let description = "";
let siteId = "";

module.exports = {

    toJID: function () {
        if (localStorage.getItem("toJID")) {
            return JSON.parse(localStorage.getItem("toJID"));
        } else {
            return "";
        }
    },
    getRoster: function () {
        let rosteriq = stropheConnection.StropheJs().$iq({
            type: 'get',
            id: utils.randomString(16, 'aA')
        }).c('query', {
            xmlns: 'jabber:iq:roster'
        });
        if (stropheConnection.Connection().connected) {
            stropheConnection.Connection().sendIQ(rosteriq, module.exports.getRosterCallback);
        }
        // Indicate that this handler should be called repeatedly
        return true;
    },
    getRosterCallback: function (rosteriq) {
        
        delete localStorage.usersProfileInfo;
        if (typeof rosteriq.getElementsByTagName("item") == "undefined" || rosteriq.getElementsByTagName("item").length == 0) { 
            console.log('Roster Callback!!!');
        } else {
            let usersProfileInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));

            usersProfileInfo = {};
            
            $(rosteriq).find('item').each(function (index) {
                let jid = $(this).attr('jid');
                let nodeJid = stropheConnection.Strophe().getNodeFromJid(jid);
                
                usersProfileInfo[nodeJid] = [];
                usersProfileInfo[nodeJid].push('chat');

                if ($(this).find('vCard').length) {

                    let nickName = $(this).find('vCard').find('nickName').text();

                    usersProfileInfo[nodeJid].push(nickName);

                    status = $(this).find('vCard').find('status').text();
                    imageId = $(this).find('vCard').find('image').text();
                    role = $(this).find('vCard').find('role').text();
                    department = $(this).find('vCard').find('department').text();
                    siteId = $(this).find('vCard').find('locationId').text();
                    let mobileNumber = $(this).find('vCard').find('mobile_number').text();
                    let workNumber = $(this).find('vCard').find('work_number').text();
                    let pagerNumber = $(this).find('vCard').find('pager_number').text();
                    let comm_notes = $(this).find('vCard').find('comm_notes').text();
                    let isAdmin = $(this).find('vCard').find('isAdmin').text();
                    let isActiveTechnician = $(this).find('vCard').find('isActiveTechnician').text();
                    let personalMobileNumber = $(this).find('vCard').find('personal_mobile_number').text();


                    usersProfileInfo[nodeJid].push(imageId);                    
                    usersProfileInfo[nodeJid].push(status);
                    usersProfileInfo[nodeJid].push(role);                    
                    usersProfileInfo[nodeJid].push(department);
                    usersProfileInfo[nodeJid].push(siteId);
                    usersProfileInfo[nodeJid].push(isAdmin);
                    usersProfileInfo[nodeJid].push(isActiveTechnician);
                    usersProfileInfo[nodeJid].push(mobileNumber);
                    usersProfileInfo[nodeJid].push(workNumber);
                    usersProfileInfo[nodeJid].push(pagerNumber);
                    usersProfileInfo[nodeJid].push(comm_notes);

                    
                    description = $(this).find('vCard').find('description').text();
                    let icon = $(this).find('vCard').find('icon').text();
                    let foregroundColor = $(this).find('vCard').find('foregroundColor').text();
                    let unregisteredUser = $(this).find('vCard').find('unregistered_user').text();

                    usersProfileInfo[nodeJid].push(description);
                    usersProfileInfo[nodeJid].push(icon);
                    usersProfileInfo[nodeJid].push(foregroundColor);
                    usersProfileInfo[nodeJid].push(unregisteredUser);
                    usersProfileInfo[nodeJid].push(personalMobileNumber);

                    
                    localStorage.setItem("usersProfileInfo", JSON.stringify(usersProfileInfo));
                }
                
            });
        }
        return true;
    },
    rosterClickDesign: function() {
        $('#message').find('#right-side-prof').attr('src', 'src/images/avatar/chat/profile_img_one.svg');
        $(".heading-online").html("");
        $('#index-chat-section').hide();
        $(".message-info-block").css({
            "right": "-100%"
        });
        $("#comment").empty();
        $('#comment').attr("placeholder", "Shift + Enter to add a new line Start");
        $("#ack").val("0");
        $(".acknowledge-icon").find('img').attr('src', './src/images/acknowledgement_ic.svg');
        $(".downarrow").hide();
        $(".reply-content").hide();
        $(".blocked-person").css("display", "none");
        $(".row.reply").css("display", "flex");
        $("#searchText").val("");
        $("#searchText").trigger("keyup");
        $("#composeText").val("");
        $('.del-msg-wrap').hide();
        $(".message-info-block .right-msg-back").trigger('click');
        $(".media-detail-section .right-msg-back").trigger('click');
        $("#right-prof-back").trigger('click');
        $(".conversation").removeClass("small-pane");
        $(".contact-bubble-block").html('');
        $('#con-head').css('display', 'flex');
        $('#conversation').css('display', 'block');
        $('.reply').css('display', 'flex');
        $(".side-two").css({
            "left": "-100%"
        });
        $(".side-global-contact-list").css({
            "left": "-100%"
        });
    },
    roasterAudioFun: function(nodeJid){
        $('.msg-close').trigger('click');
        $("[id='"+nodeJid+"']").find('.notify').html(parseInt(0));
        $("[id='"+nodeJid+"']").find('.notify').css("display", "none");
        $("audio").each(function () {
            let audioId = this.id;
            if (audioId != "") {
            let result = audioId.split("-");
            if ($("#" + result[0] + "-pButton").length) {
                if ($("#" + result[0] + "-pButton").hasClass("pause")) {
       
                    this.pause();
                    $("#" + result[0] + "-pButton").removeClass("pause").addClass("play");
      
                }
            }
            }
        });
    },
    Rosterclick: function () {
        $('.rosterfwdcontent').off('click');
        $("body").on('click', '.rosterfwdcontent', function(e) {
            e.stopImmediatePropagation();
            delete localStorage.replymsgInfo;
            let ReplyMsgContent = "";
            let ReplyMsgId = "";
            
            localStorage.setItem("ReplyMsgContent", ReplyMsgContent);
            localStorage.setItem("ReplyMsgId", ReplyMsgId);
        
            module.exports.rosterClickDesign();
            let groupaddUserInfo = {};
            localStorage.setItem("groupaddUserInfo", JSON.stringify(groupaddUserInfo));
            let broadcastAddUserInfo = {};
            localStorage.setItem("broadcastAddUserInfo", JSON.stringify(broadcastAddUserInfo));
            
            let result = $(this).attr('id').split(/-(.+)/);
            let nodeJid = result[1];
            utils.determineDropDirection();

            module.exports.roasterAudioFun(nodeJid);
            let toJID = nodeJid + "@" + stropheConnection.Server();
            lastActivity.lastActivity(toJID);

            localStorage.setItem("toJID", JSON.stringify(toJID));
            let conversation = require('./conversation.js');
            conversation.MessageLayout(toJID, 'down', '');

             /** Show Message Character Count **/
            let Settings = JSON.parse(localStorage.getItem("settings"));
            let messageCharacterLimit = Settings.messageCharacterLimit;
            let CommentTextLength = $('#comment').html().length;
            if(CommentTextLength == messageCharacterLimit){
                $('#messageCharCount').css('color','red');
                $('#messageCharLimitCount').css('color','red');
            }else {
                $('#messageCharCount').removeAttr( 'style' );
                $('#messageCharLimitCount').removeAttr( 'style' );
            }
            $('#messageCharCount').html(CommentTextLength);
            let concatedLetter;
            let name = $(this).find('.contact-list').text();
            let imageId = $(this).find('.contact-image').first().attr('usemap')
            concatedLetter = userprofile.userProHandleGetConcatImgTxt(name);
            let imageExtArray = ['jpg','jpeg','png','webp'];
            let profileExtention = (typeof imageId != 'undefined') ? imageId.split('.').pop() : '';
            if(typeof imageId != 'undefined' && imageId !== 'src/images/avatar/chat/profile_img_one.svg' && imageId.trim() !== '' && imageExtArray.includes(profileExtention)){
                let mediaUrl = window._env_.ApiURL + "/media/";
                let userToken = storage.getuserToken();    
                let profileimage =mediaUrl+ imageId+"?mf="+userToken;

                $('#message').find('#singleChatRightsideProf').hide();
                $('#message').find('#right-side-prof').show()
                $('#message').find('#right-side-prof').attr('src', profileimage);
                $('#message .heading-avatar-icon div').first().attr('src','')

            }else {
                $('#message').find('#right-side-prof').hide();
                $('#message').find('#singleChatRightsideProf').children().remove();
                $('#message').find('#singleChatRightsideProf').show();

                $('#message').find('#singleChatRightsideProf').append('<div class="avatar-icon"><svg id="rightSideSvgprof" class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="height:50px;width:50px;"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg></div>');
            }
            
        });

        //action on clicking oncall button in contacts
        $("body").on('click', '.oncall-contact', function(e) {
            e.stopImmediatePropagation();
            $("#oncall-details").css("display", "block");
            $("#oncall-details").addClass("open");
            let userId = $(this).closest(".rosterfwdcontent").attr("id").split(/-(.+)/);
            userOncalls.getOncallNames(userId[1], 0, 1, 1, $("#roster-"+userId[1]).find(".name-meta").text());
        });
        return true;
    },
    rosterreorder: function () {
        $("#roster").mCustomScrollbar('destroy');
        let $divs = $("div.roster-body");
        let alphabeticallyOrderedDivs = $divs.sort(function (a, b) {

            return $(a).find(".contact-list").text() > $(b).find(".contact-list").text() ? 1 : -1;
        });
        $("#roster").html(alphabeticallyOrderedDivs);
        $("#roster").mCustomScrollbar();
    },
    forwardreorder: function () {

        let $fwddivs = $(".rosterchatcontact div.contactfwdcontent");
        let alphabeticallyfwddivsDivs = $fwddivs.sort(function (a, b) {

            return $(a).find(".contact-list").text() > $(b).find(".contact-list").text() ? 1 : -1;
        });
        $(".rosterchatcontact").html(alphabeticallyfwddivsDivs);

    },
    participantreorder: function () {
        $("#participants").mCustomScrollbar('destroy');
        let $divs = $("div.participantcontent");
        let alphabeticallyOrderedDivs = $divs.sort(function (a, b) {
            return $(a).find(".contact-list").text() > $(b).find(".contact-list").text() ? 1 : -1;
        });
        $("#participants").html(alphabeticallyOrderedDivs);
        $("#participants").mCustomScrollbar();
    },
    groupmemberreorder: function () {

        let $divs = $("#participantcontact div.contactfwdcontent");
        let alphabeticallyOrderedDivs = $divs.sort(function (a, b) {

            return $(a).find(".contact-list").text() > $(b).find(".contact-list").text() ? 1 : -1;
        });
        $("#participantcontact").html(alphabeticallyOrderedDivs);

    }
};
