let contactStorage = require('./storage.js');
let contactStropheConnection = require("./stropheConnection.js");
let contactUtils = require('./utils.js');
let contactRoster = require('./roster.js');
let contactLanguage = require('./locale/language.js');
let contactIndexDb = require('./indexDb.js');
const utils = require('./utils.js');

module.exports = {

    getContacts: function (conQueryParams) {
        let contactsUrl = window._env_.ApiURL + "/contacts/";
        let data;
        
        $.ajax({
            url: contactsUrl + conQueryParams,
            type: 'GET',
            dataType: 'json',
            cache: false,
            contentType: false,
            processData: false,
            async: false,
            crossDomain: true,
            headers: {
                'authorization': contactStorage.getuserToken()
            },
            statusCode: {
                401: function (response) {
                    console.log('Security Token Error');
                    let loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
                    let username = loginUserInfo[0][0];
                    contactStorage.setSession(username)
                    module.exports.getContacts(conQueryParams)
                },
                500: function (response) {
                    console.log('Internal Server Error');
                }
            },
            success: function (response) {
                data = response.data;
            },
            error: function (jqXHR, exception) {
                utils.errorMsgHandling(jqXHR, exception)

            },
            complete: function(){
                $(".sideContactNavLoader").hide()
                $(".sideParticipantNavLoader").hide()
                $(".sideBroadcastParticipantNavLoader").hide()
                $(".sideBroadcastContactListNavLoader").hide()
                $(".sideContactListNavLoader").hide()
                $(".sideTeamContactListNavLoader").hide()
            }
        });
        contactRoster.Rosterclick();
        return data;
    },

    
    profileImgError: function() {
        $('img').on("error", function () {
          this.onerror = "";
          this.src = "./src/images/avatar/chat/profile_img_one.svg";
        });
    },
    msgContactsRosterEmptycheck: function(pageNumber){
        if (pageNumber == 1) 
            $("#roster").empty();

    },
    getContactDisplayName: function(name,givenName,surName,values){
        let displayName;
        if(name == null){
            if((givenName != null && givenName != '') && (surName != null && surName != '')){
                displayName = givenName + " " + surName;
            }else if((givenName != null && givenName != '')){
                displayName = givenName;
            }else if((surName != null && surName != '')){
                displayName = surName;
            }else {
                displayName = values.ikonixUsername;
            }
        }else {
            displayName = name;
        }
        return displayName;
    },
    getContactImageTxt: function(name){
        let imageText;
        if(name != "") {
            let splitedArr = name.split(' ');
            let splitedNameArr = splitedArr.filter(function(v){return v!==''});
            let firstNameLetter,secondNameLetter;
            if(splitedNameArr.length == 1){
                firstNameLetter = splitedNameArr[0][0];
                secondNameLetter = splitedNameArr[0][1];
            }else {
                firstNameLetter = splitedNameArr[0][0];
                secondNameLetter = splitedNameArr[1][0];
            }
            imageText = (firstNameLetter+secondNameLetter).toUpperCase()
        }
        return imageText;
    },
    getContactImageElment: function(imageId,values,nodeJid,imageText){
        let imageElment,image;
        let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
        let userToken = contactStorage.getuserToken();
        let mediaUrl = window._env_.ApiURL + "/media/";

        if (imageId != null && imageId.trim() != "") {
            if (userInfo != null && typeof userInfo[values.username.toLowerCase()] != "undefined") {
                
                let last2 = values.username.slice(-2);
                if(last2 == '==')
                    imageId = userInfo[values.username.toLowerCase()][2];
                
                image = mediaUrl + imageId + "?mf=" + userToken;
            } else{
                image = mediaUrl + imageId + "?mf=" + userToken;
            }
            contactIndexDb.blobImageConversion(image,'#contacImg-'+nodeJid+'',nodeJid,'profileImages');
            imageElment = '<img id="contacImg-'+nodeJid+'" class="contact-image contactImg-'+nodeJid+'" src="' + image + '" usemap="' + imageId + '" onError="' + module.exports.profileImgError(this) + '" data-text="'+imageText+'">';
        } else {
            imageElment = '<svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+imageText+'</text></svg>';
        }

        return imageElment;
    },
    getContactBlockUserCheck: function(nodeJid){
        let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
        let blockListUserInfo = JSON.parse(localStorage.getItem("blockListUserInfo"));

        if (blockListUserInfo != null && typeof blockListUserInfo[nodeJid] != "undefined") {
            let image = "src/images/avatar/chat/profile_img_one.svg";
            if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
                userInfo[nodeJid][2] = image;
                userInfo[nodeJid][3] = contactLanguage.locali().default_status;
                localStorage.setItem("usersProfileInfo", JSON.stringify(userInfo));
            }
        }
    },
    contactOncallImgName: function(statusId,isUnregisteredUser){
        let oncallImageNameClass = '';
        if(!(contactUtils.getStatusName(statusId) != "" && isUnregisteredUser == 0)) {

            oncallImageNameClass = 'global-contact-oncall'
        }
        return oncallImageNameClass;
    },
    contactfavIcon: function(values){
        let favIcon     = (values.isChatUser == 1 && values.isUnregisteredUser != 1) ? '<img class="contact-image fave_icone" src="./src/images/svg/ikonixicon.svg">' : '';

        return favIcon;

    },
    contactSingleNameAlignClass: function(department,siteAbbreviation,position,activeTechnician){
        let singleNameAlignClass = '';
        if (department == "" && siteAbbreviation == "" && (position == "" && activeTechnician) ) {
            singleNameAlignClass = 'no-destination'
        }

        return singleNameAlignClass;
    },
    contactProfileStatusDiv: function(statusId,isUnregisteredUser,values){
        let profileStatusDiv;
        if(contactUtils.getStatusName(statusId) != "" && isUnregisteredUser == 0) 
              profileStatusDiv = values.isChatUser == 1 ? '<span class="status-all" title="' + contactUtils.getStatusName(statusId) +       '" style="color:' + contactUtils.getStatusImage(statusId) + '">'+ contactUtils.getStatusName(statusId) + '</span></div>' : '';
        else 
            profileStatusDiv = "";


        return profileStatusDiv;
    },
    contactOncallElementTopClass: function(department,position,profileStatusDiv){
        let onCallTopClass = "";

        if((department != "" || position != "") && (profileStatusDiv == '') ){
            onCallTopClass = 'onCallTopMove';
        }

        return onCallTopClass;
    },
    contactPositionDiv: function(position,activeTechnician,onCallTopClass){
        let positionDiv = "";
        if(position != "") {
            positionDiv = '<div class="sideBar-msg dept-div"><span class="name-status" title="'+position+'">' + position + ' </span>';
            if(activeTechnician)
            positionDiv += '<span class="oncall-contact name-status pull-right '+onCallTopClass+'"><img class="low-priority-arrow rollicon" src="./src/images/rollicon.svg"/><span class="oncall-contact-text">On Call</span></span>';
            positionDiv +='</div>';
        }
        else if(activeTechnician){

            positionDiv = '<div class="sideBar-msg dept-div"><span class="name-status" title="'+position+'" style="display:none;">-</span><span class="oncall-contact name-status pull-right '+onCallTopClass+'"><img class="low-priority-arrow rollicon" src="./src/images/rollicon.svg"/><span class="oncall-contact-text">On Call</span></span></div>';
        }
        return positionDiv;
    },
    getcontactDeptSite: function(department,siteAbbreviation){
        let deptSite = "";
        if (department != "" && siteAbbreviation != "") {
            deptSite = department + ", " + siteAbbreviation;
        } else if (department == "" && siteAbbreviation != "") {
            deptSite = siteAbbreviation;
        } else if (department != "" && siteAbbreviation == "") {
            deptSite = department;
        }
        
        return deptSite;
    },
    getcontactStatusClassName: function(deptSite,positionDiv,profileStatusDiv){
        let statusClassName = '';
        if(deptSite == '' && positionDiv == '' && profileStatusDiv != ''){
            statusClassName = 'contact-list-status';
        }

        return statusClassName;
    },
    contactRoasterDivAppend: function(pageNumber,profileDiv,imageDiv,nameDivContacts,roleDeptDiv){
        if (pageNumber == 1 || pageNumber == "") {
            $("#roster").append(profileDiv + imageDiv + nameDivContacts + roleDeptDiv);
        } else {
            $("#roster").find('.mCSB_container').append(profileDiv + imageDiv + nameDivContacts + roleDeptDiv);
        }
    },
    msgContactsDivAppend: function(contactsData,pageNumber,queryParams){
        if (typeof contactsData !== 'undefined' && contactsData.length > 0) {
            for (let keys in contactsData) {

                let values              = contactsData[keys],
                    jid                 = values.username + '@' + contactStropheConnection.Server(),
                    nodeJid             = contactStropheConnection.Strophe().getNodeFromJid(jid),
                    statusId            = values.status,
                    surName             = values.surName,
                    givenName           = values.givenName,
                    displayName         = "",
                    siteId              = values.siteId,
                    email               = values.email,
                    siteAbbreviation    = contactUtils.getSiteAbbreviation(siteId),
                    position            = values.position.trim(),
                    department          = values.department.trim(),
                    activeTechnician    = values.isActiveTechnician,
                    positionDiv         = "",
                    deptSite            = "",
                    deptSiteDiv         = "",
                    isChatUser          = values.isChatUser,
                    isUnregisteredUser  = values.isUnregisteredUser,
                    imageId             = values.imageToken.trim(),
                    name                = values.name,
                    imageElment ,
                    imageText;

               displayName =  module.exports.getContactDisplayName(name,givenName,surName,values);
               imageText   =  module.exports.getContactImageTxt(name);
               imageElment   =  module.exports.getContactImageElment(imageId,values,nodeJid,imageText);
               module.exports.getContactBlockUserCheck(nodeJid);
               let oncallImageNameClass = module.exports.contactOncallImgName(statusId,isUnregisteredUser);
                
               let favIcon = module.exports.contactfavIcon(values);
                
                let imageDiv    = '<div class="avatar-icon">'+imageElment+favIcon+'</div></div>';
                let singleNameAlignClass = module.exports.contactSingleNameAlignClass(department,siteAbbreviation,position,activeTechnician);

               


                
                let nameDiv     = '<div class="col-sm-9 col-xs-9 sideBar-main contact-list-main"><div class="row"><div class=" sideBar-name contact-name '+oncallImageNameClass+'"><div class="contact-list-div '+singleNameAlignClass+'"><span class="name-meta contact-list">' + minEmoji(displayName) + '</span>';

                let profileStatusDiv,nameDivContacts;

                profileStatusDiv = module.exports.contactProfileStatusDiv(statusId,isUnregisteredUser,values);

                let onCallTopClass = module.exports.contactOncallElementTopClass(department,position,profileStatusDiv);

                nameDivContacts = nameDiv + profileStatusDiv + '</div>';

                positionDiv = module.exports.contactPositionDiv(position,activeTechnician,onCallTopClass);

                deptSite = module.exports.getcontactDeptSite(department,siteAbbreviation);
                
                deptSiteDiv = '<div class="sideBar-msg role-div">';
                if(deptSite != "")
                    deptSiteDiv += '<span class="name-status fwdMsgContactSpan" title="'+deptSite+'">' + deptSite + ' </span></div>';

                let roleDeptDiv = positionDiv + deptSiteDiv + '</div></div>';

                let statusClassName = module.exports.getcontactStatusClassName(deptSite,positionDiv,profileStatusDiv);

                let profileDiv  = '<div data-id="'+isChatUser+'" data-isunregister="'+isUnregisteredUser+'" class="rosterfwdcontent contactlist row sideBar-body '+statusClassName+'" id="roster-' + nodeJid + '"><span style="display: none" id="nonapp-'+nodeJid+'" >'+email+'</span><div  class="col-sm-3 col-xs-3 sideBar-avatar">';

                
                module.exports.contactRoasterDivAppend(pageNumber,profileDiv,imageDiv,nameDivContacts,roleDeptDiv);

                let imgEl = document.getElementById("contacImg-"+nodeJid);
                if(imgEl){
                    utils.imgElementErrorCheck(imgEl)
                }
            
            }
            if (pageNumber == 1 || pageNumber == "") {
                $("#roster").mCustomScrollbar({
                    callbacks: {
                        onTotalScroll: function () {
                            module.exports.getcontactRosterScrollSet()
                           
                        }
                    }
                });
            } else {
                $("#roster").mCustomScrollbar("update");
            }
        }

    },
    getcontactRosterScrollSet: function(){
        let siteId            = "",
        rosterCount       = $('#roster .rosterfwdcontent').length,
        rosterLength      = rosterCount / contactLanguage.locali().default_size_pagination,
        page              = Math.round(rosterLength) + 1;
         let locationId = JSON.parse(localStorage.getItem("locationId"));        
         if(locationId != null && typeof locationId != "undefined"){
             siteId = locationId.mysite_contact;
         }else {
             siteId = $('#siteDetails').text();
         }
         
         if (rosterCount % contactLanguage.locali().default_size_pagination == 0) {
             let rosterQueryParams = '?size=' + contactLanguage.locali().default_size_pagination + '&isChatUser='+ '&siteId=' + 
        siteId + '&page=' + page;
             module.exports.getMessageContacts(rosterQueryParams);
         }
    },
    msgContactsNoParticipant: function(contactLength){
        if(contactLength == 0) {
            $('#roster #noparticipant').remove();
            $("#roster").html("<div id='noparticipant'>" + contactLanguage.locali().nocontact_available + "</div>");
        }
    },
    getMessageContacts: function (msgQueryParams) {

        let contactsData        = module.exports.getContacts(msgQueryParams);


        let searchParams = new URLSearchParams(msgQueryParams),
            pageNumber   = searchParams.get('page');

        module.exports.msgContactsRosterEmptycheck(pageNumber);

        module.exports.msgContactsDivAppend(contactsData,pageNumber,msgQueryParams);

        
        let contactLength = $('#roster .rosterfwdcontent').length;

        module.exports.msgContactsNoParticipant(contactLength);

        contactRoster.Rosterclick();
        return true;
    },
    GlobalContactPageNoEmptyCheck: function(elementId,pageNumber){
        if (pageNumber == 1) 
            $(elementId).empty();
    },
    globelContactImageElment: function(imageId,values,nodeJid,concatedLetter){
        let userToken           = contactStorage.getuserToken(),
            userInfo            = JSON.parse(localStorage.getItem("usersProfileInfo")),
            blockListUserInfo   = JSON.parse(localStorage.getItem("blockListUserInfo"));
        let mediaUrl = window._env_.ApiURL + "/media/";

            let image,imageElment;
            if (imageId != null && imageId != "src/images/avatar/chat/profile_img_one.svg" &&imageId.trim() != "") {
                if (userInfo != null && typeof userInfo[values.username.toLowerCase()] != "undefined") {
                    
                    let last2 = values.username.slice(-2);
                    if(last2 == '==')
                        imageId = userInfo[values.username.toLowerCase()][2];
                    
                    image = mediaUrl + imageId + "?mf=" + userToken;
                } else{
                    image = mediaUrl + imageId + "?mf=" + userToken;
                }
                contactIndexDb.blobImageConversion(image,'#globalContacImg-'+nodeJid+'',nodeJid,'profileImages');

                imageElment = '<img id="globalContacImg-'+nodeJid+'" class="contact-image" src="' + image + '" usemap="' + imageId + '" onerror="' + module.exports.profileImgError() + '" data-text="'+concatedLetter+'">';
            } else {
                imageElment = '<svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg>';
            }

            if (blockListUserInfo != null && typeof blockListUserInfo[nodeJid] != "undefined") {
                imageElment = '<svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg>';

                if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
                    let image = "src/images/avatar/chat/profile_img_one.svg";
                    userInfo[nodeJid][2] = image;
                    userInfo[nodeJid][3] = contactLanguage.locali().default_status;
                    localStorage.setItem("usersProfileInfo", JSON.stringify(userInfo));
                }
            }

            return imageElment;
    },
    globelContactImageOncallClass: function(statusId,isUnregisteredUser){
        let oncallImageNameClass = '';
        if(!(contactUtils.getStatusName(statusId) != "" && isUnregisteredUser == 0)) 
            oncallImageNameClass = 'global-contact-oncall';

        return oncallImageNameClass;
    },
    globelContactSingleNameAlignClass: function(department,siteAbbreviation,position,activeTechnician){
        let singleNameAlignClass = '';
        if (department == "" && siteAbbreviation == "" && (position == "" && activeTechnician) ) {
            singleNameAlignClass = 'no-destination'
        }

        return singleNameAlignClass;
    },
    globelContactProfileStatusDiv: function(statusId,isUnregisteredUser,values){
        let profileStatusDiv;
        if(contactUtils.getStatusName(statusId) != "" && isUnregisteredUser == 0) 
            profileStatusDiv = values.isChatUser == 1 ? '<span class="status-all" title="' + contactUtils.getStatusName(statusId) + '" style="color:' + contactUtils.getStatusImage(statusId) + '">'+ contactUtils.getStatusName(statusId) + '</span></div>' : '';
        else 
            profileStatusDiv = "";

        return profileStatusDiv;
    },
    
    globelContactpositionDiv(position,activeTechnician,onCallTopClass){
        let positionDiv = "";
        if(position != "") {
            positionDiv = '<div class="sideBar-msg dept-div"><span class="name-status" title="'+position+'">' + position + ' </span>';
        }
        if(activeTechnician){
            positionDiv += '<span class="oncall-contact name-status pull-right '+onCallTopClass+'"><img class="low-priority-arrow rollicon" src="./src/images/rollicon.svg"/><span class="oncall-contact-text">On Call</span></span>';
        }
        positionDiv += '</div>';

        return positionDiv;
    },
    globelContactDeptSite: function(department,siteAbbreviation){
        let deptSite = "";
        if (department != "" && siteAbbreviation != "") {
            deptSite = department + ", " + siteAbbreviation;
        } else if (department == "" && siteAbbreviation != "") {
            deptSite = siteAbbreviation;
        } else if (department != "" && siteAbbreviation == "") {
            deptSite = department;
        } 

        return deptSite;
    },
    globelContactStatusClassName(deptSite,position,activeTechnician,profileStatusDiv){
        let statusClassName = '';
        if(deptSite == '' && (position == "" && !activeTechnician) && profileStatusDiv != ''){
            statusClassName = 'contact-list-status';
        }

        return statusClassName;
    },
    globelContactDivAppend: function(globelContactDivObj,nodeJid){
        if (globelContactDivObj.pageNumber == 1 || globelContactDivObj.pageNumber == "") {
            if($("[id='"+globelContactDivObj.elementName+"-"+nodeJid+"']").length == 0) 
                $(globelContactDivObj.elementId).append(globelContactDivObj.profileDiv + globelContactDivObj.imageDiv + globelContactDivObj.nameDivContacts + globelContactDivObj.roleDeptDiv);
        } else {
            if($("[id='"+globelContactDivObj.elementName+"-"+nodeJid+"']").length == 0) 
                $(globelContactDivObj.elementId).find('.mCSB_container').append(globelContactDivObj.profileDiv + globelContactDivObj.imageDiv + globelContactDivObj.nameDivContacts + globelContactDivObj.roleDeptDiv);
        }
    },
    getGlobalcontactRosterScrollSet(pageNumber,elementId,elementName){
        if (pageNumber == 1 || pageNumber == "") {
            $(elementId).mCustomScrollbar({
                callbacks: {
                    onTotalScroll: function () {
                        let siteId            = $("#currentSiteId").val(),
                            rosterCount       = $(elementId+' .rosterfwdcontent').length,
                            rosterLength      = rosterCount / contactLanguage.locali().default_size_pagination,
                            page              = Math.round(rosterLength) + 1;
                        if (rosterCount % contactLanguage.locali().default_size_pagination == 0) {
                            let glRosQueryParams = '?size=' + contactLanguage.locali().default_size_pagination + '&isChatUser='+ '&siteId=' + 
        siteId + '&page=' + page;
                            module.exports.getGlobalContactsList(glRosQueryParams,elementName);
                        }
                    }
                }
            });
        } else {
            $(elementId).mCustomScrollbar("update");
        }
    },
    getGlobalcontactRosterContentSet: function(elementId){
        let contactLength = $(elementId+' .rosterfwdcontent').length;
        if(contactLength == 0) {
            $(elementId+' #noparticipant').remove();
            $(elementId).html("<div id='noparticipant'>" + contactLanguage.locali().nocontact_available + "</div>");
        }
    },
    getGlobalContactsList: function (gQueryParam,elementName) {
        let elementId =  "#"+elementName;

        let contactsData        = module.exports.getContacts(gQueryParam);

        let searchParams = new URLSearchParams(gQueryParam),
            pageNumber   = searchParams.get('page');

        module.exports.GlobalContactPageNoEmptyCheck(elementId,pageNumber);
            
        if (typeof contactsData !== 'undefined' && contactsData.length > 0) {
            for (let keys in contactsData) {

                let values              = contactsData[keys],
                    jid                 = values.username + '@' + contactStropheConnection.Server(),
                    nodeJid             = contactStropheConnection.Strophe().getNodeFromJid(jid),
                    statusId            = values.status,
                    givenName           = values.givenName,
                    surName             = values.surName,
                    displayName         = "",
                    siteId              = values.siteId,
                    email               = values.email,
                    siteAbbreviation    = contactUtils.getSiteAbbreviation(siteId),
                    position            = values.position.trim(),
                    department          = values.department.trim(),
                    activeTechnician    = values.isActiveTechnician,
                    positionDiv         = "",
                    deptSite            = "",
                    deptSiteDiv         = "",
                    isChatUser          = values.isChatUser,
                    isUnregisteredUser  = values.isUnregisteredUser,
                    imageId             = values.imageToken.trim(),
                    name                = values.name,
                    imageElment,
                    concatedLetter;
                    

                    displayName = module.exports.getContactDisplayName(name,givenName,surName,values);
                    concatedLetter = contactUtils.getImageDisplayText(name);
                    imageElment = module.exports.globelContactImageElment(imageId,values,nodeJid,concatedLetter);

                    let oncallImageNameClass = module.exports.globelContactImageOncallClass(statusId,isUnregisteredUser);
                

                let favIcon     = (values.isChatUser == 1 && values.isUnregisteredUser != 1) ? '<img class="contact-image fave_icone" src="./src/images/svg/ikonixicon.svg">' : '';
                
                let imageDiv    = '<div class="avatar-icon">'+imageElment+favIcon+'</div></div>';

                let singleNameAlignClass = module.exports.globelContactSingleNameAlignClass(department,siteAbbreviation,position,activeTechnician);

                let nameDiv     = '<div class="col-sm-9 col-xs-9 sideBar-main contact-list-main"><div class="row"><div class=" sideBar-name contact-name '+oncallImageNameClass+'"><div class="contact-list-div '+singleNameAlignClass+'"><span class="name-meta contact-list">' + minEmoji(displayName) + '</span>';

                let profileStatusDiv = module.exports.globelContactProfileStatusDiv(statusId,isUnregisteredUser,values);

                let onCallTopClass = module.exports.contactOncallElementTopClass(department,position,profileStatusDiv);


                let nameDivContacts = nameDiv + profileStatusDiv + '</div>';

                positionDiv = module.exports.globelContactpositionDiv(position,activeTechnician,onCallTopClass);

                deptSite = module.exports.globelContactDeptSite(department,siteAbbreviation);

                deptSiteDiv = '<div class="sideBar-msg role-div">';
                if(deptSite != "")
                    deptSiteDiv += '<span class="name-status fwdMsgContactSpan" title="'+deptSite+'">' + deptSite + ' </span></div>';


                let roleDeptDiv = positionDiv + deptSiteDiv + '</div></div>';

                let statusClassName = module.exports.globelContactStatusClassName(deptSite,position,activeTechnician,profileStatusDiv);

                let profileDiv  = '<div data-id="'+isChatUser+'" data-isunregister="'+isUnregisteredUser+'" class="rosterfwdcontent contactlist row sideBar-body '+statusClassName+'" id="roster-' + nodeJid + '"><span style="display: none" id="nonapp-'+nodeJid+'" >'+email+'</span><div  class="col-sm-3 col-xs-3 sideBar-avatar">';
                
                let globelContactDivObj = {
                    pageNumber  : pageNumber,
                    elementName : elementName,
                    nodeJid : nodeJid,
                    elementId : elementId,
                    profileDiv : profileDiv,
                    imageDiv : imageDiv,
                    nameDivContacts : nameDivContacts,
                    roleDeptDiv : roleDeptDiv
                }

                module.exports.globelContactDivAppend(globelContactDivObj,nodeJid);

                let imgEl = document.getElementById("globalContacImg-"+nodeJid);
                if(imgEl){
                    utils.imgElementErrorCheck(imgEl)
                }
               
            }
        }

        module.exports.getGlobalcontactRosterScrollSet(pageNumber,elementId,elementName);

        module.exports.getGlobalcontactRosterContentSet(elementId);

        contactRoster.Rosterclick();
        return true;
    },
    getNewGroupPageEmptyCheck: function(pageNumber,elementId){
        if (pageNumber == 1) 
            $(elementId).empty();
    },
    getNewGroupImageElment: function(imageId,values,nodeJid,concatedLetter){
        let image,imageElment;
        let userToken           = contactStorage.getuserToken(),
            userInfo            = JSON.parse(localStorage.getItem("usersProfileInfo")),
            blockListUserInfo   = JSON.parse(localStorage.getItem("blockListUserInfo"));

        let mediaUrl = window._env_.ApiURL + "/media/";

        if (imageId != null && imageId !== 'src/images/avatar/chat/profile_img_one.svg' && imageId.trim() != "") {
            if (userInfo != null && typeof userInfo[values.username.toLowerCase()] != "undefined") {
                
                let last2 = values.username.slice(-2);
                if(last2 == '==')
                    imageId = userInfo[values.username.toLowerCase()][2];
                
                image = mediaUrl + imageId + "?mf=" + userToken;
            } else{
                image = mediaUrl + imageId + "?mf=" + userToken;
            }
            contactIndexDb.blobImageConversion(image,'#addParticipantImgId-'+nodeJid+'',nodeJid,'profileImages');

            imageElment = '<img id="addParticipantImgId-'+nodeJid+'" class="contact-image" src="' + image + '" usemap="' + imageId + '" onerror="' + module.exports.profileImgError() + '" data-text="'+concatedLetter+'">';
        } else {
            imageElment = '<svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg>';
        }

        if (blockListUserInfo != null && typeof blockListUserInfo[nodeJid] != "undefined") {
            imageElment = '<svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/     1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central"       fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></     svg>';
            if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
                userInfo[nodeJid][2] = image;
                userInfo[nodeJid][3] = contactLanguage.locali().default_status;
                localStorage.setItem("usersProfileInfo", JSON.stringify(userInfo));
            }
        }

        return imageElment;
    },
    getNewGroupOncallImgClass(statusId,isUnregisteredUser){
        let oncallImageNameClass = '';
        if(!(contactUtils.getStatusName(statusId) != "" && isUnregisteredUser == 0)) 
            oncallImageNameClass = 'global-contact-oncall'

        return oncallImageNameClass;
    },
    getNewGroupFavIcon: function(values){
        let favIcon = (values.isChatUser == 1 && values.isUnregisteredUser != 1) ? '<img class="contact-image fave_icone" src="./src/images/svg/ikonixicon.svg">' : '';

        return favIcon;
    },
    getNewGroupProfileStatusDiv: function(statusId,isUnregisteredUser,values){
        let profileStatusDiv;
        if(contactUtils.getStatusName(statusId) != "" && isUnregisteredUser == 0) 
            profileStatusDiv = values.isChatUser == 1 ? '<span class="status-all" title="' + contactUtils.getStatusName(statusId) + '" style="color:' + contactUtils.getStatusImage(statusId) + '">'+ contactUtils.getStatusName(statusId) + '</span></div>' : '';
        else 
            profileStatusDiv = "";

        return profileStatusDiv;
    },
    getNewGroupPositionDiv: function(position,activeTechnician){
        let positionDiv = "";
        if(position != "") 
            positionDiv = '<div class="sideBar-msg dept-div"><span class="name-status" title="'+position+'">' + position + ' </span>';

        if(activeTechnician){
            positionDiv += '<span class="oncall-contact name-status pull-right"><img class="low-priority-arrow rollicon" src="./src/images/rollicon.svg"/><span class="oncall-contact-text">On Call</span></span>';
        }
        positionDiv += '</div>';

        return positionDiv;
    },

    getNewGroupDeptSite: function(department,siteAbbreviation) {
        let deptSite = "";
        if (department != "" && siteAbbreviation != "") {
            deptSite = department + ", " + siteAbbreviation;
        } else if (department == "" && siteAbbreviation != "") {
            deptSite = siteAbbreviation;
        } else if (department != "" && siteAbbreviation == "") {
            deptSite = department;
        } 
        return deptSite;
    },
    getNewGroupDeptSiteDiv: function(deptSite){
        let deptSiteDiv;

        deptSiteDiv = '<div class="sideBar-msg role-div">';
        if(deptSite != "")
             deptSiteDiv += '<span class="name-status fwdMsgContactSpan" title="'+deptSite+'">' + deptSite + ' </span></div>';

        return deptSiteDiv;
    },
    getNewGroupParticipantCheck: function(elementId){
        let newGroupLength = $(""+elementId+" .participantcontent").length;
        if(newGroupLength == 0) {
            $(''+elementId+' #noparticipant').remove();
            $(elementId).html("<div id='noparticipant'>" + contactLanguage.locali().nocontact_available + "</div>");
        }
    },
    getNewGroupExistingParticipantCheck: function(elementId){
        let groupaddUserInfo = JSON.parse(localStorage.getItem("groupaddUserInfo"));
        if (groupaddUserInfo != null) {
            for (let key in groupaddUserInfo) {
                $(elementId).find("#participant-" + key).css("display", "none");
            }
        }
    },
    getNewGroupContacts: function (newGrpQueryParams,elementName,is_header_tab) {
        let elementId =  "#"+elementName;

        let contactsData        = module.exports.getContacts(newGrpQueryParams);

        let searchParams = new URLSearchParams(newGrpQueryParams),
            pageNumber   = searchParams.get('page');

        module.exports.getNewGroupPageEmptyCheck(pageNumber,elementId);

        if (typeof contactsData !== 'undefined' && contactsData.length > 0) {
            for (let keys in contactsData) {

                let values              = contactsData[keys],
                    jid                 = values.username + '@' + contactStropheConnection.Server(),
                    nodeJid             = contactStropheConnection.Strophe().getNodeFromJid(jid),
                    statusId            = values.status,
                    surName             = values.surName,
                    givenName           = values.givenName,
                    displayName         = "",
                    siteId              = values.siteId,
                    siteAbbreviation    = contactUtils.getSiteAbbreviation(siteId),
                    position            = values.position.trim(),
                    department          = values.department.trim(),
                    activeTechnician    = values.isActiveTechnician,
                    positionDiv         = "",
                    deptSite            = "",
                    deptSiteDiv         = "",
                    isUnregisteredUser  = values.isUnregisteredUser,
                    imageId             = values.imageToken.trim(),
                    name                = values.name,
                    imageElment ,
                    concatedLetter;

                    displayName = module.exports.getContactDisplayName(name,givenName,surName,values);
                    concatedLetter = contactUtils.getImageDisplayText(name);
                    imageElment = module.exports.getNewGroupImageElment(imageId,values,nodeJid,concatedLetter);

                    let oncallImageNameClass = module.exports.getNewGroupOncallImgClass(statusId,isUnregisteredUser);

                let favIcon         = module.exports.getNewGroupFavIcon(values); 
                let participantDiv  = '<div class="row sideBar-body participantcontent" id="participant-' + nodeJid + '" data-ischatuser = "'+values.isChatUser+'" data-isunregisteruser = "'+values.isUnregisteredUser+'"><div class="col-sm-3 col-xs-3 sideBar-avatar">';
                let imageDiv        = '<div class="avatar-icon">'+imageElment+favIcon+'</div></div>';
                let nameDiv         = '<div class="col-sm-9 col-xs-9 sideBar-main contact-list-main"><div class="row"><div class=" sideBar-name contact-name '+oncallImageNameClass+'"><div class="contact-list-div"><span class="name-meta contact-list">' + minEmoji(displayName) + '</span>';

                let profileStatusDiv = module.exports.getNewGroupProfileStatusDiv(statusId,isUnregisteredUser,values);

                let nameDivContacts = nameDiv + profileStatusDiv + '</div>';

                positionDiv = module.exports.getNewGroupPositionDiv(position,activeTechnician);

                deptSite = module.exports.getNewGroupDeptSite(department,siteAbbreviation);
                deptSiteDiv = module.exports.getNewGroupDeptSiteDiv(deptSite);

                let roleDeptDiv = positionDiv + deptSiteDiv + '</div></div>';

                if($(""+elementId+" [id='participant-"+nodeJid+"']").length == 0) {
                    if(pageNumber == 1 || pageNumber == ""){
                        $(elementId).append(participantDiv + imageDiv + nameDivContacts + roleDeptDiv);

                    }else {
                        $(elementId).find('.mCSB_container').append(participantDiv + imageDiv + nameDivContacts + roleDeptDiv);
                    }

                    let imgEl = document.getElementById("addParticipantImgId-"+nodeJid);
                    if(imgEl){
                        utils.imgElementErrorCheck(imgEl)
                    }
                    

                }
            }
        }

        module.exports.getNewGroupParticipantCheck(elementId);
        
        module.exports.getNewGroupExistingParticipantCheck(elementId);

        if($('#bubble-block').children().length > 0){
            $('#participants').css('padding','0px')
            $('#participant-sites-info').css('padding','0px')
        }else {
            $('#participants').css('padding','1px')
            $('#participant-sites-info').css('padding','1px')
        }
      /** Participant Div Calculation and Update**/
       if (pageNumber == 1 || pageNumber == "") {
        $(elementId).mCustomScrollbar({
            callbacks: {
                onTotalScroll: function () {
                    console.log('Scroll End')
                    let siteId        = $("#currentSiteId").val(),
                    rosterCount       = $(elementId+' .participantcontent').length,
                    rosterLength      = rosterCount / contactLanguage.locali().default_size_pagination,
                    page              = Math.round(rosterLength) + 1;
                if (rosterCount % contactLanguage.locali().default_size_pagination == 0) {
                    let glRosQueryParams = '?size=' + contactLanguage.locali().default_size_pagination + '&isChatUser=1'+ '&siteId=' + 
                    siteId + '&page=' + page;
                    module.exports.getNewGroupContacts(glRosQueryParams,elementName);
                }
                    
                }
            }
        });
        let participantStaticDivHeight = contactUtils.getAddParticipantStaticDivHeight(elementId,is_header_tab);
        $(elementId).css({ 'height': 'calc(100vh - ' + participantStaticDivHeight+ 'px)' });
        setTimeout(function () {
            $(elementId).mCustomScrollbar("update");
          }, 300);
       }else {
            let participantStaticDivHeight = contactUtils.getAddParticipantStaticDivHeight(elementId,is_header_tab);
            $(elementId).css({ 'height': 'calc(100vh - ' + participantStaticDivHeight+ 'px)' });
            $(elementId).mCustomScrollbar("update");
            
       }

    },
    existingGroupPageEmptyCheck(pageNumber){
        if (pageNumber == 1) 
        $("#participantcontact").empty();
    },
    existingGroupDisplayName: function(displayName,surName){
        if (surName != null) {
            displayName = displayName + " " + surName;  
        }

        return displayName;
    },
    existingGroupOncallImageName: function(statusId,isUnregisteredUser){
        let oncallImageNameClass = '';
        if(!(contactUtils.getStatusName(statusId) != "" && isUnregisteredUser == 0)) 
            oncallImageNameClass = 'global-contact-oncall'

        return oncallImageNameClass;
    },
    existingGroupFavIcon: function(values){
        let favIcon = (values.isChatUser == 1 && values.isUnregisteredUser != 1) ? '<img class="fave_icone" src="./src/images/svg/ikonixicon.svg">' : '';

        return favIcon;
    },

    existingGroupCheckBoxEl: function(values,displayName,nodeJid,imageId){
        let CheckBoxEl = (values.isChatUser == 1 && values.isUnregisteredUser != 1) ? '<input type="checkbox" class="select-message3" name="' + displayName + '" value="' + nodeJid + '" id="' + imageId + '">' : '';

        return CheckBoxEl;
    },
    existingGroupPositionDiv(position){
        let positionDiv = "";
        if(position != "") {
            positionDiv = '<div class="sideBar-msg dept-div"><span class="name-status" title="'+position+'">' + position + ' </span></div>';
        }

        return positionDiv;
    },
    existingGroupImgElement(nodeJid,imageId,values,concatedLetter){
        let userToken           = contactStorage.getuserToken(),
            userInfo            = JSON.parse(localStorage.getItem("usersProfileInfo")),
            blockListUserInfo   = JSON.parse(localStorage.getItem("blockListUserInfo"));
        let mediaUrl = window._env_.ApiURL + "/media/";

        let image,imageElment;

                if (blockListUserInfo != null && typeof blockListUserInfo[nodeJid] != "undefined") {
                    image = "src/images/avatar/chat/profile_img_one.svg";
                    if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
                        userInfo[nodeJid][2] = image;
                        userInfo[nodeJid][3] = contactLanguage.locali().default_status;
                        localStorage.setItem("usersProfileInfo", JSON.stringify(userInfo));
                    }
                }

                if (imageId != null && imageId != "src/images/avatar/chat/profile_img_one.svg" &&imageId.trim() != "") {
                    if (userInfo != null && typeof userInfo[values.username.toLowerCase()] != "undefined") {
                        
                        let last2 = values.username.slice(-2);
                        if(last2 == '==')
                            imageId = userInfo[values.username.toLowerCase()][2];
                        
                        image = mediaUrl + imageId + "?mf=" + userToken;
                    } else{
                        image = mediaUrl + imageId + "?mf=" + userToken;
                    }
                    contactIndexDb.blobImageConversion(image,'#existGroupcontacImg-'+nodeJid+'',nodeJid,'profileImages');

                    imageElment = '<img id="existGroupcontacImg-'+nodeJid+'" class="contact-image" src="' + image + '" usemap="' + imageId + '" onerror="' + module.exports.profileImgError() + '" data-text="'+concatedLetter+'" style="margin-left:-3px;">';
                } else {
                    imageId = "";
                    imageElment = '<svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg>';
                }

                let imgObj = {
                    imageId : imageId,
                    imageElment : imageElment
                }
                
                return imgObj;
    },
    existingGroupDeptSite: function(department,siteAbbreviation){
        let deptSite="";
        if (department != "" && siteAbbreviation != "") {
            deptSite = department + ", " + siteAbbreviation;
        } else if (department == "" && siteAbbreviation != "") {
            deptSite = siteAbbreviation;
        } else if (department != "" && siteAbbreviation == "") {
            deptSite = department;
        } 
        return deptSite;
    },
    existingGroupDeptSiteDiv(deptSite) {
        let deptSiteDiv = '<div class="sideBar-msg role-div">';
        if(deptSite != "")
            deptSiteDiv += '<span class="name-status fwdMsgContactSpan" title="'+deptSite+'">' + deptSite + ' </span>';

        deptSiteDiv += '</div>';

        return deptSiteDiv;
    },
    existingGroupParticipantAppend: function(){
        let toJID = JSON.parse(localStorage.getItem("toJID")),
        groupDetail = JSON.parse(localStorage.getItem("groupDetails")),
        broadcastDetail = JSON.parse(localStorage.getItem("broadcastDetails"));
        let isGroup = $('.add-participant-msg-send').attr('data-isgroup');
        groupDetail = (isGroup == 1) ? groupDetail : broadcastDetail;
        let id,groupmember;
        if (toJID != null) 
            id = toJID.split('@')[0];
        if (toJID != null && groupDetail != null && typeof groupDetail[id] != "undefined") {
            groupmember = groupDetail[id];
            for (let keys in groupmember) {
                let values = groupmember[keys];
                let itemvalue = values.user;
                $("#participantcontact").find("[id='forwardcontact-"+itemvalue+"']").css('display', 'none');
            }
        }
    },
    existingGroupRosterLengthCheck: function(rosterLength){
        if(rosterLength == 0) {
            $('#participantcontact #noparticipant').remove();
            $("#participantcontact").html("<div id='noparticipant'>" + contactLanguage.locali().nocontact_available + "</div>");
        }
    },
    getExistingGroupContacts: function (exQueryParams) {
        
        let contactsData        = module.exports.getContacts(exQueryParams);

        let searchParams = new URLSearchParams(exQueryParams),
            pageNumber   = searchParams.get('page');

        module.exports.existingGroupPageEmptyCheck(pageNumber)

        if (typeof contactsData !== 'undefined' && contactsData.length > 0) {
            for (let keys in contactsData) {

                let values              = contactsData[keys],
                    jid                 = values.username + '@' + contactStropheConnection.Server(),
                    nodeJid             = contactStropheConnection.Strophe().getNodeFromJid(jid),
                    statusId            = values.status,
                    surName             = values.surName,
                    displayName         = values.givenName,
                    siteId              = values.siteId,
                    siteAbbreviation    = contactUtils.getSiteAbbreviation(siteId),
                    position            = values.position.trim(),
                    department          = values.department.trim(),
                    positionDiv         = "",
                    deptSite            = "",
                    deptSiteDiv         = "",
                    isUnregisteredUser  = values.isUnregisteredUser,
                    imageId             = values.imageToken.trim(),
                    name                = values.name,
                    imageElment,
                    concatedLetter;

                    concatedLetter = contactUtils.getImageDisplayText(name);
                    displayName = module.exports.existingGroupDisplayName(displayName,surName);

                    let imageObj = module.exports.existingGroupImgElement(nodeJid,imageId,values,concatedLetter);
                    imageId = imageObj.imageId;
                    imageElment = imageObj.imageElment;


                   let oncallImageNameClass = module.exports.existingGroupOncallImageName(statusId,isUnregisteredUser);

                let favIcon         = module.exports.existingGroupFavIcon(values); 
                let CheckBoxEl      = module.exports.existingGroupCheckBoxEl(values,displayName,nodeJid,imageId);
                let contactDiv      = '<div class="contactfwdcontent contact-list row sideBar-body" id="forwardcontact-' + nodeJid + '"><div class="select-cnt-blk">'+CheckBoxEl+'<label></label></div><div class="col-sm-3 col-xs-3 sideBar-avatar">';
                let imageDiv        = '<div class="avatar-icon">'+imageElment+favIcon+'</div></div>';
                let nameDiv         = '<div class="col-sm-9 col-xs-9 sideBar-main contact-list-main"><div class="row"><div class=" sideBar-name contact-name '+oncallImageNameClass+'"><div class="contact-list-div"><span class="name-meta contact-list">' + minEmoji(displayName) + '</span>';

                let nameDivFwd = nameDiv + '</div></div>';

                positionDiv = module.exports.existingGroupPositionDiv(position); 

                deptSite = module.exports.existingGroupDeptSite(department,siteAbbreviation);

                deptSiteDiv = module.exports.existingGroupDeptSiteDiv(deptSite); 

                let roleDeptDiv = positionDiv + deptSiteDiv + '</div></div>';

                $("#participantcontact").append(contactDiv + imageDiv + nameDivFwd + roleDeptDiv);

                let imgEl = document.getElementById("existGroupcontacImg-"+nodeJid);
                if(imgEl){
                    utils.imgElementErrorCheck(imgEl)

                }
              
            }
        }

        module.exports.existingGroupParticipantAppend();
        

        let selecteduserInfo = JSON.parse(localStorage.getItem("selecteduserInfo"));
        for(let key in selecteduserInfo) {
            $("#participantcontact .contactfwdcontent").find("input[type=checkbox][value=" + selecteduserInfo[key][0] + "]").prop("checked", true).change();
        }

        let rosterLength = $('#participantcontact .contactfwdcontent:visible').length;

        module.exports.existingGroupRosterLengthCheck(rosterLength);
    },
    ForwardContactsPageNumberCheck: function(pageNumber){
        if (pageNumber == 1) 
            $(".rosterchatcontact").empty();
    },
    ForwardContactsDisplayName(values,givenName,surName){
        let displayName;
        if(values.name == null){
            if((givenName != null && givenName != '') && (surName != null && surName != '')){
                displayName = givenName + " " + surName;
            }else if((givenName != null && givenName != '')){
                displayName = givenName;
            }else if((surName != null && surName != '')){
                displayName = surName;
            }else {
                displayName = values.ikonixUsername;
            }
        }else {
            displayName = values.name;
        }
        return displayName;
    },
    ForwardContactsImgObj: function(isUnregisteredUser,imageId,statusId,values){
        let imgObj,image;
        let userToken  = contactStorage.getuserToken();
        let userInfo   = JSON.parse(localStorage.getItem("usersProfileInfo"));
        let mediaUrl = window._env_.ApiURL + "/media/";

        if (isUnregisteredUser === 1) {
            imageId = '';
            image = 'src/images/avatar/chat/profile_img_one.svg';
            statusId = '';
        } else if (imageId != null && imageId.trim() != "") {
            if (userInfo != null && typeof userInfo[values.username.toLowerCase()] != "undefined") {
                
                let last2 = values.username.slice(-2);
                if(last2 == '==')
                    imageId = userInfo[values.username.toLowerCase()][2];
                
                image = mediaUrl + imageId + "?mf=" + userToken;
            } else{
                image = mediaUrl + imageId + "?mf=" + userToken;
            }
        } else {
            imageId = "";
            image = 'src/images/avatar/chat/profile_img_one.svg';
        } 
        imgObj = {
            imageId:imageId,
            image : image,
            statusId: statusId

        }   

        return imgObj;
    },
    ForwardContactsBlockListCheck: function(nodeJid){
       let userInfo            = JSON.parse(localStorage.getItem("usersProfileInfo")),
           blockListUserInfo   = JSON.parse(localStorage.getItem("blockListUserInfo"));
        let image;

        if (blockListUserInfo != null && typeof blockListUserInfo[nodeJid] != "undefined") {
            image = "src/images/avatar/chat/profile_img_one.svg";
            if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
                userInfo[nodeJid][2] = image;
                userInfo[nodeJid][3] = contactLanguage.locali().default_status;
                localStorage.setItem("usersProfileInfo", JSON.stringify(userInfo));
            }
        }
    },
 
    ForwardContactsImageElment: function(image,nodeJid,imageId,concatedLetter){
        let imageElment;
        if(image !== 'src/images/avatar/chat/profile_img_one.svg' && image.trim() !== ''){
            contactIndexDb.blobImageConversion(image,'#fwdContacImg-'+nodeJid+'',nodeJid,'profileImages');

            imageElment ='<img id="fwdContacImg-'+nodeJid+'" class="contact-image" src="' + image + '" usemap="' + imageId + '" onerror="' + module.exports.profileImgError() + '" data-text="'+concatedLetter+'">';
        }  else {
            imageElment = '<svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg>';
        }
        return imageElment;
    },
    ForwardContactsOncallImageName(statusId,isUnregisteredUser) {
        let oncallImageNameClass = '';
        if(!(contactUtils.getStatusName(statusId) != "" && isUnregisteredUser == 0)) 
            oncallImageNameClass = 'global-contact-oncall';

        return oncallImageNameClass;
    },
    ForwardContactsPositionDiv(position) {
        let positionDiv = "";
        if(position != "") 
            positionDiv = '<div class="sideBar-msg role-div"><span class="name-status" title="'+position+'">' + position + ' </span></div>';

        return positionDiv;
    },
    ForwardContactsDeptSite: function(department,siteAbbreviation){
        let deptSite = "";
        if (department != "" && siteAbbreviation != "") {
            deptSite = department + ", " + siteAbbreviation;
        } else if (department == "" && siteAbbreviation != "") {
            deptSite = siteAbbreviation;
        } else if (department != "" && siteAbbreviation == "") {
            deptSite = department;
        } 
        return deptSite;
    },
    ForwardContactsDeptSiteDiv: function(deptSite,activeTechnician){
        let deptSiteDiv = "";
        deptSiteDiv = '<div class="sideBar-msg dept-div">';
            if(deptSite != "")
                deptSiteDiv += '<span class="name-status fwdMsgContactSpan" title="'+deptSite+'">' + deptSite + ' </span>';
            if(activeTechnician)
                deptSiteDiv += '<span class="oncall-contact name-status pull-right"><img class="low-priority-arrow rollicon" src="./src/images/rollicon.svg"/><span class="oncall-contact-text">On Call</span></span>';
            deptSiteDiv += '</div>';

        return deptSiteDiv;   
    },
    ForwardContactsFwdLengthCheck: function(){
        let fwdLength = $(".rosterchatcontact .contactfwdcontent").length;
        if(fwdLength == 0) {
            $('.rosterchatcontact #noparticipant').remove();
            $(".rosterchatcontact").html("<div id='noparticipant'>" + contactLanguage.locali().nocontact_available + "</div>");
        }
    },
    ForwardContactsSelected: function(){
        let selecteduserInfo = JSON.parse(localStorage.getItem("selecteduserInfo"));
        if (selecteduserInfo != null) {
            for(let key in selecteduserInfo) {
                $(".rosterchatcontact .contactfwdcontent").find("input[type=checkbox][value=" + selecteduserInfo[key][0] + "]").prop("checked", true);
            }
        }
    },
    ForwardContactsRecentContact: function(search){
        $(".recentchatcontact").find('.contactcontent').each(function () {

            if(!($(this).hasClass('contact-list'))) {
                $(this).addClass('contact-list')
            }
            let recentval = $(this).find('.name-meta').text().toUpperCase();
            let recentcont = $(this).find('.text-italic').text().toUpperCase();
            let searchtxt;
            if (typeof search !== "undefined" && search != null) {
                searchtxt = search.toUpperCase();
                if (recentval.indexOf(searchtxt) != -1 || recentcont.indexOf(searchtxt) != -1) {
                    $(".recentchatcontact").find("[id='"+$(this).attr('id')+"']").css('display', 'none');
                }
                $(".fwdrosters").find(".priority-message-recent").remove();
            }

            let recentFwdimgEl = $(this).find('.forwardprofileicon');
            if(recentFwdimgEl.length > 0){
                let recentFwdimgAttr = $(this).find('.forwardprofileicon').attr('src');
                if(recentFwdimgAttr.trim() !== '' && (recentFwdimgAttr == 'src/images/avatar/chat/profile_img_one.svg' || recentFwdimgAttr == './src/images/avatar/chat/profile_img_one.svg')){
                    let svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
                    svg.setAttribute('class','avatar-svg');
                    svg.setAttribute('viewBox','0 0 100 100');
        
                    svg.setAttribute('xmlns','http://www.w3.org/2000/svg');
                    svg.setAttribute('xmlns:xlink','http://www.w3.org/1999/xlink');
                    svg.setAttribute('style','transform: scale(1.25);');
        
                    let circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
                    circle.setAttribute('fill','#CCC');
                    circle.setAttribute('cx','50');
                    circle.setAttribute('cy','50');
                    circle.setAttribute('r','50');
        
                    let text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
                    text.setAttribute('dominant-baseline', 'central');
                    text.setAttribute('fill', 'rgba(255,255,255,1)');
                    text.setAttribute('font-size', '30pt');
                    text.setAttribute('text-anchor', 'middle');
                    text.setAttribute('x', '50');
                    text.setAttribute('y', '50');
                    text.setAttribute('style', 'font-weight:700');
        
                    text.textContent = recentFwdimgEl.attr('data-text');
        
                    svg.appendChild(circle);
                    svg.appendChild(text); 
                    recentFwdimgEl.replaceWith(svg);
                }
            }
        });

    },
    getForwardContacts: function (fwdQueryParams) {
        let recentdesign = require('./recentdesign.js');
        recentdesign.forwardContactClick();
        contactUtils.forwardmsg();

        let contactsData        = module.exports.getContacts(fwdQueryParams);
        let blockUserInfo   = JSON.parse(localStorage.getItem("blockListUserInfo"));

        let searchParams = new URLSearchParams(fwdQueryParams),
            pageNumber   = searchParams.get('page'),
            search       = searchParams.get('search');

            module.exports.ForwardContactsPageNumberCheck(pageNumber);

        if (typeof contactsData !== 'undefined' && contactsData.length > 0) {
            for (let keys in contactsData) {

                let values              = contactsData[keys],
                    jid                 = values.username + '@' + contactStropheConnection.Server(),
                    nodeJid             = contactStropheConnection.Strophe().getNodeFromJid(jid),
                    statusId            = values.status,
                    surName             = values.surName,
                    givenName           = values.givenName,
                    displayName         = "",
                    siteId              = values.siteId,
                    siteAbbreviation    = contactUtils.getSiteAbbreviation(siteId),
                    position            = values.position.trim(),
                    department          = values.department.trim(),
                    activeTechnician    = values.isActiveTechnician,
                    positionDiv         = "",
                    deptSite            = "",
                    deptSiteDiv         = "",
                    isUnregisteredUser  = values.isUnregisteredUser,
                    image,
                    imageId             = values.imageToken.trim();

                    displayName = module.exports.ForwardContactsDisplayName(values,givenName,surName);
                    let imgObj = module.exports.ForwardContactsImgObj(isUnregisteredUser,imageId,statusId,values);

                    imageId  = imgObj.imageId;
                    image    = imgObj.image;
                    statusId = imgObj.statusId;

                    module.exports.ForwardContactsBlockListCheck(nodeJid);

                let concatedLetter,
                    name = displayName,
                    imageElment;

                    concatedLetter = contactUtils.getImageDisplayText(name);
                    imageElment = module.exports.ForwardContactsImageElment(image,nodeJid,imageId,concatedLetter);

                    let oncallImageNameClass = module.exports.ForwardContactsOncallImageName(statusId,isUnregisteredUser);

                let favIcon         = values.isChatUser == 1 ? '<img class="fave_icone" src="./src/images/svg/ikonixicon.svg">' : '';
                let contactDiv = '<div class="contactfwdcontent contact-list sideBar-body" id="forwardcontact-' + nodeJid + '"><div class="select-cnt-blk"><input type="checkbox" class="select-message3" name="' + displayName + '" value="' + nodeJid + '" id="' + imageId + '"><label></label></div><div class="col-sm-3 col-xs-3 sideBar-avatar">';
                let imageDiv        = '<div class="avatar-icon">'+imageElment+favIcon+'</div></div>';
                let nameDiv         = '<div class="col-sm-9 col-xs-9 sideBar-main contact-list-main"><div class="row"><div class="col-sm-12 col-md-12 col-xs-12 sideBar-name contact-name '+oncallImageNameClass+'"><div class="contact-list-div"><span class="name-meta contact-list">' + minEmoji(displayName) + '</span>';

                let nameDivFwd = nameDiv + '</div></div>';
                
                positionDiv = module.exports.ForwardContactsPositionDiv(position);
                deptSite = module.exports.ForwardContactsDeptSite(department,siteAbbreviation);
                deptSiteDiv = module.exports.ForwardContactsDeptSiteDiv(deptSite,activeTechnician);
                
                let roleDeptDiv = positionDiv + deptSiteDiv + '</div></div>';

                if (blockUserInfo == null || typeof blockUserInfo[nodeJid] == "undefined") {
                    $(".rosterchatcontact #noroster").remove();
                    $(".rosterchatcontact").append(contactDiv + imageDiv + nameDivFwd + roleDeptDiv);

                    let imgEl = document.getElementById("fwdContacImg-"+nodeJid);
                    if(imgEl){
                        utils.imgElementErrorCheck(imgEl)
                    }
                   
                }                    
                
                $(".fwdrosters").find(".oncall-contact").css("display", "none");

            }
        }
        
        module.exports.ForwardContactsFwdLengthCheck()
        module.exports.ForwardContactsRecentContact(search)

        module.exports.ForwardContactsSelected()

    },

    /** New BroadCast Add Participant Start **/

    getNewBroadCastPageEmptyCheck: function(pageNumber,elementId){
        if (pageNumber == 1) 
            $(elementId).empty();
    },
    getNewBroadCastImageElment: function(imageId,values,nodeJid,concatedLetter){
        let image,imageElment;
        let userToken           = contactStorage.getuserToken(),
            userInfo            = JSON.parse(localStorage.getItem("usersProfileInfo")),
            blockListUserInfo   = JSON.parse(localStorage.getItem("blockListUserInfo"));

        let mediaUrl = window._env_.ApiURL + "/media/";

        if (imageId != null && imageId !== 'src/images/avatar/chat/profile_img_one.svg' && imageId.trim() != "") {
            if (userInfo != null && typeof userInfo[values.username.toLowerCase()] != "undefined") {
                
                let last2 = values.username.slice(-2);
                if(last2 == '==')
                    imageId = userInfo[values.username.toLowerCase()][2];
                
                image = mediaUrl + imageId + "?mf=" + userToken;
            } else{
                image = mediaUrl + imageId + "?mf=" + userToken;
            }
            contactIndexDb.blobImageConversion(image,'#addParticipantImgId-'+nodeJid+'',nodeJid,'profileImages');

            imageElment = '<img id="addParticipantImgId-'+nodeJid+'" class="contact-image" src="' + image + '" usemap="' + imageId + '" onerror="' + module.exports.profileImgError() + '" data-text="'+concatedLetter+'">';
        } else {
            imageElment = '<svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg>';
        }

        if (blockListUserInfo != null && typeof blockListUserInfo[nodeJid] != "undefined") {
            imageElment = '<svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/     1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central"       fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></     svg>';
            if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
                userInfo[nodeJid][2] = image;
                userInfo[nodeJid][3] = contactLanguage.locali().default_status;
                localStorage.setItem("usersProfileInfo", JSON.stringify(userInfo));
            }
        }

        return imageElment;
    },
    getNewBroadCastOncallImgClass(statusId,isUnregisteredUser){
        let oncallImageNameClass = '';
        if(!(contactUtils.getStatusName(statusId) != "" && isUnregisteredUser == 0)) 
            oncallImageNameClass = 'global-contact-oncall'

        return oncallImageNameClass;
    },
    getNewBroadCastFavIcon: function(values){
        let favIcon = (values.isChatUser == 1 && values.isUnregisteredUser != 1) ? '<img class="contact-image fave_icone" src="./src/images/svg/ikonixicon.svg">' : '';

        return favIcon;
    },
    getNewBroadCastProfileStatusDiv: function(statusId,isUnregisteredUser,values){
        let profileStatusDiv;
        if(contactUtils.getStatusName(statusId) != "" && isUnregisteredUser == 0) 
            profileStatusDiv = values.isChatUser == 1 ? '<span class="status-all" title="' + contactUtils.getStatusName(statusId) + '" style="color:' + contactUtils.getStatusImage(statusId) + '">'+ contactUtils.getStatusName(statusId) + '</span></div>' : '';
        else 
            profileStatusDiv = "";

        return profileStatusDiv;
    },
    getNewBroadCastPositionDiv: function(position,activeTechnician){
        let positionDiv = "";
        if(position != "") 
            positionDiv = '<div class="sideBar-msg dept-div"><span class="name-status" title="'+position+'">' + position + ' </span>';

        if(activeTechnician){
            positionDiv += '<span class="oncall-contact name-status pull-right"><img class="low-priority-arrow rollicon" src="./src/images/rollicon.svg"/><span class="oncall-contact-text">On Call</span></span>';
        }
        positionDiv += '</div>';

        return positionDiv;
    },

    getNewBroadCastDeptSite: function(department,siteAbbreviation) {
        let deptSite = "";
        if (department != "" && siteAbbreviation != "") {
            deptSite = department + ", " + siteAbbreviation;
        } else if (department == "" && siteAbbreviation != "") {
            deptSite = siteAbbreviation;
        } else if (department != "" && siteAbbreviation == "") {
            deptSite = department;
        } 
        return deptSite;
    },
    getNewBroadCastDeptSiteDiv: function(deptSite){
        let deptSiteDiv;

        deptSiteDiv = '<div class="sideBar-msg role-div">';
        if(deptSite != "")
             deptSiteDiv += '<span class="name-status fwdMsgContactSpan" title="'+deptSite+'">' + deptSite + ' </span></div>';

        return deptSiteDiv;
    },
    getNewBroadCastParticipantCheck: function(elementId){
        let newGroupLength = $(""+elementId+" .broadCastParticipantcontent").length;
        if(newGroupLength == 0) {
            $(''+elementId+' #noparticipant').remove();
            $(elementId).html("<div id='noparticipant'>" + contactLanguage.locali().nocontact_available + "</div>");
        }
    },
    getNewBroadCastExistingParticipantCheck: function(elementId){
        let broadcastAddUserInfo = JSON.parse(localStorage.getItem("broadcastAddUserInfo"));
        if (broadcastAddUserInfo != null) {
            for (let key in broadcastAddUserInfo) {
                $(elementId).find("#participant-" + key).css("display", "none");
            }
        }
        let newBrVisibleElLength = $(""+elementId+" .broadCastParticipantcontent:visible").length;
        if(newBrVisibleElLength == 0) {
            $(''+elementId+' #noparticipant').remove();
            $(elementId).append("<div id='noparticipant'>" + contactLanguage.locali().nocontact_available + "</div>");
        }
    },
    getNewBroadCastContacts: function (newGrpQueryParams,elementName,is_header_tab) {
        let elementId =  "#"+elementName;

        let contactsData        = module.exports.getContacts(newGrpQueryParams);

        let searchParams = new URLSearchParams(newGrpQueryParams),
            pageNumber   = searchParams.get('page');

        module.exports.getNewBroadCastPageEmptyCheck(pageNumber,elementId);

        if (typeof contactsData !== 'undefined' && contactsData.length > 0) {
            for (let keys in contactsData) {

                let values              = contactsData[keys],
                    jid                 = values.username + '@' + contactStropheConnection.Server(),
                    nodeJid             = contactStropheConnection.Strophe().getNodeFromJid(jid),
                    statusId            = values.status,
                    surName             = values.surName,
                    givenName           = values.givenName,
                    displayName         = "",
                    siteId              = values.siteId,
                    siteAbbreviation    = contactUtils.getSiteAbbreviation(siteId),
                    position            = values.position.trim(),
                    department          = values.department.trim(),
                    activeTechnician    = values.isActiveTechnician,
                    positionDiv         = "",
                    deptSite            = "",
                    deptSiteDiv         = "",
                    isUnregisteredUser  = values.isUnregisteredUser,
                    imageId             = values.imageToken.trim(),
                    name                = values.name,
                    imageElment ,
                    concatedLetter;

                    displayName = module.exports.getContactDisplayName(name,givenName,surName,values);
                    concatedLetter = contactUtils.getImageDisplayText(name);
                    imageElment = module.exports.getNewBroadCastImageElment(imageId,values,nodeJid,concatedLetter);

                    let oncallImageNameClass = module.exports.getNewBroadCastOncallImgClass(statusId,isUnregisteredUser);

                let favIcon         = module.exports.getNewBroadCastFavIcon(values); 
                let participantDiv  = '<div class="row sideBar-body broadCastParticipantcontent" id="participant-' + nodeJid + '" data-ischatuser = "'+values.isChatUser+'" data-isunregisteruser = "'+values.isUnregisteredUser+'"><div class="col-sm-3 col-xs-3 sideBar-avatar">';
                let imageDiv        = '<div class="avatar-icon">'+imageElment+favIcon+'</div></div>';
                let nameDiv         = '<div class="col-sm-9 col-xs-9 sideBar-main contact-list-main"><div class="row"><div class=" sideBar-name contact-name '+oncallImageNameClass+'"><div class="contact-list-div"><span class="name-meta contact-list">' + minEmoji(displayName) + '</span>';

                let profileStatusDiv = module.exports.getNewBroadCastProfileStatusDiv(statusId,isUnregisteredUser,values);

                let nameDivContacts = nameDiv + profileStatusDiv + '</div>';

                positionDiv = module.exports.getNewBroadCastPositionDiv(position,activeTechnician);

                deptSite = module.exports.getNewBroadCastDeptSite(department,siteAbbreviation);
                deptSiteDiv = module.exports.getNewBroadCastDeptSiteDiv(deptSite);

                let roleDeptDiv = positionDiv + deptSiteDiv + '</div></div>';

                if($(""+elementId+" [id='participant-"+nodeJid+"']").length == 0) {
                    if(pageNumber == 1 || pageNumber == ""){
                        $(elementId).append(participantDiv + imageDiv + nameDivContacts + roleDeptDiv);

                    }else {
                        $(elementId).find('.mCSB_container').append(participantDiv + imageDiv + nameDivContacts + roleDeptDiv);

                    }

                    let imgEl = document.getElementById("addParticipantImgId-"+nodeJid);
                    if(imgEl){
                        utils.imgElementErrorCheck(imgEl)
                    }


                }
            }
        }

        module.exports.getNewBroadCastParticipantCheck(elementId);
        
        module.exports.getNewBroadCastExistingParticipantCheck(elementId);

        if($('#broadcast-bubble-block').children().length > 0){
            $('#broadcast-participants').css('padding','0px')
            $('#broadcast-participant-sites-info').css('padding','0px')
        }else {
            $('#broadcast-participants').css('padding','1px')
            $('#broadcast-participant-sites-info').css('padding','1px')
        }
      /** Participant Div Calculation and Update**/
       let participantStaticDivHeight = contactUtils.getAddParticipantStaticDivHeight(elementId,is_header_tab);
       $(elementId).css({ 'height': 'calc(100vh - ' + participantStaticDivHeight+ 'px)' });
       if (pageNumber == 1 || pageNumber == "") {
        $(elementId).mCustomScrollbar({
            callbacks: {
                onTotalScroll: function () {
                    console.log('Broadcast Single page Scroll End')
                    let siteId            = $("#currentSiteId").val(),
                    rosterCount       = $(elementId+' .broadCastParticipantcontent').length,
                    rosterLength      = rosterCount / contactLanguage.locali().default_size_pagination,
                    page              = Math.round(rosterLength) + 1;
                if (rosterCount % contactLanguage.locali().default_size_pagination == 0) {
                    let glRosQueryParams = '?size=' + contactLanguage.locali().default_size_pagination + '&isChatUser=1'+ '&siteId=' + 
siteId + '&page=' + page;
                    module.exports.getNewBroadCastContacts(glRosQueryParams,elementName);
                }
                }
            }
        });
        }else {
            $(elementId).mCustomScrollbar("update");
        }

    },

    /** New BroadCast Add Participant End **/

}


$('#participants').on('scroll', function () {
    let siteId, scrollQueryParams,selectedSites;
    let selectedSitesInfo = JSON.parse(localStorage.getItem("selectedSitesInfo"));

    if(typeof selectedSitesInfo != "undefined" && selectedSitesInfo != null && 
        selectedSitesInfo.participantSelectedSite.length != 0) {
        selectedSites = selectedSitesInfo.participantSelectedSite;
    } else {
        selectedSites = siteId;
    }

    if ($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight) {
        let participantCount = $('.participantcontent').length;
        let participantLength = participantCount / contactLanguage.locali().default_size_pagination;
        let page = participantLength + 1;
        let search = $("#searchparticipant").val();
        if (participantCount % contactLanguage.locali().default_size_pagination == 0) {
            scrollQueryParams = '?size=' + contactLanguage.locali().default_size_pagination + '&isChatUser=1&siteId=' + selectedSites + '&page=' + page +'&search=' + search;

            module.exports.getNewGroupContacts(scrollQueryParams);
        }
    }
});

$('#contact').on('scroll', function () {
    let isScroll = isScrollAtBottom('contact');
    if (isScroll) {
        
        let siteId, csQueryParams,contactLocationId;
        let locationId = JSON.parse(localStorage.getItem("locationId"));        

        if ($('.mySiteExistTeam').hasClass('active') === false) {
            siteId = '';
        }
        else{
            if(locationId != null && typeof locationId != "undefined"){
                contactLocationId = locationId.mysite_contact;
            }else {
                contactLocationId = $('#siteDetails').text();
            }
            siteId = contactLocationId;
        } 

        let search =  $("#forwardText").val().trim();
        let contactfwd = $('.rosterchatcontact .contactfwdcontent').length;
        let contactfwdLength = parseInt(contactfwd) / contactLanguage.locali().default_size_pagination;
        let page = Math.round(contactfwdLength) + 1;

        if (search == "")
            csQueryParams = '?size=' + contactLanguage.locali().default_size_pagination + '&isChatUser=1&siteId=' + siteId + '&page=' + page;
        else 
            csQueryParams = '?size=' + contactLanguage.locali().default_size_pagination + '&isChatUser=1&siteId=' + siteId + '&page='+page+'&search=' + search;
        if (contactfwd % contactLanguage.locali().default_size_pagination == 0) {
            module.exports.getForwardContacts(csQueryParams);
        }
    }
});

// Function to check if scroll has reached the bottom
function isScrollAtBottom(elementId) {
    // Get the scroll position of the element you want to monitor
    let element = document.getElementById(elementId); // Replace with the ID of your element
    let scrollPosition = element.scrollTop;
    
    // Calculate the maximum scrollable height of the element
    let scrollableHeight = element.scrollHeight - element.clientHeight;
    
    // Check if the scroll position is at the bottom or near the bottom
    // You can adjust the threshold (e.g., 10) as needed
    let threshold = 10;
    return scrollableHeight - scrollPosition <= threshold;
}

function participantcontactSiteId(locationId){
    let siteId,participantcontactSiteIdLocationId;
    if ($('.mySiteExistTeam').hasClass('active') === false) {
        siteId = '';
    }
    else{
        if(locationId != null && typeof locationId != "undefined"){
            participantcontactSiteIdLocationId = locationId.mysite_contact;
        }else {
            participantcontactSiteIdLocationId = $('#siteDetails').text();
        }
        siteId = participantcontactSiteIdLocationId;
    } 
    return siteId;
}
function participantcontactQueryGet(siteId,page,search){
    let pcQueryParams;
    if (search == "")
        pcQueryParams = '?size=' + contactLanguage.locali().default_size_pagination + '&isChatUser=1&siteId=' + siteId + '&page=' + page;
    else 
        pcQueryParams = '?size=' + contactLanguage.locali().default_size_pagination + '&isChatUser=1&siteId=' + siteId + '&page='+page+'&search=' + search;

    return pcQueryParams;
}

$('#participantcontact').on('scroll', function () {
    let isScroll = isScrollAtBottom('participantcontact');
    if (isScroll) {
        let siteId, psQueryParams;
        let locationId = JSON.parse(localStorage.getItem("locationId"));  

        siteId = participantcontactSiteId(locationId);

        let search =  $("#partticipantText").val().trim();

        if(typeof siteId == 'undefined' ||siteId == 0) {
            siteId = "";
        }
        let contactfwd = $('#participantcontact .contactfwdcontent').length;
        let contactfwdLength = contactfwd / contactLanguage.locali().default_size_pagination;
        let page = contactfwdLength + 1;

        psQueryParams = participantcontactQueryGet(siteId,page,search);

        if (contactfwd % contactLanguage.locali().default_size_pagination == 0) {
            module.exports.getExistingGroupContacts(psQueryParams);
        }
    }
});
$('.side-two .filter').click(function () {
    $('.side-two .filter-options').toggleClass("menu")
});
$('.group-sec .filter').click(function () {
    $('.group-sec .filter-options').toggleClass("menu")
});
$('#add-participant .main_popup .filter').click(function () {
    $('#add-participant .main_popup .filter-options').toggleClass("menu")
});
$('.fwdrosters .filter').click(function () {
    $('.fwdrosters .filter-options').toggleClass("menu");
    if ($(".fwd-filter").hasClass("menu")) {
        $('.fwdrosters .filter-options').show();
    } else {
        $('.fwdrosters .filter-options').hide();
    }
});
