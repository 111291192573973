let storage = require('./storage.js');
let stropheConnection = require('./stropheConnection.js');
let profileStatusvCard = require('./vCard.js');
let profileStatususerSession = require('./session.js');
let profileStatusutils = require('./utils.js');
let profileStatuslanguage = require('./locale/language.js');
const utils = require('./utils.js');

// Create an instance of PhoneNumberUtil
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance(); 

let self = module.exports = {
    // function used to get the profilestatus via API
    getProfileStatus: function () {
        let profileStatusUrl = window._env_.ApiURL + "/users/profileStatus";
        $.ajax({
            url: profileStatusUrl,
            type: 'GET',
            dataType: 'json',
            cache: false,
            contentType: false,
            processData: false,
            async: false,
            crossDomain: true,
            headers: {
                'authorization': storage.getuserToken()
            },
            statusCode: {
                401: function (response) {
                    accessTokenRegenerate(JSON.parse(localStorage.getItem("loginUserInfo"))[0][0]);
                    console.log('Security Token Error');
                },
                500: function (response) {
                    console.log('Internal Server Error');
                }
            },
            success: function (response) {
                if(response.status == "204"){
                    return true;
                }

                if(response.count > 0) {
                    let profileStatus = {};
                    for (let keys in response.data) {
                        let values = response.data[keys];
                        profileStatus[values.id] = {};
                        profileStatus[values.id].name = values.name;
                        profileStatus[values.id].image = values.image;
                        
                        if($(".dropdown-status").find("#status-"+values.id).length == 0) {
                            $(".dropdown-status-ul").append("<li role='presentation'><a class='status-select' id='status-"+values.id+"' href='#' style='color:"+values.image+"' name="+values.id+">"+values.name+"</a></li>");
                        }
                    }

                    profileStatus[0] = {};
                    profileStatus[0].name = profileStatuslanguage.locali().offline;
                    profileStatus[0].image = profileStatuslanguage.locali().offline_color;
                    localStorage.setItem("profileStatus", JSON.stringify(profileStatus));
                } else {
                    $(".dropdown-status").css("display", "none");
                }
                               
            },
            error: function (jqXHR, exception) {
                utils.errorMsgHandling(jqXHR, exception)

            }
        });
        return true;
    },
}

// function called on selecting the status in list
$("body").on("click", ".status-select", function() {
    let userStatus = $(this).attr('name');
    
    if (userStatus == 0) {
        $(".profile-status").html('Offline');
        $(".profile-status").css("color", '#e90e18');
        $(".profile-status").attr("data-id", userStatus);

    } else {
        $(".profile-status").html(minEmoji(profileStatusutils.getStatusName(userStatus)));
        $(".profile-status").css("color", profileStatusutils.getStatusImage(userStatus));
        $(".profile-status").attr("data-id", userStatus);
    }
    $(".status-view .dropdown-status-ul").toggle();
});
$("body").on("click", ".cmn-edit", function() {
   $('.cmd-update').css('display','block');
   $('.cmn-edit').css('display','none');
    $("#notes").prop("disabled", false);

})
$("body").on("click", ".close-cmn", function() {
    let myProfileInfo = JSON.parse(localStorage.getItem("myInfo"));
    let user = stropheConnection.logininfo.userJID;
    $('#notes').val(myProfileInfo[user][15]);
    $("#notes").prop("disabled", true);
    $('.cmd-update').css('display','none');
   $('.cmn-edit').css('display','block')
})
$("body").on("click", ".communicationNoteEdit", function(e) {
    $('#mobile-icon-normal').css('display','block');
    $('#mobile-icon-error').css('display','none');

    let MobileEl = $('#profile').find('.profile-cmnt')
    let MobileNo = MobileEl.text();
    let mobileInput = $('<input id="commNoteInputBox" class="editInput" maxlength="255"/>').val(MobileNo);
    MobileEl.replaceWith(mobileInput);

    /** Mobile Number Input Onkey up Function **/
    $("#commNoteInputBox").on("keyup", function(e) {
        $(this).closest('.side-card').find('#cmnotes');
    });

    /** Mobile Number Input Blur Function **/
    let blurMobileNo = function(){
        let mobileInputVal = mobileInput.val();
       
        /** Avoid First Long Space **/
        if(mobileInputVal !='' && mobileInputVal == ' ' )
            mobileInputVal = mobileInputVal.slice(1)


        if((typeof mobileInputVal != "undefined") && mobileInputVal != '' && mobileInputVal) {
            let ReplacePagerEl = MobileEl.text(mobileInputVal);
            mobileInput.replaceWith(ReplacePagerEl);

            if( mobileInputVal.match(/^ *$/) === null) {
                $('#profile').find('.profile-cmnt').css('padding','10px 0');
                
            }else {
                $('#profile').find('.profile-cmnt').css('padding','20px 0');
            }

        }
        else {
            let ReplacePagerEl = MobileEl.text('');
            mobileInput.replaceWith(ReplacePagerEl);
            $('#profile').find('.profile-cmnt').css('padding','20px 0');
        }
       
      };

    mobileInput.one('blur', blurMobileNo).focus();
})

/** Mobile Number Edit Function Start **/
$("body").on("click", ".mobileNumberEdit", function(e) {
    $('#mobile-icon-normal').css('display','block');
    $('#mobile-icon-error').css('display','none');
    let mobNoReg = /^[ ()0-9+-]+$/;

    let MobileEl = $('#profile').find('.profile-mobile')
    let MobileNo = MobileEl.text();
    let mobileInput = $('<input id="mobileNoInputBox" class="editInput" />').val(MobileNo);
    MobileEl.replaceWith(mobileInput);

    /** Mobile No Input Allow Only Valid Phone number Format **/
    $('#mobileNoInputBox').on('input', function() {
        $(this).val($(this).val().replace(/[^ ()0-9+-]/gi, ''));
    });
    function mobNoInputboxFirstDesign(mobNo,El,mobEvent){
        if(phoneUtil.isValidNumber(mobNo)) {
            mobEvent.css('color','#808080');
            El.parent().find('#mobile-icon-normal').css('display','block');
            El.parent().find('#mobile-icon-error').css('display','none');
        }else {
            mobEvent.css('color','red');
            El.parent().find('#mobile-icon-error').css('display','block');
            El.parent().find('#mobile-icon-normal').css('display','none');
        }
    };
    function mobNoInputboxSecondDesign(MobLibNum,El,moEvent){
        if(phoneUtil.isValidNumber(MobLibNum)) {
            moEvent.css('color','#808080');
            El.parent().find('#mobile-icon-normal').css('display','block');
            El.parent().find('#mobile-icon-error').css('display','none');
        }else {
            moEvent.css('color','red');
            El.parent().find('#mobile-icon-error').css('display','block');
            El.parent().find('#mobile-icon-normal').css('display','none');
        }
    };
    function mobNoInputboxValidation(mobileInputVal,El,mobEvent){
        let MobLibNum;

        if(mobNoReg.test(mobileInputVal)) {
            if(phoneUtil.isPossibleNumberString(mobileInputVal)){
                 MobLibNum = phoneUtil.parseAndKeepRawInput(mobileInputVal);
                 mobNoInputboxFirstDesign(MobLibNum,El,mobEvent);

            } else {
                try {
                     MobLibNum = phoneUtil.parseAndKeepRawInput(mobileInputVal,'AU')
                     mobNoInputboxSecondDesign(MobLibNum,El,mobEvent);
                   
                } catch (e) {
                    mobEvent.css('color','red');
                    El.parent().find('.profile-mobile').css('color','red')
                    El.parent().find('#mobile-icon-error').css('display','block');
                    El.parent().find('#mobile-icon-normal').css('display','none');
                }
            }
        }else {
            mobEvent.css('color','red');
            El.parent().find('#mobile-icon-error').css('display','block');
            El.parent().find('#mobile-icon-normal').css('display','none');
        }
    };
    /** Mobile Number Input Onkey up Function **/
    $("#mobileNoInputBox").on("keyup", function(e) {
        let mobileInputVal = mobileInput.val();
        let El = $(this).closest('.side-card').find('#yourmobile');
        let mobEvent = $(this);
        if((typeof mobileInputVal != "undefined") && mobileInputVal != '' && mobileInputVal) {
            mobNoInputboxValidation(mobileInputVal,El,mobEvent)
        }
        else {
            $(this).css('color','#808080');
            El.parent().find('#mobile-icon-normal').css('display','block');
            El.parent().find('#mobile-icon-error').css('display','none');
        }
    });
    function blurMobNoFirstDesign(MobLibNum,El){
        if(phoneUtil.isValidNumber(MobLibNum)) {
            El.parent().find('.profile-mobile').css('color','#808080')
            El.parent().find('#mobile-icon-normal').css('display','block');
            El.parent().find('#mobile-icon-error').css('display','none');
        }else {
            El.parent().find('.profile-mobile').css('color','red')
            El.parent().find('#mobile-icon-error').css('display','block');
            El.parent().find('#mobile-icon-normal').css('display','none');
        }
    };

    function blurMobNoSecondDesign(mobNo,Elem){
        if(phoneUtil.isValidNumber(mobNo)) {
            Elem.parent().find('.profile-mobile').css('color','#808080')
            Elem.parent().find('#mobile-icon-normal').css('display','block');
            Elem.parent().find('#mobile-icon-error').css('display','none');
        }else {
            Elem.parent().find('.profile-mobile').css('color','red')
            Elem.parent().find('#mobile-icon-error').css('display','block');
            Elem.parent().find('#mobile-icon-normal').css('display','none');
        }
    };
    function blurMobNoPaddingDesign(mobileInputVal){
        if( mobileInputVal.match(/^ *$/) === null) {
            $('#profile').find('.profile-mobile').css('padding','10px 0');
            
        }else {
            $('#profile').find('.profile-mobile').css('padding','20px 0');

        }
    };
    function blurMobNoSplitedFun(mobileInputVal,El){
        let MobLibNum;

        if(mobNoReg.test(mobileInputVal)) {
            if(phoneUtil.isPossibleNumberString(mobileInputVal)){
                 MobLibNum = phoneUtil.parseAndKeepRawInput(mobileInputVal);
                let ReplacePagerEl = MobileEl.text(mobileInputVal);
                mobileInput.replaceWith(ReplacePagerEl);
                blurMobNoFirstDesign(MobLibNum,El);

            } else {
                try {
                     MobLibNum = phoneUtil.parseAndKeepRawInput(mobileInputVal,'AU')
                    let ReplacePagerEl = MobileEl.text(mobileInputVal);

                    mobileInput.replaceWith(ReplacePagerEl);

                    blurMobNoSecondDesign(MobLibNum,El);
                    
                } catch (e) {
                    let ReplacePagerEl = MobileEl.text(mobileInputVal);
                    mobileInput.replaceWith(ReplacePagerEl);
                    El.parent().find('.profile-mobile').css('color','red')
                    El.parent().find('#mobile-icon-error').css('display','block');
                    El.parent().find('#mobile-icon-normal').css('display','none');
                }
            }
        }else {
        let ReplacePagerEl = MobileEl.text(mobileInputVal);
            mobileInput.replaceWith(ReplacePagerEl);
            El.parent().find('.profile-mobile').css('color','red')
            El.parent().find('#mobile-icon-error').css('display','block');
            El.parent().find('#mobile-icon-normal').css('display','none');
        }
        blurMobNoPaddingDesign(mobileInputVal);
       
    };
    /** Mobile Number Input Blur Function **/
    let blurMobileNo = function(){
        let mobileInputVal = mobileInput.val();
       
        /** Avoid First Long Space **/

        if(mobileInputVal !='' && mobileInputVal == ' ' )
            mobileInputVal = mobileInputVal.slice(1)
        
        let El = $(this).closest('.side-card').find('#yourmobile');
        if((typeof mobileInputVal != "undefined") && mobileInputVal != '' && mobileInputVal) {
            blurMobNoSplitedFun(mobileInputVal,El);

        }
        else {
            El.parent().find('.profile-mobile').css('color','#808080')
            El.parent().find('#mobile-icon-normal').css('display','block');
            El.parent().find('#mobile-icon-error').css('display','none');
            let ReplacePagerEl = MobileEl.text('');
            mobileInput.replaceWith(ReplacePagerEl);
            $('#profile').find('.profile-mobile').css('padding','20px 0');
        }
       
      };

    mobileInput.one('blur', blurMobileNo).focus();

 
 });
/** Mobile Number Edit Function End **/

/** Personal Mobile Number Edit Function Start **/

 $("body").on("click", ".personalMobileNumberEdit", function(e) {
    $('#personal-mobile-normal').css('display','block');
    $('#personal-mobile-error').css('display','none');
    let personalmobNoReg = /^[ ()0-9+-]+$/;

    let personalMobEl = $('#profile').find('.profile-personal-mobile');
    let personalMobNo = personalMobEl.text();
    let personalMobInput = $('<input id="personalMobileNoInputBox" class="editInput" />').val(personalMobNo);
    personalMobEl.replaceWith(personalMobInput);

     /** Personal Mobile Input Allow Only Valid Phone number Format **/
     $('#personalMobileNoInputBox').on('input', function() {
        $(this).val($(this).val().replace(/[^ ()0-9+-]/gi, ''));
    });

    function personalMobNoFirstDesign(El,personalMobEvent,MobLibNum){
        if(phoneUtil.isValidNumber(MobLibNum)) {
            personalMobEvent.css('color','#808080');
            El.parent().find('#personal-mobile-normal').css('display','block');
            El.parent().find('#personal-mobile-error').css('display','none');
        }else {
            personalMobEvent.css('color','red');
            El.parent().find('#personal-mobile-normal').css('display','none');
            El.parent().find('#personal-mobile-error').css('display','block');
        }
    };

    function personalMobNoSecondDesign(El,personalMoEvent,MobLibNum){
        if(phoneUtil.isValidNumber(MobLibNum)) {
            personalMoEvent.css('color','#808080');
            El.parent().find('#personal-mobile-normal').css('display','block');
            El.parent().find('#personal-mobile-error').css('display','none');
            
        }else {
            personalMoEvent.css('color','red');
            El.parent().find('#personal-mobile-normal').css('display','none');
            El.parent().find('#personal-mobile-error').css('display','block');
        }
    }

    function personalMobNoValidationCheck(personalMobInputVal,El,personalMobEvent){
        let MobLibNum;

        if(personalmobNoReg.test(personalMobInputVal)) {
            if(phoneUtil.isPossibleNumberString(personalMobInputVal)){
                MobLibNum = phoneUtil.parseAndKeepRawInput(personalMobInputVal);
                personalMobNoFirstDesign(El,personalMobEvent,MobLibNum);
            } else {
                try {
                    MobLibNum = phoneUtil.parseAndKeepRawInput(personalMobInputVal,'AU')
                    personalMobNoSecondDesign(El,personalMobEvent,MobLibNum);
                    
                } catch (error) {
                    personalMobEvent.css('color','red');
                    El.parent().find('#personal-mobile-normal').css('display','none');
                    El.parent().find('#personal-mobile-error').css('display','block');
                }
            }
        
        }else {
            personalMobEvent.css('color','red');
            El.parent().find('#personal-mobile-normal').css('display','none');
            El.parent().find('#personal-mobile-error').css('display','block');
        }
    };

    /** Personal Mobile Number Input Onkey up Function **/
    $("#personalMobileNoInputBox").on("keyup", function(e) {
        let personalMobInputVal = personalMobInput.val();
        let personalMobEvent = $(this);
        let El = $(this).closest('.side-card').find('#personalMobile');
        if((typeof personalMobInputVal != "undefined") && personalMobInputVal != '' && personalMobInputVal) {
            personalMobNoValidationCheck(personalMobInputVal,El,personalMobEvent)
        }
        else {
            $(this).css('color','#808080');
            El.parent().find('#personal-mobile-normal').css('display','block');
            El.parent().find('#personal-mobile-error').css('display','none');
        }
    });

    function blurPersonalMobNoFirstDesign(mobNo,El){
        if(phoneUtil.isValidNumber(mobNo)) {
            El.parent().find('.profile-personal-mobile').css('color','#808080')
            El.parent().find('#personal-mobile-normal').css('display','block');
            El.parent().find('#personal-mobile-error').css('display','none');
        }else {
            El.parent().find('.profile-personal-mobile').css('color','red')
            El.parent().find('#personal-mobile-normal').css('display','none');
            El.parent().find('#personal-mobile-error').css('display','block');
        }
    };

    function blurPersonalMobNoSecondDesign(MobLibNum,Elem){
        if(phoneUtil.isValidNumber(MobLibNum)) {
            Elem.parent().find('.profile-personal-mobile').css('color','#808080')
            Elem.parent().find('#personal-mobile-normal').css('display','block');
            Elem.parent().find('#personal-mobile-error').css('display','none');
            
        }else {
            Elem.parent().find('.profile-personal-mobile').css('color','red')
            Elem.parent().find('#personal-mobile-normal').css('display','none');
            Elem.parent().find('#personal-mobile-error').css('display','block');
        }
    };
    function blurPersonalMobNoValidation(personalMobInputVal,El){
        let MobLibNum;

        if(personalmobNoReg.test(personalMobInputVal)) {
            if(phoneUtil.isPossibleNumberString(personalMobInputVal)){
                MobLibNum = phoneUtil.parseAndKeepRawInput(personalMobInputVal);
                let ReplacePersonalMobInputEl = personalMobEl.text(personalMobInputVal);
                personalMobInput.replaceWith(ReplacePersonalMobInputEl);
                blurPersonalMobNoFirstDesign(MobLibNum,El)
            } else {
                try {
                    MobLibNum = phoneUtil.parseAndKeepRawInput(personalMobInputVal,'AU')
                    let ReplacePersonalMobInputEl = personalMobEl.text(personalMobInputVal);

                    personalMobInput.replaceWith(ReplacePersonalMobInputEl);
                    blurPersonalMobNoSecondDesign(MobLibNum,El)
                } catch (error) {
                    let ReplacePersonalMobInputEl = personalMobEl.text(personalMobInputVal);
                    personalMobInput.replaceWith(ReplacePersonalMobInputEl);
                    El.parent().find('.profile-personal-mobile').css('color','red')
                    El.parent().find('#personal-mobile-normal').css('display','none');
                    El.parent().find('#personal-mobile-error').css('display','block');
                }
            }
        
        }else {
            let ReplacePersonalMobInputEl = personalMobEl.text(personalMobInputVal);
            personalMobInput.replaceWith(ReplacePersonalMobInputEl);
            El.parent().find('.profile-personal-mobile').css('color','red')
            El.parent().find('#personal-mobile-normal').css('display','none');
            El.parent().find('#personal-mobile-error').css('display','block');
        }
        if( personalMobInputVal.match(/^ *$/) === null) {
            $('#profile').find('.profile-personal-mobile').css('padding','10px 0');
            
        }else {
            $('#profile').find('.profile-personal-mobile').css('padding','20px 0');

        }
    };
    /** Personal Mobile Number Input Onkey up Function **/
    let blurPersonalMobNo = function(){
        let personalMobInputVal = personalMobInput.val();

        /** Avoid First Long Space **/

        if(personalMobInputVal !='' && personalMobInputVal == ' ' )
            personalMobInputVal = personalMobInputVal.slice(1)

        let El = $(this).closest('.side-card').find('#personalMobile');
        if((typeof personalMobInputVal != "undefined") && personalMobInputVal != '' && personalMobInputVal) {
            blurPersonalMobNoValidation(personalMobInputVal,El)
           
            
        }
        else {
            El.parent().find('.profile-personal-mobile').css('color','#808080')
            El.parent().find('#personal-mobile-normal').css('display','block');
            El.parent().find('#personal-mobile-error').css('display','none');
            let ReplacePersonalMobInputEl = personalMobEl.text('');
            personalMobInput.replaceWith(ReplacePersonalMobInputEl);
            $('#profile').find('.profile-personal-mobile').css('padding','20px 0');

        }
        
      };

      personalMobInput.one('blur', blurPersonalMobNo).focus();
 
 });

/** Personal Mobile Number Edit Function Start **/

/** WorkTelephone Number Edit Function Start **/
 
$("body").on("click", ".WorkTelephoneEdit", function() {
    $('#telephone-icon-normal').css('display','block');
    $('#telephone-icon-error').css('display','none');
    let workNoReg = /^[ ()0-9+-]+$/;


    let workPhoneEl = $('#profile').find('.profile-telephone');
    let workPhoneNo = workPhoneEl.text();
    let workPhoneInput = $('<input id="workTelephoneInputBox" class="editInput"/>').val(workPhoneNo);
    workPhoneEl.replaceWith(workPhoneInput);

    /** Work Telephone Input Allow Only Valid Phone number Format **/
    $('#workTelephoneInputBox').on('input', function() {
        $(this).val($(this).val().replace(/[^ ()0-9+-]/gi, ''));
    });

    function workPhoneInputFirstDesign(workPhoneLibNum,El,workPhoneEvent){
        if(phoneUtil.isValidNumber(workPhoneLibNum)) {
            workPhoneEvent.css('color','#808080');
            El.parent().find('#telephone-icon-normal').css('display','block');
            El.parent().find('#telephone-icon-error').css('display','none');
        }else {
            workPhoneEvent.css('color','red');
            El.parent().find('#telephone-icon-normal').css('display','none');
            El.parent().find('#telephone-icon-error').css('display','block');
        }
    };
    function workPhoneInputSecondDesign(workPhoneLibNum,El,workPhEvent){
        if(phoneUtil.isValidNumber(workPhoneLibNum)) {
            workPhEvent.css('color','#808080');
            El.parent().find('#telephone-icon-normal').css('display','block');
            El.parent().find('#telephone-icon-error').css('display','none');
        }else {
            workPhEvent.css('color','red');
            El.parent().find('#telephone-icon-normal').css('display','none');
            El.parent().find('#telephone-icon-error').css('display','block');
        }
    }
    function workPhoneInputValidation(workPhoneInputVal,El,workPhoneEvent){
        if(workNoReg.test(workPhoneInputVal)) {

            if(phoneUtil.isPossibleNumberString(workPhoneInputVal)){
                let workPhoneLibNum = phoneUtil.parseAndKeepRawInput(workPhoneInputVal);
                workPhoneInputFirstDesign(workPhoneLibNum,El,workPhoneEvent);
                

            } else {
                try {
                    let workPhoneLibNum = phoneUtil.parseAndKeepRawInput(workPhoneInputVal,'AU')
                    workPhoneInputSecondDesign(workPhoneLibNum,El,workPhoneEvent)
                   
                } catch (error) {
                    workPhoneEvent.css('color','red');
                    El.parent().find('#telephone-icon-normal').css('display','none');
                    El.parent().find('#telephone-icon-error').css('display','block');
                }
            }
        }else {
            workPhoneEvent.css('color','red');
            El.parent().find('#telephone-icon-normal').css('display','none');
            El.parent().find('#telephone-icon-error').css('display','block');
        }
    };

    /** Work Telephone Input Onkey up Function **/
    $("#workTelephoneInputBox").on("keyup", function(e) {
        let workPhoneInputVal = workPhoneInput.val();
        let workPhoneEvent = $(this);
        let El = $(this).closest('.side-card').find('#telephone');

        if((typeof workPhoneInputVal != "undefined") && workPhoneInputVal != '' && workPhoneInputVal) {
            workPhoneInputValidation(workPhoneInputVal,El,workPhoneEvent);
           
            
        } else {
            $(this).css('color','#808080');
            El.parent().find('.profile-telephone').css('color','#808080')
            El.parent().find('#telephone-icon-normal').css('display','block');
            El.parent().find('#telephone-icon-error').css('display','none');
        }
    });

    function blurWorkTelephoneFirstDesign(workPhoneLibNum,El){
        if(phoneUtil.isValidNumber(workPhoneLibNum)) {
            El.parent().find('.profile-telephone').css('color','#808080')
            El.parent().find('#telephone-icon-normal').css('display','block');
            El.parent().find('#telephone-icon-error').css('display','none');
        }else {
            El.parent().find('.profile-telephone').css('color','red')
            El.parent().find('#telephone-icon-normal').css('display','none');
            El.parent().find('#telephone-icon-error').css('display','block');
        }
    };

    function blurWorkTelephoneSecondDesign(workPhoneLibNum,Elem){
        if(phoneUtil.isValidNumber(workPhoneLibNum)) {
            Elem.parent().find('.profile-telephone').css('color','#808080')
            Elem.parent().find('#telephone-icon-normal').css('display','block');
            Elem.parent().find('#telephone-icon-error').css('display','none');
        }else {
            Elem.parent().find('.profile-telephone').css('color','red')
            Elem.parent().find('#telephone-icon-normal').css('display','none');
            Elem.parent().find('#telephone-icon-error').css('display','block');
        }
    }

    function blurWorkTelephoneValidation(workPhoneInputVal,El){
        let workPhoneLibNum;

        if(workNoReg.test(workPhoneInputVal)) {

            if(phoneUtil.isPossibleNumberString(workPhoneInputVal)){
                workPhoneLibNum = phoneUtil.parseAndKeepRawInput(workPhoneInputVal);
                let ReplaceworkPhoneEl = workPhoneEl.text(workPhoneInputVal);
                workPhoneInput.replaceWith(ReplaceworkPhoneEl);
                blurWorkTelephoneFirstDesign(workPhoneLibNum,El)
               

            } else {
                try {
                    workPhoneLibNum = phoneUtil.parseAndKeepRawInput(workPhoneInputVal,'AU')
                    let ReplaceworkPhoneEl = workPhoneEl.text(workPhoneInputVal);

                    workPhoneInput.replaceWith(ReplaceworkPhoneEl);
                    blurWorkTelephoneSecondDesign(workPhoneLibNum,El)
                   
                } catch (error) {
                    let ReplaceworkPhoneEl = workPhoneEl.text(workPhoneInputVal);
                    workPhoneInput.replaceWith(ReplaceworkPhoneEl);
                    El.parent().find('.profile-telephone').css('color','red')
                    El.parent().find('#telephone-icon-normal').css('display','none');
                    El.parent().find('#telephone-icon-error').css('display','block');
                }
            }
        }else {
            let ReplaceworkPhoneEl = workPhoneEl.text(workPhoneInputVal);
            workPhoneInput.replaceWith(ReplaceworkPhoneEl);
            El.parent().find('.profile-telephone').css('color','red')
            El.parent().find('#telephone-icon-normal').css('display','none');
            El.parent().find('#telephone-icon-error').css('display','block');
        }
        if( workPhoneInputVal.match(/^ *$/) === null) {
            $('#profile').find('.profile-telephone').css('padding','10px 0');
            
        }else {
            $('#profile').find('.profile-telephone').css('padding','20px 0');

        }
    }
    /** Work Telephone Input Function **/
    let blurWorkTelephone = function(){
        let workPhoneInputVal = workPhoneInput.val();
        
        /** Avoid First Long Space **/

        if(workPhoneInputVal !='' && workPhoneInputVal == ' ' )
            workPhoneInputVal = workPhoneInputVal.slice(1)
        
        let El = $(this).closest('.side-card').find('#telephone');
        if((typeof workPhoneInputVal != "undefined") && workPhoneInputVal != '' && workPhoneInputVal) {
            blurWorkTelephoneValidation(workPhoneInputVal,El);
            
        } else {
            El.parent().find('.profile-telephone').css('color','#808080')
            El.parent().find('#telephone-icon-normal').css('display','block');
            El.parent().find('#telephone-icon-error').css('display','none');
            let ReplaceworkPhoneEl = workPhoneEl.text('');
            workPhoneInput.replaceWith(ReplaceworkPhoneEl);
            $('#profile').find('.profile-telephone').css('padding','20px 0');

        }

      };

    workPhoneInput.one('blur', blurWorkTelephone).focus();

 
 });

/** WorkTelephone Number Edit Function End **/

/** Pager Number Edit Function Start **/
$("body").on("click", ".PagerNumberEdit", function() {
    $('#pager-icon-normal').css('display','block');
    $('#pager-icon-error').css('display','none');
    let paggerNoReg = /^-?\d+$/;

    let settings = JSON.parse(localStorage.getItem("settings"));
    let pagerMinLength = settings.pagerMinLength;
    let pagerMaxLength = settings.pagerMaxLength;

    let PagerEl = $('#profile').find('.profile-pager');
    let pagerNo = PagerEl.text();
    let pagerInput = $('<input id="pagerInputBox" class="editInput" maxlength="'+pagerMaxLength+'" />').val(pagerNo);
    PagerEl.replaceWith(pagerInput);

     /** Work Telephone Input Allow Only Valid Phone number Format **/
     $('#pagerInputBox').on('input', function() {
        $(this).val($(this).val().replace(/\D/gi, ''));
    });

    /** Pager Input Onkey up Function **/
    $("#pagerInputBox").on("keyup", function(e) {
        let El = $(this).closest('.side-card').find('#pager');
        let pagerInputVal = pagerInput.val();

        if((typeof pagerInputVal != "undefined") && pagerInputVal != '' && pagerInputVal) {
            if(pagerInputVal.length >= pagerMinLength && pagerInputVal.length <= pagerMaxLength && paggerNoReg.test(pagerInputVal)) {
                $(this).css('color','#808080');
                El.parent().find('#pager-icon-normal').css('display','block');
                El.parent().find('#pager-icon-error').css('display','none');
    
            }else {
                $(this).css('color','red');
                El.parent().find('#pager-icon-normal').css('display','none');
                El.parent().find('#pager-icon-error').css('display','block');
    
            }
        }else {
            $(this).css('color','#808080');
            El.parent().find('#pager-icon-normal').css('display','block');
            El.parent().find('#pager-icon-error').css('display','none');
        }
    })

    /** Pager Input Function **/
    let blurPager = function(){
        let El = $(this).closest('.side-card').find('#pager');
        let pagerInputVal = pagerInput.val();

        if((typeof pagerInputVal != "undefined") && pagerInputVal != '' && pagerInputVal) {
            let ReplacePagerEl = PagerEl.text(pagerInputVal);
            pagerInput.replaceWith(ReplacePagerEl);
            if(pagerInputVal.length >= pagerMinLength && pagerInputVal.length <= pagerMaxLength && paggerNoReg.test(pagerInputVal)) {
                El.parent().find('.profile-pager').css('color','#808080')
                El.parent().find('#pager-icon-normal').css('display','block');
                El.parent().find('#pager-icon-error').css('display','none');
    
            }else {
                El.parent().find('.profile-pager').css('color','red')
                El.parent().find('#pager-icon-normal').css('display','none');
                El.parent().find('#pager-icon-error').css('display','block');
    
            }
            $('#profile').find('.profile-pager').css('padding','10px 0');

        }else {
            let ReplacePagerEl = PagerEl.text('');
            pagerInput.replaceWith(ReplacePagerEl);
            El.parent().find('.profile-pager').css('color','#808080')
            El.parent().find('#pager-icon-normal').css('display','block');
            El.parent().find('#pager-icon-error').css('display','none');
            $('#profile').find('.profile-pager').css('padding','20px 0');
            
        }

      };

    pagerInput.one('blur', blurPager).focus();

 
 });
/** Pager Number Edit Function End **/


// function called on selecting the status in list
$('#saveNote').on("click", function() {
    $("#notes").prop("disabled", true);
    $('.cmd-update').css('display','none');
    $('.cmn-edit').css('display','block')

});

// function used to get the regenerate the access token to get the profile status
async function accessTokenRegenerate(username) {
    await profileStatususerSession.setSession(username);
    self.getProfileStatus();
}

function profileUpdateSuccess(response){
    if(response.status == 200){
        $('.cmd-update').css('display','none');
        $('.cmn-edit').css('display','block');
        $("#notes").prop("disabled", true);
        $("#infoContentGreen").text('Profile Updated Successfully');
        $(".side-profile").css({
            "left": "-100%"
        });
        $('.filter.plus-circles').show()
        $(".dropdown-status-ul").hide()
        $('#infoboxGreen').css("display", "block").delay(5000).fadeOut();
    }else if (response.status == 400) {
        $("#infoContent").text('Profile update failed. Please try again later');
        $('#infobox').css("display", "block").delay(4000).fadeOut();
    }
}
function profileSaveAjax(formData,userDetailsUrl){
    $.ajax({
        url: userDetailsUrl,
        type: 'PUT',
        dataType: 'json',
        data: JSON.stringify(formData),
        cache: false,
        // contentType: false,
        contentType: "application/json; charset=utf-8",
        processData: false,
        async: false,
        crossDomain: true,
        headers: {
            'authorization': storage.getuserToken()
        },
        statusCode: {
            401: function (response) {
                console.log('Security Token Error');
                let loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
                let username = loginUserInfo[0][0];
                storage.setSession(username)
                $(".Profile-save-btn").trigger('click')
            },
            500: function (response) {
                console.log('Internal Server Error');
            }
        },
        success: function (response) {
            profileUpdateSuccess(response)
        },
        error: function (jqXHR, exception) {
          
            console.log(jqXHR.responseText);
        }
    });
}
function getProfileMobNo(mobileNumber){
    mobileNumber = (mobileNumber == '') ? '' : mobileNumber;

    return mobileNumber;
}
function getProfileWorkNo(workNumber){
    workNumber = (workNumber == '') ? '' : workNumber;

    return workNumber;
}
function getProfilePersonalMobNo(personalMobileNumber){
    personalMobileNumber = (personalMobileNumber == '') ? '' : personalMobileNumber;

    return personalMobileNumber;
}
function getProfilePagerNo(pagerNumber){
    pagerNumber = (pagerNumber == '') ? '' : pagerNumber;

    return pagerNumber;
}
/** Profile Save Function Start **/

$("body").on("click", ".Profile-save-btn", function() {
    let userDetailsUrl = window._env_.ApiURL + "/users/profile";
    let loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
    let username = loginUserInfo[0][0];
    let myInfo = JSON.parse(localStorage.getItem('myInfo'));
    let currentUserDetails = myInfo[username];
    let nickname = currentUserDetails[1];
    let image = $('#profile').find('.profile-image').attr('data-imageid');
    let status = $('#profile').find('.profile-status').attr('data-id');
    let siteId = $('#profile').find(".profile-address").attr('data-id');
    let role = $('#profile').find(".profile-role").text();
    let department = $('#profile').find('.profile-loc').text();
    let workNumber = $('#profile').find('.profile-telephone').text();
    let mobileNumber = $('#profile').find('.profile-mobile').text();
    let personalMobileNumber = $('#profile').find('.profile-personal-mobile').text();
    let pagerNumber = $('#profile').find('.profile-pager').text();
    let unregisteredUser = '0';
    let commNotes = $('#profile').find('.profile-cmnt').text();
    let MobileNoValidationReg = /^[ ()0-9+-]+$/;
    let settings = JSON.parse(localStorage.getItem("settings"));
    let profileEditability = settings.profileEditability;

    let pagerMinLength = settings.pagerMinLength;
    let pagerMaxLength = settings.pagerMaxLength;

    if((MobileNoValidationReg.test(mobileNumber) || mobileNumber=='' || !(profileEditability.mobile_number)) && (MobileNoValidationReg.test(personalMobileNumber) || personalMobileNumber =='' || !(profileEditability.personal_mobile_number)) && (MobileNoValidationReg.test(workNumber) || workNumber == '' || !(profileEditability.work_telephone)) && (MobileNoValidationReg.test(pagerNumber) || pagerNumber == ''  || !(profileEditability.pager_number)) && (pagerNumber == '' || (pagerNumber.length >= pagerMinLength && pagerNumber.length <= pagerMaxLength) )) {
        mobileNumber         = getProfileMobNo(mobileNumber);
        workNumber           = getProfileWorkNo(workNumber);
        personalMobileNumber = getProfilePersonalMobNo(personalMobileNumber);
        pagerNumber          = getProfilePagerNo(pagerNumber);

        
        let formData = {
            'username': username,
            'nickName': nickname,
            'image': image,
            'status': status,
            'siteId': siteId,
            'role': role,
            'department': department,
            'workNumber': workNumber,
            'mobileNumber': mobileNumber,
            'pagerNumber': pagerNumber,
            'unregisteredUser': unregisteredUser,
            'commNotes': commNotes,
            'personalMobileNumber':personalMobileNumber
        }
        profileSaveAjax(formData,userDetailsUrl)
    }else {
        $("#infoContent").text('Profile update failed. Please try again later');
        $('#infobox').css("display", "block").delay(4000).fadeOut();
    }
    
});
/** Profile Save Function End **/

/** Profile Back Icon(Discard Changes) Click Event **/
$(".profile-back").click(function () {
    let myInfo = JSON.parse(localStorage.getItem("myInfo"));
    let loggedUser = JSON.parse(localStorage.getItem("loginUserInfo"))[0][0];
        myInfo = myInfo[loggedUser];
        let userPreviousInfo = [];
        userPreviousInfo[1] = myInfo[4];
        userPreviousInfo[2] = myInfo[16];
        userPreviousInfo[3] = myInfo[10];
        userPreviousInfo[4] = myInfo[11];
        userPreviousInfo[5] = myInfo[15];
        userPreviousInfo[6] = myInfo[2];
        userPreviousInfo[7] = myInfo[3];


        
        let userCurrentInfo = [];
        userCurrentInfo[1] = $('#profile').find('.profile-mobile').text();
        userCurrentInfo[2] = $('#profile').find('.profile-personal-mobile').text();
        userCurrentInfo[3] = $('#profile').find('.profile-telephone').text();
        userCurrentInfo[4] = $('#profile').find('.profile-pager').text();
        userCurrentInfo[6] = $('#profile').find('.profile-image').attr('data-imageid');
        userCurrentInfo[7] = $('#profile').find('.profile-status').attr('data-id');

        let notes = $('#profile').find('.profile-cmnt').text();
        userCurrentInfo[5] = (notes == ' ' || notes == '') ? '' : notes;

        if(userCurrentInfo[1] != userPreviousInfo[1] || userCurrentInfo[2] != userPreviousInfo[2] || userCurrentInfo[3] != userPreviousInfo[3] || userCurrentInfo[4] != userPreviousInfo[4] || userCurrentInfo[5] != userPreviousInfo[5] || userCurrentInfo[6] != userPreviousInfo[6] || userCurrentInfo[7] != userPreviousInfo[7]){
            $('#profile-discard-popup').show();
            $('.filter.plus-circles').hide()
            
        }else {
            $('#profile-discard-popup').hide();
            $(".side-profile").css({
            "left": "-100%"
            });
            $('.filter.plus-circles').show()

            $(".dropdown-status-ul").hide()
        
        }
        $("#notes").prop("disabled", true);
        $('.cmd-update').css('display','none');
        $('.cmn-edit').css('display','block');


});

/** Discard Changes Confirm Btn Click Function **/

$(".discard-confirm-btn").click(function () {
    $('.cmd-update').css('display','none');
    $('.cmn-edit').css('display','block');
    $("#notes").prop("disabled", true);

    $('.profileImageParentEl img').show();
    $('.profileImageParentEl svg').hide();
    
    $('#profile-discard-popup').hide();

    $(".side-profile").css({
        "left": "-100%"
    });
    $('.filter.plus-circles').show()

    $(".dropdown-status-ul").hide()

    let myInfo = JSON.parse(localStorage.getItem("myInfo"));
    let loggedUser = JSON.parse(localStorage.getItem("loginUserInfo"))[0][0];

    myInfo = myInfo[loggedUser];

    let setVcardNameList = { displayname : myInfo[1], userimage : myInfo[2], userstatus : myInfo[3], role : myInfo[6], department : myInfo[9], siteId : myInfo[13], mobileNumber : myInfo[4], workNumber : myInfo[10], pagerNumber : myInfo[11], nodes : myInfo[15],personalMobileNumber : myInfo[16] }

    profileStatusvCard.setVcard(setVcardNameList);

})

/** Discard Changes Cancel Btn Click Function **/

$(".discard-cancel-btn").click(function () {
    $('#profile-discard-popup').hide();
})

    
