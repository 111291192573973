let stropheConnection = require('./stropheConnection.js');
let timeFormat = require('./timeformat.js');
let storage = require('./storage.js');
let language = require('./locale/language.js');
let indexDb = require('./indexDb.js');
let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


module.exports = {
   
    setBroadcastInfo: function (broadcastName) {
        let utils = require('./utils.js');

        let broadcastInfoiq = stropheConnection.StropheJs().$iq({
            type: 'set',
            id: utils.getId(),
            to: utils.toJID()
        }).c('query', {
            xmlns: 'jabber:iq:mod_broadcast'
        })
        .c('broadcast', {
            name: broadcastName,
            status: 'broadcast_name_update'
        }).up();
        if (stropheConnection.Connection().connected) {
            stropheConnection.Connection().sendIQ(broadcastInfoiq, module.exports.broadcastInfoiqCallBack);
        }
    },
    broadcastInfoiqCallBack: function (broadcastInfoiq) {
        let utils = require('./utils.js');

        try {
            let fromuser = broadcastInfoiq.getAttribute("from");
            let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
            let from = stropheConnection.Strophe().getBareJidFromJid(fromuser);
            let broadcastId = stropheConnection.Strophe().getNodeFromJid(from);
            let msgContent = "You updated this broadcast profile";
            if (broadcastId == stropheConnection.Strophe().getNodeFromJid(utils.toJID())) {
                broadcastProfile[broadcastId][1] =  $('#broadcast-update-name').val();
                localStorage.setItem("broadcastProfile", JSON.stringify(broadcastProfile));  

                module.exports.broadcastInfoMsgCallback(broadcastProfile,broadcastId,msgContent);

            }
        } catch (err) {
            console.log("broadcastInfoiqCallBack", err);
            console.log('broadcastInfoiqCallBack iq', broadcastInfoiq);
        }
        return true;
    },
    broadcastInfoMsgCallback : function(broadcastInfo,broadcastId,msgContent){
        let utils = require('./utils.js');

        let lastContent = false;
        if (typeof lastContent != "undefined" || !lastContent) {
            let conversation = require('./conversation.js');
            conversation.MessageLayout(utils.toJID(), 'down', '');
        }

        if ($("[id='"+broadcastId+"']").length) {
            module.exports.broadcastMsgLableRecentUpdate(broadcastInfo,broadcastId,msgContent)
            module.exports.broadcastMsgLableConversationUpdate(broadcastInfo,broadcastId,msgContent)
        }
    },
    broadcastMsgLableRecentUpdate: function(broadcastInfo,broadcastId,msgContent){
        if (broadcastInfo != null && typeof broadcastInfo[broadcastId] != "undefined") {
            let usersPriorityInfo = JSON.parse(localStorage.getItem("usersPriorityInfo"));
            let msgcontentRecent;
            if(usersPriorityInfo != null && typeof(usersPriorityInfo[broadcastId]) != "undefined" && (parseInt(usersPriorityInfo[broadcastId][0]) + parseInt(usersPriorityInfo[broadcastId][1])) > 0) {
                msgcontentRecent = '<span class="priority-message-recent">PRIORITY</span>' + msgContent;
            } else {
                msgcontentRecent = msgContent;
            }
            $("[id='"+broadcastId+"']").find('.name-status').html(msgcontentRecent);
        }
    },
    broadcastMsgLableConversationUpdate: function(broadcastInfo,broadcastId,msgContent){
        if (( msgContent != "")) {
            let messagebody = '<div id = "notify-' + broadcastId + '" class="row message-previous"><div class="col-sm-12 previous"><a>' + msgContent + '</a></div></div>';
            let scrollposition = ''
            if (scrollposition != "") {
                $("[id='conversation"+broadcastId+"']").prepend(messagebody);
            }
            else if (scrollposition == "") {
                $("[id='conversation"+broadcastId+"']").append(messagebody);
            }
        }
    },
    getBroadcastMsginfo: function (msgid, ToJID,id) {
        let groupmsgiq = stropheConnection.StropheJs().$iq({
            type: 'get',
            to: ToJID,
            id: id
        }).c('query', {
            xmlns: 'jabber:iq:mod_broadcast',
            status: 'get_broadcast_message_status',
            message_id: msgid
        });
        if (stropheConnection.Connection().connected) {
            stropheConnection.Connection().sendIQ(groupmsgiq, module.exports.broadcastmsgiqCallback);
        }
        // Indicate that this handler should be called repeatedly
        return true;
    },
    broadcastmsgiqCallback: function (groupmsgiq) {
        try {
            
        let mediaUrl = window._env_.ApiURL + "/media/";
        let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
        let loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
        let loggedUser = loginUserInfo[0][0];
        let broadcastID;
        $('#deliver-append-data').html('');
        $('#read-append-data').html('');
        $('#acknowledge-append-data').html('');
        $('#accept-append-data').html('');
        $('#reject-append-data').html('');
        $('#read-count').html('');
        $('#deliver-count').html('');
        $('#response-count').html('');

        let delivercount = 0;
        let sendcount = 0;
        let seencount = 0;
        let ackCount = 0;
        let accCount = 0;
        let rejCount = 0;
        let fromUser;
        let msgid,totalMem,msg_ack;
        $(groupmsgiq).find('query').each(function (index) {
            msgid = $(this).attr('message_id');
        });

        let msgFromUserName = $('#chat-'+msgid).attr('data-fromuser')

        totalMem = $(groupmsgiq).find('participants').length;
        $(groupmsgiq).find('participants').each(function () {
            fromUser = $(this).find('from_user').text();
            let user = $(this).find('user').text();
            let senttime = $(this).find('senttime').text();
            let seentime = $(this).find('seentime').text();
            let receivetime = $(this).find('receivetime').text();
            let acktime = $(this).find('acktime').text();
            msg_ack = $(this).find('msg_ack').text();
            let ackStatus = $(this).find('ack_status').text();
            broadcastID = $(this).find('broadcast_id').text();
            let displayName = '';
            let imageId;
            if(userInfo != null && typeof userInfo[user] != 'undefined'){
                
                displayName = userInfo[user][1];
                imageId = userInfo[user][2];
            }

            module.exports.groupmsIqMsgDesign(msg_ack);

            let userToken = storage.getuserToken();
            let image;
            sendcount = module.exports.groupmsgIqsendCount(sendcount,loginUserInfo,user)

            image = module.exports.groupmsgIqGetImg(imageId,mediaUrl,userToken)
               
                let seen = seentime.split('-')[0];
                let deliver = receivetime.split('-')[0];
                let tack = acktime.split('-')[0];
                let sentimeOnly = timeFormat.convertTimestamp(senttime, 'created_at');
                let sentdate = new Date(senttime);
                let sentime = sentdate.getDate() + '-' + monthNames[sentdate.getMonth()] + '-' + sentdate.getFullYear() + ' at ' + sentimeOnly;
                $('#serversenttime').text(sentime);

                let delAndReadObj = {
                    deliver: deliver,
                    seen: seen,
                    seentime : seentime,
                    receivetime : receivetime,
                    user : user,
                    image : image,
                    displayName : displayName,
                    seencount : seencount,
                    delivercount : delivercount,
                    broadcastID:broadcastID
                }
                   
              let delAndSeenCountObj =  module.exports.groupmsgIqReandAndDelAppend(delAndReadObj)
              delivercount = delAndSeenCountObj.delivercount;
              seencount = delAndSeenCountObj.seencount;

              let ackAndAccObj = {
                tack: tack,
                msg_ack: msg_ack,
                acktime : acktime,
                user : user,
                image : image,
                displayName : displayName,
                ackCount : ackCount,
                accCount : accCount,
                ackStatus : ackStatus,
                rejCount : rejCount
            }

            let ackAndAccRejCountObj =  module.exports.groupmsgIqGetAckAndAccRejCountObj(ackAndAccObj)
       
            ackCount = ackAndAccRejCountObj.ackCount;
            accCount = ackAndAccRejCountObj.accCount;
            rejCount = ackAndAccRejCountObj.rejCount;

        });

        if(msgFromUserName == 'Switchboard'){
            sendcount = totalMem
        }

        let tDelivered = delivercount + seencount;
        let dCount = sendcount - (delivercount + seencount);
        let rCount = sendcount - seencount;
        let aCount = sendcount - ackCount;
        let accRejCount = sendcount - (accCount + rejCount);

        let countObj = {
            sendcount   :   sendcount,
            seencount   :   seencount,
            rCount      :   rCount,
            delivercount :  delivercount,
            dCount      :   dCount,
            aCount      :   aCount,
            ackCount    :   ackCount,
            accRejCount :   accRejCount
        }

        module.exports.groupmsgIqContentUpdate(countObj)

        module.exports.groupmsgIqDelListShowHide(rCount)

        let statusAppendObj = {
            nodeJid     :   broadcastID,
            tDelivered  :   tDelivered,
            seencount   :   seencount,
            totalMem    :   totalMem,
            msg_ack     :   msg_ack,
            ackCount    :   ackCount,
            accCount    :   accCount,
            rejCount    :   rejCount,
            loggedUser  :   loggedUser,
            msgContent  :   fromUser,
            msgid       :   msgid

        }

        module.exports.groupmsgIqStatusAppend(statusAppendObj,msgFromUserName)
        
        module.exports.groupmsgIqAppendNoData(delivercount,seencount,ackCount,accCount,rejCount)

        $(".message-info-block").find(".profile-content").mCustomScrollbar('destroy');
        $(".message-info-block").find(".profile-content").mCustomScrollbar();
        return true;
        } catch (error) {
              console.log("Broadcast MessageInfo Error : ",error)  
        }
    },
    groupmsIqMsgDesign: function(msg_ack){
        if (msg_ack == 0) {
            $('.response_list').hide();
        } else if (msg_ack == 1) {
            $('.response_list').show();
            $('.acknowledge_list').show();
            $('.accept_list').hide();
            $('.reject_list').hide();
        } else {
            $('.response_list').show();
            $('.acknowledge_list').hide();
            $('.accept_list').show();
            $('.reject_list').show();
        }
    },
    groupmsgIqsendCount: function(sendcount,loginUserInfo,user){
        if (loginUserInfo[0][0] != user) {
                sendcount++;
        }

        return sendcount;
    },
    groupmsgIqGetImg: function(imageId,mediaUrl,userToken){
        let image;
        if (imageId != null && imageId !== 'src/images/avatar/chat/profile_img_one.svg' && imageId != '' && imageId.trim() !== '') {
            image = mediaUrl + imageId + "?mf=" + userToken;
            if (image.trim() == '' || image === undefined) {
                image = imageId;
            }
        } else if (imageId == '') {
            image = 'src/images/avatar/chat/profile_img_one.svg';
        }

        return image;
    },
    groupmsgIqReandAndDelAppend: function(delAndReadObj){
        let timeOnly,date,time,readdate,deleiverdate;
        let deliver =  delAndReadObj.deliver,
        seen        =  delAndReadObj.seen,
        seentime    =  delAndReadObj.seentime,
        receivetime =  delAndReadObj.receivetime,
        user        =  delAndReadObj.user,
        image       =  delAndReadObj.image,
        displayName =  delAndReadObj.displayName,
        seencount   =  delAndReadObj.seencount,
        delivercount=  delAndReadObj.delivercount,
        broadcastID =  delAndReadObj.broadcastID;
        if(displayName == ''){
            let broadcastDetails = JSON.parse(localStorage.getItem("broadcastDetails"));
            let broadcast = broadcastDetails[broadcastID];
            if(broadcast != null){
                let UserList = Object.values(broadcast).find(obj => obj.user === user);
                displayName = UserList.nickName;
                image = UserList.image;
            }
        }

        let concatedLetter = module.exports.getImageDisplayText(displayName)

        if (parseInt(deliver) && parseInt(seen)) {
            timeOnly = timeFormat.convertTimestamp(seentime, 'created_at');
            date = new Date(seentime);
            time = date.getDate() + '-' + monthNames[date.getMonth()] + '-' + date.getFullYear() + ' at ' + timeOnly;
    
            readdate = module.exports.getReadDateImageElement(user,image,displayName,time,concatedLetter);

            $('#read-append-data').append(readdate);
            seencount++;
        }
        else if (parseInt(deliver)) {
            timeOnly = timeFormat.convertTimestamp(receivetime, 'created_at');
            date = new Date(receivetime);
            time = date.getDate() + '-' + monthNames[date.getMonth()] + '-' + date.getFullYear() + ' at ' + timeOnly;
           
            deleiverdate = module.exports.getDeleiverDateImageElement(user,image,displayName,time,concatedLetter);
            
            $('#deliver-append-data').append(deleiverdate);
            delivercount++;
        }

        let delAndSeenCountObj = {
            delivercount : delivercount,
            seencount : seencount
        }

        return delAndSeenCountObj;
    },
    getReadDateImageElement: function(user,image,displayName,time,concatedLetter){
        let readdate;
        if(image == 'src/images/avatar/chat/profile_img_one.svg' || typeof(image) == 'undefined' || image == ""){
            readdate = '<li class="user-img"><div id="deliverAppendData-'+user+'" class="col-sm-3 col-xs-3 sideBar-avatar"><div class="avatar-icon"><svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg></div></div><div class="delivered_details svgImgReadDetails"><span class="delivered_name">' + displayName + '</span><span class="delivered_time">' + time + '</span></div></li>';
        }else {
            readdate = '<li class="user-img"><img id="readAppendData-'+user+'" src="' + image + '"/><div class="delivered_details"><span class="delivered_name">' + displayName + '</span><span class="delivered_time">' + time + '</span></div></li>';
        }

        return readdate;

    },
    getDeleiverDateImageElement: function(user,image,displayName,time,concatedLetter){
        let deleiverdate;

        if (image == 'src/images/avatar/chat/profile_img_one.svg' || typeof(image) == 'undefined' || image == "") {
            deleiverdate = '<li class="user-img"><div id="deliverAppendData-'+user+'" class="col-sm-3 col-xs-3 sideBar-avatar"><div class="avatar-icon"><svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg></div></div><div class="delivered_details svgImgDeliverdDetails"><span class="delivered_name">' + displayName + '</span><span class="delivered_time">' + time + '</span></div></li>';

        }else {

            deleiverdate = '<li class="user-img"><img id="deliverAppendData-'+user+'" src="' + image + '"/><div class="delivered_details"><span class="delivered_name">' + displayName + '</span><span class="delivered_time">' + time + '</span></div></li>';
        }

        return deleiverdate;

    },
    groupmsgIqGetAckAndAccRejCountObj: function(ackAndAccObj){
        let timeOnly,date,time,ackdate,accdate,rejdate;

        let tack        = ackAndAccObj.tack,
            msg_ack     = ackAndAccObj.msg_ack,
            acktime     = ackAndAccObj.acktime,
            user        = ackAndAccObj.user,
            image       = ackAndAccObj.image,
            displayName = ackAndAccObj.displayName,
            ackCount    = ackAndAccObj.ackCount,
            accCount    = ackAndAccObj.accCount,
            ackStatus   = ackAndAccObj.ackStatus,
            rejCount    = ackAndAccObj.rejCount;

        let concatedLetter = module.exports.getImageDisplayText(displayName)

        if (parseInt(tack) && (msg_ack == 1)) {
            timeOnly = timeFormat.convertTimestamp(acktime, 'created_at');
            date = new Date(acktime);
            time = date.getDate() + '-' + monthNames[date.getMonth()] + '-' + date.getFullYear() + ' at ' + timeOnly;
            if(typeof image != 'undefined' && image != 'src/images/avatar/chat/profile_img_one.svg' && image.trim() != ""){
                indexDb.blobImageConversion(image,'#acknowledgeAppendImg-'+user+'',user);

            }

            ackdate = module.exports.getackAppendData(user,image,displayName,time,concatedLetter)

           
            $('#acknowledge-append-data').append(ackdate);
            ackCount++;
        } else if (parseInt(tack) && (msg_ack == 2)) {
            timeOnly = timeFormat.convertTimestamp(acktime, 'created_at');
            date = new Date(acktime);
            time = date.getDate() + '-' + monthNames[date.getMonth()] + '-' + date.getFullYear() + ' at ' + timeOnly;
            if (ackStatus == 1) {
                
                accdate = module.exports.getaccAppendData(user,image,displayName,time,concatedLetter)
                $('#accept-append-data').append(accdate);
                accCount++;
            } else {
               
                rejdate = module.exports.getRejAppendData(user,image,displayName,time,concatedLetter)
                $('#reject-append-data').append(rejdate);
                rejCount++;
            }
        }

        let ackAndAccCountObj = {
            ackCount : ackCount,
            accCount : accCount,
            rejCount : rejCount
        }

        return ackAndAccCountObj;
    },
    getImageDisplayText: function(name){
        let concatedLetter;
        if(name != "") {
          let splitedArr = name.split(' ');
          let splitedNameArr = splitedArr.filter(function(v){return v!==''});
          let firstNameLetter,secondNameLetter;
          if(splitedNameArr.length == 1){
              firstNameLetter = splitedNameArr[0][0];
              secondNameLetter = splitedNameArr[0][1];
          }else {
              firstNameLetter = splitedNameArr[0][0];
              secondNameLetter = splitedNameArr[1][0];
      
          }
          concatedLetter = (firstNameLetter+secondNameLetter).toUpperCase()
        }
      
        return concatedLetter;
    },
    getackAppendData: function(user,image,displayName,time,concatedLetter){
        let ackdate;
        if(image == 'src/images/avatar/chat/profile_img_one.svg' || typeof(image) == 'undefined'){
            ackdate = '<li class="user-img"><div id="acknowledgeAppendData-'+user+'" class="col-sm-3 col-xs-3 sideBar-avatar"><div class="avatar-icon"><svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg></div></div><div class="delivered_details svgImgReadDetails"><span class="delivered_name">' + displayName + '</span><span class="delivered_time">' + time + '</span></div></li>';
        }else {
            ackdate = '<li class="user-img"><img id="acknowledgeAppendImg-'+user+'" src="' + image + '"/><div class="delivered_details"><span class="delivered_name">' + displayName + '</span><span class="delivered_time">' + time + '</span></div></li>';
        }

        return ackdate;
    },
    getaccAppendData: function(user,image,displayName,time,concatedLetter){
        let accdate;
        if(image == 'src/images/avatar/chat/profile_img_one.svg' || typeof(image) == 'undefined'){
            accdate = '<li class="user-img"><div id="acknowledgeAppendData-'+user+'" class="col-sm-3 col-xs-3 sideBar-avatar"><div class="avatar-icon"><svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg></div></div><div class="delivered_details svgImgReadDetails"><span class="delivered_name">' + displayName + '</span><span class="delivered_time">' + time + '</span></div></li>';
        }else {
            accdate = '<li class="user-img"><img id="acceptAppendImg-'+user+'" src="' + image + '"/><div class="delivered_details"><span class="delivered_name">' + displayName + '</span><span class="delivered_time">' + time + '</span></div></li>';
        }

        return accdate;
    },
    getRejAppendData: function(user,image,displayName,time,concatedLetter){
        let rejdate;
        if(image == 'src/images/avatar/chat/profile_img_one.svg' || typeof(image) == 'undefined'){
            rejdate = '<li class="user-img"><div id="acknowledgeAppendData-'+user+'" class="col-sm-3 col-xs-3 sideBar-avatar"><div class="avatar-icon"><svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg></div></div><div class="delivered_details svgImgReadDetails"><span class="delivered_name">' + displayName + '</span><span class="delivered_time">' + time + '</span></div></li>';
        }else {
            rejdate = '<li class="user-img"><img id="rejectAppendImg-'+user+'" src="' + image + '"/><div class="delivered_details"><span class="delivered_name">' + displayName + '</span><span class="delivered_time">' + time + '</span></div></li>'
        }

        return rejdate;
    },
    groupmsgIqContentUpdate: function(countObj){
        let sendcount   =   countObj.sendcount,
            seencount   =   countObj.seencount,
            rCount      =   countObj.rCount,
            delivercount =  countObj.delivercount,
            dCount      =   countObj.dCount,
            aCount      =   countObj.aCount,
            ackCount    =   countObj.ackCount,
            accRejCount =   countObj.accRejCount;

        let content;
        if (sendcount != 0) {
            content = language.locali().Remaining + ' ' + sendcount;
            if (seencount != 0) {
                content = language.locali().Remaining + ' ' + rCount;
            }
            $('#read-count').html(content);
            if (delivercount != 0) {
                content = language.locali().Remaining + ' ' + dCount;
            }
            $('#deliver-count').html(content);
            if ((aCount != 0) && (ackCount != 0)) {
                content = language.locali().Remaining + ' ' + aCount;
            } else if ((aCount != 0) && (ackCount == 0)) {
                content = language.locali().Remaining + ' ' + accRejCount;
            }
            $('#response-count').html(content);
        }
    },
    groupmsgIqDelListShowHide: function(rCount){
        if (rCount == 0) {
            $('.deliver_list').hide();
        } else {
            $('.deliver_list').show();
        }
    },
    groupmsgIqStatusAppend: function(statusAppendObj,msgFromUserName){
        let nodeJid       =   statusAppendObj.nodeJid,
            tDelivered  =   statusAppendObj.tDelivered,
            seencount   =   statusAppendObj.seencount,
            totalMem    =   statusAppendObj.totalMem,
            msg_ack     =   statusAppendObj.msg_ack,
            ackCount    =   statusAppendObj.ackCount,
            accCount    =   statusAppendObj.accCount,
            rejCount    =   statusAppendObj.rejCount,
            loggedUser  =   statusAppendObj.loggedUser,
            msgContent  =   statusAppendObj.msgContent,
            msgid       =   statusAppendObj.msgid;
        let receiptStatus,rtStatus,ackStatus,atStatus,totalMemberCnt;
        if (nodeJid != null && typeof nodeJid != "undefined") {
            totalMemberCnt = totalMem;
            
            if ($("[id='"+nodeJid+"']").length) {

                receiptStatus = "<span class='receiptStatus'>Del'd <span class='deliveredCount'>" + tDelivered + "</span> of <span class='totalMembers'>" + (totalMemberCnt) + "</span>, Read <span class='readCount'>" + seencount + "</span> of <span class='totalMembers'>" + (totalMemberCnt) + "</span></span>";

                rtStatus = '<span class="deliverRecent">Delivered <span class="deliverCountRecent">' + tDelivered + '</span> of <span class="totalMemRecent">' + (totalMemberCnt) + '</span>,</span><span class="readRecent">Read <span class="readCountRecent">' + seencount + '</span> of <span class="totalMemRecent">' + (totalMemberCnt) + '</span>,</span>';

                if (msg_ack == 1) {
                    ackStatus = "<span class='ackStatus'>Ack'd <span class='acknowledgeCount'>" + ackCount + "</span> of <span class='totalMembers'>" + (totalMemberCnt) + "</span></span>";

                    atStatus = '<span class="acknowledgeRecent">Acknowledged <span class="acknowledgeCountRecent">' + ackCount + '</span> of <span class="totalMemRecent">' + (totalMemberCnt) + '</span></span>';
                } else if (msg_ack == 2) {
                    ackStatus = "<span class='ackStatus'>(Acc'd <span class='acceptCount'>" + accCount + "</span>, Rej'd <span class='rejectCount'>" + rejCount + "</span>) of <span class='totalMembers'>" + (totalMemberCnt) + "</span></span>";

                    atStatus = '<span class="acceptRecent">Accepted <span class="acceptCountRecent">' + accCount + '</span> of <span class="totalMemRecent">' + (totalMemberCnt) + '</span>,</span><span class="rejectRecent">Rejected <span class="rejectCountRecent">' + rejCount + '</span> of <span class="totalMemRecent">' + (totalMemberCnt) + '</span></span>';
                } else {
                    ackStatus = "";
                    atStatus = "";
                }

                let statusImgObj = {
                    ackStatus   :   ackStatus,
                    loggedUser  :   loggedUser,
                    msgContent  :   msgContent,
                    receiptStatus : receiptStatus,
                    rtStatus    :   rtStatus,
                    atStatus    :   atStatus,
                    nodeJid     :   nodeJid,
                    msgid       :   msgid

                }
                module.exports.groupmsgIqStatusImgUpdate(statusImgObj);
                
            }
        }
    },
    groupmsgIqStatusImgUpdate: function(statusImgObj){
        let ackStatus   =   statusImgObj.ackStatus,
             receiptStatus = statusImgObj.receiptStatus,
             rtStatus    =   statusImgObj.rtStatus,
             atStatus    =   statusImgObj.atStatus,
             nodeJid     =   statusImgObj.nodeJid;
         let statusImage,tstatusImage;
             if (ackStatus == "") {
                statusImage = receiptStatus;
                tstatusImage = rtStatus;
             } else {
                statusImage = receiptStatus + ', ' + ackStatus;
                tstatusImage = rtStatus + atStatus;
             }

             $("[id='"+nodeJid+"']").find('.list-msg-status').html(statusImage);
             $("[id='"+nodeJid+"']").find('.list-msg-status').append('<i class="tooltip-status">' + tstatusImage + '</i>');
     },
    groupmsgIqAppendNoData(delivercount,seencount,ackCount,accCount,rejCount){
        let noData = '<span>...</span>';
        if (delivercount == 0) {
            $('#deliver-append-data').append(noData);
        }
        if (seencount == 0) {
            $('#read-append-data').append(noData);
        }
        if (ackCount == 0) {
            $('#acknowledge-append-data').append(noData);
        }
        if (accCount == 0) {
            $('#accept-append-data').append(noData);
        }
        if (rejCount == 0) {
            $('#reject-append-data').append(noData);
        }
    }
};
