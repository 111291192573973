let stropheConnection = require('./stropheConnection.js');
let blockUserUtils = require('./utils.js');
let blockUserLanguage = require('./locale/language.js');
let blockUserVCard = require('./vCard.js');
let blockUserLastActivity = require('./lastActivity.js');
let blockUserStorage = require("./storage.js");
const contact = require('./contact.js');
const utils = require('./utils.js');

module.exports = {
    blockuser: function () {
        let toJID = blockUserUtils.toJID();
        let blockUser = stropheConnection.StropheJs().$iq({
            type: 'set',
            id: blockUserUtils.getId(),
            to: stropheConnection.Server(),
        }).c('block', {
            xmlns: 'urn:xmpp:blocking'
        }).c('item', {
            jid: toJID
        }).up();
        if (stropheConnection.Connection().connected) {
            stropheConnection.Connection().sendIQ(blockUser);
        }
    },
    getblockuserCallBack: function (blocklist) {
        try {
            module.exports.blocklist();
        } catch (err) {
            console.log("blocklist", err);
            console.log("blocklist iq", blocklist);
        }
        return true;
    },
    // unblock user Send IQ
    unblockuser: function () {
        let unblockUserInfo = JSON.parse(localStorage.getItem("unblockUserInfo"));
        let toJID = blockUserUtils.toJID();
        let unblocklist;
        if (unblockUserInfo != null && typeof unblockUserInfo[0] != "undefined") {
            unblocklist = unblockUserInfo[0] + "@" + stropheConnection.Server();
        } else {
            unblocklist = toJID;
        }
        let unblockUser = stropheConnection.StropheJs().$iq({
            type: 'set',
            id: blockUserUtils.getId(),
            to: stropheConnection.Server()
        }).c('unblock', {
            xmlns: 'urn:xmpp:blocking'
        }).c('item', {
            jid: unblocklist
        }).up();
        if (stropheConnection.Connection().connected) {
            stropheConnection.Connection().sendIQ(unblockUser);
            if (toJID == unblocklist) {
                $(".blocked-person").css("display", "none");
                blockUserLastActivity.lastActivity(toJID);

                $("#replyemoji").show();
                $("#microphone").show();
            }
        }
    },
    blocklist: function () {
        let blockList = stropheConnection.StropheJs().$iq({
            type: 'get',
            id: blockUserUtils.getId(),
            to: stropheConnection.Server()
        }).c('blocklist', {
            xmlns: 'urn:xmpp:blocking'
        });
        if (stropheConnection.Connection().connected) {
            stropheConnection.Connection().sendIQ(blockList, module.exports.getblocklistCallBack);
        }
    },
    getBlockListUserObj: function(userInfo,nodeJid,userName){
        let displayname,imageId,status;
        if (userInfo != null && typeof userInfo[nodeJid] != "undefined") {
            displayname = userName;
            imageId = userInfo[nodeJid][2];
            status = "";
        } else {
            displayname = userName;
            imageId = 'src/images/avatar/chat/profile_img_one.svg';
            status = blockUserLanguage.locali().default_status;
        }
        let dataObj = {
            displayname :   displayname,
            imageId     :   imageId,
            status      :   status
        }
        return dataObj;
    },
    getBlockUserImage: function(imageId,userToken){
        let image;
        if (imageId !== 'src/images/avatar/chat/profile_img_one.svg' && imageId.trim() !== '') {
            let mediaUrl = window._env_.ApiURL + "/media/";
            image = mediaUrl + imageId + "?mf=" + userToken;
            if (image.trim() == '' || image === undefined) {
                image = imageId;
            }
        } else if (imageId == '') {
            image = 'src/images/avatar/chat/profile_img_one.svg';
        } else {
            image = imageId;
        }
        return image;
    },
    getBlockUserImgElment: function(image,imageExtArray,profileExtention,nodeJid,concatedLetter){
        let imageElment;
        if(image !== 'src/images/avatar/chat/profile_img_one.svg' && image.trim() !== ''){
            if(imageExtArray.includes(profileExtention)){
                blockUserUtils.imageTokenRegenerate(image,'#blockUserImg-'+nodeJid+'',nodeJid,'profileImages');
                imageElment ='<img id="blockUserImg-'+nodeJid+'" src="' + image + '" onerror="' + contact.profileImgError() + '" />';
            }else {
                imageElment = '<svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg>';

            }
        }  else {
            imageElment = '<svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg>';
        }

        return imageElment;
    },
    blockUserParticipantDesign: function(nodeJid,tojid,displayname){
        if ($("#participantcontact").find("[id='forwardcontact-"+nodeJid+"']").length) {
            $("#participantcontact").find("[id='forwardcontact-"+nodeJid+"']").hide();
        }
        if (tojid != "") {
            if (nodeJid == stropheConnection.Strophe().getNodeFromJid(tojid)) {
                utils.blockUserDesignAdd(displayname)
            }
        }
    },
    blockUserSecondDesign: function(nodeJid,tojid){
        if (tojid != "") {
            if (nodeJid == stropheConnection.Strophe().getNodeFromJid(tojid)) {
                blockUserLastActivity.lastActivity(tojid);
                $('#comment').attr("data-text", "Start typing...");
                $(".blocked-person").css("display", "none");
                $(".row.reply").css("display", "flex");
                $("#replyemoji").show();
                $("#microphone").show();
                $(".row.reply").css("display", "flex");
                $('.heading-attach').show();
            }
        }
    },
    blockListUserHtmlAppend(blocklistcount){
        if (blocklistcount == 0) {
            $('.blocklist').html('<div class="no-blocked-cnts">' + blockUserLanguage.locali().no_blocked_contacts_yet + '</div>');
        } else {
            $(".blocklist").mCustomScrollbar();
        }
    },
    getblocklistCallBack: function (blocklistiq) {
        try {
            $(".unblock-con").hide();
            let userToken = blockUserStorage.getuserToken();
            let tojid = blockUserUtils.toJID();
            let blocklistcount = 0;
            let nodeJid,displayname,imageId,image,blocklistcontent;
            delete localStorage.blockUserInfo;
            let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
                let blockUserInfo = {};
                $('.blocklist').html('');
                let blocklistHtml = '';
                $(blocklistiq).find('item').each(function () {
                    blocklistcount = 1;
                    let jid = $(this).attr('jid');
                    nodeJid = stropheConnection.Strophe().getNodeFromJid(jid);
                    blockUserInfo[nodeJid] = [];
                    blockUserInfo[nodeJid].push(nodeJid);
                    let vCard = $(this).find('vCard');
                    let userName = vCard.find('nickName').html();
                    let status;
                    let dataObj = module.exports.getBlockListUserObj(userInfo,nodeJid,userName);
                    displayname =  dataObj.displayname;
                    imageId     =  dataObj.imageId;
                    status      =  dataObj.status;

                    let imageExtArray = ['jpg','jpeg','png','webp'];
                    let profileExtention = imageId.split('.').pop();
                    image = module.exports.getBlockUserImage(imageId,userToken);
                    let concatedLetter,
                        imageElment;

                    concatedLetter = blockUserUtils.getImageDisplayText(displayname);

                    imageElment = module.exports.getBlockUserImgElment(image,imageExtArray,profileExtention,nodeJid,concatedLetter);

                    blocklistcontent = '<div class="row sideBar-body"><div class="col-sm-3 col-xs-3 sideBar-avatar">';
                    blocklistcontent = blocklistcontent + '<div class="avatar-icon">'+imageElment+'</div></div><div class="col-sm-9 col-xs-9 sideBar-main"> <div class="row">';
                    blocklistcontent = blocklistcontent + '<div class="col-sm-12 col-xs-12 sideBar-name blocked-name"><span class="name-meta">' + displayname + '</span><div class="sideBar-msg"> <span class="name-status">' + status + '</span>';
                    blocklistcontent = blocklistcontent + '<span class="unblock-cross" id="' + "block-" + nodeJid + '"><i><svg xmlns="http://www.w3.org/2000/svg" id="cross" width="20" height="20" viewBox="0 0 512 512"> <path id="Path_107217" data-name="Path 107217" d="M256,0C114.62,0,0,114.62,0,256S114.62,512,256,512,512,397.38,512,256,397.38,0,256,0ZM389.47,332.72a22.29,22.29,0,0,1,0,31.53l-25.22,25.22a22.29,22.29,0,0,1-31.53,0L256,312.75l-76.72,76.72a22.29,22.29,0,0,1-31.53,0l-25.22-25.22a22.29,22.29,0,0,1,0-31.53L199.25,256l-76.72-76.72a22.29,22.29,0,0,1,0-31.53l25.22-25.22a22.29,22.29,0,0,1,31.53,0L256,199.25l76.72-76.72a22.29,22.29,0,0,1,31.53,0l25.22,25.22a22.29,22.29,0,0,1,0,31.53L312.75,256Z" fill="#ccc"/> </svg></i></span></div></div></div></div></div>';
                    blocklistHtml = blocklistHtml + blocklistcontent;

                    module.exports.blockUserParticipantDesign(nodeJid,tojid,displayname);
                    
                });
                $('.blocklist').html(blocklistHtml);
                localStorage.setItem("blockUserInfo", JSON.stringify(blockUserInfo));
                $(".participantcontent").each(function (index) {
                    let id = $(this).attr('id');
                    let result = id.split(/-(.+)/);
                    nodeJid = result[1];

                    if (typeof blockUserInfo[nodeJid] != "undefined") {
                        $(this).find('.name-status').html('Contact is blocked');
                    } else {
                        $(this).find('.name-status').html("");
                        module.exports.blockUserSecondDesign(nodeJid,tojid);
                    }
                });
                module.exports.blockListUserHtmlAppend(blocklistcount);

            $("body").on("click", ".unblock-cross", function () {
                let id = $(this).attr('id');
                let result = id.split(/-(.+)/);
                let unblockUserInfo = {};
                nodeJid = result[1];
                unblockUserInfo[0] = [];
                unblockUserInfo[0].push(nodeJid);
                localStorage.setItem("unblockUserInfo", JSON.stringify(unblockUserInfo));
                let displayName = $(this).closest('.blocked-name').find('.name-meta').text()
                $('.unBlockUserNodeJid').val(nodeJid);
                let content = "Unblock " + displayName + "?";
                $("#unblock-contact").find('.popup-detail').html(content);
                $("#unblock-contact").addClass("open");
            });
        } catch (err) {
            console.log(err);
            console.log("blocklist iq", blocklistiq)
        }
        return true;
    },
    blockuserlist: function () {
        let blockUserList = stropheConnection.StropheJs().$iq({
            type: 'get',
            id: blockUserUtils.randomString(16, 'a#'),
            to: stropheConnection.Server()
        }).c('query', {
            xmlns: 'jabber:iq:user_activities',
            status: "block_user"
        });
        if (stropheConnection.Connection().connected) {
            stropheConnection.Connection().sendIQ(blockUserList, module.exports.getblockuserlistCallBack);
        }
    },
    getblockuserlistCallBack: function (blocklistiq) {
        try {
            let blockListUserInfo = {};
            $(blocklistiq).find('members').each(function () {
                let nodeJid = $(this).text();
                blockListUserInfo[nodeJid] = [];
                blockListUserInfo[nodeJid].push(nodeJid);
            });
            localStorage.setItem("blockListUserInfo", JSON.stringify(blockListUserInfo));         
        } catch (err) {
            console.log("block user list callback", err);
            console.log("block user list callback iq", blocklistiq);
        }
        return true;
    },
    setBlockUserInfo: function(blockListUserInfo,blockuser,userInfo){
        if (blockListUserInfo == null) {
            blockListUserInfo = {};
        }
        if (blockListUserInfo != null && typeof blockListUserInfo[blockuser] == "undefined") {
            blockListUserInfo[blockuser] = [];
            blockListUserInfo[blockuser].push(blockuser);
            localStorage.setItem("blockListUserInfo", JSON.stringify(blockListUserInfo));
            if (userInfo != null && typeof userInfo[blockuser] != "undefined") {
                userInfo[blockuser][2] = "src/images/avatar/chat/profile_img_one.svg";
                userInfo[blockuser][3] = "";
                localStorage.setItem("usersProfileInfo", JSON.stringify(userInfo));
            }
        }
    },
    setBlockRosterDesign: function(blockuser){
        if ($("[id='"+blockuser+"']").length) {
            $("[id='"+blockuser+"']").find('.other-prof-pic').attr('src', "src/images/avatar/chat/profile_img_one.svg");
        }
        if ($("#participantcontact").find("[id='forwardcontact-"+blockuser+"']").length) {
            $("#participantcontact").find("[id='forwardcontact-"+blockuser+"']").hide();
        }
        if ($("[id='roster-"+blockuser+"']").length) {
            $("[id='roster-"+blockuser+"']").find('.avatar-icon').find('img').attr('src', "src/images/avatar/chat/profile_img_one.svg");
            $("[id='roster-"+blockuser+"']").find('.name-status').html('');
        }
    },
    setBlockRosterSecondDesign: function(blockuser,tojid,userInfo,id){
        if (tojid != "") {
            if (blockuser == stropheConnection.Strophe().getNodeFromJid(tojid)) {
                $('.conversation').find('#right-side-prof').attr('src', "src/images/avatar/chat/profile_img_one.svg");
                $(".heading-online").html("");
                $('#userprofile').find('.profile-inner').html('<img src="src/images/avatar/chat/profile_img_one.svg" /><span class ="groupname">' + minEmoji(userInfo[blockuser][1]) + '</span>');
                $('#userprofile').find('.user-status').html('');
                if (userInfo[id][4] == "emailcontact") {
                    $('#userprofile').find('.user-contact').html('<small>' + blockUserLanguage.locali().about_and_email + '</small> <span class="card-sep card-sep-space user-status"> </span><span class="card-sep-space user-contact">' + userInfo[id][5] + '</span>');
                } else {
                    $('#userprofile').find('.user-contact').html('<small>' + blockUserLanguage.locali().about_and_phoneno + '</small> <span class="card-sep card-sep-space user-status"> </span><span class="card-sep-space user-contact">' + blockuser + '</span>');
                }
            }
        }
    },
    setBlockFwdContentDesign: function(blockuser,tojid){
        if ($("#participantcontact").find("[id='forwardcontact-"+blockuser+"']").length) {
            $("#participantcontact").find("[id='forwardcontact-"+blockuser+"']").show();
        }
        if (tojid != "") {
            if (blockuser == stropheConnection.Strophe().getNodeFromJid(tojid)) {
                blockUserLastActivity.lastActivity(tojid);
            }
        }
    },
    setblockstatus: function (blockstatus, blockuser) {
        let tojid = blockUserUtils.toJID();
        let blockListUserInfo = JSON.parse(localStorage.getItem("blockListUserInfo"));
        let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
        if (blockstatus == "block_user") {
            module.exports.setBlockUserInfo(blockListUserInfo,blockuser,userInfo);
            module.exports.setBlockRosterDesign(blockuser);
            module.exports.setBlockRosterSecondDesign(blockuser,tojid,userInfo,id);

        } else if (blockstatus == "unblock_user") {
            blockUserVCard.getVcard(blockuser + "@" + stropheConnection.Server());
            let newblockListUserInfo = {};
            for (let keys in blockListUserInfo) {
                if (blockListUserInfo[keys] != blockuser) {
                    newblockListUserInfo[keys] = [];
                    newblockListUserInfo[keys].push(keys);
                }
            }
            module.exports.setBlockFwdContentDesign(blockuser,tojid);
            
            delete localStorage.blockListUserInfo;
            localStorage.setItem("blockListUserInfo", JSON.stringify(newblockListUserInfo));
        }
    }
};
