let language = require('./language.js');

module.exports = {
    textChanger: function (lang) {
        let newGroup = language.locali().new_group;
        $('.side-grp').text(newGroup);
        $('.side-grp').attr("title", newGroup);
        $('.add-group-title').text(newGroup);

        let newBroadcastChat = language.locali().new_broadcast_chat;
        $('#add-broadcast-title').text(newBroadcastChat);

        let starredMsg = language.locali().starred_msg;
        $('.star-msg').text(starredMsg);
        $('.star-msg').attr("title", starredMsg);
        

        let groupName = language.locali().group_name;
        $('#new-gp-name').attr("placeholder", groupName);



        let profile = language.locali().profile;
        $('#profile-click').text(profile);
        $('#transprofile').text(profile);
        $('#profile-click').attr("title", profile);

        let bot = language.locali().bot;
        $('#bot-click').text(bot);
        $('#bot-menu').text(bot);
        $('#bot-click').attr("title", bot);

        let settings = language.locali().settings;
        $('.side-set').text(settings);
        $('#settings-menu').text(settings);
        $('.side-set').attr("title", settings);

        let logout = language.locali().logout;
        $('#logout').text(logout);
        $('.logout').text(logout);
        $('#logout').attr("title", logout);

        let attach = language.locali().attach;

        let menu = language.locali().menu;
        $('.heading-dot').attr("title", menu);
        $('.attachment').attr("title", attach);

        let sosnewchat = language.locali().search_or_start_newChat;
        $('#searchText').attr("placeholder", sosnewchat);



        let addGroupParticipants = language.locali().add_group_participants;
        $('#addGroupMember').text(addGroupParticipants);

        let addBroadcastTitle = language.locali().add_broadcast_title;
        $('#addBroadcastTitle').text(addBroadcastTitle);
        

        let searchparticipant = language.locali().type_contact_name;
        $('#searchparticipant').attr("placeholder", searchparticipant);

        let yourname = language.locali().your_name;
        $('#yourname').text(yourname);

        let youremail = language.locali().your_email;
        $('#youremail').text(youremail);

        let yourmobile = language.locali().your_mobile;
        $('#yourmobile').text(yourmobile);

        let name_advise = language.locali().name_advise;
        $('.name-advise').children('p').text(name_advise);

        let status = language.locali().status;
        $('#status').text(status);

        let view_photo = language.locali().view_photo;
        $('#view-pro-pic').text(view_photo);

        let take_photo = language.locali().take_photo;
        $('#start_camera').text(take_photo);
        $('#group-pro-camera').text(take_photo);
        $('#first_group_camera').text(take_photo);

        let upload_photo = language.locali().upload_photo;
        $('#upload_photo').text(upload_photo);
        $('.upload_photo').text(upload_photo);

        let remove_photo = language.locali().remove_photo;
        $('#rm-pro-pic').text(remove_photo);

        let languageText = language.locali().language;
        $('#language-text').text(languageText);

        let blocked = language.locali().blocked_contact;
        $('.blocked-list').children('b').text(blocked);

        let help = language.locali().help;
        $('#help-text').text(help);

        let blockedContact = language.locali().blocked_contact;
        $('#blocked-contacts').text(blockedContact);

        let LocationList = language.locali().location_id_list;
        $('#locationIdList').text(LocationList);

        let logout_confirmation = language.locali().logout_confirmation;
        $('#logout_confirmation').text(logout_confirmation);

        let cancel = language.locali().cancel;
        $('.cancel').text(cancel);

        let newChat = language.locali().new_chat;
        $('#new-chat-text').text(newChat);
        $('.compose-msg').attr('title', newChat);

        let searchPeople = language.locali().search_people;
        $('#composeText').attr('placeholder', searchPeople);
        $('#forwardText').attr('placeholder', searchPeople);

        let selectMessages = language.locali().select_messages;
        $('.select-messages').text(selectMessages);

        let clearMessages = language.locali().clear_messages;
        $('.clear-msg-link').text(clearMessages);
        $('.clear_messages_text').text(clearMessages);

        let deleteChat = language.locali().delete_chat;
        $('.delete_chat').text(deleteChat);

        let photoAndVideo = language.locali().photos_and_videos;
        $('#photo-video-text').text(photoAndVideo);

        let camera = language.locali().camera;
        $('#camera_text').text(camera);

        let document = language.locali().document;
        $('#document_text').text(document);

        let mediaLinkDoc = language.locali().media_link_docs;
        $('.media-doc-text').children('span').text(mediaLinkDoc);

        let addParticipants = language.locali().add_participants;
        $('.add_participant_text').text(addParticipants);

        let partticipantText = language.locali().search_people;
        $('#partticipantText').attr("placeholder", partticipantText);

        let sitesText = language.locali().search_sites;
        $('#sitesText').attr("placeholder", sitesText);

        let frdMsgTxt = language.locali().forward_msg_to;
        $('.frd_msg_text').text(frdMsgTxt);

        let recentlyContacted = language.locali().recently_contacted;
        $('.recently_contacted').text(recentlyContacted);

        let contacts = language.locali().contacts;
        $('.contacts_text').text(contacts);

        let phonecontacts = language.locali().phonecontacts;
        $('.contacts_text').text(phonecontacts);

        let emailcontacts = language.locali().emailcontacts;
        $('.emailcontacts_text').text(emailcontacts);

        let contactInfo = language.locali().contact_info;
        $('.contact_info_text').text(contactInfo);

        let main_text_placeholder = language.locali().main_text_placeholder;
        $('.main_text_placeholder').attr("data-text", main_text_placeholder);

        let no_media_text = language.locali().no_media_text;
        $('.no_media_text').text(no_media_text);

        let read_by = language.locali().read_by;
        $('.read_by').text(read_by);

        let delivered_to = language.locali().delivered_to;
        $('.delivered_to').text(delivered_to);

        let exitGroup = language.locali().exit_group;
        $('.exit_group_text').text(exitGroup);

        let message_info = language.locali().message_info;
        $('.message_info_text').text(message_info);

        let blockContact = language.locali().block_contact;
        $('.black_contact').text(blockContact);

        let unBlockContact = language.locali().unblock_contact;
        $('.unblock_contact').text(unBlockContact);

        let unblock = language.locali().unblock;
        $('.unblock').text(unblock);

        let block = language.locali().block;
        $('.block').text(block);

        let deletes = language.locali().delete;
        $('.deletes').text(deletes);
        $('.delete_text').text(deletes);

        let clear = language.locali().clear;
        $('.clear_text').text(clear);

        let exit = language.locali().exit;
        $('.exit_text').text(exit);

        let make = language.locali().make;
        $('.make_text').text(make);

        let remove = language.locali().remove;
        $('.remove_text').text(remove);

        let make_sure_internet_connection = language.locali().make_sure_internet_connection;
        $('.make_sure_internet_connection').text(make_sure_internet_connection);

        let close = language.locali().close;
        $('.close_text').text(close);

        let misMatchCredentials = language.locali().mismatch_credentials;
        $('.mismatch_credentials').text(misMatchCredentials);
    }

}