module.exports = {
    currenttime: function () {
        let date = new Date();
        let hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
        let am_pm = date.getHours() >= 12 ? "PM" : "AM";
        let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        let time = hours + ":" + minutes + " " + am_pm;
        return time;
    },
    currenttimes: function (currenttim) {
        let date = new Date(currenttim);
        let hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
        let am_pm = date.getHours() >= 12 ? "PM" : "AM";
        hours = hours < 10 ? "0" + hours : hours;
        let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        let seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        let time = hours + ":" + minutes + ":" + seconds + " " + am_pm;
        return time;
    },
    getyesterday: function (currentdate){
        let c = new Date();
        let y = new Date(c);
        y.setDate(c.getDate() - 1);
        let yesterday =('0' + y.getDate()).slice(-2) + '-' + ('0' + (y.getMonth() + 1)).slice(-2) + '-' +   y.getFullYear() ;
        if(currentdate == yesterday){
            return "Yesterday";
        }else{
            return  currentdate;
        }

    },
    getTimeMiliSec: function(type,timestamp){
        let timemilliseconds;
        if (type == 'history' || type == 'list') {
            let milliseconds = timestamp / 1000000;
            timemilliseconds = milliseconds * 1000;
        } else if (type == 'last') {

            timemilliseconds = timestamp * 1000;
            timemilliseconds = new Date($.now()) - timemilliseconds;

        }
        else {
            let dateString = timestamp,
                dateTimeParts = dateString.split(' '),
                timeParts = dateTimeParts[1].split(':'),
                dateParts = dateTimeParts[0].split('-'),

                date = new Date(dateParts[0], parseInt(dateParts[1], 10) - 1, dateParts[2], timeParts[0], timeParts[1]);
            timemilliseconds = date.getTime(); //1379426880000
        }

        return timemilliseconds;
    },
    getTimeObj: function(h,hh,ampm){
        
        if (hh > 12) {
            h = hh - 12;
            ampm = 'PM';
        } else if (hh === 12) {
            h = 12;
            ampm = 'PM';
        } else if (hh == 0) {
            h = 12;
        }

        let timeObj = {
            h   : h,
            ampm : ampm
        }

        return timeObj;
    },
    getTimeFormatCurrDate: function(lastdate,currentDate,yesterday){
        let currdate;
        if (lastdate === currentDate) {
            currdate = 'today at';
        } else if (lastdate === yesterday) {
            currdate = 'yesterday at';
        } else {
            currdate = lastdate;
        }
        return currdate;
    },
    getTimeFormatLastTime: function(currdate,dayTimeObj,yearObj){
        let h = dayTimeObj.h,
            min = dayTimeObj.min,
            ampm = dayTimeObj.ampm,
            dd  = yearObj.dd,
            mm = yearObj.mm,
            yyyy = yearObj.yyyy;
        let time;
        if (currdate === 'yesterday at') {
            time = 'Yesterday' + ', ' + h + ':' + min + ampm.toLowerCase();
        } else if (currdate === 'today at') {
            time = h + ':' + min + ampm.toLowerCase();
        } else {
            time = dd + '/' + mm + '/' + yyyy + ', ' + h + ':' + min + ampm.toLowerCase();
        }
        return time;
    },
    getTimeFormatRecentAtTime: function(type,currdate,dayTimeObj,yearObj){
        let h = dayTimeObj.h,
            min = dayTimeObj.min,
            ampm = dayTimeObj.ampm,
            dd  = yearObj.dd,
            mm = yearObj.mm,
            yyyy = yearObj.yyyy;

        let time;

        if (type == 'recent_at') {
            if (currdate === 'yesterday at') {
                time = 'Yesterday';
            } else if (currdate === 'today at') {
                time = h + ':' + min + ' ' + ampm;
            } else {
                time = dd + '-' + module.exports.GetMonthName(parseInt(mm)) + '-' + yyyy;
            }
        } else {
            time = dd + '-' + module.exports.GetMonthName(parseInt(mm)) + '-' + yyyy;
        }

        return time;
    },
    //Function to convert the given timestamp
    convertTimestamp: function (timestamp, type) {
        let c = new Date();
        let timemilliseconds;
        timemilliseconds = module.exports.getTimeMiliSec(type,timestamp);
        
        let d = new Date(timemilliseconds); // Convert the passed timestamp to milliseconds
        if (type == 'created_at' || type == 'recent_at') {
            d = new Date(this.convertUTCDateToLocalDate(d));
        }
        let yyyy = d.getFullYear(),
            mm = ('0' + (d.getMonth() + 1)).slice(-2), // Months are zero based. Add leading 0.
            dd = ('0' + d.getDate()).slice(-2), // Add leading 0.
            hh = d.getHours(),
            h = hh,
            min = ('0' + d.getMinutes()).slice(-2), // Add leading 0.
            ampm = 'AM',
            time;

            let timeObj = module.exports.getTimeObj(h,hh,ampm);
                let hr     = timeObj.h;
                ampm  = timeObj.ampm;

        let lastdate = yyyy + '-' + mm + '-' + dd;
        let currentDate = c.getFullYear() + '-' + ('0' + (c.getMonth() + 1)).slice(-2) + '-' + ('0' + c.getDate()).slice(-2);
        let y = new Date(c);
        y.setDate(c.getDate() - 1);
        let yesterday = y.getFullYear() + '-' + ('0' + (y.getMonth() + 1)).slice(-2) + '-' + ('0' + y.getDate()).slice(-2);
        let currdate;
        currdate = module.exports.getTimeFormatCurrDate(lastdate,currentDate,yesterday);
        let dayTimeObj = {
            h:hr,
            min:min,
            ampm:ampm
        };
        let yearObj = {
            dd:dd,
            mm:mm,
            yyyy:yyyy
        };
        // ie: 2013-02-18, 8:35 AM
        if (type === 'history' || type === 'created_at') {
            time = hr + ':' + min + ' ' + ampm;
        }
        else if (type === 'last') {
             
            time = module.exports.getTimeFormatLastTime(currdate,dayTimeObj,yearObj);

            
        } else {
            time = module.exports.getTimeFormatRecentAtTime(type,currdate,dayTimeObj,yearObj);

            
        }
        return time;
    },
    //Function to get the time format
    formatAMPM: function (date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours || 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    },
    convertUTCDateToLocalDate: function (date) {
        let newDate = new Date(date);
        newDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return newDate;
    },
    timeSince: function (seconds) {
        let interval = Math.floor(seconds / 31536000);
        if (interval >= 1) {
            if (interval == 1) {
                return interval + " year";
            }
            return interval + " years";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
            if (interval == 1) {
                return interval + " month";
            }
            return interval + " months";
        }
        interval = Math.floor(seconds / 86400);
        if (interval >= 1) {
            if (interval == 1) {
                return interval + " day";
            }
            return interval + " days";
        }
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
            if (interval == 1) {
                return interval + " hour";
            }
            return interval + " hours";
        }
        interval = Math.floor(seconds / 60);
        if (interval >= 1) {
            if (interval == 1) {
                return interval + " minute";
            }
            return interval + " minutes";
        }
        return Math.floor(seconds) + " seconds";
    },
    GetMonthName: function (monthNumber) {
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return months[monthNumber - 1];
    }
};