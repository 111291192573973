let stropheConnection = require("./stropheConnection.js");
let utils = require("./utils.js");

module.exports = {
    //get the current chatting user JID
    toJID: function () {
        if (localStorage.getItem("toJID")) {
            return JSON.parse(localStorage.getItem("toJID"));
        } else {
            return "";
        }
    },
    //IQ sent to get the priority messages data from server
    getPriorityMessages: function (userJID) {
        let prioritymessagesiq = stropheConnection.StropheJs().$iq({
            type: "get",
            to: stropheConnection.Server(),
            id: utils.randomString(10, "a#")
        }).c("query", {
            xmlns: "jabber:iq:user_activities",
            status: "get_priority_messages",
            to_user: stropheConnection.Strophe().getNodeFromJid(userJID)
        });
        if (stropheConnection.Connection().connected) {
            stropheConnection.Connection().sendIQ(prioritymessagesiq, module.exports.PriorityMessagesHandler);
        }
    },
    //handle the response data from the server
    PriorityMessagesHandler: function (prioritymessagesiq) {
        delete localStorage.priorityMessages;
        let priorityMessages = [];

        $(prioritymessagesiq).find('chat').each(function () {
            priorityMessages.push($(this).find('msgid').text());
        });

        let toJID = stropheConnection.Strophe().getNodeFromJid(JSON.parse(localStorage.getItem("toJID")));
        let usersPriorityInfo = JSON.parse(localStorage.getItem("usersPriorityInfo"));
        if(usersPriorityInfo != null && typeof(usersPriorityInfo[toJID]) != "undefined") {
            usersPriorityInfo[toJID][0] = 0;
            localStorage.setItem("usersPriorityInfo", JSON.stringify(usersPriorityInfo));
        }
        
        if(priorityMessages == null || priorityMessages.length == 0) {
            $("[id='"+toJID+"']").find('.priority-message-recent').remove();
            $("[id='conversation"+toJID+"']").find(".priority-message-conversation").remove();
        }
        
        localStorage.setItem("priorityMessages", JSON.stringify(priorityMessages));
    }
};
