let CryptLib = require("cryptlib");
module.exports = {

  checklogin: function (username, password) {

    if (username != "" && password != "") {
      let loginUserInfo = [];
      let info = [username, password];
      let key = CryptLib.getHashSha256(username, 32);
      let cypherText = CryptLib.encrypt(JSON.stringify(info), key, username);
      sessionStorage.Info = cypherText;
      loginUserInfo[0] = [];
      loginUserInfo[0].push(username);
      let checkRemember = localStorage.getItem("remember-me");
      if (checkRemember == "true") {
        let UserInfo = {};
        UserInfo[0] = [];
        UserInfo[0].push(cypherText);
        localStorage.setItem("UserInfo", JSON.stringify(UserInfo));
        localStorage.setItem("loginUserInfo", JSON.stringify(loginUserInfo));
      } else {
        let currentItem = localStorage.getItem("localization");
        let ChatWebId = localStorage.getItem('ChatWebId');

        if (currentItem != null) {
          localStorage.setItem("localization", currentItem);
        }
        localStorage.setItem("loginUserInfo", JSON.stringify(loginUserInfo));
        localStorage.setItem("ChatWebId", ChatWebId);
        delete localStorage.UserInfo;
      }
      $("#login").trigger("click");

    }
  },
  getChecked: function () {
    localStorage.setItem("rememberme", "false");
    return localStorage.getItem("rememberme");
  }
};
