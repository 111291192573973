let settingStorage = require('./storage.js');
const utils = require('./utils.js');
let userToken;
module.exports = {
    getSettings: function () {
        let settingStorage = require('./storage.js');
        let settingUtils = require('./utils.js');

        let settingUrl = window._env_.ApiURL + "/users/config";
        $.ajax({
            url: settingUrl ,
            type: 'GET',
            dataType: 'json',
            cache: false,
            contentType: false,
            processData: false,
            async: false,
            crossDomain: true,
            headers: {
                'authorization': settingStorage.getuserToken()
            },
            statusCode: {
                401: function (response) {
                    console.log('Security Token Error');
                },
                500: function (response) {
                    console.log('Internal Server Error');
                }
            },
            success: function (response) {
                userToken = settingStorage.getuserToken();
                let loggedUser = JSON.parse(localStorage.getItem("loginUserInfo"))[0][0];
                let encryptKey = loggedUser.repeat(3);
                let decryptedResponse = JSON.parse(settingUtils.decrypt(response.data, encryptKey));
                localStorage.setItem("settings", JSON.stringify(decryptedResponse))
            },
            error: function (jqXHR, exception) {

                utils.errorMsgHandling(jqXHR, exception)

            }
        });
        return true;
    },
    updateGroups: function () {
        let groupsUrl = window._env_.ApiURL + "/users/addInPendingGroups";
        $.ajax({
            url: groupsUrl ,
            type: 'GET',
            dataType: 'json',
            cache: false,
            contentType: false,
            processData: false,
            async: false,
            crossDomain: true,
            headers: {
                'authorization': settingStorage.getuserToken()
            },
            statusCode: {
                401: function (response) {
                    console.log('Security Token Error');
                },
                500: function (response) {
                    console.log('Internal Server Error');
                }
            },
            success: function (response) {
                delete localStorage.isPendingGroupParticipant;
            },
            error: function (jqXHR, exception) {

                utils.errorMsgHandling(jqXHR, exception)

            }
        });
        return true;
    },
}