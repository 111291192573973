let stropheConnection = require("./stropheConnection.js");
let utils = require("./utils.js");
let favDesignStorage = require("./storage.js");
let favDesignconversationst = require("./conversation.js");
let favDesigngroups = require("./groups.js");
let favDesignlastActivity = require("./lastActivity.js");
let favDesignlanguage = require("./locale/language.js");
let favDesigntimeFormat = require("./timeformat.js");
const favDesignindexDb = require("./indexDb.js");
let receivername = "";
let msgcontent;
module.exports = {

  forwardmessageUserObjFirstSplit: function(userInfo,userid,mediaUrl,userToken){
    let displayname = "";
    let messageFrom ="";
    let image,nodeJid;

    if (userInfo != null && typeof userInfo[userid] != "undefined") {
      displayname = userInfo[userid][1];
      image = userInfo[userid][2];
      if(utils.getHexRegexPattern().test(image)) {
        image = userInfo[userid][2];
      } else if (image !== 'src/images/avatar/chat/profile_img_one.svg' && image.trim() !== '') {
        image = mediaUrl + image + "?mf=" + userToken;
      } else if (image.trim() == '') {
        image = "src/images/avatar/chat/profile_img_one.svg";
      }
      receivername = displayname;
    } 
    nodeJid = userid;
    displayname = "You";
    messageFrom = "receiver";

    let userFirstObj = {
      image : image,
      receivername  : receivername,
      nodeJid : nodeJid,
      displayname : displayname,
      messageFrom : messageFrom
    }

    return userFirstObj;
  },
  forwardmessageUserObjSecondSplit: function(userInfo,sendfrom,mediaUrl,userToken){
    let userDisplayName = "";
    let messageFrom ="";
    let image,nodeJid;
    if (userInfo != null && typeof userInfo[sendfrom] != "undefined") {
      userDisplayName = userInfo[sendfrom][1];
      image = userInfo[sendfrom][2];

      if(utils.getHexRegexPattern().test(image)) {
        image = userInfo[sendfrom][2];
      } else if (image !== 'src/images/avatar/chat/profile_img_one.svg' && image.trim() !== '') {
        image = mediaUrl + image + "?mf=" + userToken;
      } else if (image.trim() == '') {
        image = "src/images/avatar/chat/profile_img_one.svg";
      }
    } 
    receivername = "You";
    messageFrom = "sender";
    nodeJid = sendfrom;

    let userSecondObj = {
      image : image,
      receivername  : receivername,
      nodeJid : nodeJid,
      displayname : userDisplayName,
      messageFrom : messageFrom
    }

    return userSecondObj;
  },
  forwardmessageUserObjThirdSplit: function(userInfo,sendfrom,mediaUrl,userToken){
      let displayname = "";
      let messageFrom ="";
      let image,nodeJid;
      if (userInfo != null && typeof userInfo[sendfrom] != "undefined") {
        displayname = userInfo[sendfrom][1];
        image = userInfo[sendfrom][2];
        if(utils.getHexRegexPattern().test(image)) {
          image = userInfo[sendfrom][2];
        } else if (image !== 'src/images/avatar/chat/profile_img_one.svg' && image.trim() !== '') {
          image = mediaUrl + image + "?mf=" + userToken;
        } else if (image.trim() == '') {
          image = "src/images/avatar/chat/profile_img_one.svg";
        }
        receivername = displayname;
      } 
      displayname = "You";
      messageFrom = "receiver";
      nodeJid = sendfrom;

      let userThirdObj = {
        image : image,
        receivername  : receivername,
        nodeJid : nodeJid,
        displayname : displayname,
        messageFrom : messageFrom
      }
  
      return userThirdObj;
  },
  forwardmessageUserObj: function(userJID,sendfrom,userid){
    let displayname = "";
    let messageFrom ="";
    let userToken = favDesignStorage.getuserToken();
    let image,nodeJid;
    let mediaUrl = window._env_.ApiURL + "/media/";
    let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));

    if (userJID == sendfrom) {
      let userFirstObj = module.exports.forwardmessageUserObjFirstSplit(userInfo,userid,mediaUrl,userToken);

      image= userFirstObj.image;
      receivername = userFirstObj.receivername;
      nodeJid= userFirstObj.nodeJid;
      displayname= userFirstObj.displayname;
      messageFrom= userFirstObj.messageFrom;
    
    } else if (userJID == userid) {
      let userSecondObj = module.exports.forwardmessageUserObjSecondSplit(userInfo,sendfrom,mediaUrl,userToken);

      image= userSecondObj.image;
      receivername = userSecondObj.receivername;
      nodeJid= userSecondObj.nodeJid;
      displayname= userSecondObj.displayname;
      messageFrom= userSecondObj.messageFrom;

      
    } else if (sendfrom == favDesignStorage.adminName()) {

      let userThirdObj = module.exports.forwardmessageUserObjThirdSplit(userInfo,sendfrom,mediaUrl,userToken);

      image= userThirdObj.image;
      receivername = userThirdObj.receivername;
      nodeJid= userThirdObj.nodeJid;
      displayname= userThirdObj.displayname;
      messageFrom= userThirdObj.messageFrom;
    }

    let userObj ={
      image : image,
      receivername  : receivername,
      displayname  : displayname,
      messageFrom  : messageFrom,
      nodeJid  : nodeJid,
    }

    return userObj;

  },

  forwardmessageGrpObjFirstImgSplit: function(image,groupInfo,userid,mediaUrl,userToken){
    
    if(utils.getHexRegexPattern().test(image)) {
      image = groupInfo[userid][2];
    } else if (image !== 'src/images/avatar/group/group_four.svg' && image.trim() !== '') {
      image = mediaUrl + image + "?mf=" + userToken;
    } else if (image.trim() == '') {
      image = "src/images/avatar/group/group_four.svg";
    }
    return image;
  },
  forwardmessageGrpObjSecondImgSplit: function(image,groupInfo,userid,mediaUrl,userToken){
    if(utils.getHexRegexPattern().test(image)) {
      image = groupInfo[userid][2];
    } else if (image !== 'src/images/avatar/group/group_four.svg' && image !== 'src/images/avatar/chat/profile_img_one.svg' && image.trim() !== '') {
      image = mediaUrl + image + "?mf=" + userToken;
    } else if (image.trim() == '') {
      image = "src/images/avatar/chat/profile_img_one.svg";
    }

    return image;
  },
  forwardmessageGrpUserImgSplit: function(image,userInfo,sendfrom,mediaUrl,userToken){
    if(utils.getHexRegexPattern().test(image)) {
      image = userInfo[sendfrom][2];
    } else if (image !== 'src/images/avatar/chat/profile_img_one.svg' && image.trim() !== '') {
      image = mediaUrl + image + "?mf=" + userToken;
    } else if (image.trim() == '') {
      image = "src/images/avatar/chat/profile_img_one.svg";
    }

    return image;
  },
  forwardmessageGrpObj: function(userJID,sendfrom,userid){
    let displayname = "";
    let messageFrom ="";
    let userToken = favDesignStorage.getuserToken();
    let image,nodeJid;
    let mediaUrl = window._env_.ApiURL + "/media/";
    let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
    let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));

    if (groupInfo != null && typeof groupInfo[userid] != "undefined") {
      displayname = groupInfo[userid][1];
      image = groupInfo[userid][2];
      image = module.exports.forwardmessageGrpObjFirstImgSplit(image,groupInfo,userid,mediaUrl,userToken)
      
      if (userJID == sendfrom) {
        receivername = "You";
        image = groupInfo[userid][2];
        image = module.exports.forwardmessageGrpObjSecondImgSplit(image,groupInfo,userid,mediaUrl,userToken)

        
      } else if (userInfo != null && typeof userInfo[sendfrom] != "undefined") {
        receivername = userInfo[sendfrom][1];
        image = userInfo[sendfrom][2];
        image = module.exports.forwardmessageGrpUserImgSplit(image,userInfo,sendfrom,mediaUrl,userToken)

        
      } else {
        receivername = "";
        image = "src/images/avatar/chat/profile_img_one.svg";
      }

      nodeJid = userid;
      messageFrom = "receiver";
    }

    let grpObj ={
      image : image,
      receivername  : receivername,
      displayname  : displayname,
      messageFrom  : messageFrom,
      nodeJid  : nodeJid,
    }

    return grpObj;
  },
  forwardmessageGetReceiverName: function(receivername,messageContent){
    if(receivername == "")
          receivername = messageContent.message_from;

    return receivername;
  },

  forwardmessageGetTime: function(time){
    if (time == "") {
      time = favDesigntimeFormat.currenttime();
    }

    return time;
  },
  forwardmessageGetDisplayname: function(displayname){
    if (displayname.length > 25) {
      displayname = displayname.substring(0, 25) + "...";
    }

    return displayname;
  },
  forwardmessageGetFilePath: function(msgType,messageContent){
    let filePath;
    if (msgType == 'audio' || msgType == 'video' || msgType == 'file' || msgType == 'image') {
      filePath = '-' + messageContent.media.file_url;
    }

    return filePath;
  },
  forwardmessageGetImgDivName: function(receivername,userProfileName){
    let name = (receivername == "You") ? userProfileName : receivername;
    return name;
  },
  forwardmessageGetImgDiv(image,receivername,userProfileName,nodeJid){
    let imgDiv;
    if(utils.getHexRegexPattern().test(image)) {
      let colorCode = utils.hexToRgbA(image);
      imgDiv = '<div class="color-code-recent" style="display: block; background-color: ' + colorCode + ';"></div>';
    } else {
      let concatedLetter,
          imageElment;

      let name = module.exports.forwardmessageGetImgDivName(receivername,userProfileName);
      concatedLetter = utils.getImageDisplayText(name);

      if(typeof image !== 'undefined' && image !== 'src/images/avatar/chat/profile_img_one.svg' && image.trim() !== ''){  
        let imageExtArray = ['jpg','jpeg','png','webp'];
        let profileExtention = (typeof image != 'undefined') ? image.split('.').pop() : '';
        favDesignindexDb.blobImageConversion(image,'.favImageId-'+nodeJid+'',nodeJid,'profileImages','className');

        if(imageExtArray.includes(profileExtention)){
          imageElment = '<img id="favImageId-'+nodeJid+'"  class="other-prof-pic favImageId-'+nodeJid+'" src="' + image + '"></img>';
        }else {
          imageElment = '<img id="favImageId-'+nodeJid+'" class="other-prof-pic favImageId-'+nodeJid+'" src="' + image + '" onError="' + module.exports.profileImgError(this) + '"></img>';
        }
      }  else {
          imageElment = '<svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1.25);"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg>';
      }

      imgDiv = imageElment;
    }

    return imgDiv;
  },
  forwardmessage: function (fwdMsgObj) {

    let userid          = fwdMsgObj.userid;
    let time            = fwdMsgObj.time;
    let fvmsgid         = fwdMsgObj.fvmsgid;
    let chat_type       = fwdMsgObj.chat_type;
    let msgbody         = fwdMsgObj.msgbody;
    let sendfrom        = fwdMsgObj.sendfrom;
    let broadcastmsgid  = fwdMsgObj.broadcastmsgid;
    let broadcastid     = fwdMsgObj.broadcastid;

    let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
    let userJID = stropheConnection.logininfo.userJID;
    let displayname = "";
    let messageFrom ="";
    let msgId = fvmsgid;
    let filePath = "";
    let image,nodeJid,receivername;
    if (chat_type == "chat") {
      if (broadcastid != null && broadcastProfile != null && typeof broadcastProfile[broadcastid] != "undefined") {
        displayname = broadcastProfile[broadcastid][1];
        image = 'src/images/avatar/broadcast/profile_img_one.png';
        nodeJid = broadcastid;
        receivername = "You";
        messageFrom = "sender";
        fvmsgid = broadcastmsgid;
        sendfrom = broadcastid;
      } else {

        let userObj =  module.exports.forwardmessageUserObj(userJID,sendfrom,userid);
        image = userObj.image;
        receivername  = userObj.receivername;
        displayname  = userObj.displayname;
        messageFrom  = userObj.messageFrom;
        nodeJid  = userObj.nodeJid;
      }
    } else if (chat_type == "normal") {

      let grpObj =  module.exports.forwardmessageGrpObj(userJID,sendfrom,userid);
        image = grpObj.image;
        receivername  = grpObj.receivername;
        displayname  = grpObj.displayname;
        messageFrom  = grpObj.messageFrom;
        nodeJid  = grpObj.nodeJid;
    }

    try {

      if (typeof receivername != "undefined") {
        let messageContent = JSON.parse(utils.decrypt(msgbody, msgId));
        msgcontent = messageContent.message;

        receivername = module.exports.forwardmessageGetReceiverName(receivername,messageContent)

        let timestamp = favDesigntimeFormat.convertTimestamp(time, "recent_at");
        time = favDesigntimeFormat.convertTimestamp(time, "created_at");
        if ($('#starredcontent-' + fvmsgid).length == 0) {
          if(typeof broadcastid != 'undefined' && broadcastid != ''){
            msgId = broadcastmsgid;
            fvmsgid = broadcastmsgid;
          }

          let favmessageBodyContent = utils.getfavmessage(messageContent, msgId, receivername);

          time = module.exports.forwardmessageGetTime(time)
          
          let userProfileName = displayname;

          displayname = module.exports.forwardmessageGetDisplayname(displayname)
         
          let msgType = favmessageBodyContent[0];
          filePath = module.exports.forwardmessageGetFilePath(msgType,messageContent)
         
          let headerdiv = '<div class="staredmsg-sideBar stared-' + sendfrom + '" id="stared-' + nodeJid + '"><div class="row sideBar-body">';
          headerdiv = headerdiv + '<div class="starredtext" id="starredcontent-' + fvmsgid + '"><div class="col-sm-3 col-xs-3 sideBar-avatar">';

          let imgDiv = module.exports.forwardmessageGetImgDiv(image,receivername,userProfileName,nodeJid)

          headerdiv = headerdiv + '<div class="avatar-icon chat-list">' + imgDiv + '</div>';
          headerdiv = headerdiv + '</div> <div class="col-sm-9 col-xs-9 sideBar-main"><div class="col-sm-4 col-xs-4 pull-right sideBar-time">';
          headerdiv = headerdiv + '<span class="time-meta pull-right">' + timestamp + '</span></div><div class="row"><div class="col-sm-12 col-xs-12 sideBar-name">';
          headerdiv = headerdiv + '<div class="person-tree"><span class="userpersonal-name">' + receivername + '</span><span class="s-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 30 30"> <g id="Arrow_icon" data-name="Arrow icon" transform="translate(-10922 17056)"> <rect id="Rectangle_16503" data-name="Rectangle 16503" width="30" height="30" transform="translate(10922 -17056)" fill="none"/> <g id="down-filled-triangular-arrow" transform="translate(10944.883 -17055.385) rotate(90)"> <path id="Path_149879" data-name="Path 149879" d="M27.382,15.765H1.388A1.384,1.384,0,0,1,.413,13.4l13-13a1.387,1.387,0,0,1,1.95,0l13,13A1.384,1.384,0,0,1,27.382,15.765Z" transform="translate(0)" fill="#929292"/> </g> </g> </svg></span><span class="name-meta contact-list">' + displayname + "</span></div>";
          headerdiv = headerdiv + '<div class="' + messageFrom + '">';

          let scrolldiv = '<span class="message-time pull-right"><span class="msg-sent-time"><i class="fevorate"><svg version="1.1" viewBox="0 0 19.481 19.481" enable-background="new 0 0 19.481 19.481"><g><path d="m10.201,.758l2.478,5.865 6.344,.545c0.44,0.038 0.619,0.587 0.285,0.876l-4.812,4.169 1.442,6.202c0.1,0.431-0.367,0.77-0.745,0.541l-5.452-3.288-5.452,3.288c-0.379,0.228-0.845-0.111-0.745-0.541l1.442-6.202-4.813-4.17c-0.334-0.289-0.156-0.838 0.285-0.876l6.344-.545 2.478-5.864c0.172-0.408 0.749-0.408 0.921,0z"></path></g></svg></i>' + time + '</span></span></div></div> </div> </div></div><div class="dropdown prof-opt favpopup">';
          scrolldiv = scrolldiv + '<a class="dropdown-toggle" id="menu1" type="button" data-toggle="dropdown"><i class="option-arrow"></i></a>';
          scrolldiv = scrolldiv + '<ul class="dropdown-menu head-option" role="menu" aria-labelledby="menu1">';
          scrolldiv = scrolldiv + '<li role="presentation"><a class="forwardsingle" id="' + favmessageBodyContent[0] + '-' + msgId + filePath + '" >' + favDesignlanguage.locali().forward + "</a></li>";
          scrolldiv = scrolldiv + '<li role="presentation"><a  class="unstarmsg"  id="favmenu-' + fvmsgid + '">' + favDesignlanguage.locali().unstar_message + "</a></li>";
          scrolldiv = scrolldiv + "</ul></div>";
          scrolldiv = scrolldiv + "</div> ";

          $("#star-content").prepend(headerdiv + favmessageBodyContent[1] + scrolldiv );
        }
      }
      $("#star-content").mCustomScrollbar("destroy");
      $("#star-content").mCustomScrollbar();
    } catch (err) {
     console.log(err);
    }
  },
  favClickActionFirstSplit: function(messageId, tojid){
    if ($("#chat-" + messageId).length) {
      let container = $("[id='conversation"+tojid+"']"),
        scrollTo = $("#chat-" + messageId);

      let pos = scrollTo.offset() ? scrollTo.offset().top - container.offset().top + container.scrollTop() : container.offset().top + container.scrollTop();
      container.animate({
        scrollTop: pos
      });
      $("#chat-" + messageId).css("background-color", "rgb(234, 234, 234)");
      setTimeout(function () {
        $("#chat-" + messageId).css("background-color", "");
      }, 2000);
      return true;
    }
  },
  favClickActionSecondSplit: function(nodeValue){
    $("#comment").empty();
    $(".del-msg-wrap").hide();
    $(".row.reply").css("display", "flex");
    $(".blocked-person").css("display", "none");
    $("#recentcontent").find(".recentcontent").removeClass("active");
    $("#index-chat-section").hide();
    $(".message-info-block").css({ right: "-100%" });
    $(".message-info-block .right-msg-back").trigger("click");
    $(".media-detail-section .right-msg-back").trigger("click");
    $("#right-prof-back").trigger("click");
    $(".conversation").removeClass("small-pane");
    $("#" + nodeValue).addClass("active");
    $("#con-head").css("display", "flex");
    $("#conversation").css("display", "block");
    $(".reply").css("display", "flex");
    module.exports.favClickActionTitleSet(nodeValue)
  },
  favClickActionTitleSet: function(nodeValue){
    let title = "";
    let count = $("#" + nodeValue).find(".notify").text();
    if ($("#" + nodeValue).find(".notify").text() != 0) {
      if (document.title != "IkonixConnect") {
        if (document.title.split('(').pop().split(')')[0] != 0) {
          count = document.title.split('(').pop().split(')')[0] - 1;
          count = parseInt(count);
          if(count == 0){
            title = "IkonixConnect"
          }else {
            title = "(" + count + ") IkonixConnect"
          }
        
        localStorage.setItem('unreadMessageCount', count);
        }
        document.title = title;
      }
    }
    $("#" + nodeValue).find(".notify").html(parseInt(0));
    $("#" + nodeValue).find(".notify").css("display", "none");
    $(".heading-online").html("");
  },
  favClickActionToJidReturn: function(nodeValue){
    let toJID;
    let groupInfo = JSON.parse(localStorage.getItem("groupProfile"));
    let userInfo = JSON.parse(localStorage.getItem("usersProfileInfo"));
    let broadcastProfile = JSON.parse(localStorage.getItem("broadcastProfile"));
    if (broadcastProfile != null && typeof broadcastProfile[nodeValue] != "undefined" && broadcastProfile[nodeValue][0] == "broadcast") {
      toJID = nodeValue + "@" + stropheConnection.Server();
    } else if (groupInfo != null && typeof groupInfo[nodeValue] != "undefined") {
      toJID = nodeValue + "@mix." + stropheConnection.Server();
      favDesigngroups.getgroupdetails(toJID);
    } else if (userInfo != null && typeof userInfo[nodeValue] != "undefined") {
      toJID = nodeValue + "@" + stropheConnection.Server();
      favDesignlastActivity.lastActivity(toJID);
    }
    localStorage.setItem("toJID", JSON.stringify(toJID));
    $(".blocked-person").css("display", "none");
    $(".row.reply").css("display", "flex");
    $("#conversation"+nodeValue).remove();
    return toJID;
  },
  favClickActionThirdSplit: function(toJID,messageId){
    setTimeout(function () {
      favDesignconversationst.MessageLayout(toJID, 'up', messageId);
      let tojid = stropheConnection.Strophe().getNodeFromJid(utils.toJID());
      let container = $("#conversation"+tojid),
          scrollTo = $("#chat-" + messageId);

      $('.loader').css("display", "none");
      let pos = scrollTo.offset() ? scrollTo.offset().top - container.offset().top + container.scrollTop() : container.offset().top + container.scrollTop();
      container.animate({
        scrollTop: pos
      });
      let chatBgStarred = "rgb(" + favDesignlanguage.locali().chat_bg_starred_r + "," + favDesignlanguage.locali().chat_bg_starred_g + "," + favDesignlanguage.
   locali().chat_bg_starred_b + ")";
      $("#chat-" + messageId).css("background-color", chatBgStarred);
      
    }, 2000);
    setTimeout(function () {
      $("#chat-" + messageId).css("background-color", "");
    }, 4000);
  },
  favouriteclickaction: function () {
    // clickaction 
    utils.determineDropDirection();
    $(".starredtext").off("click");
    $(".starredtext").click(function (e) {
      e.preventDefault()
      let id = $(this).closest(".staredmsg-sideBar").attr("id");
      $("#starSearch").val("");
      $("#starSearch").trigger("keyup");
      if (id != "") {
        let result = id.split(/-(.+)/);
        let nodeValue = result[1];
        let tojid = stropheConnection.Strophe().getNodeFromJid(utils.toJID());
        let getMessageId = $(this).attr("id").split("-");
        let messageId = getMessageId[1];
        $(".star-back").trigger("click");
        if (nodeValue == tojid) {
          module.exports.favClickActionFirstSplit(messageId, tojid)
        }else{
          module.exports.favClickActionSecondSplit(nodeValue)
        let toJID = module.exports.favClickActionToJidReturn(nodeValue);
        
        favDesignconversationst.MessageLayout(toJID, 'down', '',0);

        module.exports.favClickActionThirdSplit(toJID,messageId)

      
      }
    }
    });
  },
  profileImgError: function() {
    $('img').on("error", function () {
      this.onerror = "";
      this.src = "./src/images/avatar/chat/profile_img_one.svg";
    });
},
};
