let la = require('./localization');

module.exports = {
    localiStore: function (lang) {
        localStorage.setItem("localization", lang);
    },
    locali: function () {
      let localization=la;
        let selectLanguage = "enUS";
        return localization[selectLanguage];
    }
    
 }