require('./lib/croppie/croppie.js');
let uploadjs = require('./fileupload.js');
let utils = require('./utils.js');
let language = require('./locale/language.js');

module.exports = {
    profileUpload: function () {

        document.getElementById("profile-upload").onchange = function () {
            let validImageTypes = ["image/jpeg", "image/png"];
            let sFileName = this.files[0].name;
            let fileType = this.files[0].type;
            if ( $.inArray(fileType, validImageTypes) >= 0 ) {

                let reader = new FileReader();

                reader.onload = function (e) {
                    $(".popup-pro-content").html("<div id = 'upload-demo'><a class='image-cropped'><i><svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 30 30'> <g id='Tick_icon' data-name='Tick icon' transform='translate(-10856 17056)'> <rect id='Rectangle_16501' data-name='Rectangle 16501' width='24' height='24' transform='translate(10856 -17056)' fill='none'/> <path id='Path_149878' data-name='Path 149878' d='M101.546,57.688,99.3,55.445a1.625,1.625,0,0,0-2.324,0L83.791,68.634,78.43,63.273a1.625,1.625,0,0,0-2.324,0l-2.242,2.242a1.624,1.624,0,0,0,0,2.324L82.629,76.6a1.625,1.625,0,0,0,2.324,0l16.593-16.593a1.624,1.624,0,0,0,0-2.324Z' transform='translate(10783.295 -17107.023)' fill='#ee7020'/> </g> </svg></i></a></div>");
                    document.getElementById('popup1').style.visibility = 'visible';
                    
                    document.getElementById('popup1').style.opacity = '1';
                    $('#popup1').find('img').attr('src', e.target.result);
                    let el = document.getElementById('upload-demo');
                    let vanilla = new Croppie(el, {
                        viewport: {
                            width: 300,
                            height: 300,
                            type: 'square'
                        },
                        boundary: {
                            width: 300,
                            height: 300
                        },
                        showZoomer: true,
                    });
                    vanilla.bind({
                        url: e.target.result,
                    });
                    $("#profile-upload")[0].value = '';

                    //on button click
                    $(".image-cropped").click(function () {
                         $('.loader').css("display", "block");
                         $(".image-cropped").css("display", "none");
                         $('.retry').css("display", "none");
                        vanilla.result('blob').then(function (blob) {
                            let file = new File([blob], sFileName);
                            uploadjs.profileUploadToS3(file, 'popup1');
                        });
                    });
                };
                // read the image file as a data URL.
                reader.readAsDataURL(this.files[0]);
            } else {
                $("#infoContent").text(language.locali().file_not_support + ' Make sure to upload a JPG, JPEG, PNG file and try again.');
                $('#infobox').css("display", "block").delay(4000).fadeOut();
            }
        };
    },
    groupProfileUpload: function () {
        uploadjs.groupprofileupdate();
        document.getElementById("group-image-upload").onchange = function () {
            let validImageTypes = ["image/jpeg", "image/png"];
            let sFileName = this.files[0].name;
            let fileType = this.files[0].type;
            if ( $.inArray(fileType, validImageTypes) >= 0 ) {

                let reader = new FileReader();
                reader.onload = function (e) {
                    $(".popup2-content").html("<div id = 'group-upload-demo'><a class='image-cropped'><i><svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 30 30'> <g id='Tick_icon' data-name='Tick icon' transform='translate(-10856 17056)'> <rect id='Rectangle_16501' data-name='Rectangle 16501' width='24' height='24' transform='translate(10856 -17056)' fill='none'/> <path id='Path_149878' data-name='Path 149878' d='M101.546,57.688,99.3,55.445a1.625,1.625,0,0,0-2.324,0L83.791,68.634,78.43,63.273a1.625,1.625,0,0,0-2.324,0l-2.242,2.242a1.624,1.624,0,0,0,0,2.324L82.629,76.6a1.625,1.625,0,0,0,2.324,0l16.593-16.593a1.624,1.624,0,0,0,0-2.324Z' transform='translate(10783.295 -17107.023)' fill='#ee7020'/> </g> </svg></i></a></div>");
                    document.getElementById('popup2').style.visibility = 'visible';
                    document.getElementById('popup2').style.opacity = '1';
                    $('#popup2').find('img').attr('src', e.target.result);
                    let el = document.getElementById('group-upload-demo');
                    let vanilla = new Croppie(el, {
                        viewport: {
                            width: 300,
                            height: 300,
                            type: 'square'
                        },
                        boundary: {
                            width: 300,
                            height: 300
                        },
                        showZoomer: true,
                    });
                    vanilla.bind({
                        url: e.target.result,
                    });
                    $("#group-image-upload")[0].value = '';
                    //on button click
                    $(".image-cropped").click(function () {
                        $(".image-cropped").off("click");
                        $('.loader').css("display", "block");
                        $(".image-cropped").css("display", "none");
                        $('.retry').css("display", "none");
                        vanilla.result('blob').then(function (blob) {
                            $('.loader').css("display", "block");
                            let file = new File([blob], sFileName);
                            uploadjs.GroupProfileUploadToS3(file, 'popup2');
                        });
                    });
                    $(".popup2").click(function () {
                        $('.loader').css("display", "none");
                        $(".image-cropped").css("display", "block");
                        $('.retry').css("display", "block");
                        utils.closeProfilePopUp('popup2');
                    });
                };
                // read the image file as a data URL.
                reader.readAsDataURL(this.files[0]);
            } else {
                $("#infoContent").text(language.locali().file_not_support + ' Make sure to upload a JPG, JPEG, PNG file and try again.');
                $('#infobox').css("display", "block").delay(4000).fadeOut();
            }
        };
    }
};
