
let botStatus, params;
let userToken, audioLimit;
let emailValidate = false;
let browserStatus = false;
let commentPosition = 0;
let session = require('./session.js');

module.exports = {

  botData: function (data) {
    if (data != undefined && data.status != null && data.status != undefined) {
      botStatus = 0;

      if (botStatus == "1") {
        $("input:checkbox.bot-change").prop("checked", true);
      } else {
        $("input:checkbox.bot-change").prop("checked", false);
      }
    } else {
      botStatus = 0;
      $("input:checkbox.bot-change").prop("checked", true);
    }
  },
  adminName: function () {
    let settings = JSON.parse(localStorage.getItem("settings"));
    let AdminUser = settings.adminUser;

    return AdminUser;
  },
  botStatus: function () {
    return 0;
  },
  setbrowserStatus: function (newbrowserStatus) {
    browserStatus = newbrowserStatus;
    return browserStatus;
  },
  getbrowserStatus: function () {
    return browserStatus;
  },
  getuserToken: function () {
    userToken =JSON.parse(localStorage.getItem("userToken"));
    if(typeof userToken == 'undefined' || userToken == null){
      let loginUserInfo = JSON.parse(localStorage.getItem("loginUserInfo"));
      let username = loginUserInfo[0][0];
      module.exports.setSession(username);
      userToken =JSON.parse(localStorage.getItem("userToken"));
    }
    return userToken;
  },
  setSession: async function(username){
    await session.setSession(username);
  },
  setemailvalidate: function (emailvalidate) {
    return emailvalidate;

  },
  getemailvalidate: function () {
    return emailValidate;
  },
  setCommentPosition: function (commentposition) {
    commentPosition = commentposition;
    return commentPosition;

  },
  setAudioLimit: function (audioLimit) {
    this.audioLimit = audioLimit;
    return this.audioLimit;
  },
  getAudioLimit: function () {
    return this.audioLimit;
  },
  options: function () {
    return params;
  },
  getCommentPosition: function () {
    return commentPosition;
  },
  // get keycloak logout url
  getKeycloakLogoutURL: function() {
    let getUrl = window.location;
    let baseUrl = getUrl .protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];

    return window._env_.KeycloakAuthURL + "/realms/" + window._env_.KeycloakRealm + "/protocol/openid-connect/logout?redirect_uri=" + baseUrl;
  }
};