let stropheConnection = require('./stropheConnection.js');
let utils = require('./utils.js');
let grpDesignuploadjs = require('./fileupload.js');
let grpDesignstorage = require('./storage.js');
let MediaStream;
let grpDesignlanguage = require('./locale/language.js');
module.exports = {
    toJID: function () {
        return JSON.parse(localStorage.getItem("toJID"));
    },
    createGroup: function () {
        let userJID = stropheConnection.logininfo.userJID;

        let groupId = utils.getId() + userJID;
        let toAddress = groupId + "@mix." + stropheConnection.Server();
        let group = stropheConnection.StropheJs().$iq({
            type: "set",
            id: utils.getId(),
            to: toAddress
        }).c('join', {
            xmlns: 'urn:xmpp:mix:0'
        }).c('subscribe', {
            node: 'urn:xmpp:mix:nodes:messages'
        }).up()
            .c('subscribe', {
                node: 'urn:xmpp:mix:nodes:presence'
            }).up()
            .c('subscribe', {
                node: 'urn:xmpp:mix:nodes:participants'
            }).up()
            .c('subscribe', {
                node: 'urn:xmpp:mix:nodes:subject'
            }).up()
            .c('subscribe', {
                node: 'urn:xmpp:mix:nodes:config'
            }).up();
        if (stropheConnection.Connection().connected) {
            stropheConnection.Connection().send(group);
        }
        setTimeout(function () {
            module.exports.setGroupInfo(toAddress);
        }, 1000);
        setTimeout(function () {
            module.exports.addMembersToGroup(toAddress);
        }, 2000);
        return true;
    },
 
    addMembersToGroup: function (from) {
        let groupaddUserInfo = JSON.parse(localStorage.getItem("groupaddUserInfo"));
        let myJid = JSON.parse(localStorage.getItem("loginUserInfo"))[0][0];
        let myInfo = JSON.parse(localStorage.getItem("myInfo"));
        let addMembersIq = stropheConnection.StropheJs().$iq({
            type: "set",
            id: utils.randomString(16, 'a#'),
            to: from
        }).c('query', {
            status: 'add_participants',
            xmlns: 'jabber:iq:mixgroupchat'
        })
            .c('subscriptions', {}).c('subscribe', {
                node: 'urn:xmpp:mix:nodes:messages'
            }).up()
            .c('subscribe', {
                node: 'urn:xmpp:mix:nodes:presence'
            }).up()
            .c('subscribe', {
                node: 'urn:xmpp:mix:nodes:participants'
            }).up()
            .c('subscribe', {
                node: 'urn:xmpp:mix:nodes:subject'
            }).up()
            .c('subscribe', {
                node: 'urn:xmpp:mix:nodes:config'
            }).up().up()
            .c('participants', {});
        for (let key in groupaddUserInfo) {
            addMembersIq.c("participant", {
                message_from: myInfo[myJid][1],
                message_to: groupaddUserInfo[key][1]
            }).t(key + "@" + stropheConnection.Server()).up();
        }
        if (stropheConnection.Connection().connected) {
            stropheConnection.Connection().send(addMembersIq);
        }
        delete localStorage.groupaddUserInfo;
        return true;
    },
   
    setGroupInfo: function (toAddress) {
        let temp = JSON.parse(localStorage.getItem("temp"));
        let myJid = JSON.parse(localStorage.getItem("loginUserInfo"))[0][0];
        let myInfo = JSON.parse(localStorage.getItem("myInfo"));
        let groupImage;
        if (typeof temp.groupImage == "undefined") {
            groupImage = "";
        } else {
            groupImage = temp.groupImage;
        }

        let groupinfoiq = stropheConnection.StropheJs().$iq({
            type: 'set',
            id: utils.randomString(16, 'a!'),
            to: toAddress
        }).c('query', {
            status: 'set_group_info',
            xmlns: 'jabber:iq:mixgroupchat'
        })
        .c('profile', {
            message_from: myInfo[myJid][1],
            message_to: ''
        })
            .c('vCard', {
                xmlns: 'vcard-temp'
            }).c("nickName").t(temp.name).up()
            .c("image").t(groupImage).up();
        let groupProfile = JSON.parse(localStorage.getItem("groupProfile"));
        let nodeJid = stropheConnection.Strophe().getNodeFromJid(toAddress);
        if (groupProfile == null) {
            groupProfile = {};
        }
        
        groupProfile[nodeJid] = [];
        groupProfile[nodeJid].push('group');
        groupProfile[nodeJid].push(temp.name);
        groupProfile[nodeJid].push(groupImage);
        localStorage.setItem("groupProfile", JSON.stringify(groupProfile));
        if (stropheConnection.Connection().connected) {
            stropheConnection.Connection().send(groupinfoiq);
        }
        delete localStorage.temp;
        // Indicate that this handler should be called repeatedly
        return true;
    },
    groupdelete: function (groupJid) {
        let toJid = JSON.parse(localStorage.getItem("toJID"));
        let toId;
        if(toJid!==null)
           toId = stropheConnection.Strophe().getNodeFromJid(toJid);
        if (toId != null && toId == groupJid) {
            $('#con-head').css('display', 'none');
            $('.select-messages').hide();
            $('.contact-clearmsg').hide();
            $('#conversation').hide();
            $('.del-msg-wrap').hide();
            $('.reply').hide();
            $('#index-chat-section').show();
        }

        let groupId = groupJid.split("@")[0];
        if ($("[id='forwardcontact"+groupId+"']").length) {
            $("[id='forwardcontact"+groupId+"']").remove();
        }
        if ($("[id='"+groupId+"']").length) {
            $("[id='"+groupId+"']").remove();
            
        }

        $('#media-upload-popup .close').trigger('click');
        $(".ack-popup").removeClass("view");
        $("#audio-pop").hide();

        $('.index-chat-section').css('display','block!important');
        $('#message').css('display','none!important');
    },
    addParticipant: function (jid, contactName, contactImage) {
        let bubbleheight;
        $(".group-sec").removeClass("hide");
        $(".group-sec").addClass("show");
        bubbleheight = $('#bubble-block').outerHeight();
        if(bubbleheight >= 48){
        $(".compose-sideBar").addClass('bubbleadded');
        }
        $(".contact-bubble-block").addClass('group-list-sec');
        $(".compose-sideBar").addClass('new-group-section');

        $(".participants-back").click(function(){
            $(".compose-sideBar").removeClass('bubbleadded');
            $(".contact-bubble-block").removeClass('group-list-sec');
            $(".compose-sideBar").removeClass('new-group-section');
        });
        let userToken = grpDesignstorage.getuserToken();
        bubbleheight = $('#bubble-block').outerHeight();
        if (bubbleheight >= 48) {
            $(".compose-sideBar").addClass('bubbleadded');
        }
        $(".contact-bubble-block").addClass('group-list-sec');
        $(".compose-sideBar").addClass('new-group-section');
        let nameDiv = '<li class="contact-bubble" id="' + "newmember-" + jid + '"><div class="bubble-tag"><div class="col-sm-3 col-xs-3 sideBar-avatar"><div class="avatar-icon" style="height:28px;width:28px;">';

        let concatedLetter,
            imageElment;

        if (contactImage !== 'src/images/avatar/chat/profile_img_one.svg' && typeof contactImage != 'undefined' && contactImage !== '') {
            let mediaUrl = window._env_.ApiURL + "/media/";
            contactImage = mediaUrl + contactImage + "?mf=" + userToken;
            imageElment = '<img src="' + contactImage + '">';
        } else {
            if(contactName != "") {
               
                concatedLetter = utils.getImageDisplayText(contactName);

            }
            imageElment = '<svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1);height:28px;width:28px;"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg>';
        }
        nameDiv = nameDiv + imageElment + '</div></div>';
        nameDiv = nameDiv + '<h5>' + contactName + '</h5><i class=" close-bubble"><svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 30 30"> <g id="Close_icon" data-name="Close icon" transform="translate(-10998 17056)"> <rect id="Rectangle_16502" data-name="Rectangle 16502" width="30" height="30" transform="translate(10998 -17056)" fill="none"/> <path id="close_1_" data-name="close (1)" d="M18.958,14.255l8.819-8.819a2.493,2.493,0,0,0,0-3.527L26.6.733a2.493,2.493,0,0,0-3.527,0L14.255,9.552,5.436.731a2.493,2.493,0,0,0-3.527,0L.731,1.907a2.494,2.494,0,0,0,0,3.527l8.821,8.821L.733,23.074a2.493,2.493,0,0,0,0,3.527l1.176,1.176a2.493,2.493,0,0,0,3.527,0l8.819-8.819,8.819,8.819a2.494,2.494,0,0,0,3.527,0L27.777,26.6a2.494,2.494,0,0,0,0-3.527Zm0,0" transform="translate(10998.746 -17055.254)" fill="#929292"/> </g> </svg></i></div></li>';

        if ($("[id='newmember-"+jid+"']").length == 0) {
            $(".contact-bubble-block").append(nameDiv);
        }
        $(".close-bubble").click(function () {
          
            let participantDivEl = $(this).parent().parent().parent().siblings().closest('.compose-sideBar:visible').attr('id')

            let removejid = $(this).closest('li').attr('id');
            let key = removejid.split(/-(.+)/);
            $("#participant-" + key[1]).css({
                "display": "block"
            });

            $('#globalTeamContactLists').find("#participant-" + key[1]).css({
                "display": "block"
            });

            let groupaddUserInfo = JSON.parse(localStorage.getItem("groupaddUserInfo"));
            delete groupaddUserInfo[key[1]];
            localStorage.setItem("groupaddUserInfo", JSON.stringify(groupaddUserInfo));
            $(this).closest('li').remove();
            $("[id='"+removejid+"']").remove();    
            
            let elementId = '#'+participantDivEl;
            let is_header_tab = (elementId == '#globalTeamContactLists') ? true : false;

            if($('#bubble-block').children().length > 0){
                $('#participants').css('padding','0px')
                $('#participant-sites-info').css('padding','0px')
            }else {
                $('#participants').css('padding','1px')
                $('#participant-sites-info').css('padding','1px')
            }

            /** Participant Div Calculation and Update**/
            let participantStaticDivHeight = utils.getAddParticipantStaticDivHeight(elementId,is_header_tab);
            $(elementId).css({ 'height': 'calc(100vh - ' + participantStaticDivHeight+ 'px)' });
            $(elementId).mCustomScrollbar("update");

        });
    },

    addBroadcastParticipant: function (jid, contactName, contactImage) {
        let bubbleheight;
        $(".group-sec").removeClass("hide");
        $(".group-sec").addClass("show");
        bubbleheight = $('#broadcast-bubble-block').outerHeight();
        if(bubbleheight >= 48){
        $(".compose-sideBar").addClass('bubbleadded');
        }
        $(".contact-bubble-block").addClass('group-list-sec');
        $(".compose-sideBar").addClass('new-group-section');

        $(".broadcast-participants-back").click(function(){
            $(".compose-sideBar").removeClass('bubbleadded');
            $(".contact-bubble-block").removeClass('group-list-sec');
            $(".compose-sideBar").removeClass('new-group-section');
        });
        let userToken = grpDesignstorage.getuserToken();
        bubbleheight = $('#broadcast-bubble-block').outerHeight();
        if (bubbleheight >= 48) {
            $(".compose-sideBar").addClass('bubbleadded');
        }
        $(".contact-bubble-block").addClass('group-list-sec');
        $(".compose-sideBar").addClass('new-group-section');
        let nameDiv = '<li class="contact-bubble" id="' + "newmember-" + jid + '"><div class="bubble-tag"><div class="col-sm-3 col-xs-3 sideBar-avatar"><div class="avatar-icon" style="height:28px;width:28px;">';

        let concatedLetter,
            imageElment;

        if (contactImage !== 'src/images/avatar/chat/profile_img_one.svg' && typeof contactImage != 'undefined' && contactImage !== '') {
            let mediaUrl = window._env_.ApiURL + "/media/";
            contactImage = mediaUrl + contactImage + "?mf=" + userToken;
            imageElment = '<img src="' + contactImage + '">';
        } else {
            if(contactName != "") {
               
                concatedLetter = utils.getImageDisplayText(contactName);

            }
            imageElment = '<svg class="avatar-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="transform: scale(1);height:28px;width:28px;"><circle fill="#CCC" cx="50" cy="50" r="50"></circle><text dominant-baseline="central" fill="rgba(255,255,255,1)" font-size="30pt" text-anchor="middle" x="50" y="50" style="font-weight:700">'+concatedLetter+'</text></svg>';
        }
        nameDiv = nameDiv + imageElment + '</div></div>';
        nameDiv = nameDiv + '<h5>' + contactName + '</h5><i class="broadcast-close-bubble"><svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 30 30"> <g id="Close_icon" data-name="Close icon" transform="translate(-10998 17056)"> <rect id="Rectangle_16502" data-name="Rectangle 16502" width="30" height="30" transform="translate(10998 -17056)" fill="none"/> <path id="close_1_" data-name="close (1)" d="M18.958,14.255l8.819-8.819a2.493,2.493,0,0,0,0-3.527L26.6.733a2.493,2.493,0,0,0-3.527,0L14.255,9.552,5.436.731a2.493,2.493,0,0,0-3.527,0L.731,1.907a2.494,2.494,0,0,0,0,3.527l8.821,8.821L.733,23.074a2.493,2.493,0,0,0,0,3.527l1.176,1.176a2.493,2.493,0,0,0,3.527,0l8.819-8.819,8.819,8.819a2.494,2.494,0,0,0,3.527,0L27.777,26.6a2.494,2.494,0,0,0,0-3.527Zm0,0" transform="translate(10998.746 -17055.254)" fill="#929292"/> </g> </svg></i></div></li>';

        if ($("[id='newmember-"+jid+"']").length == 0) {
            $(".contact-bubble-block").append(nameDiv);
        }
        $(".broadcast-close-bubble").click(function () {
            
            let participantDivEl = $(this).parent().parent().parent().siblings().closest('.compose-sideBar:visible').attr('id')

            let removejid = $(this).closest('li').attr('id');
            let key = removejid.split(/-(.+)/);
            $("#participant-" + key[1]).css({
                "display": "block"
            });

            $('#globalBroadcastContactLists').find("#participant-" + key[1]).css({
                "display": "block"
            });

            $("#broadcast-participant-sites-info #participant-" + key[1]).css({
                "display": "block"
            });

            let broadcastAddUserInfo = JSON.parse(localStorage.getItem("broadcastAddUserInfo"));
            delete broadcastAddUserInfo[key[1]];
            localStorage.setItem("broadcastAddUserInfo", JSON.stringify(broadcastAddUserInfo));
            $(this).closest('li').remove();
            $("[id='"+removejid+"']").remove();    
            
            let elementId = '#'+participantDivEl;
            
            $(''+elementId+' #noparticipant').remove();

            let is_header_tab = (elementId == '#globalBroadcastContactLists') ? true : false;

            if($('#broadcast-bubble-block').children().length > 0){
                $('#broadcast-participants').css('padding','0px')
                $('#broadcast-participant-sites-info').css('padding','0px')
            }else {
                $('#broadcast-participants').css('padding','1px')
                $('#broadcast-participant-sites-info').css('padding','1px')
            }
            /** Participant Div Calculation and Update**/
            let participantStaticDivHeight = utils.getAddParticipantStaticDivHeight(elementId,is_header_tab);
            $(elementId).css({ 'height': 'calc(100vh - ' + participantStaticDivHeight+ 'px)' });
            $(elementId).mCustomScrollbar("update");

        });
    },

    
    triggerCameraPic: function () {
        $('#retry-group').click(function () {
            if (typeof MediaStream != "undefined" && MediaStream != '') {
                MediaStream.stop();
            }
            $('#first_group_camera').trigger('click');
            $("#group-pic-upload h3").text(grpDesignlanguage.locali().adjust_the_camera);
        });
        $(".group-close").click(function () {
            utils.closeProfilePopUp('group-pic-upload');
            if (typeof MediaStream != "undefined" && MediaStream != '') {
                MediaStream.stop();
            }
        });

        $('#first_group_camera').click(function () {
            let videoDiv = "<video id = 'group-stream' style = 'width:100%'></video><p id = 'error-message'></p><a id = 'group-img-select' class = 'image-cropped'><i><svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'><path d='M0 0h24v24H0z' fill='none'></path><circle cx='12' cy='12' r='3.2' fill='#f07000'></circle><path d='M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z' fill='#f07000'></path></svg></i></a><canvas> </canvas>";
            $('#change-camera-2').html(videoDiv);
            $('#group-pic-upload').css('visibility', 'visible');
            $('#group-pic-upload').css('opacity', '1');
            $("#group-pic-upload h3").text(grpDesignlanguage.locali().adjust_the_camera);
            $("#change-camera-2").find('#group-img-select').show();
            $('#retry-group').show();
            let video = document.querySelector('#group-stream');
            $(".image-cropped").css("display", "none");
            $("#retry-camera").show();
            $('#camerapic .close').click(function () {
                if (typeof MediaStream != "undefined" && MediaStream != '') {
                    MediaStream.stop();
                }
                $('#camerapic').css('visibility', 'hidden');
                $('#camerapic').css('opacity', '0');
            });
            navigator.mediaDevices.getUserMedia({video: true, audio: false})
            .then(function(stream) {
              video.srcObject = stream;
              video.play();
              MediaStream = stream.getTracks()[0];
              $('#group-stream').show();
                $("#change-camera-2").find('#error-message').hide();
                $(".image-cropped").css("display", "block");
                triggergroupImageSelect();
            })
            .catch(function(err) {
              $("#change-camera-1").find("#error-message").show();
              module.exports.displayErrorMessage("Camera permission should be enabled.", true);
            });
          
        });

        function takeGroupSnapshot() {
            // Here we're using a trick that involves a hidden canvas element.  
            let video = document.querySelector('#group-stream');
            let hidden_canvas = document.querySelector('canvas');
            context = hidden_canvas.getContext('2d');
            let width = video.videoWidth,
                height = video.videoHeight;
            if (width && height) {
                // Setup a canvas with the same dimensions as the video.
                hidden_canvas.width = width;
                hidden_canvas.height = height;
                // Make a copy of the current frame in the video on the canvas.
                context.drawImage(video, 0, 0, width, height);
                // Turn the canvas image into a dataURL that can be used as a src for our photo.
                return hidden_canvas.toDataURL('image/png');
            }
        }

        function triggergroupImageSelect() {
            $('#group-img-select').click(function () {
                let output = takeGroupSnapshot();
                if (typeof MediaStream != "undefined" && MediaStream != '') {
                    MediaStream.stop();
                }
                let ImgDiv = "<a id='group-img-crop' class='image-cropped'><i><svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 30 30'> <g id='Tick_icon' data-name='Tick icon' transform='translate(-10856 17056)'> <rect id='Rectangle_16501' data-name='Rectangle 16501' width='24' height='24' transform='translate(10856 -17056)' fill='none'/> <path id='Path_149878' data-name='Path 149878' d='M101.546,57.688,99.3,55.445a1.625,1.625,0,0,0-2.324,0L83.791,68.634,78.43,63.273a1.625,1.625,0,0,0-2.324,0l-2.242,2.242a1.624,1.624,0,0,0,0,2.324L82.629,76.6a1.625,1.625,0,0,0,2.324,0l16.593-16.593a1.624,1.624,0,0,0,0-2.324Z' transform='translate(10783.295 -17107.023)' fill='#ee7020'/> </g> </svg></i></a>";
                $('#change-camera-2').html(ImgDiv);
                $("#group-pic-upload h3").text("Drag the image to adjust");
                let el = document.getElementById('change-camera-2');
                let vanilla = new Croppie(el, {
                    viewport: {
                        width: 300,
                        height: 300,
                        type: 'square'
                    },
                    boundary: {
                        width: 300,
                        height: 300
                    },
                    showZoomer: true,
                });
                vanilla.bind({
                    url: output,
                });
                //on button click
                $("#group-img-crop").click(function () {
                    $('.loader').css("display", "block");
                    vanilla.result('blob').then(function (blob) {
                        let file = new File([blob], "group-pic.webp");
                        grpDesignuploadjs.groupImageUpload(file, 'group-pic-upload');
                    });
                });
            });
        }
    },
    groupFirstProfileUpload: function () {
        document.getElementById("group-first-profile-upload").onchange = function () {
            let validImageTypes = ["image/jpeg", "image/png"];
            let fileType = this.files[0].type;
            if ( $.inArray(fileType, validImageTypes) >= 0 ) {
                let reader = new FileReader();
                reader.onload = function (e) {
                    // get loaded data and render thumbnail.
                    $(".popup3-content").html("<div id = 'group-first-upload-demo'><a class='image-cropped'><i><svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 30 30'> <g id='Tick_icon' data-name='Tick icon' transform='translate(-10856 17056)'> <rect id='Rectangle_16501' data-name='Rectangle 16501' width='24' height='24' transform='translate(10856 -17056)' fill='none'/> <path id='Path_149878' data-name='Path 149878' d='M101.546,57.688,99.3,55.445a1.625,1.625,0,0,0-2.324,0L83.791,68.634,78.43,63.273a1.625,1.625,0,0,0-2.324,0l-2.242,2.242a1.624,1.624,0,0,0,0,2.324L82.629,76.6a1.625,1.625,0,0,0,2.324,0l16.593-16.593a1.624,1.624,0,0,0,0-2.324Z' transform='translate(10783.295 -17107.023)' fill='#ee7020'/> </g> </svg></i></a></div>");
                    document.getElementById('popup3').style.visibility = 'visible';
                    document.getElementById('popup3').style.opacity = '1';
                    $('#popup3').find('img').attr('src', e.target.result);
                    let el = document.getElementById('group-first-upload-demo');
                    let vanilla = new Croppie(el, {
                        viewport: {
                            width: 300,
                            height: 300,
                            type: 'square'
                        },
                        boundary: {
                            width: 300,
                            height: 300
                        },
                        showZoomer: true,
                    });
                    vanilla.bind({
                        url: e.target.result,
                    });
                    $("#group-first-profile-upload")[0].value = '';
                    //on button click
                    $(".image-cropped").click(function () {
                        $('.loader').css("display", "block");
                        $(".image-cropped").css("display", "none");
                        $('.retry').css("display", "none");
                        vanilla.result('blob').then(function (blob) {
                            let file = new File([blob], "group-pic.webp");
                            grpDesignuploadjs.groupImageUpload(file, 'popup3');
                        });
                    });
                    $(".popup3").click(function () {
                        $('.loader').css("display", "none");
                        $(".image-cropped").css("display", "block");
                        $('.retry').css("display", "block");
                        utils.closeProfilePopUp('popup3');
                        if (typeof MediaStream != "undefined" && MediaStream != '') {
                            MediaStream.stop();
                        }
                    });
                };
                // read the image file as a data URL.
                reader.readAsDataURL(this.files[0]);
            } else {
                $("#infoContent").text(grpDesignlanguage.locali().file_not_support + ' Make sure to upload a JPG, JPEG, PNG file and try again.');
                $('#infobox').css("display", "block").delay(4000).fadeOut();
            }
        };
    },

    displayErrorMessage: function (error_msg, error) {
        MediaStream = '';
        error = error || "";
        if (error) {
            console.log(error_msg);
        }

        $("#change-camera-2").find('#error-message').html(error_msg);
        $("#group-stream").hide();
        $("#change-camera-2").find('#group-img-select').hide();
        $('#change-camera-2').find('#error-message').show();
        $('#change-camera-2').find('#upload-stream').hide();
        $('#change-camera-2').find("#retry-camera").hide();
        $('#retry-group').hide();

    }
};

