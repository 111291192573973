module.exports = {
    enUS: {
        new_group: "New Team",
        profile: "Profile",
        settings: "Settings",
        logout: "Log out",
        search_or_start_newChat: "Search...",
        add_group_participants: "Add team participants",
        add_broadcast_title: "Add Broadcast participants",
        type_contact_name: "Search my site contacts",
        your_name: "Your Name",
        your_email: "Email",
        your_mobile: "Mobile Number",
        status: "Status",
        name_advise: "This is your default profile name. You can change whenever you need",
        view_photo: "View Photo",
        take_photo: "Take Photo",
        upload_photo: "Upload Photo",
        remove_photo: "Remove Photo",
        language: "Language",
        blocked: "Blocked",
        blocked_contact: "Blocked Contacts",
        no_blocked_contacts_yet: "No blocked contacts yet",
        help: "Help",
        logout_confirmation: "Are you sure want to logout?",
        cancel: "Cancel",
        new_chat: "New Message",
        new_broadcast_chat: "Create Broadcast",
        search_people: "Search my site contacts",
        search_sites: "Search contact or site name",
        contact_info: "Contact info",
        other_details: "Other details",
        broadcast_info: "Broadcast info",
        select_messages: "Select messages",
        clear_messages: "Clear messages",
        delete_chat: "Delete chat",
        group_info: "Team info",
        exit_group: "Leave Team",
        delete_broadcast: "Delete Broadcast",
        delete_group: "Delete team",
        photos_and_videos: "Photos & videos",
        camera: "Camera",
        document: "Document",
        media_link_docs: "Media and Docs",
        participants: "Participants",
        add_participants: "Add Participants",
        remove: "Remove",
        make_group_admin: "Make team admin",
        group_admin: "Admin",
        exit: "Exit",
        message_info: "Message info",
        forward: "Forward",
        delete: "Delete",
        forward_msg_to: "Forward messages to",
        recently_contacted: "Recently Contacted",
        contacts: "Contacts",
        roles: "Roles",
        phonecontacts: "Contacts",
        emailcontacts: "Email Contacts",
        adjust_the_camera: "Adjust the camera",
        main_text_placeholder: "Start typing...",
        about_and_phoneno: "About and phone number",
        about_and_email: "About and email",
        no_media_text: "No Media and Docs",
        read_by: "Read by",
        delivered_to: "Delivered to",
        remaining: "remaining",
        Remaining: "Remaining",
        block_contact: "Block Contact",
        unblock_contact: "Unblock Contact",
        unblock: "Unblock",
        block: "Block",
        clear: "Clear",
        make: "Make",
        loginretry: "Retry",
        make_sure_internet_connection: "Make sure your computer has an active Internet connection",
        close: "Close",
        mismatch_credentials: "Your credentials do not match our records.",
        clear_chat_with: "Clear chat with ",
        delete_chat_with: "Delete chat with",
        no_contacts_found: "No Contacts Found",
        group: "Team",
        broadcast: "Broadcast",
        attach: "Attachment",
        group_name: "Team Name",
        entergroup_name: "Enter Team Name",
        enterbroadcast_name: "Enter Broadcast Name",

        noroles_available: "No Roles Available",
        nocontact_available: "No Contacts Found",
        nosite_or_contacts_found: "No sites or contacts found",
        nosite_available: "No Sites Available",
        nosite_found: "No Sites Found",
        nochats_msg: "No chats, contacts or messages found",
        filesize_large: "Upload size is large",
        file_not_support: "File type or size is not valid.",
        one_user: "Select atleast one user",
        one_msg: "Select atleast one message",
        one_user_group: "Please select one User/Team to forward",
        addone_member: "Add atleast two members to create a new team",
        no_chats_contacts_messages_found: "No chats found",
        new_group_member_validate: "Add atleast one member to create new team",
        limit_exceeded: "Limit Reached. Cannot add more than 200 participants",
        only_pdf_allowed: "Only PDF documents are currently supported. Please select a PDF file.",
        menu: "Menu",
        emailRequired: "Email is required",
        passwordRequired: "Password is required",
        credentials: "Your credentials do not match our records",
        connectionFailed: "Connection Failed",
        connectinTimeout: "Connection Timeout",
        audioRecordingNotSupport: "Your browser doesn't have support for the navigator.getUserMedia interface",
        audioRecordingStart: "Audio recording start",
        nothingToUpload: "Nothing to upload",
        webAudioNotSupport: "No web audio support in this browser!",
        blockUserSuccess: "block user successfully",
        unblockUserSuccess: "unblock user successfully",
        receiveMessageCarbon: "Receive message carbon",
        receiveMessage: "Receive message",
        groupMemberRemove: "Team member remove",
        makeAdmin: "Make Admin",
        gone: "gone",
        clearMessage: "Clear message successfully",
        noChat: "No chat",
        groupNameUpdate: "Team name update successfully",
        getGroupSuccess: "Get Team successfully",
        addMemberToGroup: "add member to the team successfully",
        exitsGroup: "exits team successfully",
        makeAdminSuccess: "Make admin successfully",
        removedMemberFromGroup: "Removed member from team",
        profileStatusEdited: "Profile status edited",
        notSendMessageBlockUser: "do not send message to block user",
        messageSendSuccess: "Message send successfuly",
        fileTypeNotSupported: "File type is not supported.",
        imageSizeValidation: "Image size should be 10 MB",
        imageUploadSuccess: "Image uploaded successfully",
        imageCaptureIssue: "You can’t take a photo because it looks like your computer doesn’t have a camera. Try connecting one or if you have one connected, try restarting your browser.",
        imageAdjust: "Drag the image to adjust",
        imageBrowserIssue: "Your browser doesn't have support for the navigator.getUserMedia interface.",
        cameraAdjust: "Adjust the camera",
        reply: "Reply",
        starred_msg:"Starred",
        star_message:"Star message",
        unstar_message:"Unstar message",
        no_favourite_message_yet: "No starred messages found",
        group_exit: "You cannot send message to this team because you are no longer a participant",
        priority_message: "PRIORITY MESSAGE",
        priority_recent: "PRIORITY",
        priority_reminder: "priority_reminder",
        chat_bg_starred_r: 234,
        chat_bg_starred_g: 234,
        chat_bg_starred_b: 234,
        get_ip_url: "https://api.ipify.org/?format=json",
        get_alias_conv_content: "You cannot send message to this chat",
        default_size_pagination: 50,
        noone_oncall: "Role not active",
        noone_active: "There is no Ikonix Connect user active in this role",
        you_are_active_oncall: "You are currently the active member",
        non_chatuser_oncall: "Role cannot be contacted using Ikonix Connect",
        isPendingGroupParticipant: "isPendingGroupParticipant",
        offline: "Offline",
        offline_color: "#ff0000",
        mailBody: "Hi Ikonix Technology, My email domain isn’t approved for access at the moment and I would like to understand Ikonix Connect. My email address is gopinath.r@contus.in. I look forward to hearing from you soon. Regards,",
        mailSubject: "Ikonix Technology Support",
        messageContent: "Your email address is not approved to log in. Would you like to send an email to the Ikonix Technology team so they can help you get started?",
        mailTo: "support@ikonixtechnology.com.au",
        location_id_list: "My Site Preferences",
        group_limit: 199,
        IV : "ddc0f15cc2c90fca",
        default_status: "Available",
        IosInviteLink: "https://apps.apple.com/us/app/ikonix-connect/id1541579001",
        andrioidInviteLink: "https://play.google.com/store/apps/details?id=au.com.ikonixtechnology.connect",
        default_oncall_pagination_size: 15,

    }
};
