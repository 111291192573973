require("./lib/audio/recorder.js");
let audioSetting = require('./settings.js')
let recordtime = "";
let recordtotaltime = "";
let timelineWidth,onplayhead,recorder,recordtimer,audio_stream;
module.exports = {
  pad: function (val) {
    return val > 9 ?
      val :
      "0" + val;
  },
  startRecording: function () {
    audioSetting.getSettings();
    try {
      // Monkeypatch for AudioContext, getUserMedia and URL
      clearTimeout(recordtotaltime);
      window.AudioContext = window.AudioContext || window.webkitAudioContext;
      navigator.getMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
      if (!navigator.getMedia) {
        displayErrorMessage("Audio permission should be enabled.", true);
      } else {
        // Store the instance of AudioContext globally
        let audio_context = new AudioContext();
        $(".reply-recording").hide();
        // Access the Microphone using the navigator.getUserMedia method to obtain a stream
        navigator.getMedia({
          audio: true
        }, function (stream) {
          let sec = 0;
          $(".reply-recording").hide();
          $("#audio-pop").show();
          let settings = JSON.parse(localStorage.getItem("settings"));
          recordtimer = setInterval(function () {
            let second = module.exports.pad(++sec % 60)
            let mints = module.exports.pad(parseInt(sec / 60, 10))
            recordtime = mints+ ":" + second;

            $("#live-time").html(recordtime);
          }, 1000);
          recordtotaltime = setTimeout(function () {
            clearInterval(recordtimer);
            recorder && recorder.stop();
          }, settings.audioLimit * 1000);
           // Expose the stream to be accessible globally
           audio_stream = stream;
          // Create the MediaStreamSource for the Recorder library
          let input = audio_context.createMediaStreamSource(stream);
          console.log("Media stream succesfully created");
          // Initialize the Recorder Library
          recorder = new Recorder(input, "audio/mp3");
          console.log("Recorder initialised");
          // Start recording !
          recorder && recorder.record();
          console.log("Recording...");
          $('.reply-box').addClass("disabledDiv");
        }, function (e) {
          $(".reply-main").removeClass("small");
          $(".reply-recording").show();
          $("#audio-pop").hide();
          $("#infoContent").text("No live audio input in this browser!");
          $('#infobox').css("display", "block").delay(4000).fadeOut();
          $('.reply-box').removeClass("disabledDiv");
        });
      }
    } catch (e) {
      $(".reply-main").removeClass("small");
      $(".reply-recording").show();
      $("#audio-pop").hide();
      $("#infoContent").text("No web audio support in this browser!");
      $('#infobox').css("display", "block").delay(4000).fadeOut();
      $("#infobox").css("display", "block");
      $('.reply-box').removeClass("disabledDiv");
    }
    function displayErrorMessage(error_msg, error) {
      MediaStream = "";
      error = error || "";
      if (error) {
        console.log(error_msg);
      }
      $(".reply-main").removeClass("small");
      $(".reply-recording").show();
      $("#audio-pop").hide();
      $("#infoContent").text("No live audio input in this browser!");
      $('#infobox').css("display", "block").delay(4000).fadeOut();
    }
  },
  stopRecording: function (callback, AudioFormat) {
    // Stop the recorder instance
    $(".reply-recording").show();
    $("#audio-pop").hide();
    if (typeof recorder != "undefined") {
      recorder && recorder.stop();
      console.log("Stopped recording.");
      clearInterval(recordtimer);
      // Stop the getUserMedia Audio Stream !
      audio_stream.getAudioTracks()[0].stop();
      // Use the Recorder Library to export the recorder Audio as a .wav file
      // The callback providen in the stop recording method receives the blob
      if (typeof callback == "function") {
        /**
         * Export the AudioBLOB using the exportWAV method.
         * Note that this method exports too with mp3 if
         * you provide the second argument of the function
         */
        recorder && recorder.exportWAV(function (blob) {
          console.log("record time" + recordtime);
          callback(blob, recordtime);
          // Clear the Recorder to start again !
          recorder.clear();
        }, AudioFormat || "audio/mp3");
      }
    }
  },
  audioPauseAndPlay: function(value,audioId,id){
    if (audioId != "") {
      let result = audioId.split("-");
      if ($("#" + result[0] + "-pButton").length) {
        if ($("#" + result[0] + "-pButton").hasClass("pause")) {
          if (id != result[0]) {
            value.pause();
            $("#" + result[0] + "-pButton").removeClass("pause").addClass("play");
          }
        }
      }
    }
  },
  musicPlayAndPause: function(id,music,tracktime){
    if ($("#" + id + "-pButton").hasClass("play")) {
      try {
        let  myInfo = JSON.parse(localStorage.getItem("myStatus"));
        let myStatus = typeof myInfo !== 'undefined'?myInfo:1;
        if(myStatus !=0){
          music.play();
          $("#" + id + "-pButton").removeClass("play").addClass("pause");
          tracktime.innerHTML = "00:00";
        }
      } catch (e) {
        console.log("Error playing file!");
      }
    } else {
      music.pause();
      // remove pause, add play     
      $("#" + id + "-pButton").removeClass("pause").addClass("play");
    }
  },
  mousePlayHeadSeperateFunction: function(event,timeline,timelineWidth,playhead){
    let newMargLeft = event.clientX - getPosition(timeline);
    if (newMargLeft >= 0 && newMargLeft <= timelineWidth) {
      playhead.style.marginLeft = newMargLeft + "px";
    }
    if (newMargLeft < 0) {
      playhead.style.marginLeft = "0px";
    }
    if (newMargLeft > timelineWidth) {
      playhead.style.marginLeft = timelineWidth + "px";
    }
  },
  audioDesign: function (id) {
    let music = document.getElementById(id + "-music"); // id for audio element
    let duration = music.duration; // Duration of audio clip, calculated here for embedding purposes
    let pButton = document.getElementById(id + "-pButton"); // play button
    let playhead = document.getElementById(id + "-playhead"); // playhead
    let timeline = document.getElementById(id + "-timeline"); // timeline
    let tracktime = document.getElementById(id + "-tracktime"); // timeline
    let audioId;
    $("#box").html(duration);
    $("audio").each(function () {
      audioId = this.id;
      module.exports.audioPauseAndPlay(this,audioId,id);
    });
      module.exports.musicPlayAndPause(id,music,tracktime);

      // timeline width adjusted for playhead
    timelineWidth = timeline.offsetWidth - playhead.offsetWidth;
    // timeupdate event listener
    music.addEventListener("timeupdate", timeUpdate, false);
    music.addEventListener("ended", function (event) {
      playhead.style.marginLeft = "0px";
      music.pause();
      // remove pause, add play
      $("#" + id + "-pButton").removeClass("pause").addClass("play");
    });
    // makes timeline clickable
    timeline.addEventListener("click", function (event) {
      moveplayhead(event);
      music.currentTime = duration * clickPercent(event);
    }, false);
    // returns click as decimal (.77) of the total timelineWidth
    function clickPercent(event) {
      return (event.clientX - getPosition(timeline)) / timelineWidth;
    }
    // makes playhead draggable
    playhead.addEventListener("mousedown", mouseDown, false);
    window.addEventListener("mouseup", mouseUp, false);
    // Boolean value so that audio position is updated only when the playhead is released
    onplayhead = false;
    // mouseDown EventListener
    function mouseDown() {
      onplayhead = true;
      window.addEventListener("mousemove", moveplayhead, true);
      music.removeEventListener("timeupdate", timeUpdate, false);
    }
    // mouseUp EventListener
    // getting input from all mouse clicks
    function mouseUp(event) {
      if (onplayhead) {
        moveplayhead(event);
        window.removeEventListener("mousemove", moveplayhead, true);
        // change current time
        music.currentTime = duration * clickPercent(event);
        music.addEventListener("timeupdate", timeUpdate, false);
      }
      onplayhead = false;
    }
    // mousemove EventListener
    // Moves playhead as user drags
    function moveplayhead(event) {

      module.exports.mousePlayHeadSeperateFunction(event,timeline,timelineWidth,playhead);
    }
    // timeUpdate
    // Synchronizes playhead position with current point in audio
    function timeUpdate() {
      let playPercent = timelineWidth * (music.currentTime / duration);
      let Playingtime = Math.floor(music.currentTime);
      if (Playingtime != 0) {
        let min,sec;
        let time = parseInt(Playingtime, 10);
        let minutes = Math.floor(time / 60);
        min = minutes < 10 ?
          "0" + minutes :
          minutes;
        let seconds = time % 60;
        sec = seconds < 10 ?
          "0" + seconds :
          seconds;
        tracktime.innerHTML = min + ":" + sec;
      }
      playhead.style.marginLeft = playPercent+15 + "px";
      if (music.currentTime == duration) {
        pButton.className = "";
        pButton.className = "play pButton";
      }
    }
    // Gets audio file duration
    music.addEventListener("canplaythrough", function () {
      duration = music.duration;
    }, false);
    // getPosition
    // Returns elements left position relative to top-left of viewport
    function getPosition(el) {
      return el.getBoundingClientRect().left;
    }
    window.addEventListener("load", function () {
      document.body.removeChild(chat);
    });
  }
};
