let utils = require("./utils.js");
let stropheConnection = require('./stropheConnection.js');
let fileUploadmediaUpload = require('./mediaupload.js');
let fileUploadgroupProfile = require('./groupProfile.js');
let fileUploadstorage = require('./storage.js');
let fileUploadlanguage = require('./locale/language.js');
let mediaUrl = window._env_.ApiURL + "/media/";
let MediaStream;

module.exports = {
    myProfileInfo: function () {
        return JSON.parse(localStorage.getItem("myInfo"));
    },
    profileUploadToS3: function (file, popupId) {
        let mediaUrl = window._env_.ApiURL + "/media/";
        $('#loader_upload').css("display",'block');
        $('.retry').css("display", "block");
        //need to do validation for status
        if (file) {
            let user = stropheConnection.logininfo.userJID;
            let fileLocation = fileUploadmediaUpload.fileUpload(user, 'profile', file);
            let userToken = fileUploadstorage.getuserToken();
            if(fileLocation != ''){
                $('.loader').css("display", "none");
                $('.profile-image').attr('src', mediaUrl+ fileLocation+"?mf="+userToken);
                $('.profile-image').attr('data-imageid', fileLocation);
                utils.imageTokenRegenerate(mediaUrl+ fileLocation+"?mf="+userToken,'#leftSideProfileInfoImgId',user,'profileImages');

                
                $('.profileImageParentEl img').show();
                $('.profileImageParentEl svg').hide();
                $('.headingProfileDiv img').show();
                $('.headingProfileDiv svg').hide();

                $('#profilepicturepick li:last-child').show();
                $('#profilepicturepick li:first-child').show();
                utils.closeProfilePopUp(popupId);
            }else{
                $('.loader').css("display", "none"); 
                $(".image-cropped").css("display", "block");
                $('.retry').css("display", "block");
                $("#infoContent").text("Error in uploading a image");
                $('#infobox').css("display", "block").delay(4000).fadeOut();
            }
        } else {
            $('.loader').css("display", "none"); 
            $(".image-cropped").css("display", "block");
            $('.retry').css("display", "block");
            results.innerHTML = fileUploadlanguage.locali().nothingToUpload;
        }
    },
    GroupProfileUploadToS3: function (file, popupId) {
        $('#loader_upload').css("display",'none');
        if (file) {
            let groupId = stropheConnection.Strophe().getNodeFromJid(JSON.parse(localStorage.getItem("toJID")));
            let groupProfileData = JSON.parse(localStorage.getItem("groupProfile"));
            let groupName = groupProfileData[groupId][1];

            let fileLocation = fileUploadmediaUpload.fileUpload(groupId, 'profile', file);
            if(fileLocation != ''){
                fileUploadgroupProfile.setGroupInfo(groupName, fileLocation);
                $('.loader').css("display", "none");                
                $(".image-cropped").css("display", "block");
                $('.retry').css("display", "block");
                utils.closeProfilePopUp(popupId);
            }else{
                $('.loader').css("display", "none"); 
                $(".image-cropped").css("display", "block");
                $('.retry').css("display", "block");
                $("#infoContent").text("Error in uploading a image");
                $('#infobox').css("display", "block").delay(4000).fadeOut();
            }
        } else {
            $('.loader').css("display", "none"); 
            $(".image-cropped").css("display", "block");
            $('.retry').css("display", "block");
            results.innerHTML = fileUploadlanguage.locali().nothingToUpload;
        }
    },
    groupprofileupdate: function () {
        $('#group-pro-camera').click(function () {
           let videoDiv = "<video id = 'group-camera-stream' style='width: 100%;'></video><p id = 'error-message'></p><a id='group-pro-select' class='image-cropped'><i><svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'><path d='M0 0h24v24H0z' fill='none'/><circle cx='12' cy='12' r='3.2' fill='#f07000'/><path d='M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z' fill='#f07000'/></svg></i></a><canvas> </canvas>";
            $('#change-camera').html(videoDiv);
            $('#camerapic').css('visibility', 'visible');
            $('#camerapic').css('opacity', '1');
            $("#camerapic h3").text(fileUploadlanguage.locali().cameraAdjust);
            $('#camerapic .close').click(function () {
                if (typeof MediaStream != "undefined" && MediaStream != '') {
                    MediaStream.stop();
                }
                $('#camerapic').css('visibility', 'hidden');
                $('#camerapic').css('opacity', '0');
            });
            $('#retry-camera').show();
            let video = document.querySelector('#group-camera-stream');
            navigator.getMedia = (navigator.getUserMedia ||
                navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia ||
                navigator.msGetUserMedia);
            if (!navigator.getMedia) {
                module.exports.displayErrorMessage("Camera permission should be enabled.", true);
            } else {
                $('#error-message').hide();
                $('#group-camera-stream').show();
                triggerImageSelect();
                // Request the camera.
                navigator.getMedia({
                    video: true
                },
                    // Success Callback
                    function (stream) {
                        // Create an object URL for the video stream and
                        // set it as src of our HTLM video element.
                        video.srcObject = stream;
                        // Play the video element to start the stream.
                        video.play();

                        MediaStream = stream.getTracks()[0];
                        video.onplay = function () {
                        };
                    },
                    // Error Callback
                    function (err) {
                        module.exports.displayErrorMessage("Camera permission should be enabled.");
                    }
                );
            }
        });

        function triggerImageSelect() {
            $('#group-pro-select').click(function () {
                let output = takeSnapshot();
                if (typeof MediaStream != "undefined" && MediaStream != '') {
                    MediaStream.stop();
                }
                let ImgDiv = "<a id='group-img-crop' class='image-cropped'><i> <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 30 30'> <g id='Tick_icon' data-name='Tick icon' transform='translate(-10856 17056)'> <rect id='Rectangle_16501' data-name='Rectangle 16501' width='24' height='24' transform='translate(10856 -17056)' fill='none'/> <path id='Path_149878' data-name='Path 149878' d='M101.546,57.688,99.3,55.445a1.625,1.625,0,0,0-2.324,0L83.791,68.634,78.43,63.273a1.625,1.625,0,0,0-2.324,0l-2.242,2.242a1.624,1.624,0,0,0,0,2.324L82.629,76.6a1.625,1.625,0,0,0,2.324,0l16.593-16.593a1.624,1.624,0,0,0,0-2.324Z' transform='translate(10783.295 -17107.023)' fill='#ee7020'/> </g> </svg></i></a>";
                $('#change-camera').html(ImgDiv);

                $("#camerapic h3").text(fileUploadlanguage.locali().imageAdjust);

                let el = document.getElementById('change-camera');
                let vanilla = new Croppie(el, {
                    viewport: {
                        width: 300,
                        height: 300,
                        type: 'square'
                    },
                    boundary: {
                        width: 300,
                        height: 300
                    },
                    showZoomer: true,
                });
                vanilla.bind({
                    url: output,
                });
                //on button click
                $("#group-img-crop").click(function () {
                    $('.loader').css("display", "block");
                    vanilla.result('blob').then(function (blob) {
                       let file = new File([blob], "group-img.webp");
                        module.exports.GroupProfileUploadToS3(file, 'popup2');
                        utils.closeProfilePopUp('camerapic');
                    });
                });
                $(".closecamerapic").click(function () {
                    utils.closeProfilePopUp('camerapic');
                });
            });
        }

        function takeSnapshot() {
            // Here we're using a trick that involves a hidden canvas element.  
            let video = document.querySelector('#group-camera-stream');
            let hidden_canvas = document.querySelector('canvas');
            context = hidden_canvas.getContext('2d');
            let width = video.videoWidth,
                height = video.videoHeight;
            if (width && height) {
                // Setup a canvas with the same dimensions as the video.
                hidden_canvas.width = width;
                hidden_canvas.height = height;
                // Make a copy of the current frame in the video on the canvas.
                context.drawImage(video, 0, 0, width, height);
                // Turn the canvas image into a dataURL that can be used as a src for our photo.
                return hidden_canvas.toDataURL('image/png');
            }
        }
    },
    compress: function (source_img_obj, quality, maxWidth, output_format) {
        let mime_type = "image/jpeg";
        if (typeof output_format !== "undefined" && output_format == "png") {
            mime_type = "image/png";
        }
        maxWidth = maxWidth || 1000;
        let natW = source_img_obj.naturalWidth;
        let natH = source_img_obj.naturalHeight;
        let ratio = natH / natW;
        if (natW > maxWidth) {
            natW = maxWidth;
            natH = ratio * maxWidth;
        }
        let cvs = document.createElement('canvas');
        cvs.width = natW;
        cvs.height = natH;
        let newImageData = cvs.toDataURL(mime_type, quality / 100);
        let result_image_obj = new Image();
        result_image_obj.src = newImageData;
        return result_image_obj;
    },
    groupImageUpload: function (file, popupId) {

        if (file) {
            let fileLocation = fileUploadmediaUpload.fileUpload('', 'profile', file);
            let userToken = fileUploadstorage.getuserToken();
            if(fileLocation != ''){
                let tempStorage = JSON.parse(localStorage.getItem("temp"));
                if (tempStorage) {
                    tempStorage.groupImage = fileLocation;
                    localStorage.setItem("temp", JSON.stringify(tempStorage));
                } else {
                    let temp = {};
                    temp.groupImage = fileLocation;
                    localStorage.setItem("temp", JSON.stringify(temp));
                }
                $(".add-icon").html('');
                $('.group-imageinputarea').attr('src', mediaUrl+ fileLocation+"?mf="+userToken);
                $('.loader').css("display", "none");
                $(".image-cropped").css("display", "block");
                $('.retry').css("display", "block");
                utils.closeProfilePopUp(popupId);
                return fileUploadlanguage.locali().imageUploadSuccess;
            }else{
                $("#infoContent").text("Error in uploading a image");
                $('#infobox').css("display", "block").delay(4000).fadeOut();
                $('.loader').css("display", "none");
                $(".image-cropped").css("display", "block");
                $('.retry').css("display", "block");
            }
        } else {
            $('.loader').css("display", "none");
            $(".image-cropped").css("display", "block");
            $('.retry').css("display", "block");
            results.innerHTML = fileUploadlanguage.locali().nothingToUpload;
        }
    },
    displayErrorMessage: function (error_msg, error) {
        MediaStream = '';
        error = error || "";
        if (error) {
            console.log(error_msg);
        }

        $('#error-message').html(error_msg);
        $('#group-camera-stream').hide();
        $('#error-message').show();
        $('#group-pro-select').hide();


    }
};
